import { map, take, throttleTime } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TabsData } from '@flexus/ui-elements';
import { AddressService, BigFormService } from '@flexus/core';
import { asyncScheduler, of, Observable, Subscription } from 'rxjs';
import { CustomValidators } from '@flexus/utilities';
import { UntypedFormControl, Validators } from '@angular/forms';
export { } from 'googlemaps';

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'flx-policy-details',
	templateUrl: './policy-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolicyDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() commsOptions;
	@Input() isMC = false;
	// @Input() commMethodDropdowns;
	provinceList: any[] = [];
	@Input() sourceToFormMapper;
	currentTab$: Observable<string> = of('house_estate');
	policyToFormSub: Subscription;
	complexFormSub: Subscription;
	script: any;
	cityFieldSubscription: Subscription;

	constructor(public _store: Store<any>, public bf: BigFormService, @Inject('environment') private environment: any, private cd: ChangeDetectorRef, private addressService: AddressService) {
		this.bf.bigForm.addControl('address_type', new UntypedFormControl('house_estate'));

		bf.bigForm
			.get('address_type')
			.valueChanges.pipe(
				map(addressType => {
					if (addressType === 'house_estate') {
						bf.bigForm.get(`insured_address`).patchValue({
							complex_unit_number: '',
							complex_block: ''
						});
					}
				})
			)
			.subscribe();
	}

	tabs: TabsData[] = [
		{
			display: 'House/Estate',
			targetId: 'house_estate',
			show: true
		},
		{
			display: 'Flat/Office',
			targetId: 'flat_office',
			show: true
		}
	];

	ngOnInit() {
		const provinceList = this.bf?.storeObject?.selectedContext?.provinceList;
		this.provinceList = provinceList?.map(province => ({ display: province?.name, value: province?.name, id: province?.id }));
		this.isMC = this.environment.client === 'mul';
	}

	ngAfterViewInit() {
		if (this.bf.bigForm?.value?.currentPolicyDetails) {
			this.policyToFormSub = of(this.bf.bigForm?.value?.currentPolicyDetails).subscribe(policyDetails => {
				if (policyDetails) {
					// TODO trim details so no spaces are patched at the end of the inputs
					// const trimedDetails = Object.keys(policyDetails).map(k => policyDetails[k] = policyDetails[k].trim());
					if (!this.bf.bigForm?.value?.previousPolicyDetails) {
						// console.log({ ChangedPolicyValue: policyDetails });
						this.patchPolicyToForm(policyDetails);
						this.bf.patchValues({ previousPolicyDetails: policyDetails });
					} else if (this.bf.bigForm?.value?.previousPolicyDetails && this.bf.bigForm?.value?.previousPolicyDetails.ACCOUNTNUMBER !== policyDetails.ACCOUNTNUMBER) {
						// console.log({ ChangedPolicyValue: policyDetails });
						this.patchPolicyToForm(policyDetails);
						this.bf.patchValues({ previousPolicyDetails: policyDetails });
					}
				}
			});
			this.validateCityField();
			this.cityFieldSubscription = this.bf.bigForm
				?.get('insured_address')
				?.get('city')
				.valueChanges.pipe(throttleTime(4000, asyncScheduler, { leading: false, trailing: true }))
				.subscribe(() => this.validateCityField());
		} else {
			// TODO:
			// this._store
			//   .select(getData)
			//   .pipe(
			//     map((data) => data && data.policies),
			//     take(1),
			//     map((items) => items[this.bf.bigForm.value.policy_number.trim()]),
			//   )
			//   .subscribe((policyDetails) => {
			//     if (policyDetails) {
			//       if (!this.bf.bigForm.value.previousPolicyDetails) {
			//         console.log({ ChangedPolicyValue: policyDetails });
			//         this.patchPolicyToForm(policyDetails);
			//         this.bf.patchValues({ previousPolicyDetails: policyDetails });
			//       } else if (
			//         this.bf.bigForm.value.previousPolicyDetails &&
			//         this.bf.bigForm.value.previousPolicyDetails.ACCOUNTNUMBER !== policyDetails.ACCOUNTNUMBER
			//       ) {
			//         console.log({ ChangedPolicyValue: policyDetails });
			//         this.patchPolicyToForm(policyDetails);
			//         this.bf.patchValues({ previousPolicyDetails: policyDetails });
			//       }
			//     }
			//   });
		}

		this.complexFormSub = this.bf.bigForm.get('address_type')?.valueChanges.subscribe(addressType => {
			const complexUnitNumberControl = this.bf.bigForm.get('insured_address')?.get('complex_unit_number');
			// TODO: jb
			if (complexUnitNumberControl) {
				const complexUnitNumberValidators = [CustomValidators.hardMaxLength(64)];
				complexUnitNumberControl.setErrors(null);
				complexUnitNumberControl.setValidators(addressType === 'flat_office' ? [...complexUnitNumberValidators, Validators.required] : complexUnitNumberValidators);
			}
		});

		setTimeout(() => this.cd.detectChanges(), 500);
	}

	handleTab(tabInfo) {
		this.bf.bigForm.get('address_type').setValue(tabInfo.target);
		this.currentTab$ = of(tabInfo.target);
	}

	validateCityField() {
		this.addressService.validateAddressFormControl(this.bf.bigForm?.get('insured_address')?.get('city'));
	}

	patchPolicyToForm(policyDetails) {
		if (window.navigator.onLine && this.sourceToFormMapper) {
			this.bf.patchToForm(of(policyDetails), this.sourceToFormMapper).pipe(take(1)).subscribe();
		}
	}

	setArea(event: string): void {
		const area = this.provinceList.find(province => province?.value === event)?.id;
		if (!this.bf?.bigForm?.get('area')) return this.bf.bigForm.addControl('area', new UntypedFormControl(area ?? '', Validators.required));
		this.bf.bigForm.patchValue({ area });
	}

	ngOnDestroy() {
		if (this.policyToFormSub) this.policyToFormSub?.unsubscribe();
		if (this.complexFormSub) this.complexFormSub?.unsubscribe();
		this.cityFieldSubscription?.unsubscribe();
	}
}
