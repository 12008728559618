import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { environment } from 'apps/studio/src/environments/environment';
import { map, skipWhile, take } from 'rxjs/operators';
import {
  CollapseActionPanel,
  setActionPanelItems,
} from '../../../app-shell-features';
import {
  AUTO_SELECT_TEMPLATE_DECISION,
  SELECT_CALLOUT_TEMPLATE,
  billingServerCalls,
} from './BILLING';

export const SP_GLOBAL_80: Flow_0_0_2 = {
  id: '80',
  name: 'job_canceled_by_customer_sp',
  itemType: 'flow',
  actionPanel: (instance) =>
    setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
  onStateInit: (inst) => {
    inst.store.dispatch(new CollapseActionPanel());
  },
  onStateDestroy: (inst) => {
    inst.store.dispatch(new CollapseActionPanel());
  },
  header: {
    title: (store) => {
      return store.select(getFullItemTwo).pipe(
        map((itemTwo) => {
          const { claim } = itemTwo || {};
          const { applicant, loan_information, mid } = claim || {};
          if (applicant && loan_information) {
            return `No work - Customer cancelled : ${mid} - ${applicant.surname}`;
          }
          return 'No work - Customer cancelled';
        })
      );
    },
    controls: () => () => [],
  },
  footer: {
    type: 'node_nav',
  },
  instructions: {
    editRoles: {
      0: '--',
    },
    viewRoles: {
      0: 'Job cancelled by customer',
    },
  },
  serverCalls: {
    skills: {
      serviceVariable: 'spService',
      functionName: 'getSkills',
      responseSlice: 'skills',
      errorMessage: 'No skills were found!',
    },
    keyValueListForSummary: {
      errorMessage: '',
      directCall: (http, store) => {
        return store.select(getFullItemTwo).pipe(
          skipWhile((x) => !x),
          take(1),
          map((job) => {
            const { claim } = job || {};
            const { applicant, loan_information, type, address } = claim || {};
            const clientName = applicant
              ? `${applicant.first_name} ${applicant.surname}`
              : '';
            const contactNumber = loan_information?.contactnumber || '';
            const clientAltNo = loan_information?.cellnumber || '';

            const list = {
              'Claim Type': type || '',
              'Client Name': clientName,
              'Contact number': contactNumber,
              'Client Alternative no': clientAltNo,
              Address: address || '',
            };

            return [list];
          })
        );
      },
    },
    ...billingServerCalls,
  },
  startNode: 'Summary',
  nodes: {
    Summary: {
      component: {
        children: [
          {
            component: 'FLXKeyValueDisplayWithInstructionsComponent',
            inputs: {
              title: 'Job cancelled by customer',
              instructions: ['No work was done. Invoice a call out fee'],
              keyValueList$: 'keyValueListForSummary',
              title$: 'title',
            },
          },
        ],
      },
      navs: [
        {
          text: 'Continue',
          nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
        },
      ],
    },
    AUTO_SELECT_TEMPLATE_DECISION,
    SELECT_CALLOUT_TEMPLATE,
    BILLING_INVOICE: {
      hideTabItem: true,
      component: 'BillingComponent',
      checkValidityForFields: [
        'invoiceDate',
        'invoiceNumber',
        'actualLineItemsFormArray',
      ],
      inputs: {
        docType: 'invoice',
        numberLabel: 'Invoice',
        currentState: 80,
        newState: 39,
        showLineItemGenerator: true,
        canEditLineItems: true,
        boqLogo:
          environment.client === 'bet_sp'
            ? 'assets/images/boq-bettersure-logo.svg'
            : 'assets/images/boq-sil-logo.svg',
        boqLogoAlt:
          environment.client === 'bet_sp'
            ? 'Bettersure'
            : 'Standard Bank Insurance Limited',
        clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL',
      },
      navs: [
				{
					text: 'Save Draft',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						draftQuote: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating draft invoice!', serviceVariable: 'spService', functionName: 'generateDraftInvoice' }
							}
						}
					}
				},
        {
          text: 'Generate Invoice',
          nextNode: 'SubmissionSuccess',
          location: 'right',
          linkType: 'submit',
          optIntoValidation: true,
          serverFirst: true,
          serverCalls: {
            postInvoice: {
              errorMessage:
                'An error occurred while trying to create or update BOQ!',
              serviceVariable: 'spService',
              functionName: 'createOrUpdateBOQ',
              followUpSuccessCalls: {
                response: {
                  errorMessage:
                    'An error occurred when generating quote/invoice!',
                  serviceVariable: 'spService',
                  functionName: 'generateBoqQuoteOrInvoice',
                },
              },
            },
          },
        },
      ],
    },
    SubmissionSuccess: { component: 'FLXSuccessTickComponent' },
  },
  bigFormToStoreMapper: {
    new_state: [
      () => {
        return 70;
      },
      'new_state',
    ],
    total: [
      (a, b, bf) => {
        return bf.total + bf.vatRate;
      },
      'job_information.quote_amount',
    ],
    invoice_number: 'job_information.quote_number',
  },
};
