import { Renderer2, ChangeDetectorRef } from '@angular/core';
import { Manifest_0_0_2 } from '@flexus/core';
import { ItemTwo_Permission_0_0_2 } from '@flexus/core';
import moment from 'moment';
import { findName, humaniseDate, humaniseSeconds } from '@flexus/utilities';
import { interval } from 'rxjs';
import { jobStateLookupTable } from 'apps/studio/src/app/sp_globals/models/indicator-lookup.model';

export const jobPermissions: ItemTwo_Permission_0_0_2 = {
  show_default_button: (
    job: any,
    claim: any,
    currentUser: any,
    renderer: Renderer2,
    domElement: HTMLElement,
    activeOrg: Manifest_0_0_2,
    allInfo,
    instance
  ) => {
    const stateFlow = activeOrg.manifestItems[job.state];
    const instructions =
      stateFlow?.itemType === 'flow' && stateFlow?.instructions;
    const editStates = currentUser?.user?.edit_states;
    const userCanEdit = editStates && editStates.includes(job.state);
    const userRole = currentUser?.user?.roles[0];
    const instructionText = userCanEdit
      ? (instructions && instructions.editRoles[userRole]) ||
        (instructions && instructions.editRoles[0]) ||
        ''
      : (instructions && instructions.viewRoles[userRole]) ||
        (instructions && instructions.viewRoles[0]) ||
        '';
    const instructionHolder = domElement
      .getElementsByClassName('take-action')
      .item(0);
    const span = renderer.createElement('span');
    const spanText = renderer.createText(instructionText);
    renderer.appendChild(span, spanText);
    renderer.appendChild(instructionHolder, span);
  },
  get_indicator_color: (
    job: any,
    claim: any,
    user: any,
    renderer: Renderer2,
    domElement: HTMLElement,
    activeOrg: Manifest_0_0_2,
    allInfo,
    instance
  ) => {
    const jobElement = domElement.getElementsByClassName('job').item(0);
    const state = +job?.state ?? undefined;
    const editRoles: number[] = user?.user?.edit_states;
    const color =
      state && editRoles?.includes(state) ? jobStateLookupTable[state] : 'grey';

    if (!editRoles.includes(state)) {
      jobElement?.classList?.add('disabled');
    }
    instance.indicator.color = color;
  },

  display_sla_time: (
    job,
    claim,
    currentUser: any,
    renderer,
    domElement: HTMLElement,
    activeOrg,
    allInfo,
    instance
  ) => {
    const appendTime = () => {
      const slaTimeHolder = domElement
        .getElementsByClassName('sla-timer')
        .item(0);
      if (slaTimeHolder)
        slaTimeHolder?.childNodes.forEach((node) => node?.remove());
      const spanEl = renderer.createElement('span') as HTMLScriptElement;
      const currentSLA = allInfo?.states
        ?.map(({ id, sla_time }) => ({ id, sla_time }))
        ?.reduce((acc, { id, sla_time }) => ({ ...acc, [id]: sla_time }), {})[
        job.state
      ];
      const actualTime = moment().diff(moment(job?.state_change_date), 's');
      if (actualTime) {
        const setColor = () => {
          const result = (actualTime / currentSLA) * 100;
          if (result <= 80) {
            renderer.setStyle(spanEl, 'color', 'grey');
          } else if (result >= 81 && result <= 100) {
            renderer.setStyle(spanEl, 'color', '#DAA520');
          } else if (result >= 101) {
            renderer.setStyle(spanEl, 'color', 'red');
          }
        };
        const transform = (sec: number) => {
          return humaniseSeconds(sec);
        };
        setColor();
        const slaTime = transform(actualTime)?.toString();
        const timerText = renderer.createText(slaTime);
        renderer.appendChild(spanEl, timerText);
        if (slaTimeHolder) renderer.appendChild(slaTimeHolder, spanEl);
      }
    };
    // _cdr.detectChanges();
    appendTime();
    return interval(60000).subscribe(() => {
      appendTime();
    });
  },
  calculate_appointment_time: (
    job: any,
    claim: any,
    user: any,
    renderer: Renderer2,
    domElement: HTMLElement,
    activeOrg: Manifest_0_0_2,
    allInfo,
    instance
  ) => {
    if (allInfo) {
      const appointmentInfoEl = domElement
        .getElementsByClassName('appointment-info')
        .item(0);
      const appointment_type =
        allInfo.appointment_types &&
        job?.appointment &&
        job?.appointment.appointment_type
          ? findName(
              job.appointment.appointment_type,
              allInfo.appointment_types
            ).name
          : '';
      let text = 'No Appointment';
      if (
        job.appointment &&
        job?.appointment.range_start !== null &&
        appointment_type !== ''
      ) {
        const startTime = moment(job.appointment.range_start).format('HH:mm');
        const endTime = job.appointment.range_end
          ? ` - ${moment(job.appointment.range_end).format('HH:mm')}`
          : '';
        text = `${humaniseDate(
          job.appointment.range_start
        )} ${appointment_type} ${startTime}${endTime}`;
      } else {
        if (appointmentInfoEl)
          appointmentInfoEl.setAttribute(
            'style',
            'color: var(--input-placeholder);'
          );
      }
      const appointment_text = renderer.createText(text);
      if (appointmentInfoEl)
        renderer.appendChild(appointmentInfoEl, appointment_text);
    }
  },
};
