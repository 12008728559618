import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Flow_0_0_2, getFullItemOne, getSubmissionData } from '@flexus/core';
import { CustomValidators, generateRange } from '@flexus/utilities';
import { environment } from '../../../../../../environments/environment';
import gql from 'graphql-tag';
import moment from 'moment';
import { flatten, values } from 'ramda';
import { forkJoin } from 'rxjs';
import { debounceTime, map, skipWhile, switchMap, take } from 'rxjs/operators';
import { NewJobPayload } from '../../../models/new-job.model';
import { BetCreateClaim } from '../../create-claim/bet-create-claim';
import {
  checkBusinessRules,
  transformJobsToJobCardRequest,
} from '../../create-claim/transform.functions';
import { BET_BANK_DETAILS } from '../../flows';
import { BET_CHANGE_CLAIM_CLASS } from './CHANGE_CLAIM_CLASS';

export const BET_ADD_NEW_JOB_CARD: Flow_0_0_2 = {
  id: 'AddNewJobCard',
  itemType: 'flow',
  name: 'Add New Job Card',
  excludeForOnlyStates: [169],
  excludeForOnlyRoles: [18],
  header: {
    title: (store) => {
      return store
        .select(getFullItemOne)
        .pipe(
          map(
            (itemOne) =>
              `Add new job card to ${
                itemOne
                  ? `this claim :  ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`
                  : 'claim'
              }`
          )
        );
    },
    controls: () => () => [],
  },
  footer: {
    type: 'node_nav',
  },
  startNode: 'ClaimDetails',
  instructions: {
    editRoles: {
      0: '',
    },
    viewRoles: {
      0: '',
    },
  },
  actionPanel: () => {
    return () => {
      return [{}];
    };
  },
  nodes: {
    ClaimDetails: {
      name: 'Claim Details',
      component: 'ClaimClassComponent',
      serverCalls: {
        ...BET_CHANGE_CLAIM_CLASS?.nodes?.ChangeClaimClass.serverCalls,
      },
      initFormFields: (bf, item, comp, sq, store) => {
        bf.bigForm.removeControl('jobs');
        BET_CHANGE_CLAIM_CLASS?.nodes?.ChangeClaimClass.initFormFields(
          bf,
          item,
          comp,
          sq,
          store
        );
      },
      inputs: {
        ...BET_CHANGE_CLAIM_CLASS?.nodes?.ChangeClaimClass.inputs,
        blockedLossClasses: [
          {
            class: 28,
            message: 'Cannot Add New Job Card',
            name: 'Home assist',
          },
        ], // Loss classes that cannot have a new job card added
      },
      checkValidityForFields: ['claim_class', 'can_progress'],
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          intersectData: () => {},
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance) => {
              instance.store
                .select(getFullItemOne)
                .pipe(take(1))
                .subscribe((data) => {
                  if (!instance.bf?.bigForm?.get('whatmatters')) {
                    instance?.bf?.bigForm?.addControl(
                      'whatmatters',
                      new UntypedFormControl(data?.loan_information.whatmatters)
                    );
                  }

                  if (!instance?.bf?.bigForm?.get('date_of_loss')) {
                    instance?.bf?.bigForm?.addControl(
                      'date_of_loss',
                      new UntypedFormControl(data?.loan_information.dateofloss)
                    );
                  }
                });
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(instance, {
                  component: 'FLXWhatMattersComponent',
                  inputs: { isDateOfLossDisabled: true },
                }),
              };
            },
          },
        },
        {
          text: 'Continue',
          color: 'primary',
          nextNode: 'newJobCard',
          optIntoValidation: true,
        },
      ],
    },
    newJobCard: {
      name: 'Add New Job',
      serverCalls: {
        claimDetailsInfo: {
          errorMessage: '',
          directCall: (http, store, sq) => {
            return sq
              .queryObject(
                gql`
                  {
                    allInfo {
                      claim_types {
                        id
                        name
                        description
                        skill_groups
                        priority
                      }
                      skills {
                        id
                        name
                      }
                      config_options {
                        general {
                          ia_request_reasons
                        }
                      }
                    }
                  }
                `,
                store
              )
              .pipe(
                skipWhile((x) => !x),
                take(1)
              );
          },
        },
      },
      initFormFields: (bf, item, comp, sq, store) => {
        store
          .select(getFullItemOne)
          .pipe(
            skipWhile((res) => !res),
            take(1)
          )
          .subscribe((claim) => {
            const controls = [];
            if (
              claim &&
              claim?.loan_information &&
              claim?.loan_information.jobcardrequest
            ) {
              console.log('claim?.loan_information ######################################################### ', claim?.loan_information)
              claim?.loan_information.jobcardrequest.forEach((request) => {
                controls.push(comp.createJob(request));
              });
            }
            bf.bigForm.addControl(
              'currentJobs',
              new UntypedFormArray(controls)
            );
          });
        // IA Reason needed
        bf.bigForm.addControl(
          'ia_request_reason',
          new UntypedFormControl(null)
        );
      },
      inputs: {
        cashInLieuComp: 'CashInLieu',
        offPanelComp: 'OffPanel',
        showCurrentJobs: true,
      },
      component: 'AddJobComponent',
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance) => {
              instance.store
                .select(getFullItemOne)
                .pipe(take(1))
                .subscribe((data) => {
                  if (!instance?.bf?.bigForm?.get('whatmatters')) {
                    instance?.bf?.bigForm?.addControl(
                      'whatmatters',
                      new UntypedFormControl(data?.loan_information.whatmatters)
                    );
                  }

                  if (!instance?.bf?.bigForm?.get('date_of_loss')) {
                    instance?.bf?.bigForm?.addControl(
                      'date_of_loss',
                      new UntypedFormControl(data?.loan_information.dateofloss)
                    );
                  }
                });
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(instance, {
                  component: 'FLXWhatMattersComponent',
                  inputs: { isDateOfLossDisabled: true },
                }),
              };
            },
          },
        },
        { text: 'Continue', color: 'primary', nextNode: 'SetAppointment' },
      ],
    },
    SetAppointment: {
      ...BetCreateClaim?.nodes?.claimAppointments,
      inputs: {
        ...BetCreateClaim?.nodes?.ClaimAppointments.inputs,
        showCurrentJobs: true,
      },
      initFormFields: (bf, _item, _instance, sq) => {
        bf.addControl(
          'on_site_person',
          new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(2),
            CustomValidators.hardMaxLength(140),
            CustomValidators.onlyAllowedASCII([
              32,
              39,
              44,
              46,
              ...generateRange(65, 90, 1),
              ...generateRange(96, 122, 1),
            ]),
          ])
        );
        bf.addControl(
          'on_site_notes',
          new UntypedFormControl('', [
            CustomValidators.onlyAllowedASCII(
              [10, 13]?.concat(generateRange(32, 126, 1))
            ),
          ])
        );
        bf.addControl(
          'on_site_contact',
          new UntypedFormControl('', [
            Validators.required,
            CustomValidators.numeric,
            Validators.minLength(10),
            CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
            CustomValidators.hardMaxLength(12),
          ])
        );
        bf.addControl('timepicker', new UntypedFormControl(''));
        bf.addControl('selectedDateType', new UntypedFormControl(''));
        bf.addControl('isSameAppointment', new UntypedFormControl(''));
        bf.addControl('appointments', new UntypedFormArray([]));
        sq.queryStore(
          gql`
            {
              selectedContext {
                fullItemOne {
                  applicant {
                    first_name
                    surname
                    contact_number
                  }
                  loan_information {
                    contactnumber
                  }
                }
              }
            }
          `
        )
          .pipe(take(1))
          .subscribe((applicantValues) => {
            bf.addControl(
              'client_details',
              new UntypedFormGroup({
                first_name: new UntypedFormControl(applicantValues.first_name),
                surname: new UntypedFormControl(applicantValues.surname),
              })
            );
            bf.addControl(
              'contact_details',
              new UntypedFormGroup({
                cell_number: new UntypedFormControl(
                  applicantValues.contactnumber
                ),
              })
            );
          });
      },
      serverCalls: {
        appointmentTypes: {
          errorMessage: '',
          directCall: (http, store, sq) => {
            return sq
              .queryObject(
                gql`
                  {
                    allInfo {
                      appointment_types {
                        id
                        name
                      }
                    }
                  }
                `,
                store
              )
              .pipe(take(1), map(values), map(flatten));
          },
        },
        appointmentData: {
          errorMessage: '',
          directCall: (http, store, sq) => {
            return sq
              .queryObject(
                gql`
                  {
                    allInfo {
                      appointment_types {
                        id
                        name
                      }
                    }
                  }
                `,
                store
              )
              .pipe(take(1), map(values), map(flatten));
          },
        },
      },
      navs: [
        {
          text: 'next',
          nextNode: 'ExcessDetails',
        },
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance) => {
              instance.store
                .select(getFullItemOne)
                .pipe(take(1))
                .subscribe((data) => {
                  if (!instance?.bf?.bigForm?.get('whatmatters')) {
                    instance?.bf?.bigForm?.addControl(
                      'whatmatters',
                      new UntypedFormControl(data?.loan_information.whatmatters)
                    );
                  }

                  if (!instance?.bf?.bigForm?.get('date_of_loss')) {
                    instance?.bf?.bigForm?.addControl(
                      'date_of_loss',
                      new UntypedFormControl(data?.loan_information.dateofloss)
                    );
                  }
                });
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(instance, {
                  component: 'FLXWhatMattersComponent',
                  inputs: { isDateOfLossDisabled: true },
                }),
              };
            },
          },
        },
      ],
      component: 'ClaimAppointmentsComponent',
    },
    CashInLieu: {
      ...BET_BANK_DETAILS,
      inputs: {
        ...BET_BANK_DETAILS.inputs,
      },
      navs: [
        {
          text: 'Enter Details Later',
          location: 'right',
          color: 'secondary',
          nextNode: 'newJobCard',
        },
        ...BET_BANK_DETAILS.navs,
      ],
    },
    ExcessDetails: {
      ...BetCreateClaim?.nodes?.ClaimPaymentDetails,
      inputs: {
        ...BetCreateClaim?.nodes?.ClaimDetails.inputs,
        showTabs: false,
        showCurrentJobs: true,
      },
      initFormFields: (bf) => {
        bf.addControl(
          'policy_excess',
          new UntypedFormGroup({
            is_cancelled: new UntypedFormControl(false),
            excess_description: new UntypedFormControl('Standard Excess'),
            amount: new UntypedFormControl(
              {
                value: '',
                disabled: false,
              },
              { validators: [CustomValidators.currency] }
            ),
            who_collects: new UntypedFormControl({
              value: null,
              disabled: false,
            }),
            who_collects_description: new UntypedFormControl(''),
            payment_method: new UntypedFormControl({
              value: null,
              disabled: false,
            }),
            payment_method_description: new UntypedFormControl(''),
          })
        );
        bf.addControl('jobs', new UntypedFormArray([]));

        const jobs = bf.bigForm?.value?.jobs;

        jobs.forEach((job, index) => {
          if (
            job.skill === 'Loss Adjusting' ||
            job?.skill === 'Internal Assessor'
          ) {
            bf.bigForm.get(`jobs.${index}`).get('is_cancelled')?.setValue(true);
          } else {
            bf.bigForm
              .get(`jobs.${index}`)
              .get('is_cancelled')
              ?.setValue(false);
          }
        });
        checkBusinessRules(bf.bigForm);
        bf.bigForm?.updateValueAndValidity();
        bf.bigForm?.valueChanges?.pipe(
          debounceTime(800),
          switchMap(() => bf.retrieveErrors$())
        );
      },
      showTabs: false,
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance) => {
              instance.store
                .select(getFullItemOne)
                .pipe(take(1))
                .subscribe((data) => {
                  if (!instance?.bf?.bigForm?.get('whatmatters')) {
                    instance?.bf?.bigForm?.addControl(
                      'whatmatters',
                      new UntypedFormControl(data?.loan_information.whatmatters)
                    );
                  }

                  if (!instance?.bf?.bigForm?.get('date_of_loss')) {
                    instance?.bf?.bigForm?.addControl(
                      'date_of_loss',
                      new UntypedFormControl(data?.loan_information.dateofloss)
                    );
                  }
                });
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(instance, {
                  component: 'FLXWhatMattersComponent',
                  inputs: { isDateOfLossDisabled: true },
                }),
              };
            },
          },
        },
        {
          text: 'submit',
          disableOnFirstClick: true,
          nextNode: 'SubmissionSuccess',
          optIntoValidation: true,
          serverFirst: true,
          serverCalls: {
            response: {
              serviceVariable: 'betService',
              errorMessage: `Claim was not updated with this new job`,
              directCall: (http, store, sq, bf) => {
                return forkJoin([
                  store.select(getFullItemOne).pipe(
                    skipWhile((x) => !x),
                    take(1)
                  ),
                  store.select(getSubmissionData).pipe(
                    skipWhile((x) => !x),
                    take(1)
                  ),
                ]).pipe(
                  switchMap(([item, submit]) => {
                    const claim_id = item?.id;
                    const { jobcardrequest } = item.loan_information;
                    const application = {
                      ...item?.loan_information,
                      ...submit.loan_information,
                    };

                    if (submit.loan_information.whatmatters) {
                      application.whatmatters =
                        submit.loan_information.whatmatters;
                      application.claimdescription =
                        submit.loan_information.claimdescription;
                      application.dateofloss =
                        submit.loan_information.dateofloss;
                    }

                    const newjobcardrequest = submit.jobcardrequest;
                    const newClaimClassRequired =
                      parseInt(submit.claim_type_id[0], 10) !==
                      item?.claim_type;

                    let newJobCardRequest = [];
                    if (Array.isArray(jobcardrequest)) {
                      newJobCardRequest = [
                        ...jobcardrequest,
                        ...newjobcardrequest,
                      ];
                    } else {
                      newJobCardRequest = [jobcardrequest, newjobcardrequest];
                    }

                    const data: NewJobPayload = {
                      claim: claim_id,
                      application: {
                        ...application,
                        jobcardrequest: newJobCardRequest,
                      },
                      jobcardrequest: newjobcardrequest,
                    };

                    if (newClaimClassRequired) {
                      data.new_claim_type = parseInt(
                        bf.bigForm.get('selectedClaimClass')?.value[0],
                        10
                      );
                    }

                    return http.post(
                      `${environment.api_url}v1/job_action/new_job/`,
                      data
                    );
                  })
                );
              },
            },
          },
          color: 'primary',
        },
      ],
    },
    SubmissionSuccess: {
      component: 'FLXSuccessTickComponent',
    },
  },
  bigFormToStoreMapper: {
    // what matters update
    whatmatters: 'loan_information.whatmatters',
    claimdescription: [
      (claimdescription, storeObj, formValues) => {
        const handler = storeObj?.identity?.currentUser?.user?.full_name;
        return (
          'Date: ' +
          moment().format('YYYY-MM-DD') +
          ' \nStaff: ' +
          (handler || '') +
          ' \n' +
          formValues.whatmatters +
          '.'
        );
      },
      'loan_information.claimdescription',
    ],
    date_of_loss: 'loan_information.dateofloss',
    claim: ['id', 'loan_information.id'],
    // ADD NEW JOB CARD
    selectedClaimClass: ['claim_type_id', 'loan_information.claimtype_id'],
    claim_class_description: 'loan_information.claimtype',
    jobs: [
      [transformJobsToJobCardRequest, 'jobcardrequest'],
      [transformJobsToJobCardRequest, 'loan_information.jobcardrequest'],
    ],
    ia_request_reason: ['loan_information.ia_requested_reason'],

    // Claim Appointment
    on_site_contact: 'loan_information.onsiteperson',
    on_site_notes: 'loan_information.onsitenotes',
    on_site_person: 'loan_information.onsiteperson',

    // Excess
    reinsuranceexcess: ['loan_information.reinsuranceexcess'],
    voluntaryexcess: ['loan_information.voluntaryexcess'],
    additionalexcess: ['loan_information.additionalexcess'],
    underwriter: 'loan_information.underwriter',
    sum_insured: 'loan_information.suminsured',

    _3rdpartyinsurancecompany: [
      (val, storeObj, formValue) => {
        return formValue &&
          formValue.selectedClaimClass &&
          Number(formValue.selectedClaimClass[0]) === 6
          ? val
          : null;
      },
      'loan_information._3rdpartyinsurancecompany',
    ],
    _3rdpartyvechilereg: [
      (val, storeObj, formValue) => {
        return formValue &&
          formValue.selectedClaimClass &&
          Number(formValue.selectedClaimClass[0]) === 6
          ? val
          : null;
      },
      'loan_information._3rdpartyvechilereg',
    ],
    _3rdpartyvechilemake: [
      (val, storeObj, formValue) => {
        return formValue &&
          formValue.selectedClaimClass &&
          Number(formValue.selectedClaimClass[0]) === 6
          ? val
          : null;
      },
      'loan_information._3rdpartyvechilemake',
    ],
    _3rdpartydriverid: [
      (val, storeObj, formValue) => {
        return formValue &&
          formValue.selectedClaimClass &&
          Number(formValue.selectedClaimClass[0]) === 6
          ? val
          : null;
      },
      'loan_information._3rdpartydriverid',
    ],
    _3rdpartydrivercell: [
      (val, storeObj, formValue) => {
        return formValue &&
          formValue.selectedClaimClass &&
          Number(formValue.selectedClaimClass[0]) === 6
          ? val
          : null;
      },
      'loan_information._3rdpartydrivercell',
    ],
    _3rdpartydrivername: [
      (val, storeObj, formValue) => {
        return formValue &&
          formValue.selectedClaimClass &&
          Number(formValue.selectedClaimClass[0]) === 6
          ? val
          : null;
      },
      'loan_information._3rdpartydrivername',
    ],
    firedamageextent: [
      (val, storeObj, formValue) => {
        return formValue &&
          formValue.selectedClaimClass &&
          Number(formValue.selectedClaimClass[0]) === 7
          ? val
          : null;
      },
      'loan_information.firedamageextent',
    ],
    recomendedadjuster: [
      (val, storeObj, formValue) => {
        return formValue &&
          formValue.selectedClaimClass &&
          Number(formValue.selectedClaimClass[0]) === 7
          ? val
          : null;
      },
      'loan_information.recomendedadjuster',
    ],
    policeclaimnumber: [
      (val, storeObj, formValue) => {
        return formValue &&
          formValue.selectedClaimClass &&
          [8, 9, 16]?.includes(Number(formValue.selectedClaimClass[0]))
          ? val
          : null;
      },
      'loan_information.policeclaimnumber',
    ],
    upfrontrepudiationreason: [
      (val, storeObj, formValue) => {
        return formValue &&
          formValue.selectedClaimClass &&
          Number(formValue.selectedClaimClass[0]) === 21
          ? val
          : null;
      },
      'loan_information.upfrontrepudiationreason',
    ],
  },
  flowErrorMessages: {
    'claim_class:required': 'Claim type is required',
    'jobs:required': 'At least one job card request is needed',
    'cell_number:cellphone': 'Cell number is required',
    'contact_number:contact_number': 'Contact number is required',
  },
};
