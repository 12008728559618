import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, signal } from '@angular/core';
import {ModalService } from '@flexus/core';
import { Observable, Subscription, of} from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'apps/studio/src/environments/environment';

@Component({
  selector: 'flexus-mc-agent-allocation',
  templateUrl: './mc-agent-allocation.component.html',
  styleUrls: ['./mc-agent-allocation.component.scss'],
})
export class McAgentAllocationComponent implements OnInit {
  @Input() agentList$!: Observable<any>;
  agentListLoaded = signal<boolean>(false);

  roleOptions = [
    {
      value: 'Validation Payment Requests',
      display: 'Validation Payment Requests',
    },
    { value: 'Validation queries', display: 'Validation queries' },
  ];
  agentsub!: Subscription;

  constructor(
    private _http: HttpClient,
    @Inject('environment') private environment: any,
	private _toastr: ToastrService,
	private _modal: ModalService,
	private _cd: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
   this.loadAgentList()
  }
  loadAgentList(): void {
    this.agentList$ = this._http
      .get(`${this.environment.api_url}v1/mc_actions/get_validation_agents/?roles=40`)
      .pipe(
        switchMap((agentresult: any) => {
          if (agentresult.success) {
            return of(agentresult.payload);
          } else {
            this._toastr.warning(`Couldn't get the list of validation agents!`);
            return of(null); // Emit null to handle errors downstream
          }
        }),
        take(1),
        tap(() => this.setAgentsLoadingFalse()), // Update loading status
        catchError((error) => {
          this._toastr.error(`Error loading agent list: ${error}`);
          this.setAgentsLoadingFalse();
          return of(null); // Emit null to handle errors downstream
        })
      );
  }
  loadBalanceAgentAssignment(agent_id: number) {
    this.setAgentsLoadingTrue();
    this._http
      .post(
        `${this.environment.api_url}v1/mc_actions/allocate_agent_jobs?staff_id/`,
        { staff_id: agent_id }
      )
      .pipe(
        switchMap((response: any) => {
          if (response.success) {
            this._toastr.info('Agent load successfully balanced!');
            return this._http.get(`${this.environment.api_url}v1/mc_actions/get_validation_agents/?roles=40`);
          } else {
            const server_message = response.reason;
            this._modal.openModalDirectly(instance => {
              instance.type = 'warning';
              instance.heading = 'Oops! Something went wrong..';
              instance.setMessage([`${server_message}.`, 'You can try again against another agent']);
              instance.navButtons = [
                {
                  text: 'ok',
                  linkType: 'close'
                }
              ];
            });
            return of(null); // Emit null to handle errors downstream
          }
          
        }),
        take(1),
        tap(() => this.setAgentsLoadingFalse()), // Update loading status
        catchError((error) => {
          this._toastr.error(`Error balancing agent load: ${error}`);
          this.setAgentsLoadingFalse();
          return of(null); // Emit null to handle errors downstream
        })
      )
      .subscribe((agentresult: any) => {
        if (agentresult && agentresult.success) {
          this.agentList$ = of(agentresult.payload);
        } else {
          this._toastr.warning(`Couldn't reallocate jobs!`);
        }
      });
      this._cd.detectChanges()
  }
  setAgentsLoadingTrue(): void {
    this.agentListLoaded.update(() => false);
  }

  setAgentsLoadingFalse(): void {
    this.agentListLoaded.update(() => true);
  }
}
