<div [class]="'container ' + cardAction">
	<div class="job-request-atom">
		<div class="skill-and-appoint">
			<div class="for-sil">
				<div *ngIf="client === 'sil'" style="text-align: left">{{ job_id }}</div>
				<div [ngClass]="client === 'sil' ? 'skill-type' : 'no-margin'" style="text-align: left">{{ skillsMap[skill] || skill  }}</div>
			</div>
			<div class="appoint">
        <span *ngIf="appointment; else noAppointment">
          {{ appointment?.range_start | date: 'd MMM yyyy' }} - {{ appointment?.appointment_name }}
          {{ appointment?.range_start | date: 'h:mm a' }}
          <ng-container *ngIf="appointment?.range_end">
            to {{ appointment?.range_end | date: 'h:mm a' }}
          </ng-container>
        </span>
				<ng-template #noAppointment>No appointment set.</ng-template>
			</div>
		</div>
		<div class="location">
			<div class="suburb">{{ job?.suburb }}</div>
			<div class="area">{{ provincesMap[job?.area] || job?.area }}</div>
		</div>
	</div>
	<div class="response-options">
		<div class="response-options__map" style="cursor: pointer; width: 55px" (click)="selectAvailableJobOnModal(job.id)"></div>
		<div class="response-options__decline" style="cursor: pointer; width: 55px" (click)="cardAction = CardActionEnum.decline; responseAction($event, 'DECLINED')" #decline></div>

		<div class="response-options__ignore" style="cursor: pointer; width: 55px" (click)="cardAction = CardActionEnum.ignore; responseAction($event, 'IGNORED')" #ignore></div>

		<div
			class="response-options__available"
			style="cursor: pointer; width: 55px"
			(click)="cardAction = CardActionEnum.available; responseAction($event, 'INTERESTED')"
			#available
		></div>
	</div>
</div>
