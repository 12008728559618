/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Deal 'any' typing..
import { BigFormService, Flow_0_0_2, ModalService, getFullItemOne, getFullItemTwo} from "@flexus/core";
import { RemoveError } from "@flexus/error-handler";
import gql from "graphql-tag";
import { take, map, skipWhile, switchMap, of, forkJoin} from "rxjs";
import { jobInfoNode } from "../reusable/JOB_INFO_NODE";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { CustomValidators } from "@flexus/utilities";
import { Store } from "@ngrx/store";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/mul.environment.dev";

export const MUL_350: Flow_0_0_2 = {
	id: '350',
	name: 'address_accuracy_low',
	itemType: 'flow',
	header: {
		title: 'Address Accuracy Low',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {	
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		}
	},
	startNode: 'JobInformation',
	nodes: {
		JobInformation: {
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('updated_latitude', new UntypedFormControl(0, []));
				_bf.bigForm.addControl('updated_longitude', new UntypedFormControl(0, []));
			},
			showTabs: true,
			...jobInfoNode(350, 351),
			navs:[]

		},
		AddressConfirmation: {
			initFormFields: (_bf: BigFormService, item, instance, sq, _store: Store) => {
				let data_complex
				let data_complex_unit_number
				let data_street_address
				let data_suburb
				let data_city
				let data_postal
				_store.select(getFullItemOne).pipe(
					skipWhile(x => !x),
					take(1),
					map(claim => {
						const {loan_information} = claim
						data_complex = loan_information?.propertycomplex
						data_complex_unit_number = loan_information?.propertycomplexunitnumber
						data_street_address = loan_information?.propertystreetaddress
						data_suburb = loan_information?.propertysuburb
						data_city = loan_information?.propertysuburb
					})
				).subscribe()
				_bf.addControl(
					'insured_address',
					new UntypedFormGroup({
						complex: new UntypedFormControl(data_complex, CustomValidators.hardMaxLength(64)),
						complex_unit_number: new UntypedFormControl(data_complex_unit_number, CustomValidators.hardMaxLength(64)),
						street_address: new UntypedFormControl(data_street_address, [Validators.required, CustomValidators.hardMaxLength(64)]),
						suburb: new UntypedFormControl(data_suburb, [Validators.required, CustomValidators.hardMaxLength(64)]),
						city: new UntypedFormControl(data_city, [Validators.required, CustomValidators.hardMaxLength(64)]),
						postal_code: new UntypedFormControl(data_postal, [Validators.required, CustomValidators.hardMaxLength(64)]),
						province: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)])
					})
				);
			},
			name: 'Address Confirmation',
			showTabs: true,
			serverCalls: {
				provinceList: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											active_regions
										}
									}
								`,
								store
							)
							.pipe(
								take(1),
								map(res => {
									return (res as any)?.active_regions;
								})
							);
					}
				},
				policyAddress: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
						.queryObject(
							gql`
							{
								selectedContext {
									fullItemOne {
										loan_information {
											propertycomplex
											propertycomplexunitnumber
											propertystreetaddress
											propertysuburb
											propertycity
											suburbcode
										}
									}
								}
							}
							`,
							store
						).pipe(
								skipWhile(f => !f),
								take(1),
								map(result => {
									const res = result as any;
									const addressArray = [];
									if (res?.propertycomplex) addressArray.push(`${res?.propertycomplexunitnumber} ${res?.propertycomplex}`);
									if (res?.propertystreetaddress) addressArray.push(res?.propertystreetaddress);
									if (res?.propertysuburb) addressArray.push(res?.propertysuburb);
									if (res?.propertycity) addressArray.push(res?.propertycity);
									if (res?.suburbcode) addressArray.push(res?.suburbcode);
									
									return addressArray.join(', ');
								})
							);
					}
				},
				policyAddressObject: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						// Get the 'policy object' detail from what was saved to loan_information during claim creation; not from submission data
						// store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											fullItemOne	 {
												loan_information {
													propertycomplex
													propertycomplexunitnumber
													propertystreetaddress
													propertysuburb
													propertycity
													suburbcode
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(result => result)
							);
					}
				}
			},
			component: 'AddressConfirmationComponent',
			navs: [
				{
					text: 'send updated location',
					color: 'primary',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					serverCalls: {
						updateLocation: {
							errorMessage: `Could not update the location`,
							directCall: (_http: HttpClient, _store: Store, sq, _bf: BigFormService, ctrllr, _modal: ModalService) => {
								return forkJoin([
									_store.select(getFullItemOne).pipe(
										skipWhile(x => !x),
										take(1),
										map(claimresult=>claimresult)
									),
									_store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(jobresult=>jobresult)
									),

								]).pipe(
									switchMap(([claim, job]) => {
										_bf.patchValues({jobid: job.id})
										const claim_id = claim?.id
										const latitude = _bf.getControl('updated_latitude').value
										const longitude = _bf.getControl('updated_longitude').value
										 return _http.post(`${environment.api_url}v1/mc_actions/update_claim_location?claim_id&latitude&longitude`, {claim_id: claim_id, latitude: latitude, longitude: longitude})
									})
								)
								.pipe(
									switchMap((result: any) => {
										const job_id = _bf.getControl('jobid').value
										const new_state = 77
										const request = {
											job_id: job_id,
											new_state: new_state
										}
										if(result?.success) {
									 		return _http.post(`${environment.api_url}v1/job_action/update_job/`, request);

										} else {
											_modal.openModalDirectly(instance => {
												instance.type = 'warning';
												instance.heading = 'Job location was not successfully updated';
												instance.message = 'You can try again or contact your system administrator if the problem persists';

												instance.navButtons = [
													{
														text: 'ok',
														color: 'secondary',
														linkType: 'closer'
													}
												]

											})
										}
										return of(result)
									})
									
								)
						
							}
							
						},
						
					},
					
				}

			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {}
};