import { Flow_0_0_2, getSelectedItemOne } from '@flexus/core';
import {
  take,
  map,
  withLatestFrom,
  switchMap,
  skipWhile,
  pluck,
} from 'rxjs/operators';
import { of, from, Observable } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment'; // import { transformJobsToJobCardRequest, checkBusinessRules } from './transform.functions';
import {
  CollapseActionPanel,
  setActionPanelItems,
} from '../../../../app-shell-features';
import { HttpClient } from '@angular/common/http';

const today = new Date();
const tomorrow = new Date(today);
const isAfterOne = today.getHours() > 12;
tomorrow.setDate(tomorrow.getDate() + 1);

export const MulTeamManagement: Flow_0_0_2 = {
  addHeaderActions: true,
  id: 'team_management',
  name: 'team management',
  deactivateStateGuard: (
    guardService: any,
    comp,
    router,
    currentRoute,
    currentState,
    nextState
  ) => {
    return from(
      guardService.indexedDbService.currentItem.get('currentItem')
    ).pipe(
      withLatestFrom(guardService.canGo$),
      switchMap(([currentItem, canGo]: any) => {
        return <Observable<any>>(
          guardService.store.select(getSelectedItemOne).pipe(
            take(1),
            map((itemOne: any) => {
              if (
                !canGo &&
                currentItem?.applicant &&
                currentItem?.loan_information
              ) {
                if (itemOne?.tempKey && guardService?.bf?.bigForm?.touched) {
                  // Local draft has been edited
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      inst.color = 'alert';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving away from this local draft.',
                        ' Your changes will be discarded!',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Discard Changes',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Save Changes Locally',
                          color: 'primary',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            const localDraft = {
                              ...currentItem,
                              tempKey: itemOne?.tempKey,
                              state: 169,
                            };
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.claimInDraft
                                .put(localDraft)
                                .then(() => {
                                  guardService.indexedDbService.currentItem.delete(
                                    'currentItem'
                                  );
                                });
                            });
                          },
                        },
                        {
                          text: 'Cancel',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                } else if (
                  itemOne?.tempKey &&
                  !guardService?.bf?.bigForm?.touched
                ) {
                  // Local draft opened but not edited yet
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      inst.color = 'alert';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving away from this local draft.',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Leave',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Stay',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                } else if (
                  !itemOne?.tempKey &&
                  !guardService?.bf?.bigForm?.touched
                ) {
                  // Any Server Draft not edited
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      inst.color = 'alert';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving away from your current view.',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Leave',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Stay',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                } else if (
                  !itemOne?.tempKey &&
                  guardService?.bf?.bigForm?.touched
                ) {
                  // Any server draft that is edited
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      inst.color = 'alert';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving away from this draft.',
                        ' Your changes will be discarded!',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Discard Changes',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Cancel',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                } else if (!itemOne && !guardService.bf.bigForm.touched) {
                  // Creating New Claim but forms has not been touched yet
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      inst.color = 'alert';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving from your current view.',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Leave',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Stay',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                } else if (!itemOne && guardService.bf.bigForm.touched) {
                  // In Claim creation and form has been touched
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving from your current view.',
                        ' Your changes will be discarded!',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Discard Changes',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Cancel',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                }
              } else {
                return true;
              }
            })
          )
        );
      })
    );
  },
  activateStateGuard: () => {
    return of(true);
  },
  onStateInit: (instance) => {
    instance.store.dispatch(new CollapseActionPanel());
  },
  onStateDestroy: (instance) => {
    instance.store.dispatch(new CollapseActionPanel());
  },
  itemType: 'flow',
  actionPanel: (instance) => setActionPanelItems(instance, ['what-matters']),
  instructions: {
    editRoles: {
      0: 'Placeholder',
    },
    viewRoles: {
      0: 'Placeholder',
    },
  },
  header: {
    title: 'Manage Team',
    controls: () => {
      return () => {
        const controls = [];
        return controls;
      };
    },
  },
  footer: {
    type: 'node_nav',
  },
  startNode: 'AgentAllocationList',
  nodes: {
    AgentAllocationList: {
      component: 'McAgentAllocationComponent',
    },

    SubmissionSuccess: {
      component: 'FLXSuccessTickComponent',
      inputs: {
        copyTextHeading: 'Manage Team',
        autoClose: false,
      },
      navs: [],
    },
  },
  bigFormToStoreMapper: {
    new_state: 'new_state',
  },
};
