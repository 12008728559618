import { HttpClient } from '@angular/common/http';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BigFormService, MakeServerCall, ManifestController, ModalService, NodePoint_0_0_2, SetNextNode, getFullItemTwo } from '@flexus/core';
import { Store } from '@ngrx/store';
import { of, skipWhile, switchMap, take } from 'rxjs';
import { environment } from '../../environments/mul.environment.dev';

export const resolvedProblemNode = (state?: number, newState?: number): NodePoint_0_0_2 => {
	return {
		component: 'FLXFlatDynamicFormComponent',
		name: state === 186 ? 'FSIA Error ' : state === 177 ? 'Validation Issue Fixed' : 'Resolved Issue',
		hideTabItem: true,
		inputs: {
			heading: state === 186 ? 'FSIA Error Comment' : 'Resolved Problem Raised by Validation',
			subheading: 'Please write a report before submission',
			formControls: state === 185 ? {
				0: {
					label: 'Please comment how you resolved the issue',
					inputType: 'textarea',
					rows: 5,
					formControlName: 'message'
				}
			} : {
				0: {
					label: state === 177 ? 'Report' : 'Please comment how you resolved the issue',
					inputType: 'textarea',
					rows: 5,
					formControlName: 'message'
				},
				1: {
					label: `What is the activation status?`,
					inputType: 'select',
					width: '30vw',
					selectConfig: {
						displayOptions: { displayKey: 'display', valueKey: 'value' },
						itemsOption: [
							{ display: 'Activated', value: 'Activated' },
							{ display: 'Activation failed', value: 'Activation failed' }
						],
						placeholder: 'Update activation status'
					},
					formControlName: 'activation_fix'
				}
			}
			,
			formLayout: 'stacked',
			containerWidth: '50vw'
		},
		checkValidityForFields: state === 185 ? ['message'] : ['message', 'activation_fix'],
		initFormFields: (bf) => {
			bf.patchValues({ new_state: newState ?? 188 });
			bf.addControl('message', new UntypedFormControl('', Validators.required));
			bf.addControl('activation_fix', new UntypedFormControl('', [Validators.required]));
		},
		navs: state !== 185 && state !== 182 ? [
			{
				text: 'Submit',
				disableOnLoad: true,
				serverFirst: true,
				nextNode: 'submissionSuccess',
				color: 'primary',
				optIntoValidation: true,
				linkType: 'submit',
				serverCalls: {
					response: {
						directCall: (_http: HttpClient, _store: Store, _sq: any, _bf: BigFormService, _ctrl: ManifestController<any>, _modal: ModalService) => {
							_modal.openModalDirectly(inst => {
								inst.type = 'warning';
								inst.heading = 'Have you resolved the FSIA Error?';
								inst.message = 'Confirming that you have resolved the FSIA error will move this out of your workflow.';
								inst.navButtons = [
									{
										text: 'cancel',
										linkType: 'close',
										color: 'default'
									},
									{
										text: 'yes',
										serverFirst: true,
										linkType: 'close',
										color: 'default',
										nextNode: 'submissionSuccess',
										clickHandler: () => {
											_store.dispatch(
												new MakeServerCall({
													errorMessage: 'Could not update job',
													dataKey: 'response',
													serviceVariable: 'mulService',
													functionName: 'updateJob'
												})
											);
											_ctrl.dispatch(new SetNextNode('submissionSuccess'));
										}
									}
								];
							});
							return of({});
						},
						errorMessage: ''
					}
					
				}
			}
		]:
		[
			{
				text: 'issue fixed',
				serverFirst: true,
				color: 'primary',
				serverCalls: {
					response: {
						// serviceVariable: 'mulService',
						errorMessage: 'Job could not be resubmitted for payment',
						directCall: (_http: HttpClient, _store: Store, sq, bf, cntrl, _modal: ModalService) => {
							return _store.select(getFullItemTwo).pipe(
								skipWhile(x => !x),
								take(1),
								switchMap(result => {
									const { job_information } = result
									const job_id = result?.id
									const { payment_validated } = job_information
									if(job_information) {
										if (!payment_validated || payment_validated === 'false') {
											const request = {
												job_id: job_id,
												new_state: 188
											}
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, { ...request })
										} else if (payment_validated === 'true') {
											const request = {
												job_id: job_id,
												new_state: 28
											}
											return _http.post(`${environment.api_url}v1/job_action/update_job/`, { ...request })
										}
									} else {
										_modal.openModalDirectly(instance => {
											instance.type = 'warning';
											instance.heading = 'There is no job information!';
											instance.message = 'Please contact an administrator';
											instance.navButtons = [
												{
													text: 'close',
													linkType: 'close',
													color: 'default'
												}
											]
										})
									}
								})
							)
						}
					}
				},
			}
		]
	};
};
