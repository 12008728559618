<ng-container *ngIf="state === 20  || state === 369 ">
  <div class="entity-card" id="entity-card">
    <div class="entity-card__column" *ngFor="let col of data$?.columns">
      <div class="entity-card__column__title">{{ col.title }}</div>
      <div class="entity-card__column__sub-title">{{ col.subTitle }}</div>
      <div class="entity-card__column__description">{{ col.description }}</div>
    </div>
    <div class="indicator">
      <flx-indicator
        [color]="getIndicatorColor(this.data$?.cardStateInfo?.state)"
        position="right"
      ></flx-indicator>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="state === 311 ">
  <div class="entity-card-311" id="entity-card">
    <div class="entity-card__column" *ngFor="let col of data$?.columns">
      <div
        *ngIf="col.spname"
        class="entity-card__column__spname_title"
        [ngClass]="data$.distance_forsort > 10 ? 'red' : 'green'"
      >
        {{ col.spname }}
      </div>
      <div *ngIf="col.title" class="entity-card__column__title">
        {{ col.title }}
      </div>
      <div *ngIf="col.subTitle" class="entity-card__column__sub-title">
        {{ col.subTitle }}
      </div>
      <div *ngIf="col.subtitle" class="entity-card__column__sub-title_small">
        {{ col.subtitle }}
      </div>
      <div *ngIf="col.spRatingTitle" class="entity-card__column__rating_title">
        {{ col.spRatingTitle }}
      </div>
      <div *ngIf="col.score" class="entity-card__column__score">
        {{ col.score }}
      </div>
      <!-- <div *ngIf="col.spacer" class="entity-card__column__spacer"></div> -->
      <div *ngIf="col.distance" class="distance_column">
        <div class="entity-card__column__sub-title_distance">
          {{ col.distance }}
        </div>
        <div class="entity-card__column__sub-title_small_super">km</div>
      </div>
      <div class="entity-card__column__description">{{ col.description }}</div>
    </div>
    <div class="indicator">
      <flx-indicator
        [color]="getIndicatorColor(this.data$?.cardStateInfo?.state)"
        position="right"
      ></flx-indicator>
    </div>
  </div>
</ng-container>

<!-- [ngClass]="+col.distance > 10 ? 'red' : 'green'" -->
