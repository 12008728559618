import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import {
  getCurrentUser,
  NetworkService,
  getAllInfo,
  ManifestController,
  getItemTwoContextMenu,
} from '@flexus/core';
import { getPermissions, getActiveOrganization } from '@flexus/core';
import { take, map, filter, skipWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getCurrentPage } from '@flexus/ux';
import { findName } from '@flexus/utilities';
import { flatten } from 'ramda';

@Component({
  selector: 'flx-sp-list-view',
  templateUrl: './sp-list-view.component.html',
  styleUrls: ['./sp-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FLXSPListViewComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() list$: Observable<any>;
  currentPage: number;
  pageSize = 30;
  itemTwoContextMenuList = [];
  itemOnePermissions: Function[] = [];
  itemTwoPermissions: Function[] = [];
  allInfo$: Observable<any>;
  user$: Observable<any>;
  isOnline$: Observable<boolean>;
  onLineSub: Subscription;
  userSub: Subscription;
  activeOrg$: Observable<any>;
  claimTypeDescriptions$: Observable<any>;
  stateDescriptions$: Observable<any>;
  indicatorObservable$: Observable<any>;
  loading$;
  claimContextMenuList = [];

  subs: any[] = [];

  currentPageSubscription: Subscription;

  constructor(
    private _store: Store<any>,
    private controller: ManifestController<any>,
    private network: NetworkService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    console.log('list view');
    this.getAllInfo();
    this.getActiveOrg();
    this.getNetworkState();
    this.getUser();
    this.getPermissions();
    this.getCurrentPage();
    this.getJobContextMenuList();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  get theList$() {
    return forkJoin([
      this.list$.pipe(
        skipWhile((res) => !res),
        take(1)
      ),
      this.allInfo$.pipe(
        skipWhile((res) => !res),
        take(1)
      ),
      this.user$.pipe(
        skipWhile((res) => !res),
        take(1)
      ),
    ])?.pipe(
      map(([list, allInfo, user]) => {
        const installers =
          allInfo && allInfo.sps ? allInfo.sps.map((sp) => sp.installer) : [];
        const cl = user?.client;
        let skill__name;

        return list.map((element) => {
          const clonedEl = JSON.parse(JSON.stringify(element));
          if (cl !== 'multichoice') {
            skill__name = findName(element.skill, allInfo.skills)?.name;
          } else if (cl === 'multichoice') {
            if (
              findName(element.skill, allInfo.skills)?.name ===
                'DStv L2 Installation' &&
              element.claim_type_id == 37
            ) {
              skill__name = `Installer Own Stock - ${
                findName(element.skill, allInfo.skills)?.name
              }`;
            } else {
              skill__name = findName(element.skill, allInfo.skills)?.name;
            }
          }

          const appointmentStartTime =
            this.extractTimeFromISOString(element.appointment?.range_start) ??
            '';
          const appointmentEndTime =
            this.extractTimeFromISOString(element.appointment?.range_end) ?? '';
          const appointmentTime =
            appointmentStartTime && appointmentEndTime
              ? `${appointmentStartTime} - ${appointmentEndTime}`
              : appointmentStartTime;

          return {
            ...clonedEl,
            stateName: findName(element.state, allInfo.states)?.description,
            skillName: skill__name,
            teamleadName: findName(element.team_leader, flatten(installers))
              ?.full_name,
            address: element.suburb,
            formattedDate:
              this.formatDate(element.appointment?.range_start) ?? '',
            appointmentType:
              findName(
                element.appointment?.appointment_type,
                allInfo.appointment_types
              )?.name ?? '',
            appointmentTime,
            timeSinceUpdate:
              this.calculateElapsed(element.appointment?.range_start) ?? '',
          };
        });
      })
    );
  }

  private formatDate(d) {
    return d?.split('T')[0] ?? '';
  }
  private extractTimeFromISOString(d) {
    return d?.replace(/^[^:]*([0-2]\d:[0-5]\d).*$/, '$1') ?? '';
  }
  private calculateElapsed(d) {
    if (d) {
      const today = new Date();
      const updated = new Date(d);
      const totalTimeElapsed = today.getTime() - updated.getTime();
      const days = Math.round(totalTimeElapsed / (1000 * 3600 * 24));
      let hoursRemaining;
      let minutesRemaining;
      if (totalTimeElapsed % 24 > 0) {
        hoursRemaining = totalTimeElapsed % 24;
        if (hoursRemaining % 3600 > 0) {
          minutesRemaining = (hoursRemaining % 3600) / 60;
        }
      }
      const hours = Math.floor(hoursRemaining);
      const minutes = Math.floor(minutesRemaining);
      return days + 'd ' + hours + ':' + minutes + ':00';
    } else {
      return '';
    }
  }

  private getActiveOrg() {
    this.activeOrg$ = this.controller.select(getActiveOrganization);
  }

  private getNetworkState() {
    this.isOnline$ = this.network.isOnline;
  }

  trackByFunc(idx, claim) {
    return claim?.id;
  }

  private getUser() {
    this.user$ = this._store.select(getCurrentUser);
  }

  getCurrentPage() {
    this.currentPageSubscription = this._store
      .select(getCurrentPage)
      .subscribe((pageNum) => {
        this.currentPage = pageNum;
        this.cdr.detectChanges();
      });
  }

  getPermissions() {
    this.controller
      .select(getPermissions)
      .pipe(take(1))
      .subscribe((perm) => {
        if (perm) {
          this.itemTwoPermissions = Object.values(perm['itemTwo']);
        }
      });
  }

  private getAllInfo() {
    // this.allInfo$ = this._store.pipe(select(getAllInfo));
    this.allInfo$ = this._store.select(getAllInfo)?.pipe(
      filter((data: any) => !!data),
      take(1),
      map((result: any) => {
        return result;
      })
    );
    this.allInfo$.subscribe();
  }

  getJobContextMenuList() {
    this.controller
      .select(getItemTwoContextMenu)
      .pipe(
        skipWhile((x) => !x),
        take(1)
      )
      .subscribe((contextMenu: any) => {
        Object.entries(contextMenu)?.forEach(([key, value]: [any, any]) => {
          const data = {
            name: value['name'],
            key,
            includeForOnlyStates: value['includeForOnlyStates'] || ['all'],
            excludeForOnlyStates: value['excludeForOnlyStates'] || [],
            includeFilterFunction: value['includeFilterFunction'],
          };
          if (value?.command) {
            data['command'] = value.command;
          }
          this.itemTwoContextMenuList.push(data);
        });
      });
  }

  ngOnDestroy() {
    if (this.currentPageSubscription) {
      this.currentPageSubscription.unsubscribe();
    }
    if (this.onLineSub) {
      this.onLineSub.unsubscribe();
    }
  }
}
