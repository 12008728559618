import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  BigFormService,
  VatService,
  getCurrentUser,
  getExcess,
  getExcessChangeDetails,
  getInvoice,
  getSP,
} from '@flexus/core';
import { Store } from '@ngrx/store';
import { map, skipWhile, take, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'flx-invoice-summary',
  templateUrl: './invoice-summary.component.html',
  styleUrls: ['./invoice-summary.component.scss'],
})
export class InvoiceSummaryComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() editing;
  @Input() actualLineItemsFormArray: FormArray;
  private invoiceDateSub: Subscription;
  excess;
  excessChangeDetails;
  client;
  subTotal$: Observable<any>;
  excessAmount$: Observable<any>;
  vat$: Observable<number>;
  summaryValues;
  sub: Subscription;
  balanceDueSub: Subscription;
  balanceDue: number = 0;
  subTotal: number = 0;
  excessAmount: number = 0;
  vat: number = 0;
  vatAmount: number = 0;

  constructor(
    public bf: BigFormService,
    private vatService: VatService,
    private store: Store<any>,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store
      .select(getCurrentUser)
      .pipe(
        skipWhile((usr: any) => !usr),
        take(1)
      )
      .subscribe((result: any) => {
        this.client = result?.client;
      });

    //
    this.subTotal$ = this.actualLineItemsFormArray.valueChanges.pipe(
      map((lineItems: any[]) => {
        return lineItems.reduce(
          (sum, item) => sum + item.unitPrice * item.quantity,
          0
        );
      })
    );

    //
    this.vat$ = this.vatService.getVATPercentage$();

    //
    this.excessAmount$ = this.subTotal$.pipe(
      withLatestFrom(this.vat$),
      map(([subTotal, vat]) => {
        if (this.excess) {
          const balanceWithVAT = subTotal + vat;
          if (
            this.excessChangeDetails &&
            this.excessChangeDetails.excess_must_change &&
            balanceWithVAT > this.excessChangeDetails.new_excess
          ) {
            return balanceWithVAT * (this.excessChangeDetails.percent / 100);
          } else {
            return this.excess.excess_to_be_collected ? this.excess.excess : 0;
          }
        }
        return 0;
      })
    );

    // //
    this.balanceDueSub = combineLatest([
      this.subTotal$,
      this.excessAmount$,
      this.vat$,
    ]).subscribe(([subTotal, excessAmount, vat]) => {
      this.subTotal = subTotal;
      this.excessAmount = excessAmount;
      this.vat = vat;
      this.vatAmount = (vat / 100) * subTotal;
      this.summaryValues = {
        subTotal,
        excessAmount,
        vatAmount: (vat / 100) * subTotal,
        vat,
      };
      this.balanceDue =
        subTotal + (vat ? (subTotal * vat) / 100 : 0) - excessAmount;
    });

    this.sub = combineLatest([
      this.store.select(getSP),
      this.store.select(getInvoice),
      this.store.select(getExcess),
      this.store.select(getExcessChangeDetails),
    ])
      .pipe(
        skipWhile((x) => !x[1] || !x[2]),
        take(1)
      )
      .subscribe(([sp, invoice, excess, excessChangeDetails]) => {
        this.excess = excess;
        this.excessChangeDetails = excessChangeDetails;

        const inValidInvoice =
          invoice.error === 'No invoice or quotation found!' ||
          invoice.data === 'No draft quote on the job';

        let invoiceDate;
        if (inValidInvoice) {
          invoiceDate = new Date();
        } else {
          const dateToConvert = invoice.invoice_date || invoice.quotation_date;
          const dateParts = dateToConvert
            ?.toString()
            .split('T')[0]
            ?.split('-')
            .map(Number);

          if (
            dateParts &&
            dateParts.length === 3 &&
            !dateParts.some(isNaN) &&
            dateParts[1] >= 1 &&
            dateParts[1] <= 12 &&
            dateParts[2] > 0 &&
            dateParts[2] <= 31
          ) {
            invoiceDate = new Date(
              dateParts[0],
              dateParts[1] - 1,
              dateParts[2]
            );
          }
        }

        if (sp && sp.vat_no) {
          const vatPercentage = VatService.getVatPercentageFromDate(
            new Date(invoiceDate)
          );
          this.vatService.setVATPercentage(vatPercentage);
          this.bf.bigForm.get('vatPercentage').setValue(vatPercentage);
        } else {
          this.vatService.setVATPercentage(0);
        }

        this.bf.bigForm.patchValue({
          invoiceDate,
          invoiceNumber:
            invoice.invoice_number || invoice.quotation_number || '',
          invoiceNotes:
            invoice.note || this.bf.bigForm.get('invoiceNotes').value || '',
        });

        if (sp && sp.vat_no) {
          this.invoiceDateSub = this.bf.bigForm
            .get('invoiceDate')
            ?.valueChanges?.subscribe((invoiceDate) => {
              const vatPercentage =
                VatService.getVatPercentageFromDate(invoiceDate);
              this.vatService.setVATPercentage(vatPercentage);
              this.bf.bigForm.get('vatPercentage').setValue(vatPercentage);
            });
        }
      });
  }

  ngAfterViewInit() {
    this.actualLineItemsFormArray.patchValue(
      this.actualLineItemsFormArray.value
    );
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.invoiceDateSub) {
      this.invoiceDateSub.unsubscribe();
    }
    this.sub?.unsubscribe();
    this.balanceDueSub?.unsubscribe();
  }
}
