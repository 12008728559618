import { claimPermissions } from './configs/permissions/claim.permissions';
import { jobPermissions } from './configs/permissions/job.permissions';
import { ClaimWorkflow } from './configs/workflow/claim-workflow';
import { BetCreateClaim } from './configs/create-claim/bet-create-claim';
import * as States from './configs/flows';
import { Manifest_0_0_2, getAllInfo, getCurrentUser } from '@flexus/core';
import { BetSearch } from './configs/search/bet-search';
import { applyBetVirtualStates } from './bet-virtual-state.helper';
import { BetEditClaim } from './configs/create-claim/bet-edit-claim';
import { Store } from '@ngrx/store';
import { skipWhile, take, map, pluck } from 'rxjs/operators';
import { FLXAppMenuComponent } from '../../app-shell-features/app-menu/app-menu.component';
import { forkJoin } from 'rxjs';

export const bet: Manifest_0_0_2 = {
  version: '0.0.2',
  orgKey: 'bet',
  settings: {
    addActionPanel: true,
    addAppBar: true,
    addAppMenu: true,
    addFooter: true,
    addHeaderActions: true,
    addSearchBanner: true,
    addReminder: true,
  },
  appMenu: (instance: FLXAppMenuComponent, store: Store<any>) =>
    forkJoin([
      store.select(getCurrentUser).pipe(
        skipWhile((user) => !user),
        take(1)
      ),
      store.select(getAllInfo).pipe(
        skipWhile((allInfo) => !allInfo),
        take(1),
        pluck('config_options', 'general', 'claim_creation_roles')
      ),
    ]).pipe(
      map(([currentUser, claimCreationRoles]: any[]) => {
        const createRoles = claimCreationRoles ?? [1, 6];
        const menuItems = [
          {
            id: 1,
            name: 'Workflow',
            routerLink: '/workflow',
            iconType: 'app-menu-workflow',
          },
        ];

        if (
          currentUser?.user?.roles.some((role) => createRoles?.includes(role))
        ) {
          menuItems.push({
            id: 2,
            name: 'New Claim',
            routerLink: '/workflow/detail',
            iconType: 'app-menu-new-claim',
          });
        }

        return menuItems;
      })
    ),
  virtualStatesFunction: applyBetVirtualStates,
  appSearch: BetSearch,
  permissions: {
    itemOne: claimPermissions,
    itemTwo: jobPermissions,
  },
  manifestItems: {
    workflow: ClaimWorkflow,
    create_item_one: BetCreateClaim,
    [States.BET_1.id]: States.BET_1,
    [States.BET_2.id]: States.BET_2,
    [States.BET_3.id]: States.BET_3,
    [States.BET_4.id]: States.BET_4,
    [States.BET_5.id]: States.BET_5,
    [States.BET_7.id]: States.BET_7,
    [States.BET_8.id]: BetEditClaim,
    [States.BET_9.id]: States.BET_9,
    [States.BET_10.id]: States.BET_10,
    [States.BET_11.id]: States.BET_11,
    [States.BET_12.id]: States.BET_12,
    [States.BET_13.id]: States.BET_13,
    [States.BET_14.id]: States.BET_14,
    [States.BET_15.id]: States.BET_15,
    [States.BET_16.id]: States.BET_16,
    [States.BET_17.id]: States.BET_17,
    [States.BET_18.id]: States.BET_18,
    [States.BET_19.id]: States.BET_19,
    [States.BET_20.id]: States.BET_20,
    [States.BET_21.id]: States.BET_21,
    [States.BET_22.id]: States.BET_22,
    [States.BET_23.id]: States.BET_23,
    [States.BET_24.id]: States.BET_24,
    [States.BET_25.id]: States.BET_25,
    [States.BET_26.id]: States.BET_26,
    [States.BET_27.id]: States.BET_27,
    [States.BET_28.id]: States.BET_28,
    [States.BET_29.id]: States.BET_29,
    [States.BET_30.id]: States.BET_30,
    [States.BET_31.id]: States.BET_31,
    [States.BET_32.id]: States.BET_32,
    [States.BET_33.id]: States.BET_33,
    [States.BET_34.id]: States.BET_34,
    [States.BET_35.id]: States.BET_35,
    [States.BET_36.id]: States.BET_36,
    [States.BET_37.id]: States.BET_37,
    [States.BET_38.id]: States.BET_38,
    [States.BET_39.id]: States.BET_39,
    [States.BET_40.id]: States.BET_40,
    [States.BET_41.id]: States.BET_41,
    [States.BET_42.id]: States.BET_42,
    [States.BET_43.id]: States.BET_43,
    [States.BET_44.id]: States.BET_44,
    [States.BET_45.id]: States.BET_45,
    [States.BET_46.id]: States.BET_46,
    [States.BET_47.id]: States.BET_47,
    [States.BET_48.id]: States.BET_48,
    [States.BET_49.id]: States.BET_49,
    [States.BET_50.id]: States.BET_50,
    [States.BET_51.id]: States.BET_51,
    [States.BET_52.id]: States.BET_52,
    [States.BET_53.id]: States.BET_53,
    [States.BET_54.id]: States.BET_54,
    [States.BET_55.id]: States.BET_55,
    [States.BET_56.id]: States.BET_56,
    [States.BET_57.id]: States.BET_57,
    [States.BET_58.id]: States.BET_58,
    [States.BET_59.id]: States.BET_59,
    [States.BET_60.id]: States.BET_60,
    [States.BET_61.id]: States.BET_61,
    [States.BET_62.id]: States.BET_62,
    [States.BET_63.id]: States.BET_63,
    [States.BET_64.id]: States.BET_64,
    [States.BET_65.id]: States.BET_65,
    [States.BET_66.id]: States.BET_66,
    [States.BET_67.id]: States.BET_67,
    [States.BET_68.id]: States.BET_68,
    [States.BET_69.id]: States.BET_69,
    [States.BET_70.id]: States.BET_70,
    [States.BET_71.id]: States.BET_71,
    [States.BET_72.id]: States.BET_72,
    [States.BET_73.id]: States.BET_73,
    [States.BET_74.id]: States.BET_74,
    [States.BET_75.id]: States.BET_75,
    [States.BET_76.id]: States.BET_76,
    [States.BET_77.id]: States.BET_77,
    [States.BET_78.id]: States.BET_78,
    [States.BET_79.id]: States.BET_79,
    [States.BET_80.id]: States.BET_80,
    [States.BET_81.id]: States.BET_81,
    [States.BET_82.id]: States.BET_82,
    [States.BET_83.id]: States.BET_83,
    [States.BET_84.id]: States.BET_84,
    [States.BET_85.id]: States.BET_85,
    [States.BET_86.id]: States.BET_86,
    [States.BET_87.id]: States.BET_87,
    [States.BET_88.id]: States.BET_88,
    [States.BET_89.id]: States.BET_89,
    [States.BET_90.id]: States.BET_90,
    [States.BET_92.id]: States.BET_92,
    [States.BET_93.id]: States.BET_93,
    [States.BET_94.id]: States.BET_94,
    [States.BET_95.id]: States.BET_95,
    [States.BET_96.id]: States.BET_96,
    [States.BET_97.id]: States.BET_97,
    [States.BET_98.id]: States.BET_98,
    [States.BET_99.id]: States.BET_99,
    [States.BET_100.id]: States.BET_100,
    [States.BET_101.id]: States.BET_101,
    [States.BET_102.id]: States.BET_102,
    [States.BET_103.id]: States.BET_103,
    [States.BET_104.id]: States.BET_104,
    [States.BET_105.id]: States.BET_105,
    [States.BET_106.id]: States.BET_106,
    [States.BET_107.id]: States.BET_107,
    [States.BET_108.id]: States.BET_108,
    [States.BET_109.id]: States.BET_109,
    [States.BET_110.id]: States.BET_110,
    [States.BET_154.id]: States.BET_154,
    [States.BET_156.id]: States.BET_156,
    [States.BET_161.id]: States.BET_161,
    [States.BET_162.id]: States.BET_162,
    [States.BET_169.id]: States.BET_169,
    [States.BET_170.id]: States.BET_170,
    [States.BET_172.id]: States.BET_172,
    [States.BET_210.id]: States.BET_210,
    [States.BET_212.id]: States.BET_212,
    [States.BET_213.id]: States.BET_213,
    [States.BET_218.id]: States.BET_218,
    [States.BET_219.id]: States.BET_219,
    [States.BET_220.id]: States.BET_220,
    [States.BET_221.id]: States.BET_221,
    [States.BET_222.id]: States.BET_222,
    [States.BET_223.id]: States.BET_223,
    [States.BET_228.id]: States.BET_228,
    [States.BET_288.id]: States.BET_288,
    [States.BET_290.id]: States.BET_290,
    [States.BET_316.id]: States.BET_316,
    [States.BET_318.id]: States.BET_318,
    [States.BET_322.id]: States.BET_322,
  },
};
