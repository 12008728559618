import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';

import { skipWhile, map, take } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { AUTO_SELECT_TEMPLATE_DECISION, BILLING_INVOICE, billingServerCalls, SELECT_CALLOUT_TEMPLATE } from './BILLING';
import { environment } from 'apps/studio/src/environments/environment';

export const SP_GLOBAL_171: Flow_0_0_2 = {
	id: '171',
	name: 'incorrect_skill_invoice_call_out',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo).pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Incorrect Skill - Call-out fee: ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Incorrect Skill - Call-out fee';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		...billingServerCalls
	},
	instructions: {
		editRoles: {
			0: 'Invoice a call-out fee'
		},
		viewRoles: {
			0: 'Wait for SP to invoice call-out fee'
		}
	},
	startNode: 'IncorrectSkillNotification',
	nodes: {
		IncorrectSkillNotification: {
			initFormFields: bf => {
				bf.addControl('new_state', new UntypedFormControl('--'));
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'No job data could be retrieved',
					directCall: (http, store) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(itemTwo => {
									console.log('ITEM TWO ', itemTwo);

									const list = {
										'Claim Type : ': itemTwo?.claim?.type ?? '',
										'Client : ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : '',
										'Contact Number : ': itemTwo?.claim?.applicant?.contact_number ?? '',
										'Cell Number : ': itemTwo?.claim?.loan_information?.cellnumber ?? '',
										'Address : ': itemTwo?.address ?? ''
									};
									return [list];
								})
							);
					}
				}
			},
			inputs: {
				title: 'Incorrect Skill - Invoice Call-out Fee',
				instructions: ['No work was done as the incorrect skill was called for the job. Invoice a call out fee']
			},
			component: 'FLXKeyValueDisplayWithInstructionsComponent',
			navs: [
				{
					text: 'Continue',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
					color: 'primary'
				}
			]
		},
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'invoice',
				// docTitle: '',
				numberLabel: 'Invoice',
				currentState: 171,
				newState: 27,
				showLineItemGenerator: true,
				canEditLineItems: true,
				boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
				boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Standard Bank Insurance Limited',
				clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL'
			},
			navs: [
				{
					text: 'Save Draft',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						draftQuote: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating draft invoice!', serviceVariable: 'spService', functionName: 'generateDraftInvoice' }
							}
						}
					}
				},
				{
					text: 'Generate Invoice',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		new_state: [
			(state, storeObj, formValue, bf) => {
				return 70;
			},
			'new_state'
		],
		total: [
			(a, b, bf) => {
				return bf.total + bf.vatRate;
			},
			'job_information.quote_amount'
		],
		invoiceNumber: 'job_information.quote_number'
	}
};
