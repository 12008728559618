<div class="container">
	<div class="top-view">
		<div class="workflow-status">
			<div class="heading-container">
				<flx-heading align="center" size="medium">Workflow Status</flx-heading>
			</div>
			<ng-container *ngIf="showGraph; else noGraph">
				<div class="states-completion-graph">
					<flx-dynamic-chart [data]="barChartData" [options]="barChartOptions"
						[type]="barChartType"></flx-dynamic-chart>
				</div>
			</ng-container>
			<ng-template #noGraph>
				<flx-heading align="center">No graph data found</flx-heading>
			</ng-template>
		</div>
		<div class="my-performance">
			<div class="heading-container">
				<flx-heading align="center" size="medium">My Performance</flx-heading>
			</div>
			<ng-container *ngIf="agent_self_performance | async as performance_data; else noPerformanceData">
				<div class="my-performance-statistics">
					<div class="my-performance-statistics_jobs_received">
						<div class="label">Current Active Jobs</div>
						<div class="statistic-value">{{ performance_data.received }}</div>
					</div>
					<div class="my-performance-statistics_jobs_resolved">
						<div class="label">Active Jobs Over SLA</div>
						<div class="statistic-value">{{ performance_data.over_sla }}</div>
					</div>
				</div>
				<div class="resolution-statistics">
					<div class="resolution-statistics-inner">
						<div class="label">Average time active jobs in state</div>
						<div class="grid-row" *ngFor="let entry of performance_data?.states | keyvalue">
							<div>States {{ entry?.['key'] }}</div>
							<div class="statistic-value">{{ convertHoursToHrsAndMin(entry?.['value']?.['average'])}}
							</div>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-template #noPerformanceData>
				<div class="no-performance-data">
					<flx-heading align="center">No performance data found</flx-heading>
				</div>
			</ng-template>
		</div>
	</div>
	<div *ngIf="(user$ | async)?.user?.roles?.includes(24)" class="bottom-view">
		<div class="agent-performance">
			<div class="heading-container">
				<flx-heading align="center" size="medium">Agent Performance</flx-heading>
				<div style="margin-top: -17px; opacity: 0.7"><flx-heading align="center" size="extra-small"
						weight="light">Average closure time per state</flx-heading></div>
			</div>
			<ng-template #noAgentPerformanceData>
				<div class="no-performance-data">
					<flx-heading align="center">No performance data found</flx-heading>
				</div>
			</ng-template>
			<ng-container *ngIf="agent_matrices?.states?.length > 0; else noAgentPerformanceData">
				<div class="performance">
					<div>
						<flx-heading class="title" align="left" size="extra-small" weight="extra-light"
							margin="0 0 1rem 0.5rem">State</flx-heading>
						<div *ngFor="let agent_name of agent_matrices?.agent_names; let i = index"
							class="agent-list-names">
							<div>{{ agent_name }}</div>
						</div>
					</div>
					<div class="states-section">
						<div class="states-wrapper">
							<div class="states">
								<flx-heading *ngFor="let state of agent_matrices?.states" align="left"
									size="extra-small" weight="extra-light"
									[style]="'width:' + 100 / agent_matrices?.states?.length + '%'">
									{{ state }}
								</flx-heading>
							</div>
						</div>
						<div *ngFor="let grid of agent_matrices?.performance_grid; let i = index"
							class="performance-grid">
							<div [style]="'width:' + 100 / agent_matrices?.states?.length + '%'"
								*ngFor="let state of agent_matrices?.states; let j = index"
								class="performance-grid-element">
								{{ agent_matrices.performance_grid?.[i]?.[j]?.[state] ?? '-'}}
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>