import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, take, skipWhile } from 'rxjs/operators';
import {
  getCurrentUser,
  NetworkService,
  StoreQuery,
  getItemTwoContextMenu,
  getItemOneContextMenu,
  ManifestController,
} from '@flexus/core';
import {
  getActiveOrganization,
  getPermissions,
  getAllInfo,
} from '@flexus/core';
import { getCurrentPage } from '@flexus/ux';
import gql from 'graphql-tag';
import { flatten, values } from 'ramda';
import { findName } from '@flexus/utilities';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'flx-sp-details-view',
  templateUrl: './details-view.component.html',
  styleUrls: ['./details-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SPDetailsViewComponent implements OnInit, OnDestroy {
  @Input() list$: Observable<any>;
  currentPage: number;
  pageSize = 30;
  itemOneContextMenuList = [];
  itemTwoContextMenuList = [];
  itemOnePermissions: Function[] = [];
  itemTwoPermissions: Function[] = [];
  allInfo$: Observable<any>;
  stateDescriptions$: Observable<any>;
  claimTypeDescriptions$: Observable<any>;
  user$: Observable<any>;
  isOnline$: Observable<boolean>;
  activeOrg$: Observable<any>;
  // loading$;
  client: string;

  currentPageSubscription: Subscription;

  constructor(
    private _store: Store<any>,
    private controller: ManifestController<any>,
    private sq: StoreQuery,
    private network: NetworkService,
    private cdr: ChangeDetectorRef
  ) {
    this.client = environment.client;
  }

  ngOnInit() {
    this.getAllInfo();
    this.getStateDescriptions();
    this.getClaimTypeDescriptions();
    this.getActiveOrg();
    this.getNetworkState();
    this.getUser();
    this.getPermissions();
    this.getItemOneContextMenuList();
    this.getItemTwoContextMenuList();
    this.getCurrentPage();
  }

  // get theList$() {
  // 	return this.list$.pipe(skipUntil(this.allInfo$), skipUntil(this.activeOrg$));
  // }
  get theList$() {
    return forkJoin([
      this.list$.pipe(
        skipWhile((res) => !res),
        take(1)
      ),
      this.allInfo$.pipe(
        skipWhile((res) => !res),
        take(1)
      ),
      this.user$.pipe(
        skipWhile((res) => !res),
        take(1)
      ),
    ])?.pipe(
      map(([list, allInfo, user]) => {
        const installers =
          allInfo && allInfo.sps ? allInfo.sps.map((sp) => sp.installer) : [];
        const cl = user?.client;
        let skill__name;

        return list.map((element) => {
          const clonedEl = JSON.parse(JSON.stringify(element));
          const client_identity = cl === 'pgg_sp' ? element?.source : null;
          if (cl !== 'multichoice') {
            skill__name = findName(element.skill, allInfo.skills)?.name;
          } else if (cl === 'multichoice') {
            if (
              findName(element.skill, allInfo.skills)?.name ===
                'DStv L2 Installation' &&
              element.claim_type_id == 37
            ) {
              skill__name = `Installer Own Stock - ${
                findName(element.skill, allInfo.skills)?.name
              }`;
            } else {
              skill__name = findName(element.skill, allInfo.skills)?.name;
            }
          }

          const appointmentStartTime =
            this.extractTimeFromISOString(element.appointment?.range_start) ??
            '';
          const appointmentEndTime = element.appointment?.range_end
            ? this.extractTimeFromISOString(element.appointment?.range_end)
            : '';
          const appointmentTime = appointmentEndTime
            ? `${appointmentStartTime} - ${appointmentEndTime}`
            : appointmentStartTime;

          return {
            ...clonedEl,
            stateName: findName(+element.state, allInfo.states)?.description,
            skillName: skill__name,
            teamleadName: findName(element.team_leader, flatten(installers))
              ?.full_name,
            address: element.suburb,
            formattedDate:
              this.formatDate(element.appointment?.range_start) ?? '',
            appointmentType:
              findName(
                element.appointment?.appointment_type,
                allInfo.appointment_types
              )?.name ?? '',
            appointmentTime,
            timeSinceUpdate:
              this.calculateElapsed(element.appointment?.range_start) ?? '',
            demandSource: client_identity,
          };
        });
      })
    );
  }
  private formatDate(d) {
    return d?.split('T')[0] ?? '';
  }
  private extractTimeFromISOString(d) {
    return d?.replace(/^[^:]*([0-2]\d:[0-5]\d).*$/, '$1') ?? '';
  }
  private calculateElapsed(d) {
    if (d) {
      const today = new Date();
      const updated = new Date(d);
      const totalTimeElapsed = today.getTime() - updated.getTime();
      const days = Math.round(totalTimeElapsed / (1000 * 3600 * 24));
      let hoursRemaining;
      let minutesRemaining;
      if (totalTimeElapsed % 24 > 0) {
        hoursRemaining = totalTimeElapsed % 24;
        if (hoursRemaining % 3600 > 0) {
          minutesRemaining = (hoursRemaining % 3600) / 60;
        }
      }
      const hours = Math.floor(hoursRemaining);
      const minutes = Math.floor(minutesRemaining);
      return days + 'd ' + hours + ':' + minutes + ':00';
    } else {
      return '';
    }
  }
  private getActiveOrg() {
    this.activeOrg$ = this.controller.select(getActiveOrganization);
  }

  private getNetworkState() {
    this.isOnline$ = this.network.isOnline;
  }

  trackByFunc(idx, claim) {
    return claim?.id;
  }

  private getUser() {
    this.user$ = this._store.select(getCurrentUser);
  }

  getCurrentPage() {
    this.currentPageSubscription = this._store
      .select(getCurrentPage)
      .subscribe((pageNum) => {
        this.currentPage = pageNum;
        this.cdr.detectChanges();
      });
  }

  getItemOneExtras$(itemOne) {
    // return this._store.select(getClaimExtra)?.pipe(map((extras) => extras && extras[itemOne.id]));
  }

  getItemOneContextMenuList() {
    this.controller
      .select(getItemOneContextMenu)
      .pipe(
        skipWhile((x) => !x),
        take(1)
      )
      .subscribe((contextMenu: any) => {
        Object.entries(contextMenu)?.forEach(([key, value]) => {
          this.itemOneContextMenuList.push({
            id: value['id'],
            name: value['name'],
            key,
            includeForOnlyStates: value['includeForOnlyStates'] || ['all'],
            excludeForOnlyStates: value['excludeForOnlyStates'] || [],
            includeFilterFunction: value['includeFilterFunction'],
            isVisible: value['isVisible'],
          });
        });
      });
  }

  getItemTwoContextMenuList() {
    this.controller
      .select(getItemTwoContextMenu)
      .pipe(
        skipWhile((x) => !x),
        take(1)
      )
      .subscribe((contextMenu: any) => {
        Object.entries(contextMenu)?.forEach(([key, value]: any[]) => {
          const data = {
            name: value['name'],
            key,
            includeForOnlyStates: value['includeForOnlyStates'] || ['all'],
            excludeForOnlyStates: value['excludeForOnlyStates'] || [],
            includeFilterFunction: value['includeFilterFunction'],
          };
          if (value?.command) {
            data['command'] = value.command;
          }
          this.itemTwoContextMenuList.push(data);
        });
      });
  }

  getPermissions() {
    this.controller
      .select(getPermissions)
      .pipe(take(1))
      .subscribe((perm) => {
        if (perm) {
          this.itemTwoPermissions = Object.values(perm['itemTwo']);
        }
      });
  }

  private getAllInfo() {
    this.allInfo$ = this._store.pipe(select(getAllInfo));
  }

  private getStateDescriptions() {
    this.stateDescriptions$ = this.sq
      .queryStore(
        gql`
          {
            allInfo {
              states {
                id
                description
              }
            }
          }
        `
      )
      .pipe(
        map(values),
        map<any, any>(flatten),
        map((data: any[]) =>
          data?.reduce(
            (acc, val: any) => ({ ...acc, [val.id]: val.description }),
            {}
          )
        )
      );
  }

  private getClaimTypeDescriptions() {
    this.claimTypeDescriptions$ = this.sq
      .queryStore(
        gql`
          {
            allInfo {
              claim_types {
                id
                name
              }
            }
          }
        `
      )
      .pipe(
        map(values),
        map<any, any>(flatten),
        map((data: any[]) =>
          data?.reduce((acc, val: any) => ({ ...acc, [val.id]: val.name }), {})
        )
      );
  }

  ngOnDestroy() {
    if (this.currentPageSubscription) {
      this.currentPageSubscription.unsubscribe();
    }
  }
}
