import { Flow_0_0_2, getFullItemOne, getSelectedItemOne } from '@flexus/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { take, map, skipWhile, filter } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

export const PGG_134: Flow_0_0_2 = {
	id: '134',
	name: 'stock_manager_await_stock',
	itemType: 'flow',
	header: {
		title: store => {
			return store.select(getFullItemOne).pipe(
				filter(x => !!x), // applicant of undefined error when going back to workflow
				skipWhile((itemOne: any) => {
					return itemOne === null || itemOne === undefined;
				}),
				map(itemOne => {
					if (itemOne) {
						return 'Stock Manager - ' + itemOne?.loan_information.voucher_key + ' - ' + itemOne?.applicant?.first_name;
					} else {
						return 'Stock Manager';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	actionPanel: (instance: any) =>
		setActionPanelItems(instance, ['call-logs', 'claim-card', 'documents', 'time-stamp'], {
			filesFunctionName: 'getAllClaimFiles'
		}),
	instructions: {
		editRoles: {
			0: 'Stock Manager - Awaited'
		},
		viewRoles: {
			0: 'Stock Manager - Awaited'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'silService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills could be found.'
		},
		serviceProviders: {
			serviceVariable: 'silService',
			functionName: 'getServiceProvidersOnClaim',
			errorMessage: 'No service providers could be found.'
		}
	},
	nodes: {
		
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {}
};
