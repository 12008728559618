<flx-loader-component *ngIf="!agentListLoaded()"></flx-loader-component>
<div class="container">
	<div class="column-headings">
		<div style="width: 40px"></div>
		<div style="padding: 10px">Name</div>
		<div style="padding: 10px">Role</div>
		<div style="padding: 10px">Job count</div>
		<div style="padding: 10px">Active</div>
		<div style="padding: 10px"></div>
	</div>
	<!-- {{agentList$ | async | json}} -->
		<!-- {{agentListLoaded()}} -->
		<ng-container *ngIf="agentList$ | async as agents">
			<div *ngFor="let agent of agents" class="agententry">
				<div class="agententry-photo">
					 <img src="" />
				</div>
				<div class="agententry-name">{{ agent.username }}</div>
				<div class="agententry-role">
					<flx-dropdown [itemsOption]="roleOptions" [displayOptions]="{ displayKey: 'display', valueKey: 'value' }" [searchEnabled]="false" placeHolder="Select role"></flx-dropdown>
				</div>
				<div class="agententry-jobcount">{{ agent.job_count }}</div>
				<div class="agententry-status">
					<flx-icon *ngIf="agent.available" [type]="'circle-correct'"></flx-icon>
					<flx-icon *ngIf="!agent.available" [type]="'circle-incorrect'"></flx-icon>
				</div>
				<div class="agententry-reallocate">
					<flx-button color="default" (click)="loadBalanceAgentAssignment(agent.id)" [disabled]="agent.available">reallocate work</flx-button>
				</div>
			</div>
		</ng-container>
</div>
