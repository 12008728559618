import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  take,
  switchMap,
  map,
  pluck,
  skipWhile,
  filter,
  tap,
  catchError,
  mapTo,
  mergeMap,
} from 'rxjs/operators';
import {
  combineLatest,
  Observable,
  from,
  forkJoin,
  empty,
  of,
  throwError,
} from 'rxjs';
import moment from 'moment';
import { environment } from 'apps/studio/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ClaimService } from './child-services/claim.service';
import { JobService } from './child-services/job.service';
import {
  getSelectedItemOne,
  getSelectedItemTwo,
  getAllInfo,
  getFullItemOne,
  BigFormService,
  getSubmissionData,
  getFullItemTwo,
  getCurrentUser,
  getAppointmentTypes,
  IndexedDbService,
  getData,
  ModalService,
} from '@flexus/core';
import { NewJobPayload } from '../models/new-job.model';
import {
  inlineRecursiveMerge,
  transformCallLog,
  findName,
  getAllInfoIndex,
} from '@flexus/utilities';
import { UUID } from 'angular2-uuid';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';

@Injectable()
export class SilService {
  internalAssessorJobStates = [
    96, // internal_assessment
    97, // internal_assessor_job_report_uploaded
  ];
  APPOINTMENT_TYPE_BETWEEN = 3;

  constructor(
    private store: Store<any>,
    private http: HttpClient,
    private claimService: ClaimService,
    private jobService: JobService,
    private bf: BigFormService,
    private indexedDbService: IndexedDbService,
    private modalsService: ModalService,
    private router: Router,
    private decimalPipe: DecimalPipe
  ) {}

  getSelectedItemOne() {
    return this.store.select(getSelectedItemOne).pipe(
      filter((x) => !!x),
      take(1)
    );
  }

  getSelectedItemTwo() {
    return this.store.select(getSelectedItemTwo).pipe(
      filter((x) => !!x),
      take(1)
    );
  }

  getJobSP() {
    return this.getSelectedItemTwo().pipe(
      pluck('sp'),
      switchMap((sp) => {
        return this.getInterestedParties().pipe(
          pluck('payload'),
          map((interested_parties: any[]) => ({
            ...interested_parties.find((ip) => ip?.sp?.id === sp),
          }))
        );
      }),
      take(1)
    );
  }

  getAppointSPSummary() {
    return this.getJobSP().pipe(
      map((interestedParty) => {
        let res;
        switch (interestedParty.new_interested) {
          case -1: {
            res = 'Rejected';
            break;
          }
          case 0: {
            res = 'Ignored';
            break;
          }
          case 1: {
            res = 'Accepted';
            break;
          }
          case null: {
            res = 'No-Reply';
            break;
          }
        }

        return [
          { 'Primary Contact': interestedParty.sp.contact_person },
          { 'Primary Contact Number': interestedParty.sp.contact_primary },
          {
            maven_id:
              interestedParty.sp.mid !== null
                ? interestedParty.sp.mid
                : 'Not On Maven',
          },
          {
            'response to job': res,
          },
          { 'Currently Awarded Jobs': interestedParty.awarded_today },
        ];
      }),
      take(1)
    );
  }

  appointSP() {
    return this.getSelectedItemTwo().pipe(
      switchMap((jobItem) => {
        const selected_sp = this.bf.bigForm.get('sp_selected_item')?.value;
        return this.jobService.manuallyAllocateJob({
          jobId: jobItem?.id,
          spId: selected_sp?.id,
        });
      })
    );
  }

  repingSP() {
    const appointment = this.bf.bigForm.get('appointmentData')?.value;
    const date = moment(appointment.appointmentDatePicker);
    date.hour(appointment.appointmentTimePicker.hour);
    date.minutes(appointment.appointmentTimePicker.minutes);

    const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
    const appointment_formatted = [
      {
        range_start: date_formatted,
        range_end: date_formatted,
        appointment_type_id: appointment.appointmentTime,
      },
    ];

    return this.getSelectedItemTwo().pipe(
      pluck('id'),
      switchMap((job_id) => {
        return this.jobService.repingJob({
          appointments: appointment_formatted,
          job_id: job_id,
        });
      })
    );
  }

  getAllClaims() {
    return this.claimService.getAllClaims();
  }

  getSuitableSps() {
    return this.getSelectedItemTwo().pipe(
      skipWhile((x) => !x),
      pluck('id'),
      switchMap((jobId) => {
        return this.jobService.getSuitableSps({ job_id: jobId });
      })
    );
  }

  getClaimAppointments() {
    return this.getSelectedItemOne().pipe(
      switchMap((claim) => {
        return this.claimService.getClaimAppointments({ claim_id: claim?.id });
      })
    );
  }

  getCurrentFullItemOne() {
    return this.getSelectedItemOne().pipe(
      take(1),
      switchMap((item) => this.getFullItemOne(item?.id))
    );
  }

  getFullItemOne(id) {
    return this.claimService.getFullClaim(id);
  }

  getFullItemOneWithItemTwo(id) {
    return this.claimService.getFullClaimWithJobs(id);
  }

  getFullItemTwo(id) {
    return this.jobService.getFullJob(id);
  }

  getInterestedParties() {
    return this.getSelectedItemTwo().pipe(
      skipWhile((x) => !x),
      pluck('id'),
      switchMap((jobId) => {
        return this.jobService.getInterestedParties({ job_id: jobId });
      })
    );
  }

  dealOutOfApp() {
    return this.getSelectedItemTwo().pipe(
      switchMap((itemTwo) =>
        this.jobService.updateJob({ job_id: itemTwo?.id, new_state: 76 })
      )
    );
  }

  cancelJob() {
    return this.getSelectedItemTwo().pipe(
      switchMap((job) => {
        return this.jobService.cancelJob({
          job_id: job?.id,
          reason: this.bf.bigForm.value?.cancelclaim_reason,
        });
      })
    );
  }

  newJob(jobData: NewJobPayload) {
    return this.jobService.newJob(jobData);
  }

  assessorVisitSite() {
    return forkJoin([
      this.store.select(getFullItemTwo).pipe(
        skipWhile((x) => !x),
        take(1),
        map((res) => res as any)
      ),
      this.store.select(getAllInfo).pipe(
        skipWhile((x) => !x),
        take(1),
        pluck('appointment_types'),
        map((res) => res as any[])
      ),
    ]).pipe(
      switchMap(([job, appointment_types]) => {
        let new_state;
        let return_to_state;
        console.log('job', job, 'app', appointment_types);
        switch (job.state) {
          case 106:
          case 32:
            new_state = job?.state;
            return_to_state = 90;
            break;
          case 90:
            new_state = 90;
            return_to_state = job?.job_information?.return_to_state;
            break;
          default:
            new_state = 90;
            if (job.state in [32, 90, 106]) {
              return_to_state = 89;
            } else {
              return_to_state = job?.state;
            }
        }

        const appointmentData: any =
          this.bf.bigForm.get('appointmentData')?.value;
        const date = appointmentData.appointmentDatePicker
          ? moment(appointmentData.appointmentDatePicker)
          : null;

        const range_start = moment(
          new Date(
            date.year(),
            date.month(),
            date.date(),
            appointmentData.appointmentTimePicker.hour,
            appointmentData.appointmentTimePicker.minutes
          )
        ).format('YYYY-MM-DDTHH:mm:ss');

        const data = {
          appointment: {
            appointment_type: appointmentData.appointmentTime
              ? appointment_types.find(
                  (type) => type?.id === appointmentData.appointmentTime
                )?.id
              : null,
            range_start: range_start,
            reason: '',
          },
          current_state: job?.state,
          job_id: job?.id,
          job_information: {
            ...job.job_information,
            return_to_state: return_to_state,
          },
          new_state: new_state,
        };

        return this.jobService.updateJob(data);
      })
    );
  }

  updateUnmanagedMavenJob() {
    return forkJoin([
      this.store.select(getSubmissionData).pipe(
        skipWhile((x) => !x || !x.job_information),
        take(1)
      ),
      this.store.select(getFullItemTwo).pipe(take(1)),
    ]).pipe(
      map(([submissionData, job]) => {
        const job_information = {
          ...job.job_information,
          ...submissionData.job_information,
        };
        if (
          !job_information.OutAppFlowSPid &&
          !job_information.OutAppFlowSPMID
        ) {
          job_information.OutAppFlowSPid = 0;
          job_information.OutAppFlowSPMID = 0;
        }
        return {
          job_id: job?.id,
          new_state: submissionData?.new_state,
          job_information: job_information,
        };
      }),
      switchMap((data) => {
        return this.jobService.updateJob(data);
      })
    );
  }

  generateBOQReport() {
    return this.store
      .select(getFullItemTwo)
      .pipe(take(1))
      .pipe(
        switchMap((response) => {
          const { id } = response;
          return this.jobService.generateBOQReport(id);
        })
      );
  }

  updateJob() {
    return forkJoin([
      this.store.select(getSubmissionData).pipe(take(1)),
      this.store.select(getFullItemTwo).pipe(take(1)),
    ]).pipe(
      map(([submissionData, job]) => {
        const { job_information } = job;
        const { job_information: ji, ...rest } = submissionData;
        const result = JSON.parse(JSON.stringify(job_information ?? {}));
        inlineRecursiveMerge(result, ji);

        const response = {
          job_information: result,
          ...rest,
          job,
        };
        return response;
      }),
      switchMap((response) => {
        const { job, ...data } = response;
        return this.jobService.updateJob({ job_id: job?.id, ...data });
      })
    );
  }

  updateClaim() {
    return forkJoin(
      this.store.select(getSubmissionData).pipe(take(1)),
      this.store.select(getFullItemOne).pipe(take(1))
    ).pipe(
      map(([submissionData, claim]) => {
        const { loan_information } = claim;
        const { loan_information: ci, ...rest } = submissionData;
        const result = JSON.parse(JSON.stringify(loan_information));
        inlineRecursiveMerge(result, ci);

        const response = {
          loan_information: result,
          ...rest,
          claim,
        };
        return response;
      }),
      switchMap((response) => {
        const { claim, ...data } = response;
        return this.claimService.updateClaim({ claim_id: claim?.id, ...data });
      })
    );
  }

  createClaim() {
    return this.store.select(getSubmissionData).pipe(
      take(1),
      switchMap((claim) => {
        return this.claimService.createClaim({ ...claim });
      })
    );
  }

  cancelClaim() {
    return this.getSelectedItemOne().pipe(
      switchMap((claim) => {
        return this.claimService.cancelClaim({
          claim_id: claim?.id,
          reason: this.bf.bigForm?.value?.cancel_reason,
        });
        // return this.claimService.cancelClaim({claim_id: claim.id, reason: this.bf.bigForm.value.cancel_reason})
      })
    );
  }

  reopenClaim(data: any) {
    return this.store
      .select(getFullItemOne)
      .pipe(
        skipWhile((x) => !x),
        take(1),
        map((res) => res as any)
      )
      .pipe(
        map((claim) => {
          const claim_id = (claim as any)?.id;
          const reason = this.bf.bigForm.get('reopen_data');

          return {
            claim_id: claim_id,
            reopen_code: reason?.value?.reopen_reason,
          };
        }),
        switchMap((response) => {
          return this.claimService.reopenClaim(response);
        })
      );
  }

  saveAsServerDraft() {
    return this.store.select(getSubmissionData).pipe(
      take(1),
      switchMap((claim) => {
        return this.claimService.createClaim({ ...claim, new_state: 8 });
      })
    );
  }

  checkNetworkAndUpdateDraft() {
    if (window.navigator.onLine) {
      // update server draft
      return this.saveAsServerDraft();
    } else {
      return this.saveAsLocalDraft();
    }
  }

  updateServerDraft(new_state) {
    return forkJoin(
      this.store.select(getSubmissionData).pipe(take(1)),
      this.store.select(getFullItemOne).pipe(take(1))
    ).pipe(
      map(([submissionData, claim]) => {
        const { loan_information } = claim;
        const { loan_information: ci, ...rest } = submissionData;
        const result = JSON.parse(JSON.stringify(loan_information));
        inlineRecursiveMerge(result, ci);

        const response = {
          loan_information: result,
          ...rest,
          claim,
        };
        // console.log({ claim });
        return response;
      }),
      switchMap((response) => {
        const { claim, ...data } = response;
        return this.claimService.updateClaim({
          claim_id: claim?.id,
          ...data,
          new_state,
        });
      })
    );
  }

  updateServerDraftAsSubmitted() {
    return forkJoin(
      this.store.select(getSubmissionData).pipe(take(1)),
      this.store.select(getFullItemOne).pipe(take(1))
    ).pipe(
      map(([submissionData, claim]) => {
        const { loan_information } = claim;
        const { loan_information: ci, new_state, ...rest } = submissionData;
        const result = JSON.parse(JSON.stringify(loan_information));
        inlineRecursiveMerge(result, ci);

        const response = {
          loan_information: result,
          ...rest,
          claim,
          new_state,
        };
        return response;
      }),
      switchMap((response) => {
        const { claim, new_state, ...data } = response;
        return this.claimService.updateClaim({
          claim_id: claim?.id,
          ...data,
          new_state: new_state,
        });
      })
    );
  }

  saveAsLocalDraft() {
    return this.store.select(getSubmissionData).pipe(
      take(1),
      switchMap((item) => {
        const localDraft = { tempKey: UUID.UUID(), ...item, state: 169 };
        return from(this.indexedDbService.claimInDraft.put(localDraft));
      })
    );
  }

  checkNetworkAndSaveDraft() {
    if (window.navigator.onLine) {
      return this.saveAsServerDraft();
    } else {
      return this.saveAsLocalDraft();
    }
  }

  deleteCurrentEditableItem() {
    return from(this.indexedDbService.currentItem.delete('currentItem')).pipe(
      map((res) => ({ success: true, payload: res }))
    );
  }

  deleteLocalDraft() {
    return this.getSelectedItemOne().pipe(
      switchMap((itemOne) =>
        from(this.indexedDbService.claimInDraft.delete(itemOne.tempKey))
      )
    );
  }

  navigateToWorkflow() {
    return from(this.router.navigate(['/workflow']));
  }

  policyLookup(data: { search: string }) {
    return this.http
      .post(`${environment.api_url}v1/claim_action/get_policies/`, {
        search: data?.search,
      })
      .pipe(
        map((res: any) => {
          const policyList = res?.payload?.policies;
          const updatedPolicyList = policyList.map((policy) => {
            return policy.hasOwnProperty('POSTALCODE')
              ? policy
              : { ...policy, POSTALCODE: policy?.SUBURBCODE };
          });
          return { ...res, payload: { policies: [...updatedPolicyList] } };
        })
      );
  }

  policyHistorySearch(data: { search: string }) {
    return this.http.post(
      `${environment.api_url}v1/claim_action/get_claim_history/`,
      {
        search: data?.search,
      }
    );
  }

  getItemOneExtra() {
    return this.getSelectedItemOne().pipe(
      switchMap((claim) => {
        return this.claimService.getClaimExtra(claim?.id);
      })
    );
  }

  getCloseClaimInfo() {
    return this.getSelectedItemOne().pipe(
      switchMap((claim) => {
        return this.getCloseClaimInfoAPI({ claim_id: claim?.id }).pipe(
          map((res) => {
            return res;
          })
        );
      })
    );
  }

  getSkills() {
    return this.store.select(getAllInfo).pipe(
      skipWhile((allInfo: any) => {
        return allInfo.states === null || allInfo.states[1] === undefined;
      })
    );
  }

  getAppointmentTypes() {
    return this.store.select(getAppointmentTypes).pipe(
      skipWhile((appointmentTypes: any) => {
        return appointmentTypes === null || appointmentTypes === undefined;
      })
    );
  }

  getActiveReions() {
    return this.store.select(this.getActiveReions).pipe(
      skipWhile((activeReions: any) => {
        return activeReions === null || activeReions === undefined;
      })
    );
  }

  getJobInvoice() {
    return this.getSelectedItemTwo().pipe(
      skipWhile((x) => !x),
      take(1),
      switchMap((itemTwo: any) => {
        return this.http
          .post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
            job_id: itemTwo?.id,
            return_type: 1,
          })
          .pipe(map((payload) => payload.payload));
      })
    );
  }

  getJobReport() {
    return this.getSelectedItemTwo().pipe(
      skipWhile((x) => !x),
      take(1),
      switchMap((itemTwo: any) => {
        return this.http
          .post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
            job_id: itemTwo?.id,
            return_type: 1,
          })
          .pipe(map((payload) => payload?.payload));
      })
    );
  }

  getServiceProvidersOnClaim() {
    return combineLatest([
      this.getSelectedItemOne(),
      this.store.select(getAllInfo).pipe(map((allInfo: any) => allInfo?.sps)),
    ]).pipe(
      map(([claim, serviceProviders]) => {
        const jobs = claim?.jobs.filter(
          (job) => job?.state !== 45 || job?.state !== 72
        );
        return jobs
          .filter((job) => !!job?.sp)
          .map((job) => ({
            jobId: job?.id,
            skill: job?.skill,
            spName: findName(job?.sp, serviceProviders)?.name,
          }));
      })
    );
  }

  getClaimMid() {
    return this.store.select(getFullItemOne).pipe(
      skipWhile((x) => !x),
      pluck('mid'),
      take(1),
      map((data) => {
        return data;
      })
    );
  }

  getAllClaimFiles() {
    return this.getSelectedItemOne().pipe(
      switchMap((itemOne) => {
        return this.getAllFilesApi(null, itemOne?.id, null);
      })
    );
  }

  getAllJobFiles() {
    return this.getSelectedItemTwo().pipe(
      switchMap((itemTwo) => {
        if (itemTwo) {
          return this.getAllFilesApi(itemTwo?.id, null);
        } else {
          return empty();
        }
      })
    );
  }

  getNotes() {
    return combineLatest([
      this.getSelectedItemTwo(),
      this.getInternalAssessorJob(),
    ]).pipe(
      switchMap(([itemTwo, iaJob]: any) => {
        if (itemTwo && iaJob && itemTwo?.id === iaJob?.id) {
          return this.getjobAssessmentNotes();
        } else {
          return this.getJobNotes();
        }
      })
    );
  }

  getJobNotes() {
    return this.getSelectedItemTwo().pipe(
      switchMap((itemTwo) => {
        if (itemTwo) {
          return this.getJobNotesApi(itemTwo?.id);
        } else {
          return empty();
        }
      })
    );
  }

  getjobAssessmentNotes() {
    return this.getSelectedItemTwo().pipe(
      switchMap((itemTwo) => {
        return this.getjobAssessmentNotesApi(itemTwo?.id).pipe(
          skipWhile((x) => !x),
          pluck('payload'),
          map((iaNotes) =>
            iaNotes.map((iaNote) => ({
              timestamp: iaNote?.created,
              message: iaNote?.note,
            }))
          )
        );
      })
    );
  }

  getInternalAssessorJob() {
    return this.store.select(getSelectedItemOne).pipe(
      take(1),
      map((itemOne) => {
        if (itemOne) {
          const iaJob = this.getinternalAssessorJobCard(itemOne?.jobs);
          return iaJob ? iaJob : { id: 0 };
        }
      })
    );
  }

  getPolicyHistory() {
    return this.store.select(getSelectedItemOne).pipe(
      skipWhile((x) => !x),
      take(1),
      switchMap((itemOne) => {
        if (itemOne) {
          return this.policyHistoryLookup({
            claim_id: itemOne?.id,
            search: this.bf.bigForm.value?.policy_number,
          }).pipe(
            pluck('payload', 'claims'),
            map((claims) => {
              if (claims) {
                return (claims as any[]).map((claim) => {
                  const obj = {
                    date: {
                      title: 'Loss Date',
                      value: this.formatDate(claim?.LOSSDATE),
                    },
                    eventType: { title: 'Claim Type', value: claim?.CLAIMTYPE },
                    additionalItems: [
                      { title: 'Status', value: claim?.STATUS },
                      { title: 'Claim Cost', value: `R${claim?.CLAIMCOST}` },
                    ],
                    description: { title: 'Comment', value: claim?.COMMENTS },
                  };
                  return obj;
                });
              }
              return claims;
            }),
            map((claims: any[]) => {
              if (claims) {
                return {
                  claims: claims.sort((a, b) =>
                    moment.utc(b.date.value)?.diff(a.date.value)
                  ),
                };
              }
              return { claims: claims };
            }),
            take(1)
          );
        } else {
          return empty();
        }
      }),
      map((res) => res?.claims)
    );
  }

  formatDate(date: string): Date {
    return moment(date, 'MM-D-YYYY h mm ss a').toDate();
  }

  // TODO CLAIM SERVICES

  getCloseClaimInfoAPI(params: any): Observable<any> {
    return this.http.post(
      environment.api_url + `v1/claim_action/can_finalise_claim/`,
      params
    );
  }

  finaliseClaim(params: { claim_id: number }): Observable<any> {
    return this.http.post(
      environment.api_url + `v1/claim_action/finalise_claim/`,
      params
    );
  }

  policyHistoryLookup(data: { claim_id: string; search: string }) {
    return this.http.post(
      `${environment.api_url}v1/claim_action/get_claim_history/`,
      {
        search: data?.search || '',
        claim_id: data?.claim_id,
      }
    );
  }

  searchClaims(params: { search: string; active: boolean }) {
    return this.http.post<any>(
      environment.api_url + `v1/claim_action/search_claims/`,
      { ...params }
    );
  }

  checkCatastrophe(param: {
    claim_type: number;
    postal_code: number;
    loss_date: string;
  }) {
    return this.http.post(
      environment.api_url + `v1/cat_action/check_catastrophe/`,
      param
    );
  }

  getExcessDetailsService(data: { claim_id: number }) {
    return this.http.post<any>(
      `${environment.api_url}v1/claim_action/get_excess_details/`,
      data
    );
  }

  // INTERNAL ASSESSOR SERVICES

  isInternalAssessorState(state: number) {
    return this.internalAssessorJobStates.includes(state);
  }

  getinternalAssessorJobCard(jobs: any[]) {
    return jobs.find((j) => [89, 90, 28, 88, 37, 97]?.includes(j?.state));
  }

  internalAssessorJobHasReport(state: number) {
    return state === 97; // internal_assessor_job_report_uploaded;
  }

  getAllFilesApi(jobId?: number, claimId?: number, purpose?: string) {
    const data: any = {};
    if (jobId) {
      data.job_id = jobId;
    }

    if (claimId) {
      data.claim_id = claimId;
    }

    if (purpose) {
      data.purpose = purpose;
    }

    return this.http.post<any>(
      environment.api_url + 'v1/file_action/get_files',
      data
    );
  }

  addAssessmentNoteApi() {
    return this.getSelectedItemTwo().pipe(
      switchMap((itemTwo) =>
        this.http.post<any>(
          environment.api_url + 'v1/job_action/add_assessment_note',
          {
            job_id: itemTwo?.id,
            note: this.bf.bigForm.get('ia_message')?.value,
          }
        )
      )
    );
  }

  getjobAssessmentNotesApi(job_id: number) {
    const data = { job_id, job_only: true };
    return this.http.post<any>(
      environment.api_url + 'v1/job_action/get_assessment_notes',
      data
    );
  }

  getAssessmentNotesForAllJobsApi(job_id: number) {
    const data = { job_id, job_only: false };
    return this.http.post<any>(
      environment.api_url + 'v1/job_action/get_assessment_notes',
      data
    );
  }

  getJobNotesApi(job_id: number) {
    const data = { job_id };
    return this.http.post<any>(
      environment.api_url + 'v1/job_action/get_notes',
      data
    );
  }

  getAuthorizers() {
    return this.http
      .get(environment.api_url + 'v1/staff_action/get_authorizers')
      .pipe(map((response) => response['payload']));
  }

  // ----------------------------- AVS and Branch look up services -----------------------------
  getAvsCheckSIL(avsData: any) {
    return this.http.post<any>(
      `${environment.api_url}v1/claim_action/check_account_details/`,
      avsData
    );
  }

  getBranchLookupResults(params: { bank: string; branch: string }) {
    return this.http.post<any>(
      `${environment.api_url}v1/claim_action/get_branch_code/`,
      { ...params }
    );
  }

  getSPReplyListData() {
    let state: number = null;
    return this.store.select(getSelectedItemTwo).pipe(
      skipWhile((selected: any) => !selected),
      take(1),
      mergeMap((selectedItem: any) => {
        state = selectedItem?.state;
        if (state === 20) {
          return forkJoin([
            this.store.select(getSelectedItemTwo).pipe(
              skipWhile((x) => !x),
              take(1)
            ),
            this.store.select(getCurrentUser).pipe(
              skipWhile((x) => !x),
              take(1)
            ),
            this.store.select(getAllInfo).pipe(
              take(1),
              map((allInfo: any) => allInfo.sps),
              map((sps: any[]) =>
                sps?.reduce((acc, sp: any) => ({ ...acc, [sp.id]: sp }), {})
              )
            ),
            this.store.select(getSelectedItemTwo).pipe(
              skipWhile((x) => !x),
              pluck('id'),
              take(1),
              switchMap((jobId) =>
                this.http.post(
                  `${environment.api_url}v1/job_action/get_interested_parties/`,
                  {
                    job_id: jobId,
                  }
                )
              ),
              map((res: any) => res?.payload)
            ),
          ]).pipe(
            map(([job, user, sps, parties]: any) => {
              const jobstate = job?.state;
              if (!!job && !!user && !!sps && !!parties) {
                const isTeamLeader = user?.user?.is_team_leader === true;
                const templateTitle = `The Currently assigned SP is : ${
                  job.sp ? sps[job.sp]?.name : 'none'
                }`;
                const listConfig = { alwaysEmitEvent: true };

                const entityCardState = {
                  // info: ' entity-card--info',
                  // warning: 'entity-card--warning',
                  '-1': { state: 'entity-card--danger', blurb: 'Declined' },
                  1: { state: 'entity-card--success', blurb: 'Accepted' },
                  null: { state: 'entity-card--default', blurb: 'No-Reply' },
                  0: { state: 'entity-card--default', blurb: 'Ignored' },
                  currentSP: {
                    state: 'entity-card--info',
                    blurb: 'Current SP',
                  },
                };
                const responseOrderWeightMap = {
                  'No-Reply': 3,
                  Accepted: 2,
                  Ignored: 4,
                  Declined: 5,
                  'Current SP': 0,
                };

                const data = parties
                  .map((party: any) => {
                    let lookupValue = party.new_interested;
                    lookupValue =
                      job && job?.sp === party.sp.id
                        ? 'currentSP'
                        : lookupValue;

                    const cardStateInfo = entityCardState[lookupValue];
                    // if (jobstate === 20) {
                    return {
                      entityId: party.sp.id,
                      cardStateInfo,
                      columns: [
                        // col1 ~ sp details
                        {
                          subTitle: party.sp.name,
                          description: party.sp.contact_primary,
                        },
                        // col2 ~ need to know
                        {
                          subTitle: `Allocated today - ${party.awarded_today}`,
                          description: `Score: ${party.awarded_today}`,
                        },
                      ],
                    };
                  })
                  .sort(
                    (a, b) =>
                      responseOrderWeightMap[a.cardStateInfo.blurb] -
                      responseOrderWeightMap[b.cardStateInfo.blurb]
                  );
                return {
                  isTeamLeader,
                  templateTitle,
                  listConfig,
                  cardData: data || [],
                  loadingMessage: 'loading',
                  noCardsMessage: 'There are no records to display',
                };
              }
              return {
                templateTitle: '',
                cardData: [],
              };
            })
          );
        } else if (state === 311) {
          return forkJoin([
            this.store.select(getSelectedItemTwo).pipe(
              skipWhile((x) => !x),
              take(1)
            ),
            this.store.select(getCurrentUser).pipe(
              skipWhile((x) => !x),
              take(1)
            ),
            this.store.select(getAllInfo).pipe(
              take(1),
              map((allInfo: any) => allInfo.sps),
              map((sps: any[]) =>
                sps?.reduce((acc, sp: any) => ({ ...acc, [sp.id]: sp }), {})
              )
            ),
            this.store.select(getSelectedItemTwo).pipe(
              skipWhile((x: any) => !x),
              pluck('id'),
              take(1),
              switchMap((jobId: any) =>
                this.http.post(
                  `${environment.api_url}v1/job_action/get_suitable_sps/`,
                  {
                    job_id: jobId,
                  }
                )
              ),
              map((res: any) => res?.payload)
            ),
            this.store.select(getSelectedItemTwo).pipe(
              skipWhile((x) => !x),
              pluck('id'),
              take(1),
              switchMap((jobId) =>
                this.http.post(
                  `${environment.api_url}v1/job_action/get_interested_parties/`,
                  {
                    job_id: jobId,
                  }
                )
              ),
              map((res: any) => res?.payload)
            ),
            this.store.select(getSelectedItemTwo).pipe(
              skipWhile((x) => !x),
              pluck('id'),
              take(1),
              switchMap((jobId) =>
                this.http.get(
                  `${environment.api_url}v1/job_action/get_sps_scores/`,
                  {
                    params: {
                      job_id: jobId,
                    },
                  }
                )
              ),
              map((result: any) => result.payload)
            ),
          ]).pipe(
            map(
              ([
                job,
                user,
                sps,
                sps_with_distance,
                interested,
                sp_scores,
              ]: any) => {
                // console.log('scores', sp_scores);
                // console.log('interest', interested);
                console.log('distance sps', sps_with_distance);
                let spsInterests = [];
                let lookupValue;
                let awardedtoday;
                for (let i = 0; i < sps_with_distance.length; i++) {
                  for (let j = 0; j < interested.length; j++) {
                    if (sps_with_distance[i].sp === interested[j]?.sp?.id) {
                      lookupValue = interested[j]?.new_interested;
                      lookupValue =
                        job && job?.sp === interested[j]?.sp.id
                          ? 'currentSP'
                          : lookupValue;
                      awardedtoday = interested[j]?.awarded_today;
                    }
                  }
                }

                if (!!job && !!user && !!sps && !!sps_with_distance) {
                  const isTeamLeader = user?.user?.is_team_leader === true;
                  const templateTitle = `The Currently assigned SP is : ${
                    job.sp ? sps[job.sp]?.name : 'none'
                  }`;
                  const listConfig = { alwaysEmitEvent: true };

                  const entityCardState = {
                    '-1': { state: 'entity-card--danger', blurb: 'Declined' },
                    1: { state: 'entity-card--success', blurb: 'Accepted' },
                    null: { state: 'entity-card--default', blurb: 'No-Reply' },
                    0: { state: 'entity-card--default', blurb: 'Ignored' },
                    currentSP: {
                      state: 'entity-card--info',
                      blurb: 'Current SP',
                    },
                  };
                  const responseOrderWeightMap = {
                    'No-Reply': 3,
                    Accepted: 2,
                    Ignored: 4,
                    Declined: 5,
                    'Current SP': 0,
                  };
                  const sps_with_score_and_distance = [];

                  const sps_with_score = sp_scores.map((sp_score: any) => {
                    for (const key in sps) {
                      if (sps[key]?.id === sp_score?.sp) {
                        const dataobj = {
                          id: sps[key]?.id,
                          name: sps[key]?.name,
                          contact: sps[key]?.contact_primary,
                          score: sp_score?.actual_score,
                        };
                        return dataobj;
                      }
                    }
                  });
                  console.log('sps with score', sps_with_score);
                  const data = sps_with_distance
                    .map((distance_sp: any) => {
                      for (const key in sps_with_score) {
                        if (sps_with_score[key]?.id === distance_sp?.sp) {
                          const dataobj = {
                            id: sps_with_score[key].id,
                            name: sps_with_score[key].name,
                            contact: sps_with_score[key].contact,
                            score: sps_with_score[key].score,
                            distance: distance_sp?.distance,
                          };
                          sps_with_score_and_distance.push(dataobj);
                        }
                      }

                      let distance_fordata;
                      let distance_forsort;
                      let spname;
                      let number;
                      let score;
                      // distance_fordata = sps_with_score_and_distance;
                      for (
                        let i = 0;
                        i < sps_with_score_and_distance.length;
                        i++
                      ) {
                        distance_fordata = this.decimalPipe.transform(
                          sps_with_score_and_distance[i]?.distance,
                          '1.0-2'
                        );
                        distance_forsort =
                          sps_with_score_and_distance[i]?.distance;
                        spname = sps_with_score_and_distance[i]?.name;
                        number = sps_with_score_and_distance[i]?.contact;
                        score = sps_with_score_and_distance[i].score;
                      }

                      const cardStateInfo = entityCardState[lookupValue];

                      return {
                        entityId: distance_sp.sp,
                        cardStateInfo,
                        distance_forsort,
                        columns: [
                          // col1 ~ sp details
                          { spname: spname, subTitle: number },
                          // col2 ~ need to know
                          {
                            title: cardStateInfo.blurb,
                            subTitle: `Allocated today - ${awardedtoday}`,
                          },
                          { spRatingTitle: 'SP Rating', score: score },
                          {
                            subtitle: 'Distance from client',
                            distance: distance_fordata,
                          },
                          { distance_unit: 'km' },
                        ],
                      };
                    })
                    .sort((a, b) => {
                      return a.distance_forsort - b.distance_forsort;
                    });
                  console.log(
                    'score and distance',
                    sps_with_score_and_distance
                  );

                  return {
                    isTeamLeader,
                    templateTitle,
                    listConfig,
                    cardData: data || [],
                    loadingMessage: 'loading',
                    noCardsMessage: 'There are no records to display',
                  };
                }
                return {
                  templateTitle: '',
                  cardData: [],
                };
              }
            )
          );
        }
      })
    );
  }

  retryOnMaven() {
    return this.getSelectedItemTwo().pipe(
      switchMap((itemTwo) => {
        return this.http
          .post(
            `${environment.api_url}v1/job_action/retry_upload_invoice_to_maven/`,
            { job_id: itemTwo?.id }
          )
          .pipe(
            filter((x) => !!x),
            take(1)
          );
      })
    );
  }

  getCallLogs() {
    return this.getSelectedItemOne().pipe(
      switchMap((itemOne) => {
        return this.http
          .post<any>(`${environment.api_url}v1/claim_action/get_call_logs/`, {
            claim_id: itemOne?.id,
          })
          .pipe(
            filter((x) => !!x),
            map((data: any) => {
              const arr = [];
              data?.payload.forEach((log) => {
                const formattedLog = transformCallLog(log);
                arr.push(formattedLog);
              });

              return arr;
            })
          );
      })
    );
  }

  addCallLog() {
    return this.getSelectedItemOne().pipe(
      switchMap((itemOne) => {
        const data = {
          claim_id: itemOne?.id,
          message: this.bf.bigForm.get('message')?.value,
          channel: this.bf.bigForm.get('channel')?.value,
          reason: this.bf.bigForm.get('reason')?.value,
          direction: this.bf.bigForm.get('direction')?.value,
        };
        return this.http
          .post(`${environment.api_url}v1/claim_action/add_call_log/`, {
            ...data,
          })
          .pipe(
            switchMap((x: any) => {
              if (x.success) {
                const addedLog = transformCallLog(x.payload);

                this.bf.bigForm.patchValue({
                  message: '',
                  channel: null,
                  reason: null,
                  direction: null,
                });

                return this.store.select(getData).pipe(
                  map((contextData: any) => [
                    ...[addedLog],
                    ...contextData.logs,
                  ]),
                  take(1)
                );
              }
            })
          );
      })
    );
  }

  getClaimNotes() {
    return this.getSelectedItemOne().pipe(
      switchMap((itemOne) => {
        return this.getClaimNotesApi(itemOne?.id);
      })
    );
  }

  getClaimNotesApi(claim_id: number) {
    const data = { claim_id };
    return this.http
      .post<any>(environment.api_url + 'v1/claim_action/get_notes', data)
      .pipe(
        filter((x) => !!x),
        map((notes: any) => {
          const arr = [];
          notes.payload.forEach((note) => {
            note.date = moment(note['timestamp']).format(moment.HTML5_FMT.DATE);
            note.time = moment(note['timestamp']).format(moment.HTML5_FMT.TIME);
            arr.push(note);
          });
          return arr;
        })
      );
  }

  addJobNote() {
    return this.getSelectedItemTwo().pipe(
      switchMap((itemTwo) => {
        const data = {
          job_id: itemTwo?.id,
          message: this.bf.bigForm.get('message')?.value,
        };
        return this.http
          .post(`${environment.api_url}v1/job_action/add_note/`, { ...data })
          .pipe(map((response) => response['payload']));
      })
    );
  }

  addClaimNote() {
    return this.getSelectedItemOne().pipe(
      switchMap((itemOne) => {
        const data = {
          claim_id: itemOne?.id,
          message: this.bf.bigForm.get('message')?.value,
        };

        return this.http
          .post(`${environment.api_url}v1/claim_action/add_note/`, { ...data })
          .pipe(map((response) => response['payload']));
      })
    );
  }

  updateJobNewState() {
    return forkJoin([
      this.getSelectedItemTwo().pipe(
        pluck('id'),
        skipWhile((x) => !x),
        take(1)
      ),
      this.store.select(getSubmissionData).pipe(
        pluck('new_state'),
        skipWhile((x) => !x),
        take(1)
      ),
    ]).pipe(
      switchMap(([job_id, new_state]) => {
        return this.jobService.updateJob({
          job_id: job_id,
          new_state: new_state,
        });
      })
    );
  }

  assignAssessor() {
    return forkJoin([
      this.getSelectedItemTwo().pipe(
        pluck('id'),
        skipWhile((x) => !x),
        take(1)
      ),
      this.store.select(getSubmissionData).pipe(
        pluck('assign_assessor_id'),
        skipWhile((x) => !x),
        take(1)
      ),
    ]).pipe(
      switchMap(([jobId, assessorId]) => {
        return this.jobService.assignAssessor({
          job_id: jobId,
          assessor_id: assessorId,
        });
      })
    );
  }

  AssessorReportUploadedUpdateJob() {
    return this.store.select(getFullItemTwo).pipe(
      skipWhile((x) => !x),
      take(1),
      switchMap((job: any) => {
        let { job_information } = job;
        let newState, return_to_state;

        switch (job.state) {
          case 89:
            newState = 97;
            break;
          case 90:
          case 32:
          case 106:
            newState = job?.state;
            return_to_state = 97;
            break;
          default:
            newState = job?.state;
            break;
        }

        const data = {
          job_id: job?.id,
          current_state: job?.state,
          new_state: newState,
        };

        let toReturn;
        if (return_to_state) {
          if (job_information) {
            inlineRecursiveMerge(job_information, { return_to_state });
          } else {
            job_information = { return_to_state };
          }

          toReturn = { job_information, ...data };
        } else {
          toReturn = data;
        }
        return this.jobService.updateJob(toReturn);
      })
    );
  }

  changeJobAppointmentFromContextMenu() {
    const appointment = this.bf.bigForm.get('appointmentData')?.value;
    const {
      appointmentTime,
      appointmentDatePicker,
      range_start,
      range_end,
      appointmentTimePicker,
    } = appointment;

    const createMomentDateTime = ({ date, hour, minute }) =>
      moment(date).set({ hour, minute });

    const isBetweenAppointment =
      appointmentTime === this.APPOINTMENT_TYPE_BETWEEN;
    const rangeStart = createMomentDateTime({
      date: appointmentDatePicker,
      hour: isBetweenAppointment
        ? range_start.hour
        : appointmentTimePicker.hour,
      minute: isBetweenAppointment
        ? range_start.minutes
        : appointmentTimePicker.minutes,
    });

    let rangeEnd;
    if (isBetweenAppointment) {
      rangeEnd = createMomentDateTime({
        date: appointmentDatePicker,
        hour: range_end.hour,
        minute: range_end.minutes,
      });
    }

    const appointmentFormatted = {
      appointment_type: appointmentTime,
      reason: '',
      range_start: rangeStart.format('YYYY-MM-DDTHH:mm:ss'),
      ...(isBetweenAppointment && {
        range_end: rangeEnd.format('YYYY-MM-DDTHH:mm:ss'),
      }),
    };

    return forkJoin([
      this.store.pipe(take(1)),
      this.store.select(getFullItemTwo).pipe(take(1)),
    ]).pipe(
      switchMap(([store, fullItemTwo]) => {
        const officeUse = {
          ...fullItemTwo.office_use,
          appointment_type: appointmentTime
            ? getAllInfoIndex(
                'appointment_types',
                'id',
                'name',
                appointmentTime,
                store
              )
            : null,
          appointment_type_id: appointmentTime,
          appointmentdatetype: appointment.appointmentDateType,
          requestedappointmentdate: rangeStart.format('YYYY-MM-DD'),
          requestedappointmenttime: rangeStart.format('HH:mm'),
          ...(isBetweenAppointment && {
            requestedappointmentendtime: rangeEnd.format('YYYY-MM-DDTHH:mm:ss'),
          }),
        };

        return this.jobService
          .changeAppointment({
            appointment: appointmentFormatted,
            job_id: fullItemTwo.id,
            job_information: {
              ...fullItemTwo.job_information,
              return_to_state: fullItemTwo.state,
            },
            office_use: officeUse,
          })
          .pipe(take(1));
      })
    );
  }

  setJobAppointment() {
    const appointment = this.bf.bigForm.get('appointmentData')?.value;
    const rangeStart = this.formatAppointmentDate(
      appointment.appointmentDatePicker,
      appointment.range_start
    );
    const appointmentFormatted = {
      range_start: rangeStart,
      appointment_type: appointment.appointmentTime,
      reason: '',
    };

    if (appointment.range_end) {
      const rangeEnd = this.formatAppointmentDate(
        appointment.appointmentDatePicker,
        appointment.range_end
      );
      appointmentFormatted['range_end'] = rangeEnd;
    }

    return this.store.select(getFullItemTwo).pipe(
      take(1),
      switchMap((fullItemTwo) => {
        const newState = this.getNewState(fullItemTwo.state);
        return this.jobService.updateJob({
          current_state: fullItemTwo.state,
          new_state: newState,
          appointment: appointmentFormatted,
          job_id: fullItemTwo.id,
          job_information: {
            ...fullItemTwo.job_information,
            return_to_state: fullItemTwo.state,
          },
        });
      })
    );
  }

  private formatAppointmentDate(
    datePicker: string,
    timePicker: { hour: number; minutes: number }
  ): string {
    return moment(datePicker)
      .hour(timePicker.hour)
      .minutes(timePicker.minutes)
      .format('YYYY-MM-DDTHH:mm:ss');
  }

  private getNewState(currentState: number): number {
    let newState;
    switch (currentState) {
      case 227:
        newState = 77;
        break;
    }
    return newState;
  }

  recommendCil() {
    return this.store
      .select(getSubmissionData)
      .pipe(take(1))
      .pipe(
        switchMap((submissionData) => {
          const { cil_state, effected_jobs, job_id } = submissionData;
          const data = { cil_state, effected_jobs, job_id };
          return this.http.post<any>(
            environment.api_url + 'v1/job_action/move_to_cil/',
            data
          );
        })
      );
  }

  getClaimsHandlers() {
    return this.http
      .get(environment.api_url + 'v1/staff_action/get_claim_handler', {
        params: { roles: '1' },
      })
      .pipe(
        take(1),
        pluck('payload'),
        map((arr: any) => arr.filter((x) => x.authorised === true)),
        map(
          (obj: {
            [key: string]: {
              id: number;
              full_name: string;
              is_team_leader: boolean;
            };
          }) => {
            return Object.values(obj).map((entry) => ({
              display: entry?.full_name,
              value: entry?.id,
              is_team_leader: entry?.is_team_leader,
            }));
          }
        )
      );
  }

  changeClaimHandler() {
    return this.store
      .select(getSubmissionData)
      .pipe(take(1))
      .pipe(
        switchMap((submissionData) => {
          const { claim_id, reason, staff_id } = submissionData;
          const data = { claim_id, reason, staff_id };
          return this.http.post<any>(
            environment.api_url + 'v1/claim_action/change_claim_handler/',
            data
          );
        })
      );
  }

  updateExcessOnClaim() {
    return this.store
      .select(getSubmissionData)
      .pipe(take(1))
      .pipe(
        switchMap((data) => {
          return this.http.post<any>(
            environment.api_url + 'v1/claim_action/update_excess/',
            data
          );
        })
      );
  }
}
