import { Component, OnInit, forwardRef, ChangeDetectionStrategy, Input, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { BigFormService } from '@flexus/core';
import { IControlValueAccessor, dynamicEvaluation } from '@flexus/utilities';
export interface SelectListOption {
	display: string;
	value: string;
	disabled?: boolean;
	image?: string;
	monogram?: string;
	shouldHaveImage?: boolean;
}

export interface SelectListOptionDisableCriteria {
	message: string;
	evaluationCriteria: {
		property: string;
		operator: string;
	};
}

@Component({
	selector: 'flx-select-list',
	templateUrl: './select-list.component.html',
	styleUrls: ['./select-list.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FLXSelectListComponent),
			multi: true
		}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXSelectListComponent implements OnInit, OnDestroy, AfterViewInit, IControlValueAccessor {
	public FilterForm: UntypedFormGroup;
	public isCollapsed = false;
	public isDisabled: boolean;
	public displayList$: BehaviorSubject<SelectListOption[]> = new BehaviorSubject<SelectListOption[]>([]);
	public isViewInitialised: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public filterSub: Subscription;
	selectedOption: any;

	sendChanges: (_: any) => {};
	touchChanges: (_: any) => {};
	validateFn: (_: any) => {};

	@Input()
	public heading: string;

	@Input()
	public subheading: string;

	private _selectedOptions: Set<string> = new Set();
	isViewInitializedSub: Subscription;
	optionsSub: Subscription;

	get selectedOptions() {
		return this._selectedOptions;
	}
	set selectedOptions(val) {
		this._selectedOptions = val;
		if (this.sendChanges) {
			this.sendChanges(Array.from(this._selectedOptions));
		}
	}

	private _disableOptionWhen: SelectListOptionDisableCriteria;
	@Input() get disableOptionWhen(): SelectListOptionDisableCriteria {
		return this._disableOptionWhen;
	}
	set disableOptionWhen(value: SelectListOptionDisableCriteria) {
		if (value) {
			this._disableOptionWhen = value;
		}
	}

	private _canFilter = true;
	@Input() get canFilter(): boolean {
		return this._canFilter;
	}
	set canFilter(value: boolean) {
		this._canFilter = value;
	}

	private _isMultiSelect = false;
	@Input() get isMultiSelect(): boolean {
		return this._isMultiSelect;
	}
	set isMultiSelect(value: boolean) {
		if (value) {
			this._isMultiSelect = value;
		}
	}

	private _collapseOnSelect = true;
	@Input() get collapseOnSelect(): boolean {
		if (this._isMultiSelect) {
			return false;
		} else {
			return this._collapseOnSelect;
		}
	}
	set collapseOnSelect(value: boolean) {
		if (typeof value === 'boolean') {
			this._collapseOnSelect = value;
		}
	}

	private _height = '150px';
	@Input() get height(): string {
		return this._height;
	}
	set height(value: string) {
		if (value) {
			this._height = value;
		}
	}

	private _maxWidth = '100%';
	@Input() get maxWidth() {
		return this._maxWidth;
	}
	set maxWidth(width: string) {
		if (width) {
			this._maxWidth = width;
		}
	}

	private _includeUserDisplay = false;
	@Input() get includeUserDisplay(): boolean {
		return this._includeUserDisplay;
	}
	set includeUserDisplay(value: boolean) {
		this._includeUserDisplay = value;
	}

	private _options: SelectListOption[] = [];
	@Input() get options(): SelectListOption[] {
		return this._options;
	}
	set options(value) {
		if (value) {
			value.map(option => {
				const cloned = JSON.parse(JSON.stringify(option));
				return { ...cloned, ['disabled']: this.testOptionValidity(option) };
			});
			this._options = value;
		}
	}

	private _options$: Observable<SelectListOption[]>;
	@Input() get options$(): Observable<SelectListOption[]> {
		return this._options$;
	}
	set options$(value: Observable<SelectListOption[]>) {
		if (value) {
			this._options$ = value.pipe(
				skipWhile(options => !options),
				map(options => {
					if (Array.isArray(options)) {
						options.map(option => {
							const cloned = JSON.parse(JSON.stringify(option));
							return { ...cloned, ['disabled']: this.testOptionValidity(option) };
						});
					}

					return options ?? [];
				})
			);
		}
	}

	@Input() selectOptionFunc = instance => {};

	constructor(private changeDetector: ChangeDetectorRef, private bf: BigFormService) {}

	ngOnInit() {
		console.log('select list');
		this.FilterForm = new UntypedFormGroup({
			searchField: new UntypedFormControl('')
		});

		this.isViewInitializedSub = this.isViewInitialised.subscribe(shouldInit => {
			if (shouldInit) {
				if (this.options$) {
					this.optionsSub = this.options$.pipe(skipWhile(res => !res && res === this.displayList$.value)).subscribe(list => {
						this.options = list;
						this.updateDisplayList();
					});
				} else {
					this.updateDisplayList();
				}
			}
		});
	}

	ngOnDestroy() {
		if (this.filterSub) {
			this.filterSub.unsubscribe();
		}
		if (this.isViewInitializedSub) {
			this.isViewInitializedSub.unsubscribe();
		}
		if (this.optionsSub) {
			this.optionsSub.unsubscribe();
		}
	}

	testOptionValidity(item: any) {
		let { disabled, ...rest } = item;
		disabled = false;
		if (this.disableOptionWhen) {
			return dynamicEvaluation({ ...rest, disabled }, this.disableOptionWhen.evaluationCriteria.property, this.disableOptionWhen.evaluationCriteria.operator);
		}
	}

	updateDisplayList() {
		if (this.canFilter) {
			this.FilterForm = new UntypedFormGroup({
				searchField: new UntypedFormControl('')
			});

			this.filterSub = this.FilterForm.get('searchField')?.valueChanges.subscribe(sf => {
				const filteredOptions = this.options.filter(item => item?.display?.toLowerCase()?.includes(sf?.toLowerCase()));
				this.displayList$.next(filteredOptions);
				this.changeDetector.detectChanges();
			});
		}

		this.displayList$.next(this.options);
		this.changeDetector.detectChanges();

		if (this.selectedOptions.size === 1 && this.collapseOnSelect) {
			this.collapseAndScroll(this.selectedOptions.values().next()?.value);
		}
	}

	ngAfterViewInit() {
		this.isViewInitialised.next(true);
	}

	writeValue(value: any): void {
		if (this.selectedOptions.size > 0 && value === '') {
			this.selectedOptions.clear();
			this.isCollapsed = false;
			this.changeDetector.detectChanges();
		}

		if (value) {
			this.selectedOptions = new Set(value);
		}
	}

	registerOnChange(fn: any): void {
		this.sendChanges = fn;
	}

	registerOnTouched(fn: any): void {
		this.touchChanges = fn;
	}

	setDisabledState?(isDisabled: boolean) {
		this.isDisabled = isDisabled;
	}

	selectOption(option: any) {
		// output
		this.selectedOption = option;
		this.selectOptionFunc(this);
		if (!this.isDisabled) {
			if (this.selectedOptions.has(option.value)) {
				this.selectedOptions.delete(option.value);
				this.selectedOptions = new Set(this.selectedOptions);
				this.isCollapsed = false;
			} else {
				if (this._isMultiSelect) {
					this.selectedOptions.add(option.value);
					this.selectedOptions = new Set(this.selectedOptions);
				} else {
					this.selectedOptions = new Set([option.value]);

					if (this.collapseOnSelect) {
						this.collapseAndScroll(option.value);
					} else if (!this.collapseOnSelect && this.canFilter) {
						this.FilterForm.get('searchField').patchValue('');
						this.collapseAndScroll(option.value);
					}
				}
			}
		}
	}

	public collapseAndScroll(sId: string) {
		this.isCollapsed = true;
		setTimeout(() => {
			const item = document.getElementById(sId);

			if (item) {
				item?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			}
		}, 100);
	}
}
