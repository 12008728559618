import { getAllInfoIndex } from '@flexus/utilities';
import moment from 'moment';
import { AbstractControl, Validators, UntypedFormGroup, UntypedFormArray, FormControl } from '@angular/forms';

export const transformJobsToJobCardRequest = (jobcards, storeObj, formValue, bf) => {
	if (jobcards !== undefined && jobcards !== null) {
		return jobcards
			.filter(job => !!job?.skill_id && !!job?.providertype_id) // Only if a skill and provider is set, can the rest of the job be built
			.map(job => {
				const theRes = {
					skillrequested: job?.skillrequested ?? 0,
					skill: job?.skill ?? null,
					skill_id: job?.skill_id ?? null,
					skillcatagory_id: job?.skillcatagory_id,
					skillcatagory: job?.skillcatagory ?? null,
					providertype: job?.providertype,
					providertype_id: job?.providertype_id,
					requestedappointmentdate: job?.appointmentDatePicker ? moment(job?.appointmentDatePicker).format('YYYY-MM-DD') : null,
          requestedappointmenttime:
            job?.appointmentTimePicker && job?.appointmentTime !== 3
              ? `${job?.appointmentTimePicker?.hour}:${job?.appointmentTimePicker?.minutes}`
              : `${job?.range_start?.hour}:${job?.range_start?.minutes}`,
          requestedappointmentendtime:
            job?.range_end && job?.appointmentTime === 3
              ? `${job?.range_end?.hour}:${job?.range_end?.minutes}`
              : null,
          range_start:
            job?.range_start && job?.appointmentTime === 3
              ? `${job?.range_start?.hour}:${job?.range_start?.minutes}`
              : null,
          range_end:
            job?.range_end && job?.appointmentTime === 3
              ? `${job?.range_end?.hour}:${job?.range_end?.minutes}`
              : null,
					appointment_type_id: job?.appointmentTime ?? null,
					appointment_type: job?.appointmentTime ? getAllInfoIndex('appointment_types', 'id', 'name', job?.appointmentTime, storeObj) : null, // at before
					appointmentdatetype: job?.appointmentDateType ?? null,
					device_details: job?.device_details
				};

				return theRes;
			})
			.map((job, index) => {
				// console.log('transform REAL jobs', job);
				return job;
			});
	} else if (jobcards === undefined) {
		let jobCards;
		const job = {
			skillrequested: null,
			skill: null,
			skill_id: null,
			skillcatagory_id: null,
			skillcatagory: null,
			providertype: null,
			providertype_id: null,
			requestedappointmentdate: null,
			requestedappointmenttime: null,
			appointment_type_id: null,
			appointment_type: null, // at before
			appointmentdatetype: null,
			device_details: null
		};
		jobcards = { ...job };
		return jobcards;
	}
	return null;
};

export const transformJobsToDeviceDetails = (jobcards, storeObj, formValue, bf) => {
	if (jobcards !== undefined && jobcards !== null) {
		// let theId: number; // ALSO INCLUDE CODE FOR WEB-2936
		let typestring: string;

		return jobcards
			.map(job => {
				switch (job.decoder_type) {
					// case 4:
					// 	typestring = 'Explora 3B + Wifi Connector';
					// 	break;
					case 1:
						typestring = 'Explora Ultra';
						break;
					// case 2:
					// 	typestring = 'Explora 3A';
					// 	break;
					case 3:
						typestring = 'Explora';
						break;
					case 8:
						typestring = 'HD Decoder';
						break;
					case 11:
						typestring = 'HDZappa - DSD4140';
						break;

					default:
						break;
				}
				const theRes = {
					name: typestring,
					id: job.decoder_type,
					type: typestring,
					bom_number: '',
					description: ''
				};

				return { replacement_device: theRes };
			})
			.map((job, index) => {
				return job;
			});
	}
	return null;
};

// Clears validators on fields not needed once repudiated and resets them after.
export function toggleUpfrontValidation(toggle: boolean, bf: UntypedFormGroup) {
	const jobs = bf.get('jobs') as UntypedFormArray;
	const controls = jobs.controls;
	for (let index = 0; index < controls.length; index++) {
		if (toggle) {
			removeValidators(controls[index] as UntypedFormGroup);
		} else {
			checkBusinessRules(controls[index] as UntypedFormGroup);
		}
	}
}

export function checkBusinessRules(bf: UntypedFormGroup) {
	if (bf.get('jobs')) {
		const jobs = bf.get('jobs') as UntypedFormArray;
		const controls = jobs.controls;
		for (let index = 0; index < controls.length; index++) {
			const formControl = controls[index] as UntypedFormGroup;
			if (
				formControl.get('providertype_id') &&
				formControl.get('assessorWaived') &&
				formControl.get('skill_id') &&
				formControl.get('providertype_id').value !== 2 &&
				!!formControl.get('assessorWaived').value === false &&
				formControl.get('skill_id').value !== 44
			) {
				// skill 44 is an internal assessor
				addAppointmentValidators(formControl);
			} else {
				removeAppointmentValidators(formControl);
			}
		}
	}
}

function removeValidators(control: UntypedFormGroup) {
	for (const key in control.controls) {
		if (control.get(key)) {
			if (key !== 'bank_details') {
				control.get(key)?.clearValidators();
				control.get(key)?.updateValueAndValidity();
			}
		}
	}
}

function addAppointmentValidators(formControl: UntypedFormGroup) {
	if (formControl.get('appointmentTime')) {
		formControl.get('appointmentTime')?.setValidators([Validators.required]);
		formControl.get('appointmentTime')?.updateValueAndValidity();
	}
	if (formControl.get('appointmentTimePicker')) {
		formControl.get('appointmentTimePicker')?.setValidators([Validators.required]);
		formControl.get('appointmentTimePicker')?.updateValueAndValidity();
	}
}

function removeAppointmentValidators(formControl: UntypedFormGroup) {
	if (formControl.get('appointmentTime')) {
		formControl.get('appointmentTime')?.clearValidators();
		formControl.get('appointmentTime')?.updateValueAndValidity();
	}
	if (formControl.get('appointmentTimePicker')) {
		formControl.get('appointmentTimePicker')?.clearValidators();
		formControl.get('appointmentTimePicker')?.updateValueAndValidity();
	}
}
