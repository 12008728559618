import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	SetView,
	UseExistingDatasourceForView,
	NetworkService,
	ManifestController,
	getOrgKey,
	SetActiveManifestItem,
	SetSPOwnJobClaimType,
	ModalService,
	SetNextNode,
	getCurrentUser
} from '@flexus/core';
import { take } from 'rxjs/operators';
import { Subscription, isObservable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'flx-header-actions',
	templateUrl: './header-actions.component.html',
	styleUrls: ['./header-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXHeaderActionsComponent implements OnInit, OnDestroy, OnChanges {
	@Input() manifestItem;
	headerActions = [];
	clickedAction = null;
	state = 'opened';
	networkCheckSubscription: Subscription;
	isOffline = false;
	showMessage = false;
	reversed = true;
	lastNotificationTime: number = Date.now() - 6 * 60 * 60 * 1000;
	currentView;
	currentUserSub: Subscription;
	isSp;
  needHelpFeature = environment.experimentalFeatures.needHelp;

	constructor(
		public store: Store<any>,
		private router: Router,
		public controller: ManifestController<any>,
		private networkService: NetworkService,
		private cdr: ChangeDetectorRef,
		private _modal: ModalService
	) {}

	ngOnInit() {
		this.notifyNetworkStatus();
		this.currentUserSub = this.store
			.select(getCurrentUser)
			.pipe(take(1))
			.subscribe((currentUser: any) => {
				const staffType = currentUser?.user?.staff_type ?? '';
				this.isSp = this.getStaffType(staffType);
			});
		if (this.manifestItem && this.manifestItem.header) {
			this.headerActions = this.headerActions.map(action => {
				if (!action.type) {
					action.type = 'standalone';
				}
				return action;
			});
			this.clickedAction = this.headerActions ? this.headerActions.find(action => action.isDefault) : [];
		}
	}

	ngOnChanges() {
		if (this.manifestItem && this.manifestItem.header) {
			const controlsResult = this.manifestItem.header.controls(this, this.store)();
			if (isObservable(controlsResult)) {
				controlsResult.subscribe((controls: any) => {
					this.headerActions = controls;
				});
			} else {
				this.headerActions = controlsResult;
			}
			this.headerActions = this.headerActions.map(action => {
				if (!action.type) {
					action.type = 'standalone';
				}
				return action;
			});
			this.clickedAction = this.headerActions ? this.headerActions.find(action => action.isDefault) : [];
		}
	}

	onClick(action) {
		action.command();
		this.clickedAction = action.icon !== 'refresh' ? action : this.clickedAction;
		this.state = 'closed';
		setTimeout(() => {
			this.state = 'opened';
		}, 1000);
	}

	loadView(viewKey, params: { reverse: boolean }) {
		const viewFunc = this.manifestItem.views[viewKey];
		this.controller.dispatch(new SetView({ func: viewFunc, key: viewKey, params }));
	}

	useExistingDataSource(viewData) {
		this.controller.dispatch(new UseExistingDatasourceForView(viewData));
	}

	refresh() {
		this.loadView(this.currentView, { reverse: this.reversed });
	}

	reOrder() {
		this.loadView(this.currentView, { reverse: !this.reversed });
		this.reversed = !this.reversed;
		this.ngOnChanges();
	}

	loadListView() {
		this.loadView('alt', { reverse: this.reversed });
		this.ngOnChanges();
	}

	loadDetailsView() {
		this.loadView('default', { reverse: this.reversed });
		this.ngOnChanges();
	}
	loadTeamManagementView() {
		this.controller.dispatch(new SetNextNode('AgentDashboard'));
	}

	loadTeamsView() {
		this.loadView('team', { reverse: this.reversed });
		this.ngOnChanges();
	}

	loadDashboardView(itemId: string) {
		this.controller
			.select(getOrgKey)
			.pipe(take(1))
			.subscribe(key => {
				this.controller.dispatch(
					new SetActiveManifestItem({
						pathToFlows: ['manifestItems'],
						orgKey: key,
						itemId
					})
				);
			});
		this.router.navigate(['/workflow/detail']);
	}

	newClaim() {
		this.controller
			.select(getOrgKey)
			.pipe(take(1))
			.subscribe(key => {
				this.controller.dispatch(
					new SetActiveManifestItem({
						pathToFlows: ['manifestItems'],
						orgKey: key,
						itemId: 'create_item_one'
					})
				);
			});
		this.router.navigate(['/workflow/detail']);
	}
	newJob() {
		this.controller
			.select(getOrgKey)
			.pipe(take(1))
			.subscribe(key => {
				this.controller.dispatch(
					new SetActiveManifestItem({
						pathToFlows: ['manifestItems'],
						orgKey: key,
						itemId: 'add_new_jobcard'
					})
				);
			});
		this.store.dispatch(new SetSPOwnJobClaimType({ claimtype: 40, isOwnJob: true }));
		this.router.navigate(['/workflow/detail']);
	}

	bulkPayments() {
		this.controller
			.select(getOrgKey)
			.pipe(take(1))
			.subscribe((key: any) => {
				this.controller.dispatch(
					new SetActiveManifestItem({
						pathToFlows: ['manifestItems'],
						orgKey: key,
						itemId: 'generate_bulk_excel'
					})
				);
			});
		this.router.navigate(['/workflow/detail']);
	}

	private notifyNetworkStatus() {
		const currentTime = Date.now();
		const sixHoursInMillis = 6 * 60 * 60 * 1000;

		this.networkCheckSubscription = this.networkService.isOnline.subscribe(isOnline => {
			if (isOnline) {
				const modalButton = document.getElementById('isOnlineBtn');
				modalButton?.click();
				this.isOffline = false;
				setTimeout(() => {
					this.showMessage = false;
					this.cdr.detectChanges();
				}, 6000);
				this.cdr.detectChanges();
			} else {
				this.isOffline = true;
				if (currentTime - this.lastNotificationTime >= sixHoursInMillis) {
					this.lastNotificationTime = currentTime;
					this._modal.openModalDirectly(instance => {
						instance.type = 'danger';
						instance.heading = ' ';
						instance.imageSource =
							'<svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.2708 24.5C13.2708 24.5 25.1447 15.3125 35.7291 24.5" stroke="black" stroke-width="3" stroke-miterlimit="10"/><path d="M13.2708 24.5C13.2708 24.5 25.1447 15.3125 35.7291 24.5" stroke="#B01D1D" stroke-width="2" stroke-miterlimit="10"/><path d="M9.52783 20.1595C9.52783 20.1595 24.812 6.78706 39.4722 20.4167" stroke="black" stroke-width="3" stroke-miterlimit="10"/><path d="M9.52783 20.1595C9.52783 20.1595 24.812 6.78706 39.4722 20.4167" stroke="#B01D1D" stroke-width="2" stroke-miterlimit="10"/><path d="M5.78467 16.3333C5.78467 16.3333 25.0038 -2.04168 43.2153 16.3333" stroke="black" stroke-width="3" stroke-miterlimit="10"/><path d="M5.78467 16.3333C5.78467 16.3333 25.0038 -2.04168 43.2153 16.3333" stroke="#B01D1D" stroke-width="2" stroke-miterlimit="10"/><path d="M2.04175 12.25C2.04175 12.25 25.1048 -10.7188 46.9584 12.25" stroke="black" stroke-width="3" stroke-miterlimit="10"/><path d="M2.04175 12.25C2.04175 12.25 25.1048 -10.7188 46.9584 12.25" stroke="#B01D1D" stroke-width="2" stroke-miterlimit="10"/><path d="M29.875 35.5C29.875 38.4685 27.4685 40.875 24.5 40.875C21.5315 40.875 19.125 38.4685 19.125 35.5C19.125 32.5315 21.5315 30.125 24.5 30.125C27.4685 30.125 29.875 32.5315 29.875 35.5Z" fill="#B01D1D" stroke="black" stroke-width="0.25"/><path d="M24.5 42C28.0898 42 31 39.0898 31 35.5C31 31.9102 28.0898 29 24.5 29C20.9101 29 18 31.9102 18 35.5C18 39.0898 20.9101 42 24.5 42Z" stroke="black" stroke-width="2.25" stroke-miterlimit="10"/><path d="M24.5 42C28.0898 42 31 39.0898 31 35.5C31 31.9102 28.0898 29 24.5 29C20.9101 29 18 31.9102 18 35.5C18 39.0898 20.9101 42 24.5 42Z" stroke="#B01D1D" stroke-width="1.40928" stroke-miterlimit="10"/></svg>';
						instance.message = `Network Connection Lost.`;
						instance.submessage = `Please check your network`;
						instance.navButtons = [
							{
								isOnlineBtn: true,
								text: 'Continue',
								clickHandler: event => {
									instance.router.navigate(['/workflow']);
								},
								linkType: 'close'
							}
						];
					});
				}
				this.showMessage = true;
				this.cdr.detectChanges();
			}
		});
	}

	getStaffType(typeindice) {
		switch (typeindice) {
			case 2:
				return true;
			default:
				return false;
		}
	}

	ngOnDestroy() {
		// this.socketConsumer.unsubscribeToSocket();
		if (this.networkCheckSubscription) {
			this.networkCheckSubscription.unsubscribe();
		}
	}
}
