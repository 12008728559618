import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { BigFormService, ModalService } from '@flexus/core';
import { DynamicFormConfig, DynamicFormInputs } from '@flexus/ui-composites';
import { SelectListOption } from '@flexus/ui-elements';
import { CustomValidators, cleanUpSub, isInteger } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, pluck, skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'flx-claim-class',
  templateUrl: './claim-class.component.html',
  styleUrls: ['./claim-class.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimClassComponent implements OnInit, OnDestroy, AfterViewInit {
  // ===========================================  Variables ===========================================================
  // Claim Group information - Filtered select
  claimTypes$!: Observable<any>;
  claimDetailsSub?: Subscription;

  claimClassesData$!: Observable<any>;
  claimClassSub?: Subscription;
  selectedClaimClass = 0;

  // Extra Information variables
  extraFormNeeded = false;
  subsidenceNeeded = false;
  extraSelectList = false;

  additionalFormData: DynamicFormConfig = {
    data: {},
    formLayout: 'flex',
  };

  // Upfront rejection reasons
  upfrontRejectReasons$!: Observable<SelectListOption[]>;
  toggleReason: boolean = false;
  extraSub?: Subscription;

  // To deal with the cascading selects auto open
  dataPatched = false;

  // ===========================================================  INPUT / OUTPUT events ===========================================================

  @Input() subsidenceExclusionsList: any[] = [];
  @Input() fireContainedOptions; // Extent of fire options
  @Input() claimClassTypes = {
    subsidence: [],
    impact: [],
    fire: [],
    police: [],
    miscellaneous: [],
    warningOnly: [],
  }; // Contextual claim class action group types
  @Input() claimDetailsInfo$!: Observable<any>;
  @Input() canEditClaimExtraInfo = true;
  @Input() blockedLossClasses: any[] = [];

  // ============================================ Constructor =========================================================
  constructor(
    public _store: Store<any>,
    private cd: ChangeDetectorRef,
    public bf: BigFormService,
    private fb: UntypedFormBuilder, // private jobCardRequestService: JobCardRequestService,
    private modalsService: ModalService
  ) {}

  // ============================================= Methods ============================================================
  // ----------------------------------------- Life-cycle methods -----------------------------------------
  ngOnInit() {
    this.extraSub && cleanUpSub(this.extraSub);
    this.splitData(this.claimDetailsInfo$);

    if (this.bf.bigForm.get('claim_class')?.value !== null) {
      this.dataPatched = true;
      this.getClaimTypeDesc(this.bf.bigForm.get('claim_class')?.value);

      this.initExtraInfo();
    }

    if (!this.bf.bigForm.get('can_progress')) {
      this.bf.bigForm.addControl('can_progress', new UntypedFormControl(true));
    }
  }

  disableClaimFormExtraControl(controlName: string) {
    const control = this.bf.bigForm.get(controlName);
    const claimClass = this.bf.bigForm.get('claim_class')?.value;
    if (control !== null && claimClass !== null)
      claimClass === this.selectedClaimClass
        ? control.disable()
        : control.enable();
  }

  initExtraInfo() {
    this.selectedClaimClass = this.bf.bigForm.get('selectedClaimClass')?.value;

    // if (!this.canEditClaimExtraInfo) {
    //   if (this.selectedClaimClass === 6) {
    //     this.disableClaimFormExtraControl('_3rdpartyinsurancecompany');
    //     this.disableClaimFormExtraControl('_3rdpartyvechilereg');
    //     this.disableClaimFormExtraControl('_3rdpartyvechilemake');
    //     this.disableClaimFormExtraControl('_3rdpartydrivername');
    //     this.disableClaimFormExtraControl('_3rdpartydriverid');
    //     this.disableClaimFormExtraControl('_3rdpartydrivercell');
    //   }
    //   if ([8, 9, 16].includes(this.selectedClaimClass)) {
    //     this.disableClaimFormExtraControl('policeclaimnumber');
    //   }
    //   if (this.selectedClaimClass === 7) {
    //     this.disableClaimFormExtraControl('firedamageextent');
    //     this.disableClaimFormExtraControl('recomendedadjuster');
    //   }
    //   if (this.selectedClaimClass === 21) {
    //     this.disableClaimFormExtraControl('upfrontrepudiationreason');
    //   }
    // }

    if (isInteger(this.selectedClaimClass)) {
      this.checkClaimClassAction(this.selectedClaimClass);
    } else {
      this.selectedClaimClass = 0;
      this.extraFormNeeded = false;
      this.subsidenceNeeded = false;
      this.extraSelectList = false;
    }
  }

  ngAfterViewInit(): void {
    // Get claim class and reset jobs every time class is changed
    this.claimClassSub = this.bf.bigForm
      .get('selectedClaimClass')
      ?.valueChanges?.subscribe((changes) => {
        if (changes) {
          const test = this.blockedLossClasses.filter(
            (val) => val.class === changes[0]
          );
          if (test?.length !== 0) {
            this.bf.bigForm.get('can_progress')?.setValue(false);
            this.modalsService.openModalDirectly((instance) => {
              instance.type = 'warning';
              instance.heading = test[0]?.message;
              instance.closeButton = false;
              instance.message =
                'Cannot change claim class to or from a ' +
                test[0]?.name +
                ' claim.';
              instance.navButtons = [
                {
                  text: 'Back to workflow',
                  clickHandler: () => {
                    instance.router.navigate(['/workflow']);
                  },
                  linkType: 'close',
                  color: 'alert',
                },
              ];
            });
          } else {
            this.bf.bigForm.get('can_progress')?.setValue(true);
          }
          this.initExtraInfo();
          this.getClaimTypeDesc(this.selectedClaimClass);
          if (
            this.selectedClaimClass !==
            this.bf.bigForm.get('claim_class')?.value
          ) {
            this.clearExtraDetails();
          }
        }
      });

    if (this.bf.bigForm.get('claim_class')?.value !== null) {
      this.selectedClaimClass = this.bf.bigForm.get('claim_class')?.value;
    }

    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.claimDetailsSub && cleanUpSub(this.claimDetailsSub);
    this.claimClassSub && cleanUpSub(this.claimClassSub);
    this.extraSub && cleanUpSub(this.extraSub);
  }

  getClaimTypeDesc(claimClass: number) {
    this.claimTypes$
      .pipe(
        take(1),
        map((claimTypes) => claimTypes.find((type) => type.id === claimClass))
      )
      .subscribe((val) => {
        if (
          val !== undefined &&
          this.bf.bigForm.get('claim_class_description')
        ) {
          this.bf.bigForm.get('claim_class_description')?.setValue(val.name);
        }
      });
  }

  splitData(d$: Observable<Record<string, unknown>>) {
    this.claimTypes$ = d$.pipe(
      skipWhile((ct) => !ct),
      map((data: Record<string, unknown>) => data['claim_types'] ?? undefined)
    );

    // build data for select list
    this.claimClassesData$ = this.claimTypes$.pipe(
      filter((x) => !!x),
      map((arr) => arr.slice().sort(this.sortByPriority)),
      map((obj: { [key: string]: { id: number; name: string } }) => {
        return (
          obj &&
          Object.values(obj).map((entry) => ({
            display: entry.name,
            value: entry.id,
          }))
        );
      })
    );

    // Upfront rejection reasons
    this.upfrontRejectReasons$ = d$.pipe(
      pluck('upfront_rejection_reasons'),
      map((entry: string[]) =>
        entry.map((reason) => ({ display: reason, value: reason }))
      )
    );
  }

  sortByPriority(a, b) {
    return b.priority - a.priority;
  }

  // ===========================================================  Contextual Methods ===========================================================

  isExtraInfo(numArr: number[], claimClass: number): boolean {
    if (numArr === undefined || claimClass === 0) return false;

    return this.findPos(claimClass, numArr) !== -1;
  }

  checkClaimClassAction(claimClass: number) {
    this.extraFormNeeded = false;
    this.subsidenceNeeded = false;
    this.extraSelectList = false;
    switch (true) {
      case this.isExtraInfo(this.claimClassTypes.impact, claimClass): {
        // Impact
        const impactFields: DynamicFormInputs = {
          0: {
            label: 'Insurance Company',
            inputType: 'input',
            defaultValue: '',
            formControlName: '_3rdpartyinsurancecompany',
            validators: [Validators.required, Validators.minLength(4)],
          },
          1: {
            label: 'Vehicle Registration',
            inputType: 'input',
            defaultValue: '',
            formControlName: '_3rdpartyvechilereg',
            validators: [Validators.required, Validators.minLength(4)],
          },
          2: {
            label: 'Vehicle Make',
            inputType: 'input',
            defaultValue: '',
            formControlName: '_3rdpartyvechilemake',
            validators: [Validators.required, Validators.minLength(2)],
          },
          3: {
            label: 'Name',
            inputType: 'input',
            defaultValue: '',
            formControlName: '_3rdpartydrivername',
            validators: [Validators.required, Validators.minLength(2)],
          },
          4: {
            label: 'ID Number',
            inputType: 'input',
            defaultValue: '',
            formControlName: '_3rdpartydriverid',
            validators: [
              Validators.required,
              Validators.minLength(8),
              CustomValidators.numeric,
              CustomValidators.noSpaces,
            ],
          },
          5: {
            label: 'Cell Number',
            defaultValue: '',
            inputType: 'input',
            formControlName: '_3rdpartydrivercell',
            validators: [
              Validators.required,
              CustomValidators.cellphone,
              CustomValidators.noSpaces,
            ],
          },
        };
        this.createExtraDataFields(impactFields, 'three-column');
        this.extraFormNeeded = true;
        break;
      }
      case this.isExtraInfo(this.claimClassTypes.fire, claimClass): {
        // Fire
        const fireFields: DynamicFormInputs = {
          0: {
            label: 'Extent of Fire',
            inputType: 'select',
            defaultValue: '',
            selectConfig: {
              displayOptions: { displayKey: 'display', valueKey: 'value' },
              itemsOption: this.fireContainedOptions,
              placeHolder: 'Fire Extent',
            },
            formControlName: 'firedamageextent',
            validators: [Validators.required],
          },
          1: {
            label: 'Recommended Adjuster',
            inputType: 'input',
            defaultValue: '',
            formControlName: 'recomendedadjuster',
            validators: [Validators.required],
            disabled: true,
          },
        };
        this.extraSub = this.bf.bigForm
          .get('firedamageextent')
          ?.valueChanges?.subscribe((value) => {
            this.getRecommendedAdjuster(value);
          });
        this.createExtraDataFields(fireFields, 'stacked');
        this.extraFormNeeded = true;
        break;
      }
      case this.isExtraInfo(this.claimClassTypes.police, claimClass): {
        // Theft Occupied, Theft Unoccupied & Malicious damage
        const policeFields: DynamicFormInputs = {
          0: {
            label: 'Police Case Number:',
            inputType: 'input',
            defaultValue: '',
            width: '100%',
            formControlName: 'policeclaimnumber',
            validators: [Validators.required, Validators.minLength(4)],
          },
        };
        this.createExtraDataFields(policeFields, 'flex');
        this.extraFormNeeded = true;
        break;
      }
      case this.isExtraInfo(this.claimClassTypes.subsidence, claimClass): {
        // Earthquake, Mining Tremor and Subsidence
        this.showWarningMessage(
          'It is strongly advised that you ONLY select a LOSS ADJUSTER for this claim'
        );
        this.subsidenceNeeded = true;
        break;
      }
      case this.isExtraInfo(this.claimClassTypes.warningOnly, claimClass): {
        // Explosion
        this.showWarningMessage(
          'It is strongly advised that you ONLY select a LOSS ADJUSTER for this claim'
        );
        break;
      }
      case this.isExtraInfo(this.claimClassTypes.miscellaneous, claimClass): {
        // Miscellaneous
        this.extraSelectList = true;
        break;
      }
    }
  }

  getRecommendedAdjuster(fireArea: number) {
    let res = '';
    switch (fireArea) {
      case 0: {
        res = 'Internal Assessor';
        this.showWarningMessage(
          'It is strongly advised that you ONLY select a Internal Assessor for this claim'
        );
        break;
      }
      case 1:
      case 2: {
        res = 'Loss Adjuster';
        this.showWarningMessage(
          'It is strongly advised that you ONLY select a Loss Adjuster for this claim'
        );
        break;
      }
    }
    const recommendedAdjusterControl =
      this.bf.bigForm.get('recomendedadjuster');
    if (recommendedAdjusterControl) {
      recommendedAdjusterControl.patchValue(res);
    }
  }

  createExtraDataFields(dataFields: DynamicFormInputs, layout: string) {
    this.additionalFormData = {
      data: dataFields,
      parentForm: this.bf.bigForm,
      formLayout: layout || 'flex',
    };
  }

  rejectClaim() {
    this.toggleReason = !this.toggleReason;
  }

  triggerUpfrontRejection() {
    this.showWarningMessage(
      'This claim can now be submitted as repudiated upfront.'
    );
    this.bf.bigForm.patchValue({
      dontpingsp: 1,
      upfrontrepudiation: 1,
    });
  }

  showWarningMessage(message: string | string[]) {
    // Display a warning prompt
    setTimeout(() => {
      this.modalsService.openModalDirectly((insta) => {
        insta.setMessage(Array.isArray(message) ? message : [message]);
        insta.closeButton = 'true';
        insta.type = 'warning';
        insta.navButtons = [];
      });
    }, 0);
  }

  //find the position of the id in the look up array
  findPos<T>(
    id: T | number,
    arr: T[],
    field: keyof T = 'id' as keyof T
  ): number {
    if (!arr) return -1; // If the array is undefined or null, immediately return -1.

    return arr.findIndex((item) => item[field] === id || item === id);
  }

  clearExtraDetails() {
    this.bf.bigForm.patchValue({
      firedamageextent: null,
      recomendedadjuster: null,
      _3rdpartyinsurancecompany: null,
      _3rdpartyvechilereg: null,
      _3rdpartyvechilemake: null,
      _3rdpartydrivername: null,
      _3rdpartydriverid: null,
      _3rdpartydrivercell: null,
      policeclaimnumber: null,
      upfrontrepudiationreason: null,
      upfrontrepudiation: null,
    });
  }
}
