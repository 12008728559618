import { getAllInfoIndex } from '@flexus/utilities';
import moment from 'moment';
import { AbstractControl, Validators, UntypedFormGroup, UntypedFormArray, FormControl } from '@angular/forms';

export function checkPolicyType(pol: string): string {
	if (pol !== undefined && pol !== null) {
		const policy = pol.substring(0, 3);
		// SIL
		const silPolicies = ['HLC', 'BMB', 'HLB', 'EBK'];
		const stansurePolicies = ['STH', 'SHS'];
		if (silPolicies.includes(policy.toUpperCase())) {
			return 'SIL';
		}
		// Stansure
		else if (stansurePolicies.includes(policy.toUpperCase())) {
			return 'StanSure';
		} else {
			return 'SIL';
		}
	} else return null;
}

export const transformJobsToJobCardRequest = (jobcards, storeObj, formValue, bf) => {
	if (jobcards !== undefined && jobcards !== null) {
		return jobcards
			.filter(job => !!job?.skill_id && !!job?.providertype_id) // Only if a skill and provider is set, can the rest of the job be built
			.map(job => {
				let jobExtras = {};
				if (job?.providertype_id === 2) {
					jobExtras = {
						initials: job?.bank_details?.initials,
						surname: job?.bank_details?.account_holder_name,
						bank_acc_number: job?.bank_details?.account_number,
						acc_type: job?.bank_details?.acc_type,
						acc_type_desc: getAllInfoIndex('account_type', 'mid', 'description', job?.bank_details?.acc_type, storeObj),
						idnumber: job?.bank_details?.identification_number,
						branch_code: job?.bank_details?.branch_code,
						bank: job?.bank_details?.bank_name,
						branch: job?.bank_details?.branch,
						isnonpanel: job?.bank_details?.account_use === 'Individual' ? false : true,
						avsresult: job?.bank_details?.avsSuccess,
						avsinitialsfailreason: job?.bank_details?.avsFailedReasons
					};
				} else if (job?.providertype_id === 3) {
					// off panel
				}

				const theRes = {
					...jobExtras,
					skillrequested: job?.skillrequested ? job?.skillrequested : 0,
					skill: job?.skill ? job?.skill : null,
					skill_id: job?.skill_id ? job?.skill_id : null,
					skillcatagory_id: job?.skillcatagory_id,
					skillcatagory: job?.skillcatagory ? job?.skillcatagory : null,
					providertype: job?.providertype ? job?.providertype : null,
					providertype_id: !bf.bigForm?.value?.upfrontrepudiation ? (job?.providertype_id ? job?.providertype_id : null) : 7,

					requestedappointmentdate: job?.appointmentDatePicker ? moment(job?.appointmentDatePicker).format('YYYY-MM-DD') : null,
					requestedappointmenttime: job?.appointmentTimePicker && job?.appointmentTime !== 3 ? `${job?.appointmentTimePicker?.hour}:${job?.appointmentTimePicker?.minutes}` : null,
          requestedappointmentendtime: job?.range_end && job?.appointmentTime === 3 ? `${job?.range_end?.hour}:${job?.range_end?.minutes}` : null,
					range_start: job?.range_start && job?.appointmentTime === 3 ? `${job?.range_start?.hour}:${job?.range_start?.minutes}` : null,
					range_end: job?.range_end && job?.appointmentTime === 3 ? `${job?.range_end?.hour}:${job?.range_end?.minutes}` : null,
					appointment_type_id: job?.appointmentTime ? job?.appointmentTime : null,
					appointment_type: job?.appointmentTime ? getAllInfoIndex('appointment_types', 'id', 'name', job?.appointmentTime, storeObj) : null, // at before
					appointmentdatetype: job?.appointmentDateType ? job?.appointmentDateType : null,

					excess: [
						{
							excess_amount: job?.amount ? job?.amount : null,
							excess_description: job?.excess_description || null,
							who_collects_excess: job?.who_collects ? job?.who_collects : null,
							who_collects_excess_desc: job?.who_collects ? getAllInfoIndex('excess_who', 'id', 'name', job?.who_collects, storeObj) : null,
							excess_payment_method: job?.payment_method ? job?.payment_method : null,
							excess_payment_method_desc: !job?.is_cancelled && job?.payment_method ? getAllInfoIndex('excess_how', 'id', 'name', job?.payment_method, storeObj) : null
						}
					]
				};
				const { excess, ...withoutExcess } = theRes;
				return job?.is_cancelled ? withoutExcess : theRes;
			})
			.map((job, index) => {
				if (index === 0 && bf.bigForm?.value?.policy_excess?.amount !== 0) {
					const pExcess = bf.bigForm?.value?.policy_excess;
					const policyExcess = {
						excess_amount: pExcess.amount ? pExcess.amount : null,
						excess_description: pExcess?.excess_description,
						who_collects_excess: pExcess.who_collects ? pExcess.who_collects : null,
						who_collects_excess_desc: pExcess.who_collects ? getAllInfoIndex('excess_who', 'id', 'name', pExcess.who_collects, storeObj) : null,
						excess_payment_method: pExcess.payment_method ? pExcess.payment_method : null,
						excess_payment_method_desc: pExcess.payment_method ? getAllInfoIndex('excess_how', 'id', 'name', pExcess.payment_method, storeObj) : null
					};
					const excess = job?.excess ? [{ ...policyExcess }, ...job?.excess] : [{ ...policyExcess }];
					return pExcess && pExcess.is_cancelled ? job : { ...job, excess };
				}
				return job;
			});
	}
	return null;
};

// Clears validators on fields not needed once repudiated and resets them after.
export function toggleUpfrontValidation(toggle: boolean, bf: UntypedFormGroup) {
	const jobs = bf.get('jobs') as UntypedFormArray;
	const controls = jobs.controls;
	for (let index = 0; index < controls.length; index++) {
		if (toggle) {
			removeValidators(controls[index] as UntypedFormGroup);
		} else {
			checkBusinessRules(controls[index] as UntypedFormGroup);
		}
	}
}

export function checkBusinessRules(bf: UntypedFormGroup) {
	if (bf.get('jobs')) {
		const jobs = bf.get('jobs') as UntypedFormArray;
		const controls = jobs.controls;
		for (let index = 0; index < controls.length; index++) {
			const formControl = controls[index] as UntypedFormGroup;
			if (
				formControl.get('providertype_id') &&
				formControl.get('assessorWaived') &&
				formControl.get('skill_id') &&
				formControl.get('providertype_id')?.value !== 2 &&
				!!formControl.get('assessorWaived')?.value === false &&
				formControl.get('skill_id')?.value !== 44
			) {
				// skill 44 is an internal assessor
				addAppointmentValidators(formControl);
			} else {
				removeAppointmentValidators(formControl);
			}
			if (!((formControl.get('is_cancelled') && !!formControl.get('is_cancelled')?.value) || (formControl.get('assessorWaived') && !!formControl.get('assessorWaived')?.value))) {
				addExcessValidators(formControl);
			} else {
				removeExcessValidators(formControl);
			}
		}
	}
}

function removeValidators(control: UntypedFormGroup) {
	for (const key in control.controls) {
		if (control.get(key)) {
			if (key !== 'bank_details') {
				control.get(key)?.clearValidators();
				control.get(key)?.updateValueAndValidity();
			}
		}
	}
}

function addExcessValidators(formControl: UntypedFormGroup) {
	if (formControl.get('amount')) {
		formControl.get('amount')?.setValidators([Validators.required]);
		formControl.get('amount')?.updateValueAndValidity();
	}
	if (formControl.get('who_collects')) {
		formControl.get('who_collects')?.setValidators([Validators.required]);
		formControl.get('who_collects')?.updateValueAndValidity();
	}
	if (formControl.get('payment_method')) {
		formControl.get('payment_method')?.setValidators([Validators.required]);
		formControl.get('payment_method')?.updateValueAndValidity();
	}
}

function addAppointmentValidators(formControl: UntypedFormGroup) {
	if (formControl.get('appointmentTime')) {
		formControl.get('appointmentTime')?.setValidators([Validators.required]);
		formControl.get('appointmentTime')?.updateValueAndValidity();
	}
	if (formControl.get('appointmentTimePicker')) {
		formControl.get('appointmentTimePicker')?.setValidators([Validators.required]);
		formControl.get('appointmentTimePicker')?.updateValueAndValidity();
	}
}

function removeExcessValidators(formControl: UntypedFormGroup) {
	if (formControl.get('amount')) {
		formControl.get('amount')?.clearValidators();
		formControl.get('amount')?.updateValueAndValidity();
	}
	if (formControl.get('who_collects')) {
		formControl.get('who_collects')?.clearValidators();
		formControl.get('who_collects')?.updateValueAndValidity();
	}
	if (formControl.get('payment_method')) {
		formControl.get('payment_method')?.clearValidators();
		formControl.get('payment_method')?.updateValueAndValidity();
	}
}

function removeAppointmentValidators(formControl: UntypedFormGroup) {
	if (formControl.get('appointmentTime')) {
		formControl.get('appointmentTime')?.clearValidators();
		formControl.get('appointmentTime')?.updateValueAndValidity();
	}
	if (formControl.get('appointmentTimePicker')) {
		formControl.get('appointmentTimePicker')?.clearValidators();
		formControl.get('appointmentTimePicker')?.updateValueAndValidity();
	}
}
