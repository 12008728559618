import { Route } from '@angular/router';
import { PggWorkflowShellComponent } from 'apps/studio/src/app/organisations/pinggo/base-components/pgg-workflow-shell/pgg-workflow-shell.component';
import { PggFlowComponent } from 'apps/studio/src/app/organisations/pinggo/base-components/pgg-flow/pgg-flow.component';
import { AuthGuard } from '@flexus/core';
import { ActivateStateGuard } from 'apps/studio/src/app/organisations/pinggo/guards/activate-state.guard';
import { DeactivateStateGuard } from 'apps/studio/src/app/organisations/pinggo/guards/deactivate-state.guard';
import { FLXWorkflowAppComponent } from 'apps/studio/src/app/application-modules';

export const routes: { [id: string]: Route[] } = {
	core: [
		{
			path: '',
			redirectTo: '/workflow',
			pathMatch: 'full'
		}
	],
	workflowApp: [
		{
			path: 'workflow',
			component: FLXWorkflowAppComponent,
			canActivate: [AuthGuard],
			children: [
				{ path: '', component: PggWorkflowShellComponent }, // As outlet list,
				{
					path: 'detail',
					component: PggFlowComponent,
					canActivate: [ActivateStateGuard],
					canDeactivate: [DeactivateStateGuard],
					runGuardsAndResolvers: 'always'
				} // As Workflow detail,
				// { path: 'context-menu-detail', component: WorkflowContextMenuDetailComponent },
				// { path: ':id/edit', component: CreateWorkflowItemComponent, resolve: { editableItem: ClaimDataResolver } }, //As Create Item One Component,
				// { path: 'chart', component: FlowChartComponent },
			]
		}
	]
};
