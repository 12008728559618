import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { getAllInfo, getCurrentUser, SelectAvailableJob } from '@flexus/core';
import { JOB_INTEREST } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { JobRequest } from '../models';

enum CardAction {
  noAction = '',
  decline = 'decline',
  ignore = 'ignore',
  available = 'available',
}

@Component({
  selector: 'flx-sp-available-job-ping',
  templateUrl: './sp-available-job-ping.component.html',
  styleUrls: ['./sp-available-job-ping.component.scss'],
})
export class FLXSpAvailableJobDetailComponent implements OnInit, OnDestroy {
  private _job!: JobRequest;
  modalState!: string;
  skill!: any;
  skillsMap: { [id: string | number]: string } = {};
  provincesMap: { [id: string | number]: string } = {};
  appointment: any;
  user$!: Observable<any>;
  userSub!: Subscription;
  client!: string;
  job_id = '10297';

  displayUndoButton!: boolean;
  showJobResponseOptions!: boolean;
  CardActionEnum = CardAction;
  cardAction: CardAction = CardAction.noAction;
  allInfoSub: Subscription;

  @Input()
  set job(val: any) {
    this._job = val;
  }
  get job() {
    return this._job;
  }

  @Output() interestShown = new EventEmitter<{}>();
  @Output() setShowDetailView = new EventEmitter<boolean>();

  constructor(private _store: Store<any>) {}

  ngOnInit() {
    this.job_id = this.job?.id;
    this.allInfoSub = this._store.select(getAllInfo).subscribe((allInfo) => {
      this.skillsMap = allInfo?.skills?.reduce((acc, skill) => {
        return {
          ...acc,
          [skill.id]: skill.name,
        };
      }, {});
      this.provincesMap = allInfo?.active_regions?.reduce((acc, province) => {
        return {
          ...acc,
          [province.id]: province.name,
        };
      }, {});
    });

    const appointment = this.job?.appointment;
    if (appointment && Array.isArray(appointment)) {
      this.appointment = appointment[0];
    } else {
      this.appointment = appointment;
    }
    this.displayUndoButton = false;
    this.showJobResponseOptions = true;
    this.user$ = this._store.select(getCurrentUser).pipe(
      skipWhile((data: any) => !data),
      take(1),
      map((res: any) => res)
    );
    this.userSub = this.user$.subscribe((mapped: any) => {
      this.client = mapped?.client;
      if (this.client === 'multichoice') {
        if (this.job.claim_type_id === '37') {
          this.skill = `Own Stock - ${this.job.skill}`;
        } else {
          this.skill = `${this.job.skill}`;
        }
      } else if (this.client === 'pinggo') {
        this.skill = `${this.job.voucher}`;
      } else {
        this.skill = `${this.job.skill}`;
      }
    });
  }

  responseAction(
    event: Event,
    interest: 'INTERESTED' | 'DECLINED' | 'IGNORED'
  ) {
    event.stopPropagation();
    const time_of_response = moment().valueOf();
    const responseObj = {
      job: this.job,
      interest: JOB_INTEREST[interest],
      time_of_response: time_of_response,
    };
    this.interestShown.emit(responseObj);
  }

  selectDetailView() {
    this.setShowDetailView.emit(true);
  }

  selectAvailableJobOnModal(id: string) {
    this._store.dispatch(new SelectAvailableJob(id));
    this.setShowDetailView.emit(true);
  }

  ngOnDestroy(): void {
    this.allInfoSub?.unsubscribe();
  }
}
