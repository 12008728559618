<flx-flex-container alignItems="center">
  <flx-heading size="medium" align="center" type="creation" weight="extra-light"
    >Select the Claim Class</flx-heading
  >

  <form [formGroup]="bf.bigForm">
    <div class="dynamic-component claim-details-container">
      <div class="claim-class-information">
        <!-- select claim class -->
        <div
          class="claim-details-claim-class"
          [ngClass]="{
            animateExtraInMoveClaimClass:
              extraFormNeeded || subsidenceNeeded || extraSelectList
          }"
        >
          <flx-select-list
            [options$]="claimClassesData$"
            formControlName="selectedClaimClass"
            [height]="
              selectedClaimClass === 0 || selectedClaimClass === null
                ? '40vh'
                : '150px'
            "
            [canFilter]="
              selectedClaimClass !== 0 && selectedClaimClass !== null
            "
            [collapseOnSelect]="false"
          >
          </flx-select-list>
        </div>
      </div>

      <!-- additional claim information -->
      <div
        class="extra-claim-details-info"
        [ngClass]="{
          animateExtraIn: extraFormNeeded || subsidenceNeeded || extraSelectList
        }"
      >
        <div *ngIf="extraFormNeeded && additionalFormData.data">
          <flx-flat-dynamic-form
            [formControls]="additionalFormData.data"
            [formLayout]="additionalFormData.formLayout + ''"
          >
          </flx-flat-dynamic-form>
        </div>
        <!-- subsidence exclusions -->
        <div *ngIf="subsidenceNeeded">
          <div class="subsidenceExclusions">
            <flx-heading size="extra-small" align="center"
              >Exclusions:</flx-heading
            >
            <p>The following items are excluded from subsidence claims</p>
            <ul>
              <ng-container *ngFor="let reason of subsidenceExclusionsList">
                <li>{{ reason }}</li>
              </ng-container>
            </ul>
          </div>
        </div>
        <!-- upfront reject claim -->
        <div *ngIf="extraSelectList">
          <ng-container *ngIf="toggleReason">
            <flx-heading size="small">Upfront Rejection Reason</flx-heading>
            <flx-select-list
              formControlName="upfrontrepudiationreason"
              layout="basic"
              [options$]="upfrontRejectReasons$"
              height="150px"
              (click)="triggerUpfrontRejection()"
            >
            </flx-select-list>
          </ng-container>
          <div class="reject-button">
            <flx-button
              *ngIf="!toggleReason"
              class="text-center"
              size="small"
              color="secondary"
              (click)="rejectClaim()"
            >
              Upfront Reject Claim
            </flx-button>
          </div>
        </div>
      </div>

      <flx-glow-line
        size="100%"
        *ngIf="extraFormNeeded || subsidenceNeeded || extraSelectList"
      ></flx-glow-line>
    </div>
  </form>
</flx-flex-container>
