<ng-container *ngIf="showJobsList">
  <div class="jobs-container">
    <flx-heading *ngIf="showJobsList" size="medium" align="left" color="primary"
      >Available Jobs</flx-heading
    >
    <ng-container
      *ngIf="
        availableJobs$
          | async
          | paginate
            : {
                itemsPerPage: paginationVariables.pageSize,
                currentPage: paginationVariables.currentPage
              } as jobs
      "
    >
      <div class="jobs-content">
        <div class="job-items">
          <ng-container *ngFor="let job of jobs">
            <div (click)="selectAvailableJob(job.id)" class="job-item">
              <div class="job-details">
                <div>{{ job?.skill }}</div>

                <div>{{ job?.suburb }}</div>
                <div>
                  <span *ngIf="job.range_start; else noAppointment"
                    >{{ job?.range_start
                    }}<ng-container *ngIf="job?.range_end">
                      - {{ job?.range_end }}</ng-container
                    ></span
                  >
                  <ng-template #noAppointment>No appointment set.</ng-template>
                </div>
              </div>
              <div class="interest-icon">
                <div
                  [ngClass]="{
                    ignored: ignored,
                    declined: declined
                  }"
                ></div>
              </div>
            </div>
          </ng-container>
          <div
            class="pagination-controls"
            *ngIf="
              (availableJobs$ | async)?.length > paginationVariables.pageSize
            "
          >
            <pagination-controls
              class="flx-pagination"
              (pageChange)="page($event)"
              [maxSize]="5"
              [autoHide]="false"
              previousLabel="←"
              nextLabel="→"
            >
            </pagination-controls>
          </div>
        </div>
        <div *ngIf="jobs.length > 0; else noJobs" style="margin: 10px 10px 0 0">
          <flx-button
            display="block"
            color="secondary"
            (click)="openIgnoreWarningModal()"
            >IGNORE ALL</flx-button
          >
        </div>
        <ng-template #noJobs>
          <flx-alert type="no-info" missingItem="NEW jobs"></flx-alert>
        </ng-template>
      </div>
    </ng-container>
  </div>
</ng-container>
