import { UntypedFormControl } from '@angular/forms';
import {
  Flow_0_0_2,
  getAllInfo,
  getFullItemTwo,
  getSelectedItem,
} from '@flexus/core';
import { humaniseDate } from '@flexus/utilities';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';

export const SP_CONTEXT_MENU_MAKE_CIL: Flow_0_0_2 = {
	id: 'ViewDocuments',
	itemType: 'flow',
	excludeForOnlyStates: [21, 22, 23, 27, 25, 28, 30, 39, 41, 45, 48, 49, 50, 51, 66, 70, 85, 206],
	actionPanel: () => {
		return () => {
			return [{}];
		};
	},
	name: 'Make Cash-in-Lieu',

  header: {
    title: (store, bf) => {
      return store.select(getFullItemTwo).pipe(
        map((itemTwo) => {
          if (itemTwo) {
            return `Make Cash-in-Lieu : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
          } else {
            return 'Make Cash-in-Lieu';
          }
        })
      );
    },
    controls: () => () => [],
  },
  footer: {
    type: 'node_nav',
  },
  startNode: 'CILRequestNotification',
  instructions: {
    editRoles: {
      0: '',
    },
    viewRoles: {
      0: '',
    },
  },
  serverCalls: {
    files: {
      serviceVariable: 'spService',
      functionName: 'getAllJobFiles',
      responseSlice: 'payload',
      errorMessage: 'Could not get files from server!',
    },
    notes: {
      serviceVariable: 'spService',
      functionName: 'getJobNotes',
      responseSlice: 'payload',
      errorMessage: 'No job notes could be found!',
    },
    claimDetailKeyValues: {
      errorMessage: 'Something went wrong claim details',
      directCall: (http, store, sq) => {
        return store
          .select(getFullItemTwo)
          .pipe(
            skipWhile((itt) => !itt),
            take(1),
            map((res) => res as any)
          )
          .pipe(
            map((itemTwo) => {
              let appointment;
              if (itemTwo?.appointment) {
                appointment = itemTwo?.appointment[0];
              }

              let originalAppointmentTime = 'Appointment time not retrieved';
              if (appointment?.range_start) {
                const startTime = moment(appointment.range_start).format(
                  'HH:mm'
                );
                const endTime = appointment.range_end
                  ? ` - ${moment(appointment.range_end).format('HH:mm')}`
                  : '';
                originalAppointmentTime = `${startTime}${endTime}`;
              }

              const claimdetaillist = {
                'Claim Type ': itemTwo?.claim?.type ?? '',
                'Skill required ': itemTwo?.office_use?.skillcatagory ?? '',
                'Address ': itemTwo?.address ?? '',
                'Original Appointment Date': appointment?.range_start
                  ? `${humaniseDate(appointment.range_start)}`
                  : 'No appointment date set',
                'Original Appointment Time': originalAppointmentTime,
              };
              return [claimdetaillist];
            })
          );
      },
    },
    excessDetailKeyValues: {
      errorMessage: 'Something went wrong with the excess info',
      directCall: (http, store, sq) => {
        return forkJoin([
          store.select(getFullItemTwo).pipe(
            skipWhile((itt) => !itt),
            take(1)
          ),
          store.select(getAllInfo).pipe(
            skipWhile((ai) => !ai),
            take(1)
          ),
        ]).pipe(
          map(([itemTwo, info]) => {
            const job_id = itemTwo?.id;
            const job_excess = itemTwo?.excess;
            let excess_collection: string;
            let excess_amount: string;
            let excess_method: string;
            let excess_state: number;
            const excess_whoArr: any = info.excess_who;
            const excess_howArr: any = info.excess_how;
            const excess_stateArr: any = info.excess_states;
            for (let i = 0; i <= job_excess.length - 1; i++) {
              if (job_excess[i]?.job === job_id) {
                excess_amount = job_excess[i]?.amount;
              }
            }
            for (let i = 0; i <= job_excess.length - 1; i++) {
              if (job_excess[i]?.job === job_id) {
                excess_whoArr.forEach((element) => {
                  if (element.id === job_excess[i]?.who_collects) {
                    excess_collection = element.name;
                  }
                });
              }
            }
            for (let i = 0; i <= job_excess.length - 1; i++) {
              if (job_excess[i]?.job === job_id) {
                excess_howArr.forEach((element) => {
                  if (element.id === job_excess[i]?.how_collect) {
                    excess_method = element.name;
                  }
                });
              }
            }
            for (let i = 0; i <= job_excess.length - 1; i++) {
              if (job_excess[i]?.job === job_id) {
                excess_stateArr.forEach((element) => {
                  if (element.id === job_excess[i]?.state) {
                    excess_state = element.name;
                  }
                });
              }
            }
            const excessinfo = {
              'Excess Collection : ': `${excess_collection} to collect`,
              'Excess Amount : ': `R${excess_amount}`,
              'Excess payment : ': excess_method,
              'Excess state : ': excess_state,
            };
            return [excessinfo];
          })
        );
      },
    },
    customerDetailKV: {
      errorMessage: 'Something went wrong with customer info',
      directCall: (http, store, sq) => {
        return store
          .select(getFullItemTwo)
          .pipe(
            skipWhile((itt) => !itt),
            take(1),
            map((res) => res as any)
          )
          .pipe(
            map((itemTwo) => {
              let firstname;
              let surname;
              let customername;
              let customercell;
              let clientalternativeno;
              if (!itemTwo?.claim?.applicant) {
                customername = 'This claim is missing applicant data';
              } else {
                if (!itemTwo?.claim?.applicant?.first_name) {
                  firstname = 'no first name';
                } else {
                  firstname = itemTwo?.claim?.applicant?.first_name;
                }
                if (!itemTwo?.claim?.applicant?.surname) {
                  surname = 'no surname';
                } else {
                  surname = itemTwo?.claim?.applicant?.surname;
                }
                customername = `${firstname} ${surname}`;
              }
              if (!itemTwo?.claim?.loan_information) {
                customercell = 'No loan information on this claim';
                clientalternativeno = 'No loan information on this claim';
              } else {
                customercell = itemTwo?.claim?.loan_information?.cellnumber;
                clientalternativeno =
                  itemTwo?.claim?.loan_information?.contactnumber;
              }

              const list3 = {
                Customer: customername,
                'Customer Cell': customercell,
                'Client Alternative No.': clientalternativeno,
              };
              return [list3];
            })
          );
      },
    },
  },
  nodes: {
    CILRequestNotification: {
      initFormFields: (bf, item, instance, sq, store) => {
        bf.addControl('selectedJobs', new UntypedFormControl(66));
        bf.patchValues({ cil_state: 93 });
        bf.patchValues({ new_state: 105 });
        // bf.patchValues({
        //     effected_jobs: [[...bf.bigForm.get('selectedJobs')?.value, bf.bigForm.get('itemTwo')?.value.id]]
        // });
      },
      component: {
        children: [
          {
            component: 'FLXHeadingWithInstructionsComponent',
            inputs: {
              title: 'Customer Requests Cash-in-Lieu After Quote Approval',
              instructions: [
                'This will request cash-in-lieu and allow you to charge a call-out fee',
              ],
              itemMargin: '0 0 35px 0',
            },
          },
          {
            component: 'FLXKeyValueListComponent',
            inputs: {
              heading: 'Claim Details',
              data$: 'claimDetailKeyValues',
              itemMargin: '0 0 35px 0',
            },
          },
          {
            component: 'FLXKeyValueListComponent',
            inputs: {
              heading: 'Excess Details',
              data$: 'excessDetailKeyValues',
              itemMargin: '0 0 35px 0',
            },
          },
          {
            component: 'FLXKeyValueListComponent',
            inputs: {
              heading: 'Customer Details',
              data$: 'customerDetailKV',
              itemMargin: '0 0 35px 0',
            },
          },
        ],
      },

      navs: [
        {
          text: 'Request Cash-in-Lieu',
          nextNode: 'SubmissionSuccess',
          serverFirst: true,
          color: 'primary',
          serverCalls: {
            response: {
              errorMessage: 'Could not change to CIL',
              directCall: (http, store, sq) => {
                return store
                  .select(getSelectedItem)
                  .pipe(
                    skipWhile((x) => !x),
                    take(1)
                  )
                  .pipe(
                    map((id) => {
                      const job_id = id.id;
                      const packet = {
                        effected_jobs: [job_id],
                        cil_state: environment.client === 'bet_sp' ? 56 : 66,
                        // cil_state: 66
                      };
                      return packet;
                    }),
                    switchMap((data) => {
                      return http.post(
                        `${environment.api_url}v1/job_action/move_to_cil/`,
                        data
                      );
                    })
                  );
              },
            },
          },
        },
      ],
    },
    SubmissionSuccess: {
      component: 'FLXSuccessTickComponent',
    },
  },
  bigFormToStoreMapper: {},
};
