/* eslint-disable no-case-declarations */
import { Component, Input, OnInit} from '@angular/core';
import { BigFormService } from '@flexus/core';
import { Observable, Subscription, map, skipWhile, take } from 'rxjs';

@Component({
	selector: 'flx-autopay-add-equipment',
	templateUrl: 'autopay-add-equipment.component.html',
	styleUrls: ['autopay-add-equipment.component.scss']
})
export class FLXAutopayAddEquipmentComponent implements OnInit {
	// variables
	rfmodulator_qty: number = 0;
	dishplate_standard_steel_qty: number = 0
	single_lnb_uni_qty: number = 0
	diplexer_qty: number = 0;
	wificonnector_qty: number = 0;
	dishplate_mild_steel_qty: number = 0;
	dishplate_fibre_qty: number = 0;
	dishplate_alum_qty: number = 0;
	standard_bracket_qty: number = 0;
	lnb_lmx_501_qty: number = 0;
	lnb_lmx_502_qty: number = 0;
	mud_smart_switch_qty: number = 0
	extended_bracket_qty: number = 0
	additional_cable_qty: number = 0
	aa_travel_rates_qty: number = 0
	hd_explora_power_supply_qty: number = 0
	extra_labour_qty: number = 0

	@Input() existing_peripherals$!: Observable<any>;
	@Input() mc_peripheral_list$!: Observable<any>
	peripheralSub!: Subscription;
	periphProductCodeSub!: Subscription;
	products
	constructor(private bigForm: BigFormService) {}

	setExistingPeripheralQuantities(incoming_peripheral_quantities) {
		console.log('FUNCTION PERIPHERALS', incoming_peripheral_quantities)
		for(let i = 0; i <= incoming_peripheral_quantities.length; i++) {
			switch(incoming_peripheral_quantities[i]?.item) {
				case 'RF Modulator - Powered':
					this.rfmodulator_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					console.log('setting')
					this.bigForm.getControl('rfmodulator').setValue(+this.rfmodulator_qty)
					break;
				case 'Diplexer':
					this.diplexer_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('diplexer').setValue(+this.diplexer_qty)
					break
				case 'Wi-Fi Connector':
					this.wificonnector_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('wificonnector').setValue(+this.wificonnector_qty)
					break
				case 'Dish Plate/Face - Standard Steel':
					this.dishplate_standard_steel_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('dishplate_standard_steel').setValue(+this.dishplate_standard_steel_qty)
					break
				case 'Dish Plate/Face - Aluminium':
					this.dishplate_alum_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('dishplate_aluminium').setValue(+this.dishplate_alum_qty)

					break

				case 'Dish Plate/Face - Fibre':
					this.dishplate_fibre_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('dishplate_fibre').setValue(+this.dishplate_fibre_qty)
					break
				case 'Dish Plate/Face - Standard Mild  Steel - Epoxy Coated Inverto':
					this.dishplate_mild_steel_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('dishplate_standard_mild_steel').setValue(+this.dishplate_mild_steel_qty)
					break
				case 'Single LNB - Universal':
					this.single_lnb_uni_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('single_lnb_universal').setValue(+this.single_lnb_uni_qty)
					break
				case 'Standard Bracket':
					this.standard_bracket_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('standard_bracket').setValue(+this.standard_bracket_qty)

					break
				case 'LNB LMX 502 (2 port)':
					this.lnb_lmx_502_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('lnb_lmx_502').setValue(+this.lnb_lmx_502_qty)
					break
				case 'LNB LMX 501 (4 port)':
					this.lnb_lmx_501_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('lnb_lmx_501').setValue(+this.lnb_lmx_501_qty)
					break
				case `DStv Smart Switch (MUD's Only)`:
					this.mud_smart_switch_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('mud_smart_switch').setValue(+this.mud_smart_switch_qty)
					break
				case `Extended Bracket`:
					this.extended_bracket_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('extended_bracket').setValue(+this.extended_bracket_qty)
					break
				case 'Additional Cable':
					this.additional_cable_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('additional_cable').setValue(+this.additional_cable_qty)
					break
				case 'HD/Explora Power Supply':
					this.hd_explora_power_supply_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('hd_explora_power_supply').setValue(+this.hd_explora_power_supply_qty)
					break
				case 'Travel AA Rates Per KM':
					this.aa_travel_rates_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('aa_travel_rates').setValue(+this.aa_travel_rates_qty)
					break
				case 'Extra labour 30 minutes':
					this.extra_labour_qty = incoming_peripheral_quantities[i].qty ? incoming_peripheral_quantities[i].qty : 0
					this.bigForm.getControl('extra_labour').setValue(+this.extra_labour_qty)
					break
			}
		}
	}

	incrementNumber(childElement) {
		switch (childElement) {
			case 'rfmodulator':
				let rmmodulator_number = +this.rfmodulator_qty;
				rmmodulator_number = rmmodulator_number + 1;
				this.rfmodulator_qty = rmmodulator_number;

				this.bigForm.getControl('rfmodulator').setValue(this.rfmodulator_qty);
				break;
			case 'dishplate_standard_steel':
				let s_steel_dishplate_number = +this.dishplate_standard_steel_qty;
				s_steel_dishplate_number = s_steel_dishplate_number + 1;
				this.dishplate_standard_steel_qty = s_steel_dishplate_number;

				this.bigForm.getControl('dishplate_standard_steel').setValue(this.dishplate_standard_steel_qty);
				break;
			case 'single_lnb_universal':
				let single_lnb_universal_number = +this.single_lnb_uni_qty;
				single_lnb_universal_number = single_lnb_universal_number + 1;
				this.single_lnb_uni_qty = single_lnb_universal_number;

				this.bigForm.getControl('single_lnb_universal').setValue(this.single_lnb_uni_qty);
				break;
			case 'dishplate_aluminium':
				let alu_dishplate_number = +this.dishplate_alum_qty;
				alu_dishplate_number = alu_dishplate_number + 1;
				this.dishplate_alum_qty = alu_dishplate_number;

				this.bigForm.getControl('dishplate_aluminium').setValue(this.dishplate_alum_qty);
				break;
			case 'dishplate_fibre':
				let fibre_dishplate_number = +this.dishplate_fibre_qty;
				fibre_dishplate_number = fibre_dishplate_number + 1;
				this.dishplate_fibre_qty = fibre_dishplate_number;

				this.bigForm.getControl('dishplate_fibre').setValue(this.dishplate_fibre_qty);
				break;
			case 'dishplate_standard_mild_steel':
				let mild_steel_dishplate_number = +this.dishplate_mild_steel_qty;
				mild_steel_dishplate_number = mild_steel_dishplate_number + 1;
				this.dishplate_mild_steel_qty = mild_steel_dishplate_number;

				this.bigForm.getControl('dishplate_fibre').setValue(this.dishplate_mild_steel_qty);
				break;
			case 'standard_bracket':
				let standard_bracket_number = +this.standard_bracket_qty;
				standard_bracket_number = standard_bracket_number + 1;
				this.standard_bracket_qty = standard_bracket_number;

				this.bigForm.getControl('standard_bracket').setValue(this.standard_bracket_qty);
				break;
			case 'extended_bracket':
				let extended_bracket_number = +this.extended_bracket_qty;
				extended_bracket_number = extended_bracket_number + 1;
				this.extended_bracket_qty = extended_bracket_number;

				this.bigForm.getControl('extended_bracket').setValue(this.extended_bracket_qty);
				break;
			case 'lnb_lmx_502':
				let lnb_lmx_502_number = +this.lnb_lmx_502_qty;
				lnb_lmx_502_number = lnb_lmx_502_number + 1;
				this.lnb_lmx_502_qty = lnb_lmx_502_number;

				this.bigForm.getControl('lnb_lmx_502').setValue(this.lnb_lmx_502_qty);
				break;
			case 'lnb_lmx_501':
				let lnb_lmx_501_number = +this.lnb_lmx_501_qty;
				lnb_lmx_501_number = lnb_lmx_501_number + 1;
				this.lnb_lmx_501_qty = lnb_lmx_501_number;

				this.bigForm.getControl('lnb_lmx_501').setValue(this.lnb_lmx_501_qty);
				break;
			case 'mud_smart_switch':
				let mud_smart_switch_number = +this.mud_smart_switch_qty;
				mud_smart_switch_number = mud_smart_switch_number + 1;
				this.mud_smart_switch_qty = mud_smart_switch_number;

				this.bigForm.getControl('mud_smart_switch').setValue(this.mud_smart_switch_qty);
				break;
			case 'additional_cable':
				let additional_cable_number = +this.additional_cable_qty;
				additional_cable_number = additional_cable_number + 1;
				this.additional_cable_qty = additional_cable_number;

				this.bigForm.getControl('additional_cable').setValue(this.additional_cable_qty);
				break;
			case 'hd_explora_power_supply':
				let hd_explora_power_supply_number = +this.hd_explora_power_supply_qty;
				hd_explora_power_supply_number = hd_explora_power_supply_number + 1;
				this.hd_explora_power_supply_qty = hd_explora_power_supply_number;

				this.bigForm.getControl('hd_explora_power_supply').setValue(this.hd_explora_power_supply_qty);
				break;
			case 'aa_travel_rates':
				let aa_travel_rates_number = +this.aa_travel_rates_qty;
				aa_travel_rates_number = aa_travel_rates_number + 1;
				this.aa_travel_rates_qty = aa_travel_rates_number;

				this.bigForm.getControl('aa_travel_rates').setValue(this.aa_travel_rates_qty);
				break;
			case 'extra_labour':
				let extra_labour_number = +this.extra_labour_qty;
				extra_labour_number = extra_labour_number + 1;
				this.extra_labour_qty = extra_labour_number;

				this.bigForm.getControl('extra_labour').setValue(this.extra_labour_qty);
				break;
			case 'wificonnector':
				let wificonnect_number = +this.wificonnector_qty;
				wificonnect_number = wificonnect_number + 1;
				this.wificonnector_qty = wificonnect_number;

				this.bigForm.getControl('wificonnector').setValue(this.wificonnector_qty);
				break;
			
			case 'diplexer':
				let diplexer_number = +this.diplexer_qty;
				diplexer_number = diplexer_number + 1;
				this.diplexer_qty = diplexer_number;

				this.bigForm.getControl('diplexer').setValue(this.diplexer_qty);
				break;
		}
	}
	decrementNumber(childElement) {
		switch (childElement) {
			case 'rfmodulator':
				if (this.rfmodulator_qty === 0) {
					this.rfmodulator_qty = 0;
				} else {
					let rmmodulator_number = this.rfmodulator_qty;
					rmmodulator_number = rmmodulator_number - 1;
					this.rfmodulator_qty = rmmodulator_number;
				}

				this.bigForm.getControl('rfmodulator').setValue(this.rfmodulator_qty);
				break;
			case 'dishplate_standard_steel':
				if (this.dishplate_standard_steel_qty === 0) {
					this.dishplate_standard_steel_qty = 0;
				} else {
					let s_steel_dishplate_number = this.dishplate_standard_steel_qty;
					s_steel_dishplate_number = s_steel_dishplate_number - 1;
					this.dishplate_standard_steel_qty = s_steel_dishplate_number;
				}

				this.bigForm.getControl('dishplate_aluminium').setValue(this.dishplate_alum_qty);
				break;
			case 'dishplate_aluminium':
				if (this.dishplate_alum_qty === 0) {
					this.dishplate_alum_qty = 0;
				} else {
					let alu_dishplate_number = this.dishplate_alum_qty;
					alu_dishplate_number = alu_dishplate_number - 1;
					this.dishplate_alum_qty = alu_dishplate_number;
				}

				this.bigForm.getControl('dishplate_aluminium').setValue(this.dishplate_alum_qty);
				break;
			case 'single_lnb_universal':
				if (this.single_lnb_uni_qty === 0) {
					this.single_lnb_uni_qty = 0;
				} else {
					let single_lnb_universal_number = +this.single_lnb_uni_qty;
					single_lnb_universal_number = single_lnb_universal_number - 1;
					this.single_lnb_uni_qty = single_lnb_universal_number;
				}

				this.bigForm.getControl('dishplate_steel').setValue(this.single_lnb_uni_qty);
				break;
			case 'dishplate_fibre':
				if (this.dishplate_fibre_qty === 0) {
					this.dishplate_fibre_qty = 0;
				} else {
					let fibre_dishplate_number = this.dishplate_fibre_qty;
					fibre_dishplate_number = fibre_dishplate_number - 1;
					this.dishplate_fibre_qty = fibre_dishplate_number;
				}

				this.bigForm.getControl('dishplate_fibre').setValue(this.dishplate_fibre_qty);
				break;
			case 'dishplate_standard_mild_steel':
				if (this.dishplate_mild_steel_qty === 0) {
					this.dishplate_mild_steel_qty = 0;
				} else {
					let mild_steel_dishplate_number = +this.dishplate_mild_steel_qty;
					mild_steel_dishplate_number = mild_steel_dishplate_number - 1;
					this.dishplate_mild_steel_qty = mild_steel_dishplate_number;
				}

				this.bigForm.getControl('dishplate_standard_mild_steel').setValue(this.dishplate_mild_steel_qty);
				break;
			case 'standard_bracket':
				if (this.standard_bracket_qty === 0) {
					this.standard_bracket_qty = 0;
				} else {
					let standard_bracket_number = +this.standard_bracket_qty;
					standard_bracket_number = standard_bracket_number - 1;
					this.standard_bracket_qty = standard_bracket_number;
				}

				this.bigForm.getControl('standard_bracket').setValue(this.standard_bracket_qty);
				break;
			case 'extended_bracket':
				if (this.extended_bracket_qty === 0) {
					this.extended_bracket_qty = 0;
				} else {
					let extended_bracket_number = +this.extended_bracket_qty;
					extended_bracket_number = extended_bracket_number - 1;
					this.extended_bracket_qty = extended_bracket_number;
				}

				this.bigForm.getControl('extended_bracket').setValue(this.extended_bracket_qty);
				break;
			case 'lnb_lmx_502':
				if (this.lnb_lmx_502_qty === 0) {
					this.lnb_lmx_502_qty = 0;
				} else {
					let lnb_lmx_502_number = +this.lnb_lmx_502_qty;
					lnb_lmx_502_number = lnb_lmx_502_number - 1;
					this.lnb_lmx_502_qty = lnb_lmx_502_number;
				}

				this.bigForm.getControl('lnb_lmx_502').setValue(this.lnb_lmx_502_qty);
				break;
			case 'lnb_lmx_501':
				if (this.lnb_lmx_501_qty === 0) {
					this.lnb_lmx_501_qty = 0;
				} else {
					let lnb_lmx_501_number = +this.lnb_lmx_501_qty;
					lnb_lmx_501_number = lnb_lmx_501_number - 1;
					this.lnb_lmx_501_qty = lnb_lmx_501_number;
				}

				this.bigForm.getControl('lnb_lmx_501').setValue(this.lnb_lmx_501_qty);
				break;
			case 'mud_smart_switch':
				if (this.mud_smart_switch_qty === 0) {
					this.mud_smart_switch_qty = 0;
				} else {
					let mud_smart_switch_number = +this.mud_smart_switch_qty;
					mud_smart_switch_number = mud_smart_switch_number - 1;
					this.mud_smart_switch_qty = mud_smart_switch_number;
				}

				this.bigForm.getControl('mud_smart_switch').setValue(this.mud_smart_switch_qty);
				break;
			case 'additional_cable':
				if (this.additional_cable_qty === 0) {
					this.additional_cable_qty = 0;
				} else {
					let additional_cable_number = +this.additional_cable_qty;
					additional_cable_number = additional_cable_number - 1;
					this.additional_cable_qty = additional_cable_number;
				}

				this.bigForm.getControl('additional_cable').setValue(this.additional_cable_qty);
				break;
			case 'hd_explora_power_supply':
				if (this.hd_explora_power_supply_qty === 0) {
					this.hd_explora_power_supply_qty = 0;
				} else {
					let hd_explora_power_supply_number = +this.hd_explora_power_supply_qty;
					hd_explora_power_supply_number = hd_explora_power_supply_number - 1;
					this.hd_explora_power_supply_qty = hd_explora_power_supply_number;
				}

				this.bigForm.getControl('hd_explora_power_supply').setValue(this.hd_explora_power_supply_qty);
				break;
			case 'extra_labour':
				if (this.extra_labour_qty === 0) {
					this.extra_labour_qty = 0;
				} else {
					let extra_labour_number = +this.extra_labour_qty;
					extra_labour_number = extra_labour_number - 1;
					this.extra_labour_qty = extra_labour_number;
				}

				this.bigForm.getControl('extra_labour').setValue(this.extra_labour_qty);
				break;
			case 'wificonnector':
				if (this.wificonnector_qty === 0) {
					this.wificonnector_qty = 0;
				} else {
					let wificonnector_number = this.wificonnector_qty;
					wificonnector_number = wificonnector_number - 1;
					this.wificonnector_qty = wificonnector_number;
				}

				this.bigForm.getControl('wificonnector').setValue(this.wificonnector_qty);
				break;
			
			case 'diplexer':
				if (this.diplexer_qty === 0) {
					this.diplexer_qty = 0;
				} else {
					let diplexer_number = this.diplexer_qty;
					diplexer_number = diplexer_number - 1;
					this.diplexer_qty = diplexer_number;
				}

				this.bigForm.getControl('diplexer').setValue(this.diplexer_qty);
				break;
		}
	}
	initialiseFormFields() {}
	
	createTemplatePeripheralItems(productList) {
		// const mcArray: any = []
		// for(let i = 0; i <= productList.length; i++){
		// 	const product = productList[i]
		// 	let itemtopush = {}
		// 	switch(product) {
		// 		case 'Diplexer':
		// 			itemtopush = { description: product, qty: this.bigForm.getControl('diplexer').value }
		// 			mcArray.push(itemtopush)
		// 			break
		// 		case 'RF Modulator - Powered':
		// 			 itemtopush = { description: product, qty: this.bigForm.getControl('rfmodulator').value }
		// 			mcArray.push(itemtopush)
		// 			console.log('MODULATOR',mcArray)
		// 			break
					
		// 	}
		// 	this.products = mcArray
		// }
		// console.log('INSIDE THE COMPONENT [RP', this.products)
		
	}

	ngOnInit() {
		this.peripheralSub = this.existing_peripherals$.pipe(
			skipWhile(x => !x),
			take(1),
			map(data => {
				const peripherals_array = Object.values(data)
				this.setExistingPeripheralQuantities(peripherals_array)
				
			})
		).subscribe()
		// this.periphProductCodeSub = this.mc_peripheral_list$.pipe(
		// 	skipWhile(x => !x),
		// 	take(1),
		// 	map(data => {
		// 		const productList = Object.keys(data)
		// 		this.createTemplatePeripheralItems(productList)
			
		// 		return data
		// 	})
		// ).subscribe()
	
		
		
	}
	ngOnDestroy() {
		if(this.peripheralSub) {
			this.peripheralSub.unsubscribe()
		}
	}
}
