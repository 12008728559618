import {
  Flow_0_0_2,
  getFullItemTwo,
  getSubmissionData,
  getCurrentUser,
} from '@flexus/core';
import {
  CollapseActionPanel,
  setActionPanelItems,
} from '../../../app-shell-features';
import { combineLatest, forkJoin } from 'rxjs';
import { skipWhile, take, map, switchMap, filter } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from '../../../../../../../apps/studio/src/environments/environment';
import {
  AUTO_SELECT_TEMPLATE_DECISION,
  billingServerCalls,
  SELECT_CALLOUT_TEMPLATE,
} from './BILLING';

export const SP_GLOBAL_46: Flow_0_0_2 = {
  id: '46',
  name: 'invoice-query',
  itemType: 'flow',
  actionPanel: (instance) =>
    setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
  onStateInit: (inst) => {
    inst.store.dispatch(new CollapseActionPanel());
  },
  onStateDestroy: (inst) => {
    inst.store.dispatch(new CollapseActionPanel());
  },
  header: {
    title: (store) => {
      return store.select(getFullItemTwo)?.pipe(
        map((itemTwo) => {
          if (itemTwo) {
            if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
              return `Invoice Query : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
            } else {
              return 'Invoice Query';
            }
          }
        })
      );
    },
    controls: () => () => [],
  },
  footer: {
    type: 'node_nav',
  },
  serverCalls: {
    skills: {
      serviceVariable: 'spService',
      functionName: 'getSkills',
      responseSlice: 'skills',
      errorMessage: 'No skills were found!',
    },
    dataFile: {
      errorMessage: 'No files found',
      serviceVariable: 'spService',
      functionName: 'getJobInvoice',
    },
    keyValueList1: {
      errorMessage: `Couldn't get the job info`,
      directCall: (http, store) => {
        const fullItemTwo$ = store.select(getFullItemTwo).pipe(
          skipWhile((x) => !x),
          take(1)
        );

        const teamLeaders$ = http
          .get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`)
          .pipe(
            skipWhile((x) => !x),
            take(1),
            map((res) => res['payload'])
          );

        return forkJoin([fullItemTwo$, teamLeaders$]).pipe(
          map(([job, teamLeaders]) => {
            const teamLeadName = job?.team_leader
              ? teamLeaders.find((lead) => lead?.id === job.team_leader.id)
                  ?.full_name || 'Team leader not found.'
              : 'No team leader appointed.';

            const invoiceQueryArray = Array.isArray(
              job?.job_information?.invoicequery
            )
              ? job.job_information.invoicequery
              : [job?.job_information?.invoicequery];

            const invoiceQuery = invoiceQueryArray.at(-1)?.query; // using .at(-1) to get the last element

            const list = {
              'Reason for invoice query 1 ': invoiceQuery,
              'Allocated team leader ': teamLeadName,
              'Claim Type  ': job?.claim?.type ?? '',
              'Address  ': job?.address ?? '',
              'Appointment Date  ':
                job?.office_use?.requestedappointmentdate ??
                'No appointment date set',
              'Appointment Time  ': job?.office_use
                ? `${job.office_use.appointment_type} ${job.office_use.requestedappointmenttime}`
                : 'Appointment time not retrieved',
            };

            return [list];
          })
        );
      },
    },
    ...billingServerCalls,
  },
  instructions: {
    editRoles: {
      0: 'Respond to invoice query',
    },
    viewRoles: {
      0: 'Wait for SP to respond',
    },
  },
  startNode: 'InvoiceQuery',
  nodes: {
    InvoiceQuery: {
      component: {
        children: [
          {
            component: 'FLXFileViewWithSideExtrasComponent',
            inputs: {
              inputHeading: 'Invoice Query',
              dataFiles$: 'dataFile',
              keyValueList$: 'keyValueList1',
              instructions: [
                'There is a query on the invoice',
                'Please read below and address the query',
              ],
            },
          },
        ],
      },
      navs: [
        {
          text: 'Reply, no new invoice',
          color: 'default',
          nextNode: 'NoteOnInvoiceQuery',
        },
        {
          text: 'Upload new invoice',
          color: 'default',
          nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
          serverCalls: {
            response: {
              serviceVariable: 'spService',
              functionName: 'updateJob',
              errorMessage: 'Job could not be updated!!',
            },
          },
          serverFirst: true,
          optIntoValidation: true,
        },
      ],
    },
    NoteOnInvoiceQuery: {
      showTabs: false,
      hideTabItem: true,
      initFormFields: (bf, _item, _instance, _storeQuery, store) => {
        combineLatest([
          store.select(getCurrentUser).pipe(
            filter((x) => !!x),
            take(1)
          ),
          store.select(getFullItemTwo).pipe(
            filter((x) => !!x),
            take(1)
          ),
        ])
          .pipe(take(1))
          .subscribe(([author, job]) => {
            bf.patchValues({ author: author.user.id });
            bf.patchValues({ author_name: author.user.full_name });
            bf.patchValues({ currentState: job?.state });
          });
        bf.addControl(
          'reply_to_invoicequery',
          new UntypedFormControl('', [Validators.required])
        );
      },
      component: {
        children: [
          {
            component: 'FLXFlatDynamicFormComponent',
            inputs: {
              heading: 'Note on Invoice Query',
              formControls: {
                0: {
                  label: 'Please enter your comments here',
                  inputType: 'textarea',
                  rows: 5,
                  formControlName: 'reply_to_invoicequery',
                },
              },
              formLayout: 'stacked',
              containerWidth: '50vw',
              headingSize: 'medium',
              headingWeight: 'light',
              headingType: 'creation',
              headingMargin: '30px 0 75px 0',
            },
          },
        ],
      },
      navs: [
        {
          text: 'Continue',
          color: 'primary',
          nextNode: 'SubmissionSuccess',
          visible: (bf) => {
            return bf
              .getControl('reply_to_invoicequery')
              .valueChanges.pipe(
                map(
                  (value) =>
                    bf.getControl('reply_to_invoicequery').valid && value !== ''
                )
              );
          },
          serverFirst: true,
          serverCalls: {
            response: {
              errorMessage: 'Job could not be updated!!',
              directCall: (http, store) => {
                return forkJoin([
                  store.select(getFullItemTwo)?.pipe(
                    skipWhile((x) => !x),
                    take(1)
                  ),
                  store.select(getSubmissionData)?.pipe(
                    skipWhile((x) => !x),
                    take(1)
                  ),
                ]).pipe(
                  map(([job, submit]) => {
                    const job_id = job?.id;
                    const current_state = job?.state;
                    const new_state = 48;
                    return { job_id, current_state, new_state, ...submit };
                  }),
                  switchMap((data) => {
                    return http.post(
                      `${environment.api_url}v1/job_action/update_job/`,
                      data
                    );
                  })
                );
              },
            },
          },
        },
      ],
    },
    AUTO_SELECT_TEMPLATE_DECISION,
    SELECT_CALLOUT_TEMPLATE,
    BILLING_INVOICE: {
      hideTabItem: true,
      component: 'BillingComponent',
      checkValidityForFields: [
        'invoiceDate',
        'invoiceNumber',
        'actualLineItemsFormArray',
      ],
      inputs: {
        docType: 'invoice',
        numberLabel: 'Invoice',
        currentState: 46,
        newState: 27,
        showLineItemGenerator: true,
        canEditLineItems: true,
        boqLogo:
          environment.client === 'bet_sp'
            ? 'assets/images/boq-bettersure-logo.svg'
            : 'assets/images/boq-sil-logo.svg',
        boqLogoAlt:
          environment.client === 'bet_sp'
            ? 'Bettersure'
            : 'Standard Bank Insurance Limited',
        clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL',
      },
      navs: [
				{
					text: 'Save Draft',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						draftQuote: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating draft invoice!', serviceVariable: 'spService', functionName: 'generateDraftInvoice' }
							}
						}
					}
				},
        {
          text: 'Generate Invoice',
          nextNode: 'NoteOnInvoiceQuery',
          location: 'right',
          linkType: 'submit',
          optIntoValidation: true,
          serverFirst: true,
          serverCalls: {
            postInvoice: {
              errorMessage:
                'An error occurred while trying to create or update BOQ!',
              serviceVariable: 'spService',
              functionName: 'createOrUpdateBOQ',
              followUpSuccessCalls: {
                response: {
                  errorMessage:
                    'An error occurred when generating quote/invoice!',
                  serviceVariable: 'spService',
                  functionName: 'generateBoqQuoteOrInvoice',
                },
              },
            },
          },
        },
      ],
    },
    SubmissionSuccess: { component: 'FLXSuccessTickComponent' },
  },

  bigFormToStoreMapper: {
    currentState: [() => 46, 'current_state'],
    // new_state: [() => 48, 'new_state'],
    reply_to_invoicequery: [
      (rtiq, storeObj) => {
        let ia_query_replies = [];
        if (
          storeObj['selectedContext']?.fullItemTwo?.job_information
            ?.invoicequery
        ) {
          let temp =
            storeObj['selectedContext']?.fullItemTwo?.job_information
              ?.invoicequery;
          if (Array.isArray(temp)) {
            const lastquery = temp[temp.length - 1].query;
            const updatedQueryObj = { query: lastquery, queryreply: rtiq };
            temp = temp.slice(0, temp.length - 1);
            ia_query_replies = [...temp, updatedQueryObj];
          } else {
            ia_query_replies = [
              {
                ...temp,
                queryreply: rtiq,
              },
            ];
          }
        }
        return ia_query_replies;
      },
      'job_information.invoicequery',
    ],
    total: [
      (_a, _b, bf) => {
        return bf.total + bf.vatRate;
      },
      'job_information.quote_amount',
    ],
    invoiceNumber: 'job_information.quote_number',
  },
};
