<div class="container">
	<flx-heading size="small" color="default" align="center">Edit Additional Equipment</flx-heading>

	<!-- <ng-container *ngFor="let product of products" >
		<div>
			<div class="hardware-item">
				<div class="hardware-descriptor">{{product.description}}</div>
				<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('rfmodulator')">down </flx-icon></div>
				<div class="quantity">{{ product.qty }}</div>
				<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('rfmodulator')">up</flx-icon></div>
			</div>
		</div>
		
		{{product.description}}{{product.qty}}
	</ng-container> -->
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">RF Modulator - Powered</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('rfmodulator')">down </flx-icon></div>
			<div class="quantity">{{ rfmodulator_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('rfmodulator')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Diplexer</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('diplexer')">down </flx-icon></div>
			<div class="quantity">{{ diplexer_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('diplexer')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Wi-Fi Connector</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('wificonnector')">down </flx-icon></div>
			<div class="quantity">{{ wificonnector_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('wificonnector')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Dish Plate/Face - Standard Steel</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('dishplate_standard_steel')">down </flx-icon></div>
			<div class="quantity">{{ dishplate_standard_steel_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('dishplate_standard_steel')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Dish Plate/Face - Aluminium</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('dishplate_aluminium')">down </flx-icon></div>
			<div class="quantity">{{ dishplate_alum_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('dishplate_aluminium')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Dishplate / Fibre</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('dishplate_fibre')">down </flx-icon></div>
			<div class="quantity">{{ dishplate_fibre_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('dishplate_fibre')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Dish Plate/Face - Standard Mild Steel - Epo...</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('dishplate_standard_mild_steel')">down </flx-icon></div>
			<div class="quantity">{{ dishplate_mild_steel_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('dishplate_standard_mild_steel')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Single LNB - Universal</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('single_lnb_universal')">down </flx-icon></div>
			<div class="quantity">{{ single_lnb_uni_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('single_lnb_universal')">up</flx-icon></div>
		</div>
	</div>
	
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">LNB LMX 502 (2 port)</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('lnb_lmx_502')">down </flx-icon></div>
			<div class="quantity">{{ lnb_lmx_502_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('lnb_lmx_502')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">LNB LMX 501 (4 port)</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('lnb_lmx_501')">down </flx-icon></div>
			<div class="quantity">{{ lnb_lmx_501_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('lnb_lmx_501')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Standard Bracket</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('standard_bracket')">down </flx-icon></div>
			<div class="quantity">{{ standard_bracket_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('standard_bracket')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Extended Bracket</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('extended_bracket')">down </flx-icon></div>
			<div class="quantity">{{ extended_bracket_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('extended_bracket')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">DStv Smart Switch (MUD's Only)</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('mud_smart_switch')">down </flx-icon></div>
			<div class="quantity">{{ mud_smart_switch_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('mud_smart_switch')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">HD/Explora Power Supply</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('hd_explora_power_supply')">down </flx-icon></div>
			<div class="quantity">{{ hd_explora_power_supply_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('hd_explora_power_supply')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Travel AA Rates Per KM</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('aa_travel_rates')">down </flx-icon></div>
			<div class="quantity">{{ aa_travel_rates_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('aa_travel_rates')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Extra labour 30 minutes</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('extra_labour')">down </flx-icon></div>
			<div class="quantity">{{ extra_labour_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('extra_labour')">up</flx-icon></div>
		</div>
	</div>
	<div>
		<div class="hardware-item">
			<div class="hardware-descriptor">Additional Cable</div>
			<div class="chevron-down"><flx-icon [type]="'chevron-down'" (click)="decrementNumber('additional_cable')">down </flx-icon></div>
			<div class="quantity">{{ additional_cable_qty }}</div>
			<div class="chevron-up"><flx-icon [type]="'chevron-up'" (click)="incrementNumber('additional_cable')">up</flx-icon></div>
		</div>
	</div>
	
	

	

</div>
