import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';

import { UntypedFormControl } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { map, skipWhile, take } from 'rxjs/operators';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { SAVE_TO_DESKTOP } from '../../../../sp_globals/configs/reusable';
import { AUTO_SELECT_TEMPLATE_DECISION, CHECK_IF_ATTENDED_AFTER_HOURS, SELECT_CALLOUT_TEMPLATE, billingServerCalls } from '../../../../sp_globals/configs/reusable/BILLING';

SELECT_CALLOUT_TEMPLATE.navs = [
	{
		text: 'Continue',
		optIntoValidation: false,
		nextNode: 'BILLING_INVOICE'
	}
];

export const BET_SP_69: Flow_0_0_2 = {
	id: '69',
	name: 'small_job_invoice_call_out',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Small job - Invoice call-out : ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Small job - Invoice call-out';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		...billingServerCalls
	},
	instructions: {
		editRoles: {
			0: 'Invoice a call-out fee'
		},
		viewRoles: {
			0: 'Wait for SP to invoice call-out fee'
		}
	},
	startNode: 'SmallJobNotification',
	nodes: {
		SmallJobNotification: {
			initFormFields: bf => {
				bf.addControl('new_state', new UntypedFormControl('--'));
			},
			serverCalls: {
				claimDetailsKeyValues: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								take(1),
								map(job => {
									const appointment = job?.appointment[job.appointment.length - 1];

									const list = {
										'Claim Type': job?.claim ? job?.claim?.type : '',

										Address: job?.claim ? job?.claim?.address : ''
									};

									return [list];
								})
							);
					}
				},
				customerDetailKeyValues: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.pipe(
								take(1),
								map(job => {
									const appointment = job?.appointment[job.appointment.length - 1];

									const list = {
										Customer: job?.claim && job?.claim?.applicant ? job?.claim?.applicant?.first_name + ' ' + job?.claim?.applicant?.surname : '',
										'Contact number': job?.claim && job?.claim?.loan_information ? job?.claim?.loan_information?.onsitecontact : '',
										'Cell Number': job?.claim && job?.claim?.loan_information ? job?.claim?.loan_information?.cellnumber : ''
									};

									return [list];
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'No work required - Invoice call-out fee',
							instructions: ['No work, minor work or assessor required.', 'Invoice a call-out fee.']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Claim Details',
							data$: 'claimDetailsKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: 'Customer Details',
							data$: 'customerDetailKeyValues',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
					color: 'primary'
				}
			]
		},
		AUTO_SELECT_TEMPLATE_DECISION,

		SELECT_CALLOUT_TEMPLATE,

		CHECK_IF_ATTENDED_AFTER_HOURS,
		BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'invoice',
				numberLabel: 'Invoice',
				currentState: 69,
				newState: 27,
				showLineItemGenerator: true,
				canEditLineItems: true,
				boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
				boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Standard Bank Insurance Limited',
				clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL'
			},
			navs: [
				{
					text: 'Generate Invoice',
					nextNode: 'SAVE_TO_DESKTOP',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
    SAVE_TO_DESKTOP,
    DynamicAnchor: {
			component: 'DynamicAnchorComponent'
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent', inputs: { heading: 'This submission was successful. You can return to the workflow.', autoClose: true } }
	},
	bigFormToStoreMapper: {
		new_state: [
			(state, storeObj, formValue, bf) => {
				return 70;
			},
			'new_state'
		],
		total: [
			(a, b, bf) => {
				return bf.total + bf.vatRate;
			},
			'job_information.quote_amount'
		],
		invoice_number: 'job_information.quote_number'
	}
};
