<ng-container>
  <flx-heading size="extra-small" align="left" color="secondary"
    >Job Details</flx-heading
  >
  <div class="job-details">
    <!-- <flx-key-value-list [data$]="jobDetailData$" align="leFt" width="220px"></flx-key-value-list> -->

    <div class="key-value-key">Skill</div>
    <div class="key-value-value">{{ selectedJob?.skill }}</div>
    <div class="key-value-key">Suburb</div>
    <div class="key-value-value">{{ selectedJob?.suburb }}</div>
    <div class="key-value-key">Appointment</div>
    <div class="key-value-value">
      {{ selectedJob?.range_start
      }}<ng-container *ngIf="selectedJob?.range_end">
        - {{ selectedJob?.range_end }}</ng-container
      >
    </div>
  </div>
  <flx-heading size="extra-small" align="left" color="secondary"
    >What Matters</flx-heading
  >
  <ng-container *ngIf="whatMattersData$ | async; let whatmatters">
    <p>{{ whatmatters }}</p>
  </ng-container>
  <flx-heading size="extra-small" align="left" color="secondary"
    >Location by Suburb</flx-heading
  >
  <div style="height: 40vh" *ngIf="apiLoaded; else mapLoading">
    <google-map [options]="options" width="100%" height="100%">
      <map-marker
        *ngIf="jobloco"
        [position]="{ lat: jobloco.lat, lng: jobloco.lng }"
      ></map-marker>
    </google-map>
  </div>
  <ng-template #mapLoading>Loading...</ng-template>
  <div class="job-responses">
    <div class="ignore" (click)="respondToJobHandler('IGNORED')">Ignore</div>
    <div class="decline" (click)="respondToJobHandler('DECLINED')">Decline</div>
    <div class="available" (click)="respondToJobHandler('INTERESTED')">
      Accept
    </div>
  </div>
  <div class="buttons-container">
    <div *ngIf="displayGoBackButton" class="button">
      <flx-button display="block" color="secondary" (click)="goBack()"
        >BACK</flx-button
      >
    </div>
  </div>
</ng-container>

<!-- *ngIf="showJobDetail" -->
