import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BigFormService } from '@flexus/core';
import { skipWhile, take, map } from 'rxjs/operators';

@Component({
  selector: 'flx-internal-assessor-select-other-jobs',
  templateUrl: './internal-assessor-select-other-jobs.component.html',
  styleUrls: ['./internal-assessor-select-other-jobs.component.scss'],
})
export class InternalAssessorSelectOtherJobsComponent implements OnInit {
  @Input() relatedItemTwos$!: Observable<any[]>;
  @Input() skills$!: Observable<any>;
  otherJobs$!: Observable<any[]>;
  selectedOtherJobs: any[] = [];
  instruction!: string;
  header!: string;

  constructor(private bf: BigFormService) {}

  ngOnInit() {
    this.otherJobs$ = this.relatedItemTwos$.pipe(
      skipWhile((x) => !x),
      take(1),
      map((relatedItemTwos: any) => {
        const allowedStates = [92, 96, 90];
        if (relatedItemTwos && relatedItemTwos.length > 0) {
          return relatedItemTwos.filter((job) =>
            allowedStates.includes(job.state)
          );
        } else {
          return relatedItemTwos;
        }
      })
    );
  }

  toggleOtherJobSelection(job: any): void {
    // see if job is already selected
    if (this.checkIfJobIsSelected(job)) {
      this.selectedOtherJobs = this.selectedOtherJobs.filter(
        (selected: any) => selected.id !== job?.id
      );
      this.bf.bigForm?.value?.effected_jobs.splice(
        this.bf.bigForm?.value?.effected_jobs.indexOf(job.id),
        1
      );
      this.bf.bigForm.controls['effected_jobs']?.patchValue(
        this.bf.bigForm?.value?.effected_jobs
      );
      return;
    }
    this.selectedOtherJobs.push(job);
    this.bf.bigForm.controls['effected_jobs']?.patchValue([
      ...(this.bf.bigForm?.value?.effected_jobs ?? []),
      job?.id,
    ]);
  }

  checkIfJobIsSelected(job: any): boolean {
    return this.selectedOtherJobs.some(
      (selected: any) => selected.id === job?.id
    );
  }
}
