import { Flow_0_0_2, getSelectedItemOne, ChangeManifestState, getCurrentUser } from '@flexus/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import gql from 'graphql-tag';
import { take, map, tap, withLatestFrom, switchMap, debounceTime, skipWhile} from 'rxjs/operators';
import { flatten, values } from 'ramda';
import moment from 'moment';
import { empty, of, from, Observable} from 'rxjs';
import { MUL_BANK_DETAILS } from '../flows/MUL_BANK_DETAILS';
import { environment } from '../../../../../environments/environment';
import { transformJobsToJobCardRequest, checkBusinessRules } from './transform.functions';
import { CustomValidators, generateRange } from '@flexus/utilities';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { RemoveError } from '@flexus/error-handler';

const today = new Date();
const tomorrow = new Date(today);
const isAfterOne = today.getHours() > 12;
tomorrow.setDate(tomorrow.getDate() + 1);

export const MulCreateClaim: Flow_0_0_2 = {
	id: 'create_item_one',
	name: 'new claim',
	startNode: 'PolicyDetails',
	serverCalls: {
		provinceList: {
			errorMessage: 'Error retrieving province list',
			directCall: (http, store, sq) => {
				return sq
					.queryObject(
						gql`
							{
								allInfo {
									active_regions
								}
							}
						`,
						store
					)
					.pipe(
						take(1),
						map((res: any) => {
							return res?.active_regions;
						})
					);
			}
		}
	},
	deactivateStateGuard: (guardService: any, comp, router, currentRoute, currentState, nextState) => {
		return from(guardService.indexedDbService.currentItem.get('currentItem')).pipe(
			withLatestFrom(guardService.canGo$),
			switchMap(([currentItem, canGo]: any) => {
				return <Observable<any>>guardService.store.select(getSelectedItemOne).pipe(
					take(1),
					map((itemOne: any) => {
						if (!canGo && currentItem?.applicant && currentItem?.loan_information) {
							if (itemOne?.tempKey && guardService?.bf?.bigForm?.touched) {
								// Local draft has been edited
								guardService.modalService.openModalDirectly((inst) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this local draft.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Save Changes Locally',
											color: 'primary',
											linkType: 'close',
											clickHandler: () => {
												guardService.canGo$.next(true);
												const localDraft = {
													...currentItem,
													tempKey: itemOne?.tempKey,
													state: 169
												};
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.claimInDraft.put(localDraft).then(() => {
														guardService.indexedDbService.currentItem.delete('currentItem');
													});
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (itemOne?.tempKey && !guardService?.bf?.bigForm?.touched) {
								// Local draft opened but not edited yet
								guardService.modalService.openModalDirectly((inst) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this local draft.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne?.tempKey && !guardService?.bf?.bigForm?.touched) {
								// Any Server Draft not edited
								guardService.modalService.openModalDirectly((inst) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from your current view.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne?.tempKey && guardService?.bf?.bigForm?.touched) {
								// Any server draft that is edited
								guardService.modalService.openModalDirectly((inst) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving away from this draft.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne && !guardService.bf.bigForm.touched) {
								// Creating New Claim but forms has not been touched yet
								guardService.modalService.openModalDirectly((inst) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									inst.color = 'alert';
									// inst.message = '';
									inst.setMessage(['You are moving from your current view.']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Leave',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Stay',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							} else if (!itemOne && guardService.bf.bigForm.touched) {
								// In Claim creation and form has been touched
								guardService.modalService.openModalDirectly((inst) => {
									inst.type = 'warning';
									inst.closeButton = 'false';
									// inst.message = '';
									inst.setMessage(['You are moving from your current view.', ' Your changes will be discarded!']);
									// instance.open();
									inst.navButtons = [
										{
											text: 'Discard Changes',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												guardService.canGo$.next(true);
												router.navigate([nextState.url]).then(() => {
													inst.close();
													setTimeout(() => {
														guardService.resetCanGoVariable();
													}, 2500);
													guardService.indexedDbService.currentItem.delete('currentItem');
												});
											}
										},
										{
											text: 'Cancel',
											color: 'alert',
											linkType: 'close',
											clickHandler: () => {
												inst.close();
											}
										}
									];
								});
								return guardService.canGo$.asObservable();
							}
						} else {
							return true;
						}
					})
				);
			})
		);
	},
	activateStateGuard: () => {
		return of(true);
	},
	onStateInit: instance => {
		const paramFunc = (inst, mapper) => {
			return {
				id: '1',
				icon: 'what-matters',
				command: inst.loadComponent.bind(inst, mapper['FLXWhatMattersComponent'])
			};
		};
		instance.navService.portalActions.next({ call: 'onClick', paramFunc });
	},
	onStateDestroy: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['what-matters']),
	instructions: {
		editRoles: {
			0: 'Placeholder'
		},
		viewRoles: {
			0: 'Placeholder'
		}
	},
	header: {
		title: 'New Claim',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	events: {
		checkCatastrophe: {
			// sys. denotes system events
			triggerOn: ['sys.online', 'date_of_loss', 'claim_class', 'latitude', 'longitude', 'suburb_code', 'province'],
			triggerWhen: (online, date_of_loss, claim_class, latitude, longitude, suburb_code, province) =>
				online && !!date_of_loss && date_of_loss instanceof Date && !isNaN(date_of_loss.valueOf()) && !!claim_class && !!latitude && !!longitude && !!suburb_code && !!province,
			dataMutations: () => {},
			serverCalls: {
				checkCatastrophe: {
					errorMessage: 'Error could not check Catastrophe',
					ignoreFalseError: true,
					isBackgroundTask: true,
					offlineFallback: {
						errorMessage: 'Could not run CAT check, you are offline.',
						directCall: () => {
							return of('Is Offline');
						}
					},
					directCall: (http, store, sq, bf) => {
						const { date_of_loss, claim_class, suburb_code } = bf.bigForm.value;
						const body = {
							loss_date: moment(date_of_loss)?.format('YYYY-MM-DD'),
							claim_type: claim_class,
							postal_code: suburb_code
						};
						return http.post(`${environment.api_url}v1/cat_action/check_catastrophe/`, body).pipe(
							skipWhile(f => !f),
							map((res: any) => res?.payload),
							tap((res: any) => {
								bf.bigForm.addControl('cat_checked', new UntypedFormControl(1));
								if (res && res?.is_cat) {
									bf.bigForm.addControl('is_cat', new UntypedFormControl(res?.is_cat || null));
									bf.bigForm.addControl('cat_code', new UntypedFormControl(res?.cat_code || null));
								}
							})
						);
						// return of({ msg: 'testing cat check calls...' }).pipe(tap(console.log));
					}
				}
			}
		}
	},
	nodes: {
		PolicyDetails: {
			name: 'Policy Details',
			showTabs: true,
			initFormFields: (bf, item, instance, sq, store) => {
				// To check that the screens have been visited
				bf.bigForm.addControl('checked_policy_details', new UntypedFormControl(null));
				bf.bigForm.addControl('checked_address_confirmation', new UntypedFormControl(null));
				bf.bigForm.addControl('checked_claim_details', new UntypedFormControl(null));
				bf.bigForm.addControl('checked_device_details', new UntypedFormControl(null));
				bf.bigForm.addControl('checked_current_setup', new UntypedFormControl(null));
				bf.bigForm.addControl('checked_appointments', new UntypedFormControl(null));

				bf.bigForm.patchValue({ checked_policy_details: true });

				store
					.select(getCurrentUser)
					.pipe(take(1))
					.subscribe(user => {
						const { full_name, contact_number } = user.user;
						const email_address = user?.email;
						bf.bigForm.addControl('sil_agent', new UntypedFormControl(false));
						bf.bigForm.addControl('handler', new UntypedFormControl(full_name));
						bf.bigForm.addControl('handlercontact', new UntypedFormControl(contact_number));
						bf.bigForm.addControl('handleremail', new UntypedFormControl(email_address));
					});

				// bf.patchValues({ policy_number: '' });
				bf.bigForm.addControl('new_state', new UntypedFormControl(1));
				bf.bigForm.addControl('sub_section', new UntypedFormControl(3));
				// bf.bigForm.addControl('clientcode', new FormControl(null, [Validators.required]));
				bf.bigForm.addControl('claim_class', new UntypedFormControl(null, [Validators.required]));

				// For maps initialisation. Otherwise validators do not pick it up
				let suburb_code, latitude, longitude, province, area_code, area, postal_code;

				if (bf.bigForm.get('latitude')) {
					latitude = bf.bigForm.get('latitude')?.value;
					bf.bigForm.get('latitude')?.setValidators([Validators.required]);
				} else {
					bf.addControl('latitude', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('longitude')) {
					longitude = bf.bigForm.get('longitude')?.value;
					bf.bigForm.get('longitude')?.setValidators([Validators.required]);
				} else {
					bf.addControl('longitude', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('suburb_code')) {
					suburb_code = bf.bigForm.get('suburb_code')?.value;
					// bf.bigForm.get('suburb_code')?.setValidators([Validators.required]);
				} else {
					bf.addControl('suburb_code', new UntypedFormControl(null));
				}

				if (bf.bigForm.get('postal_code')) {
					postal_code = bf.bigForm.get('postal_code')?.value;
					// bf.bigForm.get('postal_code')?.setValidators([Validators.required]);
				} else {
					bf.addControl('postal_code', new UntypedFormControl(null));
				}

				if (bf.bigForm.get('province')) {
					province = bf.bigForm.get('province')?.value;
					bf.bigForm.get('province')?.setValidators([Validators.required]);
				} else {
					bf.addControl('province', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('area_code')) {
					area_code = bf.bigForm.get('area_code')?.value;
					bf.bigForm.get('area_code')?.setValidators([Validators.required]);
				} else {
					bf.addControl('area_code', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('area')) {
					area = bf.bigForm.get('area')?.value;
					bf.bigForm.get('area')?.setValidators([]);
				} else {
					bf.addControl('area', new UntypedFormControl(null, []));
				}

				if (!latitude || !longitude) {
					latitude = null;
					longitude = null;
				}

				bf.addControl(
					'client_details',
					new UntypedFormGroup({
						surname: new UntypedFormControl('', [
							Validators.required,
							Validators.minLength(2),
							CustomValidators.hardMaxLength(140),
							CustomValidators.onlyAllowedASCII([32, 39, 44, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
						]),
						id_number: new UntypedFormControl('', [
							// Validators.required,
							// CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
							CustomValidators.alphaNumericWithForwardSlash,
							Validators.minLength(6),
							CustomValidators.hardMaxLength(16)
						]),
						first_name: new UntypedFormControl('', [
							Validators.required,
							Validators.minLength(2),
							CustomValidators.hardMaxLength(140),
							CustomValidators.onlyAllowedASCII([32, 39, 44, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
						]),
						policy_number: new UntypedFormControl(bf.bigForm?.value?.policy_lookup ?? '', [
							Validators.required
							// CustomValidators.alphaNumericWithForwardSlash,
							// Validators.minLength(8),
							// CustomValidators.hardMaxLength(30)
						]),
						dstv_account_number: new UntypedFormControl('', [Validators.required, Validators.minLength(5), CustomValidators.hardMaxLength(20)]),
						mavenclaimnumber: new UntypedFormControl('', [Validators.required, Validators.minLength(2), CustomValidators.hardMaxLength(20)])
					})
				);
				bf.addControl(
					'contact_details',
					new UntypedFormGroup({
						contact_number: new UntypedFormControl('', [
							Validators.required,
							CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
							CustomValidators.contact_number,
							CustomValidators.numeric,
							Validators.minLength(10),
							CustomValidators.hardMaxLength(12)
						]),
						cell_number: new UntypedFormControl('', [
							Validators.required,
							CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
							CustomValidators.numeric,
							Validators.minLength(10),
							CustomValidators.hardMaxLength(12)
						]),
						email: new UntypedFormControl('', [CustomValidators.onlyAllowedASCII(generateRange(32, 126, 1))]),
						preferred_com_method: new UntypedFormControl('SMS', Validators.required)
					})
				);
				bf.addControl(
					'insured_address',
					new UntypedFormGroup({
						complex: new UntypedFormControl('', CustomValidators.hardMaxLength(64)),
						complex_unit_number: new UntypedFormControl('', CustomValidators.hardMaxLength(64)),
						street_address: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)]),
						suburb: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)]),
						city: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)]),
						postal_code: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)]),
						province: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)])
					})
				);

				bf.addControl(
					'current_environment',
					new UntypedFormGroup({
						no_view_environments: new UntypedFormControl(null, [Validators.required]),
						dwelling_type: new UntypedFormControl(null, [Validators.required]),
						no_of_tvs: new UntypedFormControl(null, [Validators.required]),
						type_of_tvs: new UntypedFormControl(null, [Validators.required])
					})
				);
			},
			inputs: {
				title: 'Policy Details',
				isMC: true,
				commsOptions: [
					{ value: 'Phone', display: 'Phone' },
					{ value: 'Sms', display: 'SMS' },
					{ value: 'Email', display: 'Email' },
					{ value: 'Other', display: 'Other' }
				],
				sourceToFormMapper: {
					FIRSTNAME: 'client_details.first_name',
					SURNAME: 'client_details.surname',
					IDNUMBER: 'client_details.id_number',
					DSTVACCOUNTNUMBER: 'client_details.dstv_account_number',
					MAVENCLAIMNUMBER: 'client_details.mavenclaimnumber',
					POLICYNUMBER: 'client_details.policy_number',
					ACCOUNTNUMBER: 'client_details.bond_number',
					HOMETELNUMBER: 'contact_details.contact_number',
					CELLNUMBER: 'contact_details.cell_number',
					EMAIL: 'contact_details.email',
					LOCALFILE: 'local_file',
					ISJOINTACCOUNT: 'is_joint_account',
					CLIENTCODE: 'clientcode',
					PREFEREDCONTACT: 'contact_details.preferred_com_method',
					ADDRESS1: 'insured_address.street_address',
					ADDRESS2: 'insured_address.suburb',
					SUBURB: 'insured_address.city',
					SUBURBCODE: 'suburb_code',
					SUMINSURED: 'sum_insured',
					// UNDERWRITERCODE: 'underwriter',
					SPECIALCLAUSE: 'specialclause',
					MARKETSEGMENTATIONREF_KEY: 'market_segment_key'
				}
			},
			component: 'PolicyDetailsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'Continue',
					location: 'right',
					nextNode: 'AddressConfirmation',
					color: 'primary'
				}
			]
		},
		AddressConfirmation: {
			name: 'Maps',
			showTabs: true,
			serverCalls: {
				provinceList: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											active_regions
										}
									}
								`,
								store
							)
							.pipe(
								take(1),
								map(res => {
									return (res as any)?.active_regions;
								})
							);
					}
				},
				policyAddress: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											submissionData {
												loan_information {
													propertycomplex
													propertycomplexunitnumber
													propertystreetaddress
													propertysuburb
													propertycity
													suburbcode
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(result => {
									const res = result as any;
									const addressArray = [];

									if (res?.propertycomplex) addressArray.push(`${res?.propertycomplexunitnumber} ${res?.propertycomplex}`);
									if (res?.propertystreetaddress) addressArray.push(res?.propertystreetaddress);
									if (res?.propertysuburb) addressArray.push(res?.propertysuburb);
									if (res?.propertycity) addressArray.push(res?.propertycity);
									if (res?.suburbcode) addressArray.push(res?.suburbcode);

									return addressArray.join(', ');
								})
							);
					}
				},
				policyAddressObject: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											submissionData {
												loan_information {
													propertycomplex
													propertycomplexunitnumber
													propertystreetaddress
													propertysuburb
													propertycity
													suburbcode
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1)
							);
					}
				}
			},
			initFormFields: (bf) => {
				bf.bigForm.patchValue({ checked_address_confirmation: true });
			},
			component: 'AddressConfirmationComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'ClaimDetails' }
			]
		},
		ClaimDetails: {
			name: 'Claim Details',
			showTabs: true,
			serverCalls: {
				claimDetailsInfo: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											claim_types {
												id
												name
												description
												skill_groups
												priority
											}
											skills {
												id
												name
											}
											config_options {
												desktop {
													upfront_rejection_reasons
												}
												general {
													ia_request_reasons
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(({ claim_types, skills, upfront_rejection_reasons }) => {
									const clonedSkills = [...skills];

									clonedSkills.sort((x, y) => (x.id === 92 ? -1 : y.id === 92 ? 1 : 0));

									return { claim_types, skills: clonedSkills, upfront_rejection_reasons };
								})

								// tap(console.log),
							);
					}
				}
			},
			initFormFields: (bf, item, comp) => {
				bf.bigForm.patchValue({ checked_claim_details: true });

				// bf.addControl('claim_class', new FormControl(null, Validators.required));
				bf.addControl('selectedClaimClass', new UntypedFormControl());
				bf.addControl('claim_class_description', new UntypedFormControl(''));
				comp.isMC = true;
				bf.addControl('jobs', new UntypedFormArray([comp.createJob()]));

				// Contextual form values
				//fire
				bf.bigForm.addControl('firedamageextent', new UntypedFormControl(null));
				bf.bigForm.addControl('recomendedadjuster', new UntypedFormControl(null));
				// Theft / damage
				bf.bigForm.addControl('policeclaimnumber', new UntypedFormControl(null));
				// Miscellaneous
				bf.bigForm.addControl('dontpingsp', new UntypedFormControl(0));
				bf.bigForm.addControl('extra_info_type', new UntypedFormControl(null));
				// IA Reason needed
				bf.bigForm.addControl('ia_request_reason', new UntypedFormControl(null));
			},
			inputs: {
				cashInLieuComp: 'CashInLieu',
				offPanelComp: 'OffPanel',
				jobLimit: [{ lossClass: 28, limit: 1 }], // After hours only one Job
				claimActions: {
					// fire: {
					// 	triggerOn: [7],
					// 	action: 'fire'
					// },
					police: {
						triggerOn: [8, 9, 16],
						action: 'form',
						layout: 'flex',
						inputs: {
							0: {
								label: 'Police Case Number:',
								inputType: 'input',
								defaultValue: '',
								width: '100%',
								formControlName: 'policeclaimnumber',
								validators: [Validators.required, Validators.minLength(4)]
							}
						}
					},
					subsidence: {
						triggerOn: [14, 15, 19],
						action: 'display-list',
						list: {
							heading: 'Exclusions:',
							subheading: 'The following items are excluded from subsidence claims',
							points: [
								'Any digging or excavation (other than mining activities) or removal or weakening of support.',
								'Any alteration, addition or repair to the Insured Property, even if done before this Policy started.',
								'The compaction of made up ground or fill',
								'Any defective design, materials or workmanship',
								'Any changes in the volume or moisture of Active Soils',
								'Any cause that existed for over 12 months or before this Policy started',
								'Any similar cause that You already claimed for, unless You did what is needed to prevent future damage from that cause and maintained it',
								'Any foundation system, foundation, plinth wall, floor or flooring system below the level of the main living area level (for example, in any basement, entrance or garage below the main living ground floor area)',
								'Any swimming pool, tennis court, patio, terrace, driveway, path, paving, surfacing system, concealed or exposed pipe (or other * fluid conduit), boundary, garden, retaining wall, fence, post or gate.',
								'Settlement, shrinkage or expansion of the Insured Property because of active soil; and/or',
								'Consequential loss or damage of any kind whatsoever upfront rejection reason'
							]
						}
					},
					// lossAdjustWarning: {
					// 	triggerOn: [20],
					// 	action: 'warning',
					// 	message: 'It is strongly advised that you ONLY select a LOSS ADJUSTER for this claim'
					// },
					miscellaneous: {
						triggerOn: [21],
						action: 'upfront-reject'
					}
				},
				fireContainedOptions: [
					{ display: 'Contained to Kitchen', value: 0 },
					{ display: 'Contained to Other Area', value: 1 },
					{ display: 'Not Contained', value: 2 }
				],
				providerType: [{ value: 1, display: 'Request SP' }],
				isMC: true
				// Remove off panel for now
			},
			component: 'ClaimDetailsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'DeviceDetails' }
			]
		},
		DeviceDetails: {
			name: 'Device Details',
			showTabs: true,
			serverCalls: {
				replacementDeviceTypes: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											config_options {
												general {
													mc_insurance_items
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(values),
								map<any, any>(flatten)
							);
					}
				}
			},
			initFormFields: (bf) => {
				bf.bigForm.patchValue({ checked_device_details: true });
				bf.addControl('selectedClaimClass', new UntypedFormControl());
				bf.addControl('claim_class_description', new UntypedFormControl(''));
			},
			inputs: {},
			component: 'DeviceDetailsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'ClaimAppointments' }
			]
		},
		ClaimAppointments: {
			name: 'Appointments',
			initFormFields: bf => {
				bf.bigForm.patchValue({ checked_appointments: true });

				bf.addControl(
					'on_site_person',
					new UntypedFormControl('', [
						Validators.required,
						Validators.minLength(2),
						CustomValidators.hardMaxLength(140),
						CustomValidators.onlyAllowedASCII([32, 39, 44, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
					])
				);
				bf.addControl('on_site_notes', new UntypedFormControl('', [CustomValidators.onlyAllowedASCII([10, 13]?.concat(generateRange(32, 126, 1)))]));
				bf.addControl(
					'on_site_contact',
					new UntypedFormControl('', [
						Validators.required,
						CustomValidators.numeric,
						Validators.minLength(10),
						CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
						CustomValidators.hardMaxLength(12)
					])
				);
				bf.addControl('timepicker', new UntypedFormControl(''));
				bf.addControl('selectedDateType', new UntypedFormControl(''));
				bf.addControl('isSameAppointment', new UntypedFormControl(''));
				bf.addControl('appointments', new UntypedFormArray([]));
			},
			showTabs: true,
			inputs: {
				minHour: 8,
				maxHour: 17,
				alternateMaxHour: 16,
				alternateMaxHourDays: { days: [6], alsoForPHs: false },
				...(isAfterOne ? { minDate: tomorrow } : {}),
				allowSundays: false,
				allowPublicHolidays: false
			},
			serverCalls: {
				appointmentTypes: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											appointment_types {
												id
												name
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(values),
								map<any, any>(flatten)
							);
					}
				},
				appointmentData: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											appointment_types {
												id
												name
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(values),
								map<any, any>(flatten)
							);
					}
				},
				publicHolidays: {
					serviceVariable: 'mulService',
					functionName: 'getPublicHolidays',
					responseSlice: 'payload',
					errorMessage: 'Could not get public holidays!'
				}
			},
			component: 'ClaimAppointmentsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'CurrentSetup' }
			]
		},
		CurrentSetup: {
			name: 'Current Setup',
			showTabs: true,
			serverCalls: {
				installationDetails: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											config_options {
												general {
													installation_details_options {
														tv_count
														tv_type
														viewing_environs
														dwelling_type
													}
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(values),
								map<any, any>(flatten)
							);
					}
				}
			},
			initFormFields: (bf) => {
				bf.bigForm.patchValue({ checked_current_setup: true });
			},
			inputs: {},
			component: 'CurrentSetupComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{ text: 'Continue', color: 'primary', nextNode: 'ClaimSubmissionSummary' }
			]
		},
		ClaimSubmissionSummary: {
			name: 'Submit',
			showTabs: true,
			initFormFields: (bf) => {
				const clientDetails = bf.bigForm.get('client_details')?.value?.policy_number ?? '';
				if (bf.bigForm.get('underwriter')) {
					bf.bigForm.get('underwriter').patchValue(clientDetails);
				} else {
					bf.addControl('underwriter', new UntypedFormControl(clientDetails));
				}
			},
			// component: 'FLXFlatDynamicFormComponent',
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Submit Claim To Proceed',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXAlertMessagesComponent',
						inputs: { alertMessages$: 'alertMessages' }
					}
				]
			},
			serverCalls: {
				alertMessages: {
					errorMessage: 'Could not retrieve the form errors',
					directCall: (http, store, sq, bf) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						if (!!bf.bigForm?.get('jobs')) {
							const jobs = bf.bigForm?.value?.jobs;

							jobs.forEach((job, index) => {
								if (job.skill === 'Loss Adjusting' || job?.skill === 'Internal Assessor') {
									if (bf.bigForm?.get(`jobs.${index}`)?.get('assessorWaived')) {
										bf.bigForm?.get(`jobs.${index}`)?.get('assessorWaived')?.setValue(true);
									}
								} else {
									if (bf.bigForm?.get(`jobs.${index}`)?.get('assessorWaived')) {
										bf.bigForm?.get(`jobs.${index}`)?.get('assessorWaived')?.setValue(false);
									}
								}
							});
						}

						checkBusinessRules(bf.bigForm);

						bf.bigForm?.updateValueAndValidity();
						return bf.bigForm?.valueChanges?.pipe(
							// takeUntil(of({ type: 'ROUTER_NAVIGATED' })),
							debounceTime(800),
							switchMap(() => {
								return bf.retrieveErrors$();
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'Save as Draft',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						saveAsServerDraft: {
							errorMessage: 'Could not save the draft!',
							serviceVariable: 'mulService',
							functionName: 'checkNetworkAndSaveDraft',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'mulService',
									functionName: 'deleteCurrentEditableItem'
								}
							}
						}
					}
				},
				{
					text: 'Submit',
					linkType: 'submit',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'This claim could not be created!',
							serviceVariable: 'mulService',
							functionName: 'createClaim',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'mulService',
									functionName: 'deleteCurrentEditableItem'
								},
								clearCachedClaims: {
									errorMessage: 'Could not clear cached claims!',
									directCall: (http, store, sq, bf, controller, modal) => {
										if (!!bf.bigForm?.value?.is_cat === true) {
											modal.openModalDirectly(inst => {
												inst.heading = 'Appointment will be ignored';
												inst.type = 'info';
												inst.subheading = 'This claim is part of a catastrophe.';
												inst.message = 'Notify customer that appointment times cannot be met based on the volume of claims.';
												inst.navButtons = [
													{
														text: 'Continue',
														color: 'primary',
														linkType: 'close'
													}
												];
												inst.closeButton = false;
											});
										}
										bf.bigForm.reset();
										Object.keys(bf.bigForm.controls)?.forEach(control => bf.bigForm.removeControl(control));
										controller.dispatch(
											new ChangeManifestState(state => {
												return {
													...state,
													viewData: {
														...state.viewData,
														default: []
													}
												};
											})
										);
										return empty();
									}
								}
							},
							followUpFailCalls: {
								createLocalDraft: {
									errorMessage: 'Could not create the claim in local draft',
									serviceVariable: 'mulService',
									functionName: 'saveAsLocalDraft',
									followUpSuccessCalls: {
										deleteCurrentEditableClaim: {
											errorMessage: 'Could not delete the current editable claim!',
											serviceVariable: 'mulService',
											functionName: 'deleteCurrentEditableItem'
										},
										navigateToWorkflow: {
											errorMessage: '',
											serviceVariable: 'mulService',
											functionName: 'navigateToWorkflow'
										}
									}
								}
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent',
			serverCalls: {
				copyText: {
					errorMessage: 'Could not retrieve new claim number!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											response {
												payload {
													mid
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(values),
								map<any, any>(flatten),
								switchMap((val: any) => {
									if (!!val) return val;
									else return null;
								}),
								tap(() => {
									store.dispatch(new CollapseActionPanel());
								})
							);
					}
				}
			},
			inputs: {
				copyTextHeading: 'Claim Number',
				autoClose: false
			},
			navs: []
		},
		CashInLieu: {
			...MUL_BANK_DETAILS,
			inputs: {
				...MUL_BANK_DETAILS.inputs,
				modalNavSuccess: {
					linkType: 'nextNode',
					nextNode: 'ClaimDetails',
					text: 'Continue',
					color: 'primary'
				}
			},
			navs: [
				{
					text: 'Enter Details Later',
					location: 'right',
					color: 'secondary',
					nextNode: 'ClaimDetails'
				},
				...MUL_BANK_DETAILS.navs
			]
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		sub_section: [
			() => {
				return 3;
			},
			'sub_section_id'
		],
		// General
		handler: 'loan_information.handler',
		handlercontact: 'loan_information.handlercontact',
		handleremail: 'loan_information.handleremail',

		// Policy Details
		'client_details.first_name': ['applicant.first_name', 'name'],
		'client_details.surname': ['applicant.surname', 'surname'],
		'client_details.id_number': ['applicant.id_number'],
		'contact_details.contact_number': ['loan_information.contactnumber'],
		'client_details.policy_number': ['policy_id', 'loan_information.policy_number', 'applicant.claimantpoliceynum'],
		'client_details.dstv_account_number': ['dstv_account_number', 'loan_information.dstv_account_number', 'applicant.dstv_account_number'],
		'client_details.mavenclaimnumber': ['mavenclaimnumber', 'loan_information.mavenclaimnumber', 'applicant.mavenclaimnumber'],
		'client_details.bond_number': ['applicant.bondnumber'],
		market_segment_key: ['market_segment', 'loan_information.market_segment_key'],
		// local_file: 'applicant.local_file',
		'contact_details.cell_number': ['loan_information.cellnumber'],
		'contact_details.email': ['loan_information.email'],
		'contact_details.preferred_com_method': 'loan_information.preferedcommethod',
		is_joint_account: ['loan_information.isjointaccount'],
		clientcode: ['loan_information.clientcode'],
		specialclause: ['loan_information.specialclause'],
		sum_insured: 'loan_information.suminsured',

		// Address confirmation
		latitude: ['location.lat', 'loan_information.claimlatitude'],
		longitude: ['location.long', 'loan_information.claimlongitude'],
		suburb_code: ['postal_code', 'loan_information.suburbcode'],
		'insured_address.province': ['loan_information.claimprovince', 'claimprovince'],
		area: ['area', 'loan_information.areacode'],
		'insured_address.complex': ['loan_information.propertycomplex'],
		'insured_address.complex_unit_number': ['loan_information.propertycomplexunitnumber'],
		'insured_address.street_address': ['address', 'loan_information.propertystreetaddress'],
		'insured_address.suburb': ['suburb', 'loan_information.propertysuburb'],
		'insured_address.city': ['loan_information.propertycity'],
		'insured_address.postal_code': ['postal_code', 'loan_information.suburbcode', 'loan_information.postal_code'],
		addressconfirmation: 'loan_information.addressconfirmation',

		// Current Setup

		'current_environment.no_view_environments': ['loan_information.current_environment.no_view_environments'],
		'current_environment.dwelling_type': ['loan_information.current_environment.dwelling_type'],
		'current_environment.no_of_tvs': ['loan_information.current_environment.no_of_tvs'],
		'current_environment.type_of_tvs': ['loan_information.current_environment.type_of_tvs'],

		// Claim details
		claim_class: ['claim_type_id', 'loan_information.claimtype_id'],
		claim_class_description: 'loan_information.claimtype',
		jobs: [
			[transformJobsToJobCardRequest, 'jobcardrequest'],
			[transformJobsToJobCardRequest, 'loan_information.jobcardrequest']
		],

		// Claim Appointment
		on_site_contact: 'loan_information.onsitecontact',
		on_site_notes: 'loan_information.onsitenotes',
		on_site_person: 'loan_information.onsiteperson',

		// Other
		dontpingsp: 'loan_information.dontpingsp',

		// What Matters Action Panel
		date_of_loss: [date => moment(date)?.format('YYYY-MM-DD'), 'loan_information.dateofloss'],
		whatmatters: 'loan_information.whatmatters',
		claimdescription: [
			(claimdescription, storeObj, formValues) => {
				const handler = storeObj?.identity?.currentUser?.user?.full_name;
				return 'Date: ' + moment()?.format('YYYY-MM-DD') + ' \nStaff: ' + (handler || '') + ' \n' + formValues.whatmatters + '.';
			},
			'loan_information.claimdescription'
		],

		// Claim Details context actions

		firedamageextent: [
			(val, storeObj, formValue) => {
				return formValue.claim_class === 7 ? val : null;
			},
			'loan_information.firedamageextent'
		],
		recomendedadjuster: [
			(val, storeObj, formValue) => {
				return formValue.claim_class === 7 ? val : null;
			},
			'loan_information.recomendedadjuster'
		],
		policeclaimnumber: [
			(val, storeObj, formValue) => {
				return [8, 9, 6]?.includes(formValue.claim_class) ? val : null;
			},
			'loan_information.policeclaimnumber'
		],
		ia_request_reason: ['loan_information.ia_requested_reason'],

		// Catastrophe Check
		is_cat: 'is_cat',
		cat_checked: ['loan_information.cat_checked'],
		cat_code: ['cat_code', 'loan_information.cat_ID']
	},
	flowErrorMessages: {
		'checked_policy_details:required': 'Please fill in the Policy Details',
		'checked_address_confirmation:required': 'Please confirm the address on the Map.',
		'checked_claim_details:required': 'Please fill in the Claim Details.',
		'checked_device_details:required': 'Please fill in the Device Details.',
		'checked_appointments:required': 'Please set an Appointment.',
		'payment_method:required': 'Specify payment method',
		'clientcode:required': 'Please search for a Valid Policy (Policy Lookup)',
		'claim_class:required': 'Claim class is required (Claim Details)',
		'jobs:required': 'At least one job card request is needed (Claim Details)',
		'appointmentDatePicker:flxDatepickerMin': 'Job appointment date has to be from today',
		'complex_unit_number:required': 'Complex unit number is required (in Policy Details)',
		'street_address:required': 'Property street address is required (in Policy Details)',
		'suburb:required': 'Property suburb is required (in Policy Details)',
		'postal_code:required': 'Postal code is required (in Policy Details)',
		'email:required': 'Email address is required (in Policy Details)',
		'email:email': 'You need to enter a valid email address (in Policy Details)',
		'province:required': 'Province is required (in Policy Details)',
		'cell_number:cellphone': 'Cell number is invalid (in Policy Details)',
		'cell_number:required': 'Cell number is required (in Policy Details)',
		'cell_number:onlyAllowedASCII': 'Cell number should not contain characters like \'+\' (in Policy Details)',
		'cell_number:numeric': 'Cell number should be numeric (in Policy Details)',
		'contact_number:contact_number': 'Contact number is invalid (in Policy Details)',
		'contact_number:required': 'Contact number is required (in Policy Details)',
		'contact_number:onlyAllowedASCII': 'Contact number should not contain characters like \'+\' (in Policy Details)',
		'contact_number:numeric': 'Contact number should be numeric (in Policy Details)',
		'preferred_com_method:required': 'Communication method is required (in Policy Details)',
		'addressconfirmation:required': 'Please confirm property address',
		'longitude:required': 'No address longitude has been picked up (in Maps)',
		'latitude:required': 'No address latitude has been picked up (in Maps)',
		'date_of_loss:required': 'No date of loss has been selected (on your right Action Panel)',
		'whatmatters:required': 'What Matters has not been completed (on your right Action Panel)',
		'first_name:required': 'Applicant first name is required (in Policy Details)',
		'first_name:minlength': 'First name needs a minimum of 2 characters (in Policy Details)',
		'first_name:onlyAllowedASCII': 'First name field contains invalid character(s) (in Policy Details)',
		'surname:required': 'Applicant surname is required (in Policy Details)',
		'id_number:required': 'Applicant ID / Passport number is required (in Policy Details)',
		'first_name:text': 'Applicant first name has additional characters (in Policy Details)',
		// 'historyfetchedflag:required': 'No history lookup has been performed',
		'appointmentDatePicker:required': 'Job appointment date is required (in Appointments)',
		'appointmentTimePicker:required': 'Job appointment time is required (in Appointments)',
		'on_site_contact:required': 'On site contact number required (in Appointments)',
		'on_site_contact:contact_number': 'On site contact number is invalid (in Appointments)',
		'on_site_contact:onlyAllowedASCII': 'On site contact number should not contain characters like \'+\' (in Appointments)',
		'on_site_contact:numeric': 'On site contact number should be numeric \'+\' (in Appointments)',
		'on_site_person:required': 'On site person name is required (in Appointments)',
		// 'id:required': 'Replacement device type is required (in Device Details)',
		'serial_number:required': 'Device number is required (in Device Details)',
		'dstv_account_number:required': 'DSTV Account number is required (in Policy Details)',
		'mavenclaimnumber:required': 'Claim Number is required (in Policy Details)',
		'no_view_environments:required': 'Number of viewing environments is required (in Current Setup)',
		'dwelling_type:required': 'Dwelling type is required (in Current Setup)',
		'no_of_tvs:required': 'Number of TVs is required (in Current Setup)',
		'type_of_tvs:required': 'Type of TVs is required (in Current Setup)'
	}
};
