import { filter, map, skipWhile, switchMap, take } from 'rxjs/operators';
import { FLXDetailsComponent } from '../../views/details-view/details-view.component';
import { environment } from 'apps/studio/src/environments/environment';
import { FLXListViewComponent } from '../../views/list-view/list-view.component';
import { getActiveViewData, getCurrentUser, getViewData, ViewSet_0_0_2 } from '@flexus/core';
import { from, Observable, zip } from 'rxjs';
import { CollapseActionPanel, FLXActionPanelComponent, setActionPanelItems } from 'apps/studio/src/app/app-shell-features/action-panel';
import { FLXHeaderActionsComponent } from 'apps/studio/src/app/app-shell-features/header-actions/header-actions.component';
import { MulWorkflowShellComponent } from '../../base-components/mul-workflow-shell/mul-workflow-shell.component';

import * as itemTwoContextMenuItems from './item-two-context-menu-items';
import * as itemOneContextMenuItems from './item-one-context-menu-items';
import { Store } from '@ngrx/store';

export const ClaimWorkflow: ViewSet_0_0_2 = {
	id: 'Workflow',
	itemType: 'view_set',
	baseComponent: MulWorkflowShellComponent,
	name: 'Workflow',
	onStateInit: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: instance => {
		instance.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: 'MultiChoice Workflow',
		// actionsComponent: WorkflowActionsComponent,
		controls: (instance: FLXHeaderActionsComponent, store: Store<any>) => {
			return () => {
				return zip(store.select(getCurrentUser).pipe(filter(user => !!user)), instance.controller.select(getActiveViewData).pipe(take(1))).pipe(
					map(([res, data]: any) => {
						const createRoles = [1, 6];
						const validationRoles = [22, 36, 24];
						instance.currentView = data?.key;
						const controls = [
							// {
							//   id: 1,
							//   icon: 'refresh',
							//   instruction: 'Refresh',
							//   command: instance.refresh.bind(instance)
							// },
							{
								id: 5,
								icon: data?.params.reverse ? 'sorting-up' : 'sorting-down',
								instruction: 'Reorder',
								command: instance.reOrder.bind(instance)
							},
							{
								id: 4,
								type: 'dropdown',
								children: [
									{
										text: 'Details View',
										command: instance.loadDetailsView.bind(instance)
									},
									{
										text: 'List View',
										command: instance.loadListView.bind(instance)
									}
								]
							}
						];
						if (res?.user?.roles.some(role => createRoles.includes(role))) {
							controls.push({
								id: 3,
								icon: 'addition',
								instruction: 'Create Claim',
								command: instance.newClaim.bind(instance)
							});
						}
						if(res?.user?.roles.some(role => validationRoles.includes(role))){
							controls.forEach((control, indx) => {
								if(control?.id == 4) {
									controls?.[indx]?.['children']?.push(
										{
											text: 'My Dashboard',
											command: instance.loadDashboardView.bind(instance, 'agent_dashboard'),
										}
									) 
								}
							});
						}
						return controls;
					})
				);
			};
		}
	},
	footer: {
		type: 'pagination'
	},
	actionPanel: (instance: FLXActionPanelComponent) => setActionPanelItems(instance, ['search', 'filter', 'bucket', 'recent-activity']),
	views: {
		default: (http, controller) => {
			return {
				id: 'default',
				dataSource: (): Observable<any> =>
					http.get(`${environment.api_url}v1/staff_action/get_summary/`).pipe(
						map((data: any) => data?.payload),
						map(data => data?.map(d => ({ ...d, orgKey: 'mul' })))
					),
				//   .pipe(map((data: any) => data.payload));
				// ------------------------------
				// return input$;
				// return fromWorker<any, any>(() => new Worker('../../workers/test.worker', { type: 'module' }), input$).pipe(take(1));
				storeBinding: controller.select(getViewData).pipe(
					map((data: any) => {
						if (data) {
							return data?.default;
						}
					})
				),
				template: FLXDetailsComponent
			};
		},
		defaultOffline: (http, controller, indexedDbService, offlineService) => {
			return {
				id: 'defaultOffline',
				dataSource: () => {
					return offlineService.getAllClaims().pipe(
						map((data: any) => data?.payload),
						take(1),
						map((items: any[]) => items.filter(item => !item?.tempKey)),
						switchMap((items: any[]) => {
							return from(indexedDbService.claimInDraft.toArray()).pipe(
								skipWhile(x => !x),
								take(1),
								map((localDrafts: any[]) => [...localDrafts, ...items])
							);
						})
					);
				},
				storeBinding: controller.select(getViewData).pipe(map((data: any) => data?.defaultOffline)),
				template: FLXDetailsComponent
			};
		},
		alt: (http, controller) => {
			return {
				id: 'alt',
				dataSource: (): Observable<any> => http.get(`${environment.api_url}v1/staff_action/get_summary/`).pipe(map((data: any) => data?.payload)),
				storeBinding: controller.select(getViewData).pipe(
					map((data: any) => {
						if (data) {
							return data?.alt;
						}
					})
				),
				template: FLXListViewComponent
			};
		}
		// reversed: (http, controller, indexedDbService, reverse, offlineService) =>
		//   claimWorkflowViews.default(http, controller, indexedDbService, true, offlineService)
	},
	useMockContextData: false,
	mockContextData: {},
	contextMenu: {
		itemOne: {
			...itemOneContextMenuItems
		},
		itemTwo: {
			...itemTwoContextMenuItems
		}
	}
};
