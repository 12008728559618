import { BigFormService, Flow_0_0_2, getAllInfo, getCurrentUser, getFullItemOne, getFullItemTwo, getSelectedItem, getSelectedItemTwo } from '@flexus/core';
import { map, pluck, skipWhile, take, filter, mergeMap, switchMap } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { EMPTY, forkJoin, of } from 'rxjs';
import { CustomValidators } from '@flexus/utilities';
import { HttpClient } from '@angular/common/http';

let jobId;

export const MUL_MOVE_TO_ANOTHER_AGENT: Flow_0_0_2 = {
	id: 'MoveToAnotherAgent',
	itemType: 'flow',
	includeForOnlyStates: [331, 335, 176, 185],
	name: 'Move To Another Agent',
	header: {
		title: (store, bf) => {
			return store.select(getFullItemOne).pipe(
				skipWhile(x => !x),
				map(itemOne => {
					if (itemOne && itemOne?.applicant && itemOne?.applicant?.information) {
						return `Move To Another Agent - ${itemOne?.loan_information?.mavenclaimnumber}  -  ${itemOne?.applicant?.surname}`;
					} else {
						return 'Move to Anothet Agent';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'EscalationCommentNode',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	nodes: {
		EscalationCommentNode: {
			checkValidityForFields: ['escalation_reason'],
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('escalation_reason', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Add a Comment',
							headingConfig: { weight: 'normal' }
						}
					},
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							formControls: {
								0: {
									label: `Please enter why you are escalating the job`,
									inputType: 'textarea',
									rows: 6,
									formControlName: 'escalation_reason'
								}
							},
							containerWidth: '500px'
						}
					}
				]
			},
			navs: [
				{
					text: 'Next',
					optIntoValidation: true,
					nextNode: 'AgentSelectionListNode',
					color: 'primary'
				}
			]
		},
		AgentSelectionListNode: {
			checkValidityForFields: ['agent_id'],
			initFormFields: (_bf: BigFormService) => {
				_bf.addControl('agent_id', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Select who to escalate to',
							headingConfig: { weight: 'normal' }
						}
					},
					{
						component: 'FLXSelectListComponent',
						inputs: {
							options$: 'response',
							// canFilter: false,
							maxWidth: '40%',
							selectOptionFunc: instance => instance?.bf?.bigForm?.get('agent_id')?.patchValue(instance.selectedOption.value)
						}
					}
				]
			},
			serverCalls: {
				response: {
					errorMessage: 'Not Found',
					directCall: (http: HttpClient) => {
						return http
							.get(`${environment.api_url}v1/claim_action/get_claim_handler/?roles=${40}`)
							.pipe(
								skipWhile(result => !result),
								take(1),
								map((data: any) => {
									return data.payload;
								})
							)
							.pipe(
								map((result: any) => {
									const agents = [];

									for (let x of result) {
										agents.push({ display: x.full_name, value: x.id });
									}

									return agents;
								})
							);
						// return of([
						// 	{ display: 'Option 1', value: 1 },
						// 	{ display: 'Option 2', value: 2 }
						// ]);
					}
				}
			},
			navs: [
				{
					text: 'Submit',
					nextNode: 'EscalateSubmissionSuccessNode',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Error',
							directCall: (http: HttpClient, s, sq, bf: BigFormService) => {
								// {     "job_id": 3766,     "agent_id": 3,     "reason": "The reason" }
								return s
									.select(getSelectedItem)
									.pipe(
										skipWhile(result => !result),
										take(1),
										map(result => result)
									)
									.pipe(
										switchMap((result: any) => {
											const dataStub = {
												job_id: result.id,
												agent_id: bf.getControl('agent_id').value,
												reason: bf.getControl('escalation_reason').value
											};

											return http.post(`${environment.api_url}v1/job_action/assign_validation_agent/`, dataStub);
										})
									);
							}
						}
					},
					color: 'primary'
				}
			]
		},
		EscalateSubmissionSuccessNode: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		escalation_reason: 'job_information.escalation_reason',
		agent_id: 'job_information.agent_id'
	}
};
