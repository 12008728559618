import {
	BigFormService,
	ChangeManifestState,
	Flow_0_0_2,
	GetSPOwnJobTeamLeaderStaff,
	GetSPOwnJobTeamPositions,
	MakeServerCall,
	ManifestController,
	ModalService,
	SetNextNode,
	SetPreviousNode,
	getCurrentUser,
	getFullItemTwo,
	getSelectedItem,
	getSelectedItemTwo,
	getSubmissionData
} from '@flexus/core';

import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { filter, skipWhile, first, map, take, pluck, switchMap, mergeMap, debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { EMPTY, empty, forkJoin, of } from 'rxjs';
import moment from 'moment';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'apps/studio/src/environments/environment';
import { JobLocation } from '@flexus/ui-elements';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import gql from 'graphql-tag';
import { CustomValidators, generateRange } from '@flexus/utilities';
import { RemoveError } from '@flexus/error-handler';
import { values, flatten } from 'ramda';
// import { transformJobsToDeviceDetails, transformJobsToJobCardRequest } from '../add-new-job-card';

export const MUL_355: Flow_0_0_2 = {
	id: '355',
	name: 'autopay_payment_deffered_mc_ops',
	itemType: 'flow',
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store) => {
			return store.select(getFullItemTwo)?.pipe(
				filter(x => !!x),
				skipWhile((itemTwo: any) => {
					return itemTwo === null || itemTwo === undefined;
				}),
				first(itemTwo => itemTwo !== null || itemTwo !== undefined),
				map((itemTwo: any) => {
					let headerStr = 'HD Installation';
					if (itemTwo) {
						headerStr += ` : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.surname}`;
					}
					return headerStr;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	instructions: {
		editRoles: {
			0: 'Capture Claim Details - Confirm latlong'
		},
		viewRoles: {
			0: 'Capture Claim Details - Confirm latlong'
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		},
		teamleaderLocations: {
			errorMessage: 'Team leader locations not returned!',
			directCall: (http: HttpClient) => {
				return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
					pluck('payload'),
					filter(x => !!x),
					map((teamleaders: any[]) => {
						return teamleaders;
					})
				);
			}
		},
        provinceList: {
            errorMessage: '',
            directCall: (http, store, sq) => {
              return sq
                .queryObject(
                  gql`
                    {
                      allInfo {
                        active_regions
                      }
                    }
                  `,
                  store
                )
                .pipe(
                  take(1),
                  map((res) => {
                    return (res as any)?.active_regions;
                  })
                );
            },
          },
		
	},
	startNode: 'Summary',
	nodes: {
		
		Summary: {
			initFormFields: () => {},
			showTabs: true,
            // name:'Summary',
			...jobInfoNode(355, 77)
		},
        CustomerDetails: {
			name: 'Customer Details',
			showTabs: true,
			initFormFields: (bf, item, instance, sq, store: Store) => {
				bf.bigForm.addControl('checked_customer_details', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('checked_address_confirmation', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('checked_installation_details', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('checked_appointments', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('checked_current_setup', new UntypedFormControl(null, [Validators.required]));
				bf.bigForm.addControl('checked_team_lead', new UntypedFormControl(null, [Validators.required]));

				bf.bigForm.patchValue({ checked_customer_details: true });
				// bf.addControl('jobs', new UntypedFormArray([]));

				store
					.select(getCurrentUser)
					.pipe(take(1))
					.subscribe((user) => {
                        console.log('THE USER', user)
						const { full_name, contact_number } = user?.user;
						const email_address = user?.email;
						// const service_provider_id = user?.user?.sp?.id.toString();
						bf.bigForm.addControl('sil_agent', new UntypedFormControl(false));
						bf.bigForm.addControl('handler', new UntypedFormControl(full_name));
						bf.bigForm.addControl('handlercontact', new UntypedFormControl(contact_number));
						bf.bigForm.addControl('handleremail', new UntypedFormControl(email_address));
						// bf.bigForm.addControl('service_provider_id', new UntypedFormControl(service_provider_id, [Validators.required]));
						bf.bigForm.addControl('claim_type_id', new UntypedFormControl(40, [Validators.required]));
						bf.bigForm.addControl('sub_section_id', new UntypedFormControl(6, [Validators.required]));
					});

				bf.bigForm.addControl('new_state', new UntypedFormControl(1));

				// For maps initialisation. Otherwise validators do not pick it up
				let suburb_code, latitude, longitude, province, area_code, area;

				if (bf.bigForm.get('latitude')) {
					latitude = bf.bigForm.get('latitude')?.value;
					bf.bigForm.get('latitude')?.setValidators([Validators.required]);
				} else {
					bf.addControl('latitude', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('longitude')) {
					longitude = bf.bigForm.get('longitude')?.value;
					bf.bigForm.get('longitude')?.setValidators([Validators.required]);
				} else {
					bf.addControl('longitude', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('suburb_code')) {
					suburb_code = bf.bigForm.get('suburb_code')?.value;
					// bf.bigForm.get('suburb_code')?.setValidators([Validators.required]);
				} else {
					bf.addControl('suburb_code', new UntypedFormControl(null));
				}

				if (bf.bigForm.get('province')) {
					province = bf.bigForm.get('province')?.value;
					bf.bigForm.get('province')?.setValidators([Validators.required]);
				} else {
					bf.addControl('province', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('area_code')) {
					area_code = bf.bigForm.get('area_code')?.value;
					bf.bigForm.get('area_code')?.setValidators([Validators.required]);
				} else {
					bf.addControl('area_code', new UntypedFormControl(null, [Validators.required]));
				}

				if (bf.bigForm.get('area')) {
					area = bf.bigForm.get('area')?.value;
					bf.bigForm.get('area')?.setValidators([Validators.required]);
				} else {
					bf.addControl('area', new UntypedFormControl(null, [Validators.required]));
				}

				if (!latitude || !longitude) {
					latitude = null;
					longitude = null;
				}

				// add flag to set this node checked
				bf.addControl(
					'client_details',
					new UntypedFormGroup({
						clarity_customer_number: new UntypedFormControl('', [Validators.minLength(9), CustomValidators.hardMaxLength(9)]),
						first_name: new UntypedFormControl(null, [
							Validators.required,
							Validators.minLength(2),
							CustomValidators.hardMaxLength(100),
							CustomValidators.onlyAllowedASCII([32, 39, 44, 45, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
						]),
						surname: new UntypedFormControl('', [
							Validators.required,
							Validators.minLength(2),
							CustomValidators.hardMaxLength(100),
							CustomValidators.onlyAllowedASCII([32, 39, 44, 45, 46, ...generateRange(65, 90, 1), ...generateRange(96, 122, 1)])
						])
					})
				);
				bf.addControl(
					'contact_details',
					new UntypedFormGroup({
						contact_number: new UntypedFormControl('', [
							Validators.required,
							CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
							CustomValidators.contact_number,
							Validators.minLength(10),
							CustomValidators.hardMaxLength(12)
						]),
						cell_number: new UntypedFormControl('', [
							Validators.required,
							CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
							CustomValidators.numeric,
							Validators.minLength(10),
							CustomValidators.hardMaxLength(12)
						]),
						email: new UntypedFormControl('', [Validators.required, CustomValidators.onlyAllowedASCII(generateRange(32, 126, 1))])
					})
				);
				bf.addControl(
					'insured_address',
					new UntypedFormGroup({
						complex: new UntypedFormControl('', CustomValidators.hardMaxLength(64)),
						street_address: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)]),
						suburb: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)]),
						city: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)]),
						postal_code: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(5)]),
						province: new UntypedFormControl('', [Validators.required, CustomValidators.hardMaxLength(64)])
					})
				);

				bf.addControl(
					'current_environment',
					new UntypedFormGroup({
						no_view_environments: new UntypedFormControl(null, [Validators.required]),
						dwelling_type: new UntypedFormControl(null, [Validators.required]),
						no_of_tvs: new UntypedFormControl(null, [Validators.required]),
						type_of_tvs: new UntypedFormControl(null, [Validators.required]),
						mounted_installation: new UntypedFormControl(null, [Validators.required])
					})
				);
				// store.dispatch(new GetSPOwnJobTeamLeaderStaff());
			},
			component: 'CustomerDetailsComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},

                {
                    text: `I can't install at this address`,
                    color: 'secondary',
                    serverCalls: {
                        cant_install: {
                            errorMessage: 'could not open modal',
                            directCall: (
                                http,
                                _store: Store,
                                sq,
                                bf,
                                cntrllr,
                                _modal: ModalService
                              ) => {
                                _modal.openModalDirectly((instance) => {
                                  instance.type = 'warning';
                                  instance.heading = 'Address correctly marked on map';
                                  instance.message =
                                    'Have you moved the pin to the correct address on the map?';
                                  instance.navButtons = [
                                    {
                                      text: 'yes',
                                      clickHandler: () => {
                                        _store.dispatch(
                                          new MakeServerCall({
                                            directCall: (
                                              _http: HttpClient,
                                              _store: Store,
                                              sq,
                                              _bf: BigFormService,
                                              control,
                                              _modal: ModalService
                                            ) => {
                                              return _store.select(getSelectedItem).pipe(
                                                skipWhile((x) => !x),
                                                take(1),
                                                mergeMap((fit) => {
                                                  const claim_id = fit.claim.id;
                                                  const latitude = _bf.getControl('updated_latitude').value;
                                                  const longitude = _bf.getControl('updated_longitude').value;
                                                  return forkJoin([
                                                    _http
                                                      .post(
                                                        `${environment.api_url}v1/mc_actions/update_claim_location?claim_id&latitude&longitude`,
                                                        {
                                                          claim_id: claim_id,
                                                          latitude: latitude,
                                                          longitude: longitude,
                                                        }
                                                      )
                                                      .pipe(
                                                        skipWhile((x) => !x),
                                                        take(1),
                                                        map((result) => result)
                                                      ),
                                                    _store.select(getFullItemTwo).pipe(
                                                      skipWhile((x) => !x),
                                                      take(1),
                                                      map((result) => result)
                                                    ),
                                                  ]).pipe(
                                                    switchMap(([server_response, itemTwo]: any) => {
                                                      const { job_information } = itemTwo;
                                                      const job_id = itemTwo.id;
                              
                                                      
                                                      if (server_response.success) {
                                                        // const scheduler_confirmed_latlong: number = 1;
                                                        // const parsed_jobinfo_cycle1 = JSON.parse(
                                                        //   JSON.stringify(job_information)
                                                        // );
                                                        // const jobInformation_cycle1 = addObjectProperty(
                                                        //   'scheduler_confirmed_latlong',
                                                        //   scheduler_confirmed_latlong,
                                                        //   parsed_jobinfo_cycle1
                                                        // );
                                                        const request = {
                                                          job_id: job_id,
                                                        //   job_information: jobInformation_cycle1,
                                                        };
                                                        return _http.post(
                                                          `${environment.api_url}v1/job_action/update_job/`,
                                                          request
                                                        );
                                                      } else {
                                                        _modal.openModalDirectly((instance) => {
                                                          instance.type = 'warning';
                                                          instance.heading =
                                                            'Job location was not successfully updated';
                                                          instance.message =
                                                            'You can try again or contact your system administrator if the problem persists';
                              
                                                          instance.navButtons = [
                                                            {
                                                              text: 'ok',
                                                              color: 'secondary',
                                                              linkType: 'closer',
                                                            },
                                                          ];
                                                        });
                                                      }
                                                    })
                                                  );
                                                })
                                              );
                                            },
                                            errorMessage: 'Could not update Job',
                                          })
                                        );
                                      },
                                      linkType: 'close',
                                      nextNode: 'isAppointmentRequired',
                                    },
                                    {
                                      text: 'no',
                                      linkType: 'close',
                                    },
                                  ];
                                });
                                return of({});
                              },
                        }
                    }
                },
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'AddressConfirmation'
				}
			]
		},
        AddressConfirmation: {
			name: 'Maps',
			showTabs: true,
			serverCalls: {
				provinceList: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											active_regions
										}
									}
								`,
								store
							)
							.pipe(
								take(1),
								map(res => {
									return (res as any)?.active_regions;
								})
							);
					}
				},
				policyAddress: {
					errorMessage: '',
					directCall: (http: any, store: any, sq: any) => {
						// clear any errors
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											submissionData {
												loan_information {
													propertycomplex
													propertycomplexunitnumber
													propertystreetaddress
													propertysuburb
													propertycity
													suburbcode
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile((returned: any) => !returned),
								take(1),
								map((result: any) => {
									const res = result;
									const addressArray = [];

									if (res?.propertycomplex) addressArray.push(`${res?.propertycomplexunitnumber} ${res?.propertycomplex}`);
									if (res?.propertystreetaddress) addressArray.push(res?.propertystreetaddress);
									if (res?.propertysuburb) addressArray.push(res?.propertysuburb);
									if (res?.propertycity) addressArray.push(res?.propertycity);
									if (res?.suburbcode) addressArray.push(res?.suburbcode);

									return addressArray.join(', ');
								})
							);
					}
				},
				policyAddressObject: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										selectedContext {
											submissionData {
												loan_information {
													propertycomplex
													propertycomplexunitnumber
													propertystreetaddress
													propertysuburb
													propertycity
													suburbcode
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1)
							);
					}
				}
			},
			initFormFields: (bf: any, item, instance, sq, _store: Store) => {
				bf.bigForm.patchValue({ checked_address_confirmation: true });
				_store.dispatch(new GetSPOwnJobTeamPositions());
			},
			inputs: {
				title: 'Address Confirmation',
				addressConfirmationText: 'Customer Address',
				addressType: 'Installation Address'
			},
			component: 'AddressConfirmationComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'InstallationJobCard'
				}
			]
		},
        SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			hideTabItem: true,
			checkValidityForFields: ['appointmentData'],
			initFormFields: (bf: BigFormService) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, Validators.required),
						appointmentTime: new UntypedFormControl(null, Validators.required),
						appointmentTimePicker: new UntypedFormControl(null, Validators.required),
						appointmentDateType: new UntypedFormControl(null, Validators.required),
						skill: new UntypedFormControl('Dish Installation')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				// bf.patchValues({ new_state: 21 });
			},
			serverCalls: {
				claimaintKeyValues: {
					errorMessage: 'Claimant detail could not be retrieved',
					directCall: (http:HttpClient, store) => {
						return store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									const claimantinfo = {
										'Claimant Details ': {
											'Full name ': `${job.claim?.applicant?.first_name} ` + `${job.claim?.applicant?.surname}`,
											'Contact Number': job?.claim?.loan_information.ContactNumber,
											'Mobile Number ': job?.claim?.loan_information.cellnumber,
											'On-site contact name ': job?.claim?.loan_information.onsiteperson
										}
									};
									return [claimantinfo];
								})
							);
					}
				},
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
												}
												loan_information {
													contactnumber
													cellnumber
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									return [
										{
											'Client Name': `${qdat.first_name} ${qdat.surname}`
										},
										{ 'Contact Number': `${qdat.contactnumber}` },
										{ 'Mobile Number': `${qdat.cellnumber}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http: HttpClient, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Set Appointment',
					optIntoValidation: true,
					linkType: 'portal',
					color: 'primary',
					nextNode: 'AssignTLNode',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http:HttpClient, store) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submit]) => {
										const job_id = job?.id;
										const current_state = job?.state;
										const appointment = submit?.appointment;
										const data = {
											job_id: job_id,
											current_state: current_state,
											appointment: appointment
											// new_state: 21
										};
										return data;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							},
							followUpSuccessCalls: {
								response: {
									errorMessage: `Couldn't assign team leader`,
									directCall: (_http: HttpClient, _store: Store, sq: any, _bf: BigFormService, _ctrl: ManifestController<any>) => {
										_ctrl.dispatch(new SetNextNode('AssignTLNode'));
										return of({});
									}
								}
							}
						}
					}
				}
			],
			component: 'FLXJobAppointmentComponent'
		},
        CurrentSetup: {
			name: 'Current Setup',
			showTabs: true,
			serverCalls: {
				installationDetails: {
					errorMessage: '',
					directCall: (http, store, sq) => {
						// Clearing Claim History Error Message, Had to add it to multiple nodes as there is no deactivate per node.
						store.dispatch(new RemoveError({ dataKey: 'claimHistory' }));
						return sq
							.queryObject(
								gql`
									{
										allInfo {
											config_options {
												general {
													installation_details_options {
														tv_count
														tv_type
														viewing_environs
														dwelling_type
														mounted_installation
													}
												}
											}
										}
									}
								`,
								store
							)
							.pipe(
								skipWhile(f => !f),
								take(1),
								map(values),
								map<any, any>(flatten)
							);
					}
				}
			},
			initFormFields: (bf) => {
				bf.bigForm.patchValue({ checked_current_setup: true });
			},
			inputs: {
				is_sp_job_card: true
			},
			component: 'CurrentSetupComponent',
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
				{
					text: 'continue',
					color: 'primary',
					nextNode: 'AssignTeamLead'
				}
			]
		},
        JobSubmissionSummary: {
			name: 'Submit',
			showTabs: true,
			checkValidityForFields: [
				'checked_customer_details',
				'checked_address_confirmation',
				'checked_installation_details',
				'checked_appointments',
				'checked_current_setup',
				'checked_team_lead',
				'insured_address',
				'current_environment',
				'client_details',
				'contact_details',

			],
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Submit Job Card To Proceed',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXAlertMessagesComponent',
						inputs: { alertMessages$: 'alertMessages' }
					}
				]
			},
			serverCalls: {
				alertMessages: {
					errorMessage: 'Could not retrieve the form errors',
					directCall: (_http: any, _store: any, _sq: any, _bf: BigFormService) => {
						if (!!_bf.bigForm.get('jobs')) {
							const jobs = _bf.bigForm?.value?.jobs;
						}
						_bf.bigForm?.updateValueAndValidity();
						return _bf.bigForm?.valueChanges?.pipe(
							debounceTime(100),
							switchMap(() => {
								return _bf.retrieveErrors$();
							})
						);
					}
				}
			},
			navs: [
				{
					text: 'What Matters',
					location: 'center',
					linkType: 'portal',
					portalData: {
						type: 'actionPanel',
						paramFunc: (instance, mapper) => {
							return {
								id: '1',
								icon: 'what-matters',
								command: instance.loadComponent.bind(instance, mapper['FLXWhatMattersComponent'])
							};
						}
					}
				},
			
				{
					text: 'Submit',
					linkType: 'portal',
					optIntoValidation: true,
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'This claim could not be created!',
							serviceVariable: 'mulSpService',
							functionName: 'createClaim',
							followUpSuccessCalls: {
								deleteCurrentEditableClaim: {
									errorMessage: 'Could not delete the current editable claim!',
									serviceVariable: 'mulSpService',
									functionName: 'deleteCurrentEditableItem'
								},
								clearCachedClaims: {
									errorMessage: 'Could not clear cached claims!',
									directCall: (http, store, sq, bf, controller) => {
										// if (!!bf.bigForm?.value?.is_cat === true) {
										// 	modal.openModalDirectly(inst => {
										// 		inst.heading = 'Appointment will be ignored';
										// 		inst.type = 'info';
										// 		inst.subheading = 'This claim is part of a catastrophe.';
										// 		inst.message = 'Notify customer that appointment times cannot be met based on the volume of claims.';
										// 		inst.navButtons = [
										// 			{
										// 				text: 'Continue',
										// 				color: 'primary',
										// 				linkType: 'close'
										// 			}
										// 		];
										// 		inst.closeButton = false;
										// 	});
										// }
										bf.bigForm.reset();
										Object.keys(bf.bigForm.controls)?.forEach(control => bf.bigForm.removeControl(control));
										controller.dispatch(
											new ChangeManifestState(state => {
												return {
													...state,
													viewData: {
														...state.viewData,
														default: []
													}
												};
											})
										);
										return empty();
									}
								}
							},
							followUpFailCalls: {
								createLocalDraft: {
									errorMessage: 'Could not create the claim in local draft',
									serviceVariable: 'mulSpService',
									functionName: 'saveAsLocalDraft',
									followUpSuccessCalls: {
										deleteCurrentEditableClaim: {
											errorMessage: 'Could not delete the current editable claim!',
											serviceVariable: 'mulService',
											functionName: 'deleteCurrentEditableItem'
										},
										navigateToWorkflow: {
											errorMessage: '',
											serviceVariable: 'mulService',
											functionName: 'navigateToWorkflow'
										}
									}
								}
							}
						}
					}
				}
			]
		},
		
        JobSubmissionSuccess: {
            hideTabItem: true,
            component: 'FLXSuccessTickComponent',
            inputs: {},
            navs: []
        },

		AssignTLNode: {
			hideTabItem: true,
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: bf => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl(null, Validators.required));
				bf.patchValues({ new_state: 345 });
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http:HttpClient, store) => {
						return store
							.select(getSelectedItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map((res: any) => res)
							)
							.pipe(
								switchMap(job => {
									const { id } = job;
									const job_id = id;
									// return of({});
									return http.post(`${environment.api_url}v1/sp_action/manage_list_staff/`, { role_id: 10, image: true, job_id: job_id }).pipe(
										pluck('payload'),
										filter(x => !!x),
										map((teamleaders: any[]) =>
											teamleaders.map(teamLeader => {
												const namestringarr = teamLeader.full_name?.split(' ');
												let mono1, mono2;
												if (!namestringarr[0]) {
													mono1 = ' ';
												} else {
													mono1 = namestringarr[0]?.slice(0, 1);
												}
												if (!namestringarr[1]) {
													mono2 = '';
												} else {
													mono2 = namestringarr[1]?.slice(0, 1);
												}
												const monogram = mono1 + mono2;
												return {
													display: teamLeader.full_name,
													value: teamLeader.id,
													image: teamLeader.image,
													monogram: monogram,
													teamLeader,
													shouldHaveImage: true
												};
											})
										)
									);
								})
							);
					}
				},
				jobLocationData: {
					errorMessage: 'The job location was not returned!',
					directCall: (http, store) => {
						return store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1),
							switchMap(j => {
								const job = j as any;
								return http
									.post(`${environment.api_url}v1/job_action/get_job/`, { job_id: job?.id })
									.pipe(
										skipWhile(x => !x),
										take(1),
										map(result => result as any)
									)
									.pipe(
										map(jobloc => {
											const locationString = jobloc['payload']?.claim?.location;
											const locArray = locationString?.split(',');
											const latitude = +locArray[0];
											const longitude = +locArray[1];
											return new JobLocation(latitude, longitude);
										})
									);
							})
						);
					}
				},
				teamleaderLocations: {
					errorMessage: 'Team leader locations not returned!',
					directCall: (http: HttpClient) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`)?.pipe(
							pluck('payload'),
							filter(x => !!x),
							map((teamleaders: any[]) => {
								return teamleaders;
							})
						);
					}
				}
			},
			component: 'AssignTLComponent',
			navs: [
				{
					text: 'submit',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't assign team leader!",
							directCall: (http, store, sq, bf, ctrl, modal) => {
								return store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									).pipe(
									switchMap((j) => {
										const job = j as any;
										const job_id = job?.id;
										const team = bf.bigForm.get('assign_teamleader_id')?.value;

										const data = {
											job_id: job_id,
											tl_id: team[0]
											// new_state: 339
										};
										return http.post(`${environment.api_url}v1/mc_actions/update_team_leader/`, data).pipe(
											skipWhile(x => !x),
											switchMap((response: any) => {
												const new_state = 345;
												const request = {
													job_id: job_id,
													new_state: new_state
												};
												if (response?.success) {
													return http.post(`${environment.api_url}v1/job_action/update_job/`, request);
												} else if (!response.success) {
													modal.openModalDirectly(instance => {
														instance.type = 'warning';
														instance.heading = 'Something went wrong!';
														instance.setMessage(['Your request could not be completed.', 'Please try again or return contact your system administrator.']);
														instance.navButtons = [
															{
																text: 'close',
																linkType: 'close'
															}
														];
													});
												}
											})
										);
									})
								);
							}
						}
					},

					location: 'right'
				}
			]
		},
		
		
		

	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		claim_type_id: 'claim_type_id',
		sub_section_id: 'sub_section_id',

		handler: 'loan_information.handler',
		handlercontact: 'loan_information.handlercontact',
		handleremail: 'loan_information.handleremail',

		// Customer Details
		'client_details.clarity_customer_number': ['applicant.claimantpoliceynum'],
		'client_details.first_name': ['applicant.first_name', 'name'],
		'client_details.surname': ['applicant.surname', 'surname'],
		'contact_details.contact_number': ['loan_information.contactnumber', 'loan_information.cellnumber'],
		'contact_details.cell_number': ['loan_information.cell_number'],
		'contact_details.email': ['loan_information.email'],

		// Address confirmation
		latitude: ['location.lat', 'loan_information.claimlatitude'],
		longitude: ['location.long', 'loan_information.claimlongitude'],
		suburb_code: ['postal_code', 'loan_information.suburbcode'],
		province: ['claimprovince'],
		'insured_address.province': ['loan_information.claimprovince'],
		area: ['area'],
		'insured_address.complex': ['loan_information.propertycomplex'],
		'insured_address.complex_unit_number': ['loan_information.propertycomplexunitnumber'],
		'insured_address.street_address': ['address', 'loan_information.propertystreetaddress'],
		'insured_address.suburb': ['suburb', 'loan_information.propertysuburb'],
		'insured_address.city': ['loan_information.propertycity'],
		'insured_address.postal_code': ['postal_code', 'loan_information.suburbcode', 'loan_information.postal_code'],
		// Current Setup

		'current_environment.no_view_environments': ['loan_information.current_environment.no_view_environments'],
		'current_environment.dwelling_type': ['loan_information.current_environment.dwelling_type'],
		'current_environment.no_of_tvs': ['loan_information.current_environment.no_of_tvs'],
		'current_environment.type_of_tvs': ['loan_information.current_environment.type_of_tvs'],
		'current_environment.mounted_installation': ['loan_information.current_environment.mounted_installation'],

		jobs: [
			// [transformJobsToJobCardRequest, 'jobcardrequest'],
			// [transformJobsToJobCardRequest, 'loan_information.jobcardrequest'],
			// [transformJobsToDeviceDetails, 'loan_information.device_details']
		],

		whatmatters: 'loan_information.whatmatters',

		service_provider_id: ['loan_information.sp_id'],
		assign_teamleader_id: [
			(_id) => {
				let teamlead;
				if (Array.isArray(_id)) {
					teamlead = _id[0];
				} else {
					teamlead = _id;
				}
				return teamlead;
			},
			'loan_information.tl_id'
		],

		// Claim Appointment
		on_site_contact: 'loan_information.onsitecontact',
		on_site_notes: 'loan_information.onsitenotes',
		on_site_person: 'loan_information.onsiteperson'
	},
    flowErrorMessages: {
		'checked_customer_details:required': 'Please fill in the Customer Details',
		'checked_address_confirmation:required': 'Please confirm the address on the map!',
		'checked_installation_details:required': 'Please fill in the installation details',
		'checked_appointments:required': 'Please set an Appointment',
		'checked_current_setup:required': 'Please specify the current setup',
		'checked_team_lead:required': 'Please pick a team leader',
		// 'clarity_customer_number:required': 'The Clarity customer number is required.',
		'first_name:required': 'Applicant first name is required.',
		'first_name:minLength': 'First name needs a minimum of 3 characters.',
		'first_name:onlyAllowedASCII': 'First name field contains invalid character(s).',
		'first_name:text': 'Applicant first name contains non-next characters.',
		'surname:required': 'Surname is required. In (Customer Details)',
		'surname:clarity_customer_number': 'Surname field contains invalid characters.',
		'contact_number:required': 'Contact number is required (in Customer Details).',
		'contact_number:contact_number': 'Contact number is invalid (in Customer Details).',
		'cell_number:required': 'Alternative contact number is required (in Customer Details).',
		'email:required': 'Email address is required (in Customer Details)',
		'province:required': 'Province is required (in Policy Details)',
		'email:email': 'You need to enter a valid email address (in Customer Details)',
		'street_address:required': 'Property street address is required (in Customer Details)',
		'suburb:required': 'Property suburb is required (in Customer Details)',
		'postal_code:required': 'Postal code is required (in Customer Details)',
		'addressconfirmation:required': 'Please confirm property address',
		'longitude:required': 'No address longitude has been picked up (in Maps)',
		'latitude:required': 'No address latitude has been picked up (in Maps)',
		'jobs:allFieldsRequired': 'All fields are required',
		'skill_id:required': 'Skill is required (in Installation)',
		// 'to_purchase:required': 'To purchase? is required (In Installation)',
		'decoder_type:required': 'Decoder type is required (In Installation)',
		'on_site_contact:required': 'On site contact number required (in Appointments)',
		'on_site_contact:contact_number': 'On site contact number is invalid (in Appointments)',
		'on_site_person:required': 'On site person name is required (in Appointments)',
		'no_view_environments:required': 'Number of viewing environments is required (in Current Setup)',
		'dwelling_type:required': 'Dwelling type is required (in Current Setup)',
		'no_of_tvs:required': 'Number of TVs is required (in Current Setup)',
		'mounted_installation:required': 'Specify if mounted installation allowed (in Current Setup)',
		'type_of_tvs:required': 'Type of TVs is required (in Current Setup)',
		'appointmentDatePicker:required': 'Job appointment date is required (in Appointments)',
		'appointmentTimePicker:required': 'Job appointment time is required (in Appointments)',
		'whatmatters:required': 'What matters is required (in What Matters)'
	}
};
