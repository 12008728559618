<div class="job-notification-container">
	<div class="job-notification-details">
		<div class="job-notification-id">{{ notificationDetails.id }}</div>
		<div class="job-notification-skill">{{ _vouchertype_skill }}</div>
		<div class="job-notification-suburb">{{ notificationDetails.job.suburb }}</div>
    <div class="job-notification-appointment">
      {{ notificationDetails?.job?.appointment?.range_start | date: 'd MMM yyyy' }} -
      {{ notificationDetails?.job?.appointment?.appointment_name }}
      {{ notificationDetails?.job?.appointment?.range_start | date: 'h:mm a' }}
      <ng-container *ngIf="notificationDetails?.job?.appointment?.range_end">
        to {{ notificationDetails?.job?.appointment?.range_end | date: 'h:mm a' }}
      </ng-container>
    </div>

		<div class="job-notification-remove">
			<flx-icon type="remove" color="default" active="danger" use="default" hover="danger" size="small" (click)="removeNotification()"></flx-icon>
		</div>
	</div>
</div>
