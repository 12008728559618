import { Flow_0_0_2, getFullItemTwo, getAllInfo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../app-shell-features';

import { skipWhile, map, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { AUTO_SELECT_TEMPLATE_DECISION, BILLING_INVOICE, billingServerCalls, SELECT_CALLOUT_TEMPLATE } from './BILLING';
import { environment } from 'apps/studio/src/environments/environment';

export const SP_GLOBAL_207: Flow_0_0_2 = {
	id: '207',
	name: 'Electronic assessment',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store, bf) => {
			return store.select(getFullItemTwo)?.pipe(
				map(itemTwo => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Electronic Assessor - Call-out fee: ${itemTwo?.claim?.loan_information?.mavenclaimnumber} - ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Electronic Assessor - Call-out fee';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		keyValueList: {
			errorMessage: '',
			directCall: (http, store, sq) => {
				return forkJoin([
					store.select(getFullItemTwo)?.pipe(
						skipWhile(x => !x),
						take(1)
					),
					store.select(getAllInfo)?.pipe(
						skipWhile(x => !x),
						take(1)
					)
				])?.pipe(
					take(1),
					map(([job, allInfo]) => {
						const appointment = job?.appointment[job.appointment.length - 1];

						const list = {
							'Claim Type': job?.claim?.type ?? '',
							Client: job?.claim?.applicant?.first_name + ' ' + job?.claim?.applicant?.surname ?? '',
							'Contact number': job?.claim?.loan_information?.onsitecontact ?? '',
							'Cell Number': job?.claim?.loan_information?.cellnumber ?? '',
							Address: job?.claim?.address ?? ''
						};

						return [list];
					})
				);
			}
		},
		...billingServerCalls
	},
	instructions: {
		editRoles: {
			0: 'No work - Changes to CIL - SP Notice'
		},
		viewRoles: {
			0: 'No work - Changes to CIL - SP Notice'
		}
	},
	startNode: 'RecommendCIL',
	nodes: {
		RecommendCIL: {
			component: {
				children: [
					{
						component: 'FLXKeyValueDisplayWithInstructionsComponent',
						inputs: {
							title: 'Client has requested to be paid out in cash',
							instructions: ['No work was done. Invoice a call out fee.'],
							keyValueList$: 'keyValueList',
							title$: 'title'
						}
					}
				]
			},
			navs: [
				{
					text: 'Continue',
					nextNode: 'AUTO_SELECT_TEMPLATE_DECISION',
					color: 'primary'
				}
			]
		},
		AUTO_SELECT_TEMPLATE_DECISION,
		SELECT_CALLOUT_TEMPLATE,
		BILLING_INVOICE: {
			hideTabItem: true,
			component: 'BillingComponent',
			checkValidityForFields: ['invoiceDate', 'invoiceNumber', 'actualLineItemsFormArray'],
			inputs: {
				docType: 'invoice',
				// docTitle: '',
				numberLabel: 'Invoice',
				currentState: 207,
				newState: 27,
				showLineItemGenerator: true,
				canEditLineItems: true,
				boqLogo: environment.client === 'bet_sp' ? 'assets/images/boq-bettersure-logo.svg' : 'assets/images/boq-sil-logo.svg',
				boqLogoAlt: environment.client === 'bet_sp' ? 'Bettersure' : 'Standard Bank Insurance Limited',
				clientName: environment.client === 'bet_sp' ? 'Bettersure' : 'SIL'
			},
			navs: [
				{
					text: 'Save Draft',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						draftQuote: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating draft invoice!', serviceVariable: 'spService', functionName: 'generateDraftInvoice' }
							}
						}
					}
				},
				{
					text: 'Generate Invoice',
					nextNode: 'SubmissionSuccess',
					location: 'right',
					linkType: 'submit',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						postInvoice: {
							errorMessage: 'An error occurred while trying to create or update BOQ!',
							serviceVariable: 'spService',
							functionName: 'createOrUpdateBOQ',
							followUpSuccessCalls: {
								response: { errorMessage: 'An error occurred when generating quote/invoice!', serviceVariable: 'spService', functionName: 'generateBoqQuoteOrInvoice' }
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		manufacturercontact: 'itemTwo.job_information.gyeser_warranty.manufacturercontact',
		manufacturerwarrantyref: 'itemTwo.job_information.gyeser_warranty.manufacturerwarrantyref',
		new_state: [
			(state, storeObj, formValue, bf) => {
				return 70;
			},
			'new_state'
		],
		total: [
			(a, b, bf) => {
				return bf.total + bf.vatRate;
			},
			'job_information.quote_amount'
		],
		invoiceNumber: 'job_information.quote_number'
	}
};
