import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BigFormService, MakeServerCall, ManifestController, ModalService, SetNextNode } from '@flexus/core';
import { DynamicFormConfig, DynamicFormInputs } from '@flexus/ui-composites';
import { cleanUpSub, CustomValidators, generateRange, isInteger } from '@flexus/utilities';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, pluck, skipWhile, take } from 'rxjs/operators';

export interface ClaimDetailsTriggerActions {
	triggerOn: number[]; // what loss classes to trigger on
	action: 'warning' | 'form' | 'display-list' | 'upfront-reject' | 'display-text' | 'fire';
	message?: string; // warning
	inputs?: DynamicFormInputs; // form
	layout?: 'three-column' | 'stacked' | 'flex'; // form
	list?: { heading: string; subheading?: string; points?: string[] }; // display-list
	displayText?: { text: string; color: 'default' | 'primary' | 'secondary' }; // display-text
}

@Component({
	selector: 'flx-claim-details',
	templateUrl: './claim-details.component.html',
	styleUrls: ['./claim-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
	// ===========================================  Variables ===========================================================
	displayConfig = { displayKey: 'display', valueKey: 'value' };

	claimClassForm = new UntypedFormGroup({});

	// Claim Group information - Filtered select
	claimTypes$: Observable<any>;
	claimDetailsSub: Subscription;

	//  claimClassesData$: Observable<FilteredSelectListData>;
	claimClassesData$: Observable<any[]>;
	claimClassSub: Subscription;
	selectedClaimClass = 0;
	selectedJob = 0;

	// Look up all skill information
	skillLookUp$: Observable<any>;
	skillLookUp: Array<any> = [];
	skillLookUpSub: Subscription;

	// Skill group information - drop down 1
	skillGroups$: Observable<any>;
	selectedClaimGroup$: Observable<any>;
	selectGroupData$: any;

	// Specific skill information - drop down 2
	allSkills$: Observable<any>;
	allSkillSub: Subscription;
	contextSkillsArr: any[];

	// Form data
	jobList: any[] = null;

	additionalFormData: DynamicFormConfig = {
		data: {},
		formLayout: 'flex'
	};

	// Upfront rejection reasons
	upfrontRejectReasons$: Observable<any>;
	toggleReason = false;
	extraSub: Subscription;

	// To deal with the cascading selects auto open
	dataPatched = false;
	warningModal = false;

	isIaOnJob: { [index: number]: boolean } = {};
	iaRequested = false;
	IAreasons$: Observable<any>;

	enabledSkillContextAction: ClaimDetailsTriggerActions[] = [];
	// ===========================================================  INPUT / OUTPUT events ===========================================================

	@Input() subsidenceExclusionsList: any[];
	@Input() fireContainedOptions; // Extent of fire options
	@Input() claimClassTypes = { subsidence: [], impact: [], fire: [], police: [], miscellaneous: [], warningOnly: [] }; // Contextual claim class action group types
	@Input() providerType: { display: string; value: string }[];
	@Input() cashInLieuComp: any;
	@Input() offPanelComp: any;
	@Input() claimDetailsInfo$: Observable<any>;
	@Input() excludeJobs = false;
	@Input() excludeClaimClass = false;
	@Input() resetFormValues = true;
	@Input() isMC = false;

	// New Afterhours vars for refactor
	// TODO: refector the skill alerts too. @Input() skillActions: { [group: string]: ClaimDetailsTriggerActions } = {};
	@Input() jobLimit: { lossClass: number; limit: number; warning?: boolean; warningMessage?: string }[] = [];
	@Input() claimActions: { [group: string]: ClaimDetailsTriggerActions } = {};

	enabledClaimContextAction: ClaimDetailsTriggerActions[] = [];

	// ============================================ Constructor =========================================================
	constructor(
		public _store: Store<any>,
		private controller: ManifestController<any>,
    @Inject('environment') private environment: any,
		private cd: ChangeDetectorRef,
		public bf: BigFormService,
		private fb: UntypedFormBuilder,
		private modalsService: ModalService
	) {}

	// ============================================= Methods ============================================================
	// ----------------------------------------- Life-cycle methods -----------------------------------------
	ngOnInit() {
		cleanUpSub(this.extraSub);
		this.jobList = this.bf.bigForm?.value?.jobs;
		this.splitData();

		if (this.bf.bigForm.get('claim_class')?.value !== null) {
			this.dataPatched = true;
			this.getSkillGroup(this.bf.bigForm.get('claim_class')?.value);
			this.getClaimTypeDesc(this.bf.bigForm.get('claim_class')?.value);
		}
	}

	ngAfterViewInit(): void {
		this.claimDetailsSub = this.bf.bigForm?.valueChanges?.subscribe(val => {
			this.jobList = this.bf.bigForm?.value?.jobs;
		});

		// Get claim class and reset jobs every time class is changed
		this.claimClassSub = this.bf.bigForm.get('selectedClaimClass')?.valueChanges?.subscribe(changes => {
			if (this.bf.bigForm.get('selectedClaimClass')?.value) {
				if (this.selectedClaimClass !== parseInt(this.bf.bigForm.get('selectedClaimClass')?.value[0], 10)) {
					this.selectedClaimClass = parseInt(this.bf.bigForm.get('selectedClaimClass')?.value[0], 10);
					if (this.resetFormValues) this.resetJobList();
					if (isInteger(this.selectedClaimClass)) {
						this.getSkillGroup(this.selectedClaimClass);
						this.checkClaimContextAction(this.selectedClaimClass, this.claimActions);

						// remove jobs with skill not associated with claim class
						if (this.jobList && this.selectGroupData$) {
							this.selectGroupData$.pipe(take(1)).subscribe((sGd: any[]) => {
								this.jobList.forEach(job => {
									const hasSkill = sGd.findIndex(skill => skill?.value === job?.skillcatagory_id) !== -1;
									if (job?.skillcatagory_id && !hasSkill) {
										const i = sGd.indexOf(job);
										this.removeJob(i);
									}
								});
								if (this.jobList?.length === 0) {
									this.bf.bigForm.setControl('jobs', this.fb.array([this.createJob()]));
								}
							});
						}
					} else {
						this.selectedClaimClass = 0;
						this.enabledClaimContextAction = [];
					}
				}
				this.bf.bigForm.get('claim_class')?.setValue(this.selectedClaimClass);
				this.getClaimTypeDesc(this.selectedClaimClass);
			}
		});

		if (this.bf.bigForm.get('claim_class')?.value !== null) {
			this.selectedClaimClass = this.bf.bigForm.get('claim_class')?.value;
			this.checkClaimContextAction(this.selectedClaimClass, this.claimActions);
			this.checkIfHomeAssitChange();
		}
		this.cd.detectChanges();
	}

	ngOnDestroy() {
		cleanUpSub(this.claimDetailsSub);
		cleanUpSub(this.claimClassSub);
		cleanUpSub(this.skillLookUpSub);
		cleanUpSub(this.allSkillSub);
		cleanUpSub(this.extraSub);
	}

	checkIfHomeAssitChange() {
		if (parseInt(this.bf.bigForm.get('claim_class')?.value, 10) === 28) {
			this.modalsService.openModalDirectly(instance => {
				instance.type = 'warning';
				instance.message = `If you are changing a from Home Assist loss class, excess information requires to be confirmed again.`;
				instance.closeButton = 'true';
			});
		}
	}

	getClaimTypeDesc(claimClass: number) {
		this.claimTypes$
			.pipe(
				take(1),
				map(claimTypes => claimTypes.find(type => type.id === claimClass))
			)
			.subscribe(val => {
				if (val !== undefined && this.bf.bigForm.get('claim_class_description')) {
					this.bf.bigForm.get('claim_class_description')?.setValue(val.name);
				}
			});
	}

	splitData() {
		this.claimTypes$ = this.claimDetailsInfo$.pipe(
			skipWhile(cd => !cd),
			map(data => data?.claim_types)
		);
		this.skillLookUp$ = this.claimDetailsInfo$.pipe(
			skipWhile(cd => !cd),
			map(data => data?.skills)
		);

		this.skillLookUpSub = this.skillLookUp$.subscribe(skills => (this.skillLookUp = skills));

		// build data for select list
		this.claimClassesData$ = this.claimTypes$.pipe(
			filter(ct => !!ct),
			map(arr => (arr = arr.slice().sort(this.sortByPriority))),
			map((obj: { [key: string]: { id: number; name: string } }) => {
				if (this.isMC) {
					return (
						obj &&
						Object.values(obj)
							.filter(x => x.id !== 26 && x.id !== 8 && x.id !== 37 && x.id !== 36 && x.id !== 40)
							.map(entry => ({ display: entry.name, value: entry.id }))
					);
				} else {
					return obj && Object.values(obj).map(entry => ({ display: entry.name, value: entry.id }));
				}
			})
		);

		// All Claim IDs associated with their skill groups only
		this.skillGroups$ = this.claimTypes$.pipe(
			map((obj: { [key: string]: { id: number; skill_groups: Array<any> } }) => {
				return obj && Object.values(obj).map(entry => ({ id: entry.id, skill_groups: entry.skill_groups }));
			})
		);

		// Upfront rejection reasons
		this.upfrontRejectReasons$ = this.claimDetailsInfo$.pipe(
			pluck('upfront_rejection_reasons'),
			map(entry => entry.map(reason => ({ display: reason, value: reason })))
		);

		// Assign IA reasons
		this.IAreasons$ = this.claimDetailsInfo$.pipe(
			pluck('ia_request_reasons'),
			map(entry => entry.map(reason => ({ display: reason, value: reason })))
		);
	}

	sortByPriority(a, b) {
		if (a.priority > b.priority) return -1;
		if (a.priority < b.priority) return 1;
	}

	// ===========================================================  Fetch drop down data methods ===========================================================
	getSkillGroup(claimClass: number) {
		const mc_insurance_skilltypes = [20, 23, 24, 25, 26, 27];
		if (claimClass && claimClass !== 0) {
			// find chosen group based on claim class
			this.selectedClaimGroup$ = this.skillGroups$.pipe(
				map(skillGroups => skillGroups && skillGroups.find(skillGroup => Number(skillGroup.id) === Number(claimClass))),
				map(skillGroup => skillGroup && skillGroup.skill_groups)
			);

			// build data for the drop down
			this.selectGroupData$ = this.selectedClaimGroup$.pipe(
				map((obj: { [key: string]: { id: number; name: string } }) => {
					if (this.isMC) {
						return (
							obj &&
							Object.values(obj)
								// .filter(x => x.id === 23)
								.filter(x => mc_insurance_skilltypes.includes(x.id))
								.map((skill: { id: number; name: string }) => {
									return skill;
								})
								.map(entry => ({ display: entry.name, value: entry.id }))
						);
					} else {
						return obj && Object.values(obj).map(entry => ({ display: entry.name, value: entry.id }));
					}
				})
			);

			// Initialize the possible skills based on the group with id and an associated array
			this.allSkills$ = this.selectedClaimGroup$.pipe(
				map((obj: { [key: string]: { id: number; skills: Array<any> } }) => {
					return obj && Object.values(obj).map(entry => ({ id: entry.id, skills: entry.skills }));
				})
			);

			this.allSkillSub = this.allSkills$.subscribe(skills => {
				if (skills) {
					this.contextSkillsArr = skills;
				}
			});
		}
	}

	getSkills(sGroup: string) {
		const group = parseInt(sGroup, 10);
		if (sGroup !== null || group !== 0) {
			const pos = this.findPos(group, this.contextSkillsArr);
			if (pos !== -1) {
				const skill = this.contextSkillsArr[pos];
				const skillArr = this.lookUpSkills(skill.skills);
				return skillArr;
			}
		}
		return this.defaultSkills();
	}

	// Look up each skill id and return a name based on the skill groups
	lookUpSkills(indexes: number[]) {
		const res = [];
		this.skillLookUp?.forEach(skill => {
			if (indexes.includes(skill.id)) {
				res?.push({ display: skill.name, value: skill.id });
			}
		});
		return res;
	}

	// This is to return a list of all the skills for the select to use as a default, to help patch the data
	defaultSkills(): Array<any> {
		const arr = this.skillLookUp ?? [];
		return arr.map(skill => ({ display: skill?.name, value: skill?.id }));
	}

	getProviderType(skill: number) {
		let res = [];
		switch (skill) {
			case 41: // temp accommodation
			case 46: // Emergency Repairs
			case 22: // TV Aerial
				res = [{ value: 2, display: 'Cash in Lieu' }];
				break;
			case 44:
				res = [{ value: 1, display: 'Request SP' }];
				break;
			default:
				res = [
					{ value: 1, display: 'Request SP' },
					{ value: 2, display: 'Cash in Lieu' }
				];
				// Remove off panel for now
				// res = [{ value: 1, display: 'Request SP' }, { value: 2, display: 'Cash in Lieu' }, { value: 3, display: 'Off Panel' }];
				break;
		}
		return res;
	}

	// ===========================================================  Contextual Methods ===========================================================
	checkClaimContextAction(value: number, actions: any) {
		for (const key in actions) {
			if (actions.hasOwnProperty(key)) {
				const group = actions[key];
				if (group.triggerOn.includes(value)) {
					switch (group.action) {
						case 'warning':
							// console.log('Trigger a warning');
							this.showWarningMessage(group.message);
							break;
						case 'form':
							// console.log('Trigger a form');
							this.enabledClaimContextAction.push(group);
							break;
						case 'display-list':
							// console.log('Trigger a displayList');
							this.enabledClaimContextAction.push(group);
							break;
						case 'fire':
							// console.log('Trigger a Fire');
							// Needs to be built up from the component as it references a variable that is collected from the store.
							const newGroup = {
								...group,
								action: 'form',
								layout: 'stacked',
								inputs: {
									0: {
										label: 'Extent of Fire',
										inputType: 'select',
										defaultValue: '',
										selectConfig: {
											displayOptions: { displayKey: 'display', valueKey: 'value' },
											itemsOption: this.fireContainedOptions,
											placeHolder: 'Fire Extent'
										},
										formControlName: 'firedamageextent',
										validators: [Validators.required]
									},
									1: {
										label: 'Recommended Adjuster',
										inputType: 'input',
										defaultValue: '',
										formControlName: 'recomendedadjuster',
										validators: [Validators.required],
										disabled: true
									}
								}
							};
							this.extraSub = this.bf.bigForm.get('firedamageextent')?.valueChanges?.subscribe(val => {
								this.getRecommendedAdjuster(val);
							});
							this.enabledClaimContextAction.push(newGroup);
							break;
						case 'storm':
							this.getRecommendedAdjuster(0);
							this.enabledClaimContextAction.push({
								...group,
								action: 'form',
								layout: 'stacked',
								inputs: {
									0: {
										label: 'Recommended Adjuster',
										inputType: 'input',
										defaultValue: '',
										formControlName: 'recomendedadjuster',
										validators: [Validators.required],
										disabled: true
									}
								}
							});
							break;
						case 'upfront-reject':
							// console.log('Trigger a upfront-reject');
							this.enabledClaimContextAction.push(group);
							break;
						case 'display-text':
							// console.log('Trigger a upfront-reject');
							this.enabledClaimContextAction.push(group);
							break;
					}
				}
			}
		}
	}

	goSkillGroupAction(groupID: number, index: number) {
		if (groupID !== null) {
			this.selectGroupData$.pipe(take(1)).subscribe(groups => {
				groups.forEach(group => {
					if (group.value === groupID) {
						this.bf.bigForm.get(`jobs.${index}.skillcatagory`)?.patchValue(group.display);
						this.bf.bigForm.get(`jobs.${index}.skill_id`)?.patchValue(null);
						this.bf.bigForm.get(`jobs.${index}.skill`)?.patchValue(null);
						return;
					}
				});
			});
		}
	}

	goSkillAction(skillID: number, index: number) {
		// Give the  warnings
		if (skillID !== null) {
			this.bf.bigForm.get(`jobs.${index}.skill`)?.patchValue(this.nameLookup(skillID, 'skills', 'name', this.claimDetailsInfo$));
			this.bf.bigForm.get(`jobs.${index}.skillrequested`)?.patchValue(skillID);
			switch (skillID) {
				case 44:
					this.showWarningMessage([
						'You have selected an Internal Assessor',
						'',
						'It is strongly advised that you do not request any other skills when selecting an Assessor. The Assessor will take care of appointing service providers.'
					]);
					this.isIaOnJob[index] = true;
					break;
				default:
					this.isIaOnJob[index] = false;
					break;
			}
		}
	}

	isIaRelated(): boolean {
		let flag = false;
		for (const job in this.isIaOnJob) {
			if (!!job && this.isIaOnJob[job]) {
				flag = true;
			}
		}
		return flag;
	}

	changeExcessManagementWhoCollects(index: number, whoCollects: number, whoCollectDescription: string) {
		this.bf.bigForm.get(`jobs.${index}.who_collects`)?.patchValue(whoCollects);
		this.bf.bigForm.get(`jobs.${index}.who_collects_description`)?.patchValue(whoCollectDescription);
	}

	goProviderTypeAction(event: any, index: number) {
		this.validateJobInputs();
		this.iaRequested = this.isIaRelated();
		if (event !== null) {
			this.bf.bigForm.get(`jobs.${index}.providertype`)?.patchValue(this.providerType[event - 1]?.display);
			this.bf.bigForm.get(`jobs.${index}.providertype_id`)?.patchValue(this.providerType[event - 1]?.value);
			switch (event) {
				case 1:
					// Uncomment for excess management pre-population
					if (this.environment.client === 'sil') {
						this.changeExcessManagementWhoCollects(index, 2, 'Service provider');
					}
					break;
				case 2:
					if (this.environment.client === 'sil') {
						this.changeExcessManagementWhoCollects(index, 1, 'Insurer');
					}

					this._store.dispatch(
						new MakeServerCall({
							dataKey: 'jobID',
							errorMessage: 'Could not save the job index',
							directCall: (http, store, sq) => {
								return of(this.jobList[index]?.id);
							}
						})
					);
					this.controller.dispatch(new SetNextNode(this.cashInLieuComp));
					break;
				case 3:
					this.controller.dispatch(new SetNextNode(this.offPanelComp));
					break;
			}
		}
	}

	isExtraInfo(numArr: number[], claimClass: number) {
		if (numArr !== undefined && claimClass !== 0) {
			const res = this.findPos(claimClass, numArr);
			if (res === -1) {
				return false;
			} else {
				this.bf.bigForm.get('extra_info_type')?.setValue(claimClass);
				return true;
			}
		}
	}

	nameLookup(targetId: number, group: string, field: string, source$: Observable<any>) {
		// Used to look up the name that corresponds to an id in an observable
		let res: any[] = [];
		const tempSub: Subscription = source$
			.pipe(
				pluck(group),
				map((obj: { [key: string]: { id: number; name: string } }) => {
					return Object.values(obj).map(entry => ({ id: entry.id, name: entry[field] }));
				})
			)
			.subscribe(testData => {
				res = testData;
				const resPos = this.findPos(targetId, res);
				res = res[resPos];
			});

		if (tempSub) {
			tempSub.unsubscribe();
		}

		return res !== undefined ? res['name'] : '';
	}

	validateJobInputs() {
		if (this.jobList.length > 1) {
			// Check for duplicates
			for (let index = 0; index < this.jobList?.length; index++) {
				const element = this.jobList[index];
				for (let index2 = 0; index2 < this.jobList?.length; index2++) {
					if (index !== index2) {
						const elementCompare = this.jobList[index2];
						if (this.compareJobs(element, elementCompare)) {
							this.showWarningMessage('You have just added the same Job Card.');
							break;
						}
					}
				}
			}
		}
	}

	// Compare two jobs against each other to see if they ae the same
	compareJobs(first: any, second: any): boolean {
		let flag = true;
		if (first.skillcatagory_id !== second.skillcatagory_id) {
			flag = false;
		}
		if (first.skill_id !== second.skill_id) {
			flag = false;
		}
		return flag;
	}

	getRecommendedAdjuster(fireArea: number) {
		let res = '';
		switch (fireArea) {
			case 0:
				res = 'Internal Assessor';
				break;
			case 1:
			case 2:
				res = 'Loss Adjuster';
				break;
		}
		this.bf.bigForm.get('recomendedadjuster')?.patchValue(res);
		this.showWarningMessage('It is strongly advised that you ONLY select a ' + res?.toUpperCase() + ' for this claim');
	}

	createExtraDataFields(dataFields: DynamicFormInputs, layout: string) {
		this.additionalFormData = {
			data: dataFields,
			parentForm: this.bf.bigForm,
			formLayout: layout || 'flex'
		};
	}

	rejectClaim() {
		this.toggleReason = !this.toggleReason;
	}

	triggerUpfrontRejection() {
		this.showWarningMessage('This claim can now be submitted as repudiated upfront.');
		this.bf.bigForm.patchValue({
			dontpingsp: 1,
			upfrontrepudiation: 1
		});
	}

	showWarningMessage(message: string | string[]) {
		// Display a warning prompt
		setTimeout(() => {
			this.modalsService.openModalDirectly(insta => {
				insta.setMessage(Array.isArray(message) ? message : [message]);
				insta.closeButton = 'true';
				insta.type = 'warning';
				insta.navButtons = [];
			});
		}, 0);
	}

	//find the position of the id in the look up array
	findPos(id: any, arr: any[], field = 'id'): number {
		let res = -1;
		if (arr !== undefined) {
			let count = 0;
			arr.forEach(item => {
				if (item[field] === id || item === id) {
					res = count;
				}
				count++;
			});
		}
		return res;
	}

	// =========================================================== Add / Remove Job Methods ===========================================================

	get jobListDynamic() {
		if (this.bf.bigForm.get('jobs') === null) {
			this.bf.bigForm.setControl('jobs', this.fb.array([this.createJob()]));
			this.resetJobList();
		}
		return this.bf.bigForm.get('jobs') as UntypedFormArray;
	}

	canAddJob(index: number): boolean {
		let result = false;
		if (this.jobList?.length - 1 === index) {
			const canAdd = this.jobLimit.find(x => x.lossClass === this.selectedClaimClass);
			if (canAdd && this.jobList?.length >= canAdd.limit) {
				result = false;
				// Diplay a warning but dont block user
				if (canAdd.warning) {
					this.showWarningMessage(canAdd.warningMessage);
				}
			} else {
				result = true;
			}
		} else {
			result = false;
		}
		return result;
	}

	createJob(job?): UntypedFormGroup {
		if (job) {
			let jobExcess;
			if (Array.isArray(job.excess)) {
				jobExcess = job?.excess?.find(j => j?.excess_description === 'Additional Excess');
			} else {
				jobExcess = job?.excess;
			}
			return this.fb.group({
				id: UUID.UUID(),
				skillrequested: job?.skillrequested,
				skillcatagory_id: job?.skillcatagory_id,
				skillcatagory: job?.skillcatagory, // string name
				skill_id: job?.skill_id,
				skill: job?.skill, // string name
				providertype_id: job?.providertype_id,
				providertype: job?.providertype, // string name
				appointmentDatePicker: new Date(job.requestedappointmentdate),
				appointmentTimePicker: {
					hour: job?.requestedappointmenttime?.split(':')[0] ?? '',
					minutes: job?.requestedappointmenttime?.split(':')[1] ?? ''
				},
				appointmentTime: job?.appointment_type_id,
				appointmentDateType: job?.appointmentdatetype,
				amount: [
					jobExcess?.excess_amount,
					[Validators.required, Validators.minLength(2), CustomValidators.hardMaxLength(13), CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])]
				],
				who_collects: jobExcess?.who_collects_excess,
				who_collects_description: jobExcess?.who_collects_excess_desc,
				is_cancelled: false,
				assessorWaived: false,
				excess_description: jobExcess?.excess_description,
				payment_method: jobExcess?.excess_payment_method,
				payment_description: jobExcess?.excess_payment_method_desc,
				...(this.isMC
					? {
							device_details: job?.device_details
					  }
					: {})
			});
		} else {
			return this.fb.group({
				id: UUID.UUID(),
				skillrequested: [1, [Validators.required]], // signal to the server to create job
				skillcatagory_id: [null, [Validators.required]],
				skillcatagory: null, // string name
				skill_id: [null, [Validators.required]],
				skill: null, // string name
				providertype_id: [this.isMC ? 1 : null, [Validators.required]],
				providertype: this.isMC ? 'Request SP' : null, // string name
				appointmentDatePicker: null,
				appointmentTimePicker: null,
				appointmentTime: null,
				amount: [
					this.isMC ? 0 : null,
					[Validators.required, Validators.minLength(2), CustomValidators.hardMaxLength(13), CustomValidators.onlyAllowedASCII([46, ...generateRange(48, 57, 1)])]
				],
				who_collects: [this.isMC ? ' ' : null, [Validators.required]],
				who_collects_description: null,
				is_cancelled: false,
				assessorWaived: false,
				excess_description: 'Standard Excess',
				payment_method: [this.isMC ? ' ' : null, [Validators.required]],
				payment_description: null,
				...(this.isMC
					? {
							device_details: new UntypedFormArray([
								this.fb.group({
									replacement_device: this.fb.group({ name: [null], id: [null, [Validators.required]] }),
									insured_device: this.fb.group({
										serial_number: [null, [Validators.required]]
									})
								})
							])
					  }
					: {})
			});
		}
	}

	incJobCount(index: number) {
		this.jobListDynamic.push(this.createJob());
		this.dataPatched = false;
		if (this.jobList !== null) {
			if (this.jobList?.length > 1) {
				if (this.selectedClaimClass === 1 || this.selectedClaimClass === 4) {
					const name = this.selectedClaimClass === 1 ? 'Geyser' : 'Pipes';
					this.showWarningMessage('You are adding multiple job cards to a ' + name + ' Only claim. Please double check you have selected the correct claim class.');
				}
			}
		}
	}

	removeJob(i: number) {
		if (this.jobList !== null) {
			this.jobListDynamic.removeAt(i);
			this.isIaOnJob[i] = false;
			this.iaRequested = this.isIaRelated();
		}
	}

	resetJobList() {
		cleanUpSub(this.extraSub);
		if (!this.dataPatched) {
			this.toggleReason = false;
			// this.bf.bigForm.setControl('jobs', this.fb.array([this.createJob()]));
			this.clearExtraDetails();
		} else {
			this.dataPatched = false;
		}
	}

	clearExtraDetails() {
		cleanUpSub(this.extraSub);
		this.bf.bigForm.patchValue({
			firedamageextent: null,
			recomendedadjuster: null,
			_3rdpartyinsurancecompany: null,
			_3rdpartyvechilereg: null,
			_3rdpartyvechilemake: null,
			_3rdpartydrivername: null,
			_3rdpartydriverid: null,
			_3rdpartydrivercell: null,
			policeclaimnumber: null,
			upfrontrepudiationreason: null,
			upfrontrepudiation: null,
			extra_info_type: null
		});
	}
}
