import {
  Component,
  OnChanges,
  OnInit,
  Input,
  QueryList,
  ViewChildren,
  OnDestroy,
  Output,
  EventEmitter,
  Inject,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import {
  GetSPOwnJobClaimType,
  ModalService,
  getOwnJobClaimType,
} from '@flexus/core';
import {
  AppointmentTypesToExclude,
  timeRangeValidator,
} from '@flexus/utilities';
import { Subscription } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Component({
  selector: 'flx-set-appointment',
  templateUrl: './set-appointment.component.html',
  styleUrls: ['./set-appointment.component.scss'],
})
export class FLXSetAppointmentComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() appointmentTypes: { id: number; name: string }[];
  @Input() appointmentNeeded = true;
  @Input() appointmentData: any;
  @Input() mustIncludeSkillDetails = true;
  @Input() appointmentTypestoExclude: string[] = AppointmentTypesToExclude.list;
  @Input() maxDate: Date;
  @Input() minuteInterval: number;
  @Input() isDisabled = false;
  @Input() hourBuffer = 2;
  @Input() hasMinDate = true;
  @Input() datePickerFilter;
  @Input() publicHolidays: { date: string; name: string }[];
  @Output() appointmentDataChange = new EventEmitter();
  @Output() appointmentTimeChange = new EventEmitter();

  private valueChangesSubscription: Subscription;

  skillString: string;
  selectedDateType = 'Today';
  nowTimeSub: Subscription;

  private _minDate = new Date();
  nowAppointmentSub: Subscription;
  @Input()
  set minDate(value: Date) {
    if (value) {
      this._minDate = value;
    }
  }
  get minDate() {
    return this._minDate;
  }

  private _minHour = 0;
  @Input()
  set minHour(value: number) {
    if (value) {
      this._minHour = value;
    }
  }
  get minHour() {
    return this._minHour;
  }

  private _maxHour = 18;
  @Input()
  set maxHour(value: number) {
    if (value) {
      this._maxHour = value;
    }
  }
  get maxHour() {
    return this._maxHour;
  }

  private _alternateMaxHour = 16;
  @Input()
  set alternateMaxHour(value: number) {
    if (value) {
      this._alternateMaxHour = value;
    }
  }
  get alternateMaxHour() {
    return this._alternateMaxHour;
  }

  private _alternateMaxHourDays: { days: number[]; alsoForPHs: boolean };
  @Input()
  set alternateMaxHourDays(value: { days: number[]; alsoForPHs: boolean }) {
    if (value) {
      this._alternateMaxHourDays = value;
    }
  }
  get alternateMaxHourDays() {
    return this._alternateMaxHourDays;
  }

  @ViewChildren('myTimePicker') myTimePicker: QueryList<any>;
  @ViewChildren('myAppointmentType') myAppointmentType: QueryList<any>;
  @ViewChildren('myDatePicker') myDatePicker: QueryList<any>;

  displayOptions: { displayKey: string; valueKey: string };

  _show_time_picker = false;
  isCustomDate = false;
  dateInputType = 'text';
  errorMessage = '';
  disabledHours: { min?: number; max?: number };
  disabledMin: { min?: number; max?: number };

  private _subscriptions: Subscription[] = [];

  constructor(
    public modalService: ModalService,
    @Inject('environment') private environment: any,
    public _store: Store<any>
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
    if (
      changes['appointmentData'] &&
      changes['appointmentData'].currentValue instanceof UntypedFormGroup
    ) {
      this.valueChangesSubscription =
        changes['appointmentData'].currentValue.valueChanges.subscribe(
          () => {
            const { skillcatagory, skill } = changes['appointmentData'].currentValue.value;

          this.skillString =
            skillcatagory && skill
              ? `${skillcatagory} - ${skill}`
              : skill
              ? skill
              : 'Skill needed not set';
        });
    }
  }

  showWarningMessage(message: string[]) {
    this.errorMessage = message[0];

    this.modalService.openModalDirectly((inst) => {
      inst.closeButton = true;
      inst.setMessage(message);
      inst.type = 'warning';
      inst.navButtons = [{ text: 'OK', linkType: 'close', color: 'alert' }];
    });
  }

  listenToOnChangeEvents() {
    const datePickerControl = this.appointmentData?.get(
      'appointmentDatePicker'
    );
    if (!datePickerControl) {
      this.appointmentData.addControl(
        'appointmentDatePicker',
        new UntypedFormControl(null, [])
      );
    } else {
      this.setSelectedDateType(moment(datePickerControl.value));
    }
    const appointmentDatePickerValueChanges = this.appointmentData?.get('appointmentDatePicker')?.valueChanges;
    if (appointmentDatePickerValueChanges) {
      this._subscriptions.push(appointmentDatePickerValueChanges.subscribe((date) => {
        if (date) {
          this.dateChanged(date);
          this.setSelectedDateType(moment(date));
          this.myAppointmentType['_results'][0]?.open();

          const _timePickerControl = this.appointmentData?.get(
            'appointmentTimePicker'
          );
          if (_timePickerControl) {
            _timePickerControl.setValue(null);
          }
        }
      }));
    }

    const appointmentTypeControl = this.appointmentData?.get('appointmentTime');
    if (!appointmentTypeControl) {
      this.appointmentData.addControl(
        'appointmentTime',
        new UntypedFormControl(null, [])
      );
    }
    const appointmentTimeValueChanges = this.appointmentData?.get('appointmentTime')?.valueChanges;
    if (appointmentTimeValueChanges) {
      this._subscriptions.push(appointmentTimeValueChanges.subscribe(() => {
        if (this.myTimePicker['_results'][0]) {
          this.myTimePicker['_results'][0].open();
        }
      }));
    }

    const timePickerControl = this.appointmentData?.get(
      'appointmentTimePicker'
    );
    if (!timePickerControl) {
      this.appointmentData.addControl(
        'appointmentTimePicker',
        new UntypedFormControl(null, [])
      );
    } else if (timePickerControl.value && timePickerControl.value.hour) {
      this.validateDisabledMin(timePickerControl.value.hour);
    }
  }

  validateDisabledMin(hour) {
    // Test for today only
    this.disabledMin = {};
    if (this.selectedDateType === 'Today') {
      const currTime = new Date().getHours() + this.hourBuffer;
      if (currTime === parseInt(hour, 10)) {
        this.disabledMin = { max: new Date().getMinutes() };
      }
    }
  }

  setSelectedDateType(d: moment.Moment) {
    const today = moment().startOf('day');
    const dateDiff = d.diff(today, 'days');
    this.disabledHours = {};

    switch (dateDiff) {
      case 0:
        this.isCustomDate = false;
        this.selectedDateType = 'Today';
        this.disabledHours = {
          max: new Date().getHours() + this.hourBuffer - 1,
        };
        break;
      case 1:
        this.isCustomDate = false;
        this.selectedDateType = 'Tomorrow';
        break;
      default:
        this.selectedDateType = 'Custom';
        this.isCustomDate = true;
        break;
    }

    const selectedDateType = this.appointmentData?.get('appointmentDateType');

    if (!selectedDateType) {
      this.appointmentData.addControl(
        'appointmentDateType',
        new UntypedFormControl('')
      );
    }
    this.appointmentData
      ?.get('appointmentDateType')
      ?.setValue(this.selectedDateType);
  }

  timeChanged(time: any, control = 'appointmentTimePicker') {
    if (time.hour && time.minutes) {
      if (this.validateTime(time)) {
        this.appointmentTimeChange.emit({
          time,
          date: this.appointmentData?.get('appointmentDatePicker')?.value,
        });
      } else {
        // If invalid time, round to the nearest 15 min interval
        const allowedTime = moment()?.add(this.hourBuffer, 'hours');
        const remainder = 15 - (allowedTime.minute() % 15);
        const dateTime = moment(allowedTime)?.add(remainder, 'minutes');

        if (this.appointmentData) {
          this.appointmentData.get(control)?.patchValue({ hour: dateTime?.hours(), minutes: dateTime?.minutes() });
          this.appointmentTimeChange.emit({
            time: { hour: dateTime?.hours(), minutes: dateTime?.minutes() },
            date: this.appointmentData?.get('appointmentDatePicker')?.value,
          });
        }
      }
    }
  }

  dateChanged(date: any) {
    if (
      this.alternateMaxHourDays?.days?.includes(date.getDay()) ||
      (this.alternateMaxHourDays?.alsoForPHs && this.isPublicHoliday(date))
    ) {
      this.maxHour = this.alternateMaxHour;
    }
  }

  isPublicHoliday(d: any): boolean {
    const date = moment(d).format('YYYY-MM-DD');
    return this.publicHolidays.some((x) => x.date === date);
  }

  // Validate the time. If the time picker hour is changed back after min already selected it will allow it. Need to validate agaisnt it
  validateTime(time) {
    if (this.selectedDateType === 'Today') {
      const currTime = moment(); // current time
      const timeStr =
        currTime.format('MM-DD-YYYY') + ' ' + time.hour + ':' + time.minutes; // building test string
      const selectedTime = moment(timeStr); // creating time from test string
      const checkHourGap = selectedTime.subtract(this.hourBuffer, 'hours'); // take selected time minus buffer to see if the time has passed or not to see if its valid
      return currTime.isBefore(checkHourGap);
    } else {
      return true;
    }
  }
  claimtypeCheckForOwnJobAppointment() {
    const client = this.environment?.client;
    this._store.dispatch(new GetSPOwnJobClaimType());
    this._subscriptions.push(
      this._store
        .select(getOwnJobClaimType)
        .pipe(
          skipWhile((x) => !x),
          take(1),
          map((res) => res)
        )
        .subscribe((result) => {
          if (
            client === 'mul_sp' &&
            result?.claimtype === 40 &&
            result &&
            result?.isOwnJob === true
          ) {
            this.hourBuffer = 0;
          }
        })
    );
  }

  ngOnInit(): void {
    this.claimtypeCheckForOwnJobAppointment();

    this.listenToOnChangeEvents();

    this.displayOptions = {
      displayKey: 'name',
      valueKey: 'id',
    };

    this.appointmentTypes = this.appointmentTypes?.filter(
      (appointmentType) =>
        !this.appointmentTypestoExclude?.includes(
          appointmentType.name?.toLowerCase()
        )
    );

    // this.skillString = this.createSkillString();

    this.appointmentData
      ?.get('appointmentTime')
      ?.valueChanges.subscribe((type) => {
        const APPOINTMENT_TYPE_BETWEEN = 3;
        if (type === APPOINTMENT_TYPE_BETWEEN) {
          this.appointmentData.removeControl('appointmentTimePicker');
          this.appointmentData.addControl(
            'range_start',
            new UntypedFormControl(null, [Validators.required])
          );
          this.appointmentData.addControl(
            'range_end',
            new UntypedFormControl(null, [Validators.required])
          );
          this.appointmentData.addValidators(timeRangeValidator());
        } else {
          this.appointmentData.addControl(
            'appointmentTimePicker',
            new UntypedFormControl(null, [Validators.required])
          );
          this.appointmentData.removeControl('range_start');
          this.appointmentData.removeControl('range_end');
          this.appointmentData.setValidators(null);
        }
        this.appointmentData.updateValueAndValidity();
      });
  }

  ngOnDestroy(): void {
    this._subscriptions
      .filter((x) => !!x)
      .forEach((subscription) => {
        subscription.unsubscribe();
      });
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
