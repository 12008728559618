import { NodePoint_0_0_2, getSelectedItemOne, getSubmissionData } from '@flexus/core';
import { environment } from '../../../../../environments/environment';
import gql from 'graphql-tag';
import { forkJoin, of } from 'rxjs';
import { skipWhile, take, map, switchMap, filter } from 'rxjs/operators';
import { getFullItemOne, getFullItemTwo, getRelatedItemTwos, getAllInfo } from '@flexus/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { OWN_BILLING_INVOICE } from './OWN_BILLING';
import { BusinessRules } from '../../business-rules';

export const ASSESSOR_OVERVIEW: {
	[id: string]: NodePoint_0_0_2;
} = {
	JobSummary: {
		name: 'Summary',
		showTabs: true,
		serverCalls: {
			claimDetails: {
				errorMessage: 'error retrieving claim details',
				directCall: (http, store, sq) => {
					return sq
						.queryObject(
							gql`
								{
									fullItemOne {
										loan_information {
											ia_requested_reason
											claimtype
											dateofloss
										}
									}
								}
							`,
							store.select(getFullItemOne).pipe(
								skipWhile(f => !f),
								take(1),
								map(res => ({ fullItemOne: res }))
							)
						)
						.pipe(
							map((res: any) => {
								let data = res;

								if (!!res && !!res?.loan_information && !res?.claimtype && !res?.dateofloss) {
									data = res?.loan_information;
								}

								return {
									ia_appointment_reason: data?.ia_requested_reason ? data?.ia_requested_reason : 'No appointment reason has been set.',
									claim_type: data?.claimtype,
									date_of_loss: data?.dateofloss,
									recent_actions: '-'
								};
							})
						);
				}
			},
			address: {
				errorMessage: 'error retrieving address',
				directCall: (http, store, sq) => {
					return sq
						.queryObject(
							gql`
								{
									fullItemOne {
										loan_information {
											propertystreetaddress
											propertysuburb
											claimprovince
											suburbcode
											claimlatitude
											claimlongitude
											loan_information
										}
									}
								}
							`,
							store.select(getFullItemOne).pipe(
								skipWhile(f => !f),
								take(1),
								map(res => ({ fullItemOne: res }))
							)
						)
						.pipe(
							map((res: any) => {
								let data = res;

								if (!!res && !!res?.loan_information && !res?.propertystreetaddress) {
									data = res?.loan_information;
								}

								return {
									street: data?.propertystreetaddress,
									suburb: data?.propertysuburb,
									province: data?.claimprovince,
									suburb_code: data?.propertysuburb,
									latitude: data?.claimlatitude,
									longitude: data?.claimlongitude
								};
							})
						);
				}
			}
		},
		component: {
			children: [
				{
					component: 'FLXHeadingWithInstructionsComponent',
					inputs: {
						title: 'Job Summary'
					}
				},
				{
					component: 'FLXKeyValueDisplayWithInstructionsComponent',
					inputs: {
						title: 'Claim Details',
						headingConfig: { color: 'secondary', size: 'small', align: 'center' },
						keyValueList$: 'claimDetails'
					}
				},
				{
					component: 'FLXKeyValueDisplayWithInstructionsComponent',
					inputs: {
						title: 'Address',
						headingConfig: { color: 'secondary', size: 'small', align: 'center' },
						keyValueList$: 'address'
					}
				}
			]
		},
		navs: [
			{ text: 'Site Visit', nextNode: 'SetAppointment' },
			{ text: 'Edit Assessor BOQ', nextNode: 'EditAssessorBOQ' },
			{
				text: 'Edit Report',
				nextNode: 'EditReport',
				intersectData: (bf) => {
					bf.getControl('circumstance_of_loss').addValidators(Validators.required);
					bf.getControl('description_of_claim').addValidators(Validators.required);
					bf.getControl('recommendations').addValidators(Validators.required);
					bf.addControl('uploadReport', new UntypedFormControl(false));
				}
			},
			{
				text: 'Upload Report',
				nextNode: 'UploadReport',
				color: 'primary',
				intersectData: (bf) => {
					bf.addControl('uploadReport', new UntypedFormControl(true));
				}
			},
			{
				text: 'Complete Assessment',
				visible: (bf, store) => {
					return store.select(getSelectedItemOne)?.pipe(
						filter(claim => !!claim),
						map(claim => {
							const canIAComplete = BusinessRules.IACanComplete(claim?.state, claim?.jobs);
							return canIAComplete;
						})
					);
				},
				linkType: 'portal',
				color: 'alert',
				portalData: {
					type: 'modal',
					paramFunc: instance => {
						instance.type = 'warning';
						instance.setMessage(['Are you sure you want to complete your assessment?', '', 'The claim will be updated, and removed from your workflow']);
						instance.navButtons = [
							{
								text: 'Cancel',
								color: 'alert',
								linkType: 'close'
							},
							{
								text: 'Complete',
								color: 'alert',
								linkType: 'submitThenNext',
								nextNode: 'SubmissionSuccess',
								serverCalls: {
									updateClaim: {
										errorMessage: 'ERROR UPDATING CLAIM. STATE 109',
										directCall: (http, store) => {
											return store
												.select(getFullItemTwo)
												.pipe(
													skipWhile(x => !x),
													take(1)
												)
												.pipe(
													switchMap(job => {
														const iaJob = job as any;

														const data = {
															job_information: iaJob.job_information,
															job_id: iaJob.id,
															new_state: 104
														};

														return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
													})
												);
										}
									}
								}
							}
						];
					}
				}
			}
		]
	},
	ContactDirectory: {
		name: 'Contact Directory',
		showTabs: true,
		serverCalls: {
			customerDetails: {
				errorMessage: 'error retrieving customer details.',
				directCall: (http, store, sq) =>
					forkJoin([
						store.select(getFullItemOne).pipe(
							skipWhile(x => !x),
							take(1)
						),
						store.select(getFullItemTwo).pipe(
							skipWhile(x => !x),
							take(1)
						)
					]).pipe(
						take(1),
						map(([claim, job]: any[]) => ({
							'Full Name': claim?.applicant ? claim?.applicant?.first_name + ' ' + claim?.applicant?.surname : '',
							'Contact Number': claim?.applicant ? claim?.loan_information.contactnumber : '',
							'Alternative Contact Number': claim?.loan_information?.cellnumber ?? ''
						}))
					)
			},
			jobsDetails: {
				errorMessage: 'error retrieving job details',
				directCall: (http, store, sq) => {
					return forkJoin([
						sq
							.queryObject(
								gql`
									{
										relatedItemTwos
									}
								`,
								store.select(getRelatedItemTwos).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => {
										return { relatedItemTwos: res };
									})
								)
							)
							.pipe(map((queryData: any) => queryData?.relatedItemTwos)),
						store.select(getAllInfo).pipe(
							skipWhile(x => !x),
							take(1),
							map((res: any) => {
								const { states, skills, sps, supplier_type, appointment_types } = res;
								return { states, skills, sps, supplier_type, appointment_types };
							})
						)
					]).pipe(
						map(([jobInfo, allInfo]: any[]) => {
							const { token, area, claim, ping_count, job_creator, ...jobs } = jobInfo;
							const { states, skills, sps, supplier_type, appointment_types } = allInfo;

							return Object.values(jobs).map((job: any) => {
								let entity: Record<string, unknown> = {};

								// if (typeof job?.state === 'number') {
								// 	const stateDescription = states.find(state => state.id === job.state)?.description;
								// 	if (stateDescription) {
								// 		entity = { ...entity, state: stateDescription };
								// 	}
								// }

								if (typeof job?.skill === 'number') {
									const skillName = skills.find(skill => skill.id === job.skill)?.name;
									if (skillName) {
										entity = { ...entity, skill: skillName };
									}
								}

								if (typeof job?.sp === 'number') {
									const serviceProvider = sps.find(sp => sp.id === job.sp);
									if (serviceProvider) {
										entity = {
											...entity,
											assigned_sp: serviceProvider.name,
											contact_number: serviceProvider.contact_primary
										};
									}
								}

								// if (typeof job?.supplier_type === 'number') {
								// 	const supplierTypeName = supplier_type.find(st => st.id === job.supplier_type)?.name;
								// 	if (supplierTypeName) {
								// 		entity = { ...entity, supplier_type: supplierTypeName };
								// 	}
								// }

								return entity;
							});
						})
					);
				}
			}
		},
		component: {
			children: [
				{
					component: 'FLXHeadingWithInstructionsComponent',
					inputs: {
						title: 'Contact Directory'
					}
				},
				{
					component: 'FLXKeyValueDisplayWithInstructionsComponent',
					inputs: {
						title: 'Customer Details',
						headingConfig: { color: 'secondary', size: 'small', align: 'center' },
						keyValueList$: 'customerDetails'
					}
				},
				{
					component: 'FLXInternalAssessorJobsSummaryComponent',
					inputs: {
						jobs$: 'jobsDetails'
					}
				}
			]
		},
		navs: [
			{ text: 'Site Visit', nextNode: 'SetAppointment' },
			{ text: 'Edit Assessor BOQ', nextNode: 'EditAssessorBOQ' },
			{ text: 'Edit Report', nextNode: 'EditReport' },
			{ text: 'Upload Report', nextNode: 'UploadReport', color: 'primary' },
			{
				text: 'Complete Assessment',
				linkType: 'portal',
				color: 'alert',
				portalData: {
					type: 'modal',
					paramFunc: instance => {
						instance.type = 'warning';
						instance.setMessage(['Are you sure you want to complete your assessment?', '', 'The claim will be updated, and removed from your workflow']);
						instance.navButtons = [
							{
								text: 'Cancel',
								color: 'alert',
								linkType: 'close'
							},
							{
								text: 'Complete',
								color: 'alert',
								linkType: 'submitThenNext',
								nextNode: 'SubmissionSuccess',
								serverCalls: {
									updateClaim: {
										errorMessage: 'ERROR UPDATING CLAIM. STATE 109',
										directCall: (http, store) => {
											return store
												.select(getFullItemTwo)
												.pipe(
													skipWhile(x => !x),
													take(1)
												)
												.pipe(
													switchMap(job => {
														const iaJob = job as any;

														const data = {
															job_information: iaJob.job_information,
															job_id: iaJob.id,
															new_state: 104
														};

														return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
													})
												);
										}
									}
								}
							}
						];
					}
				}
			}
		]
	},
	EditAssessorBOQ: OWN_BILLING_INVOICE,
	DynamicAnchor: {
		component: 'DynamicAnchorComponent'
	},
	EditReport: {
		initFormFields: (bf, item, instance, sq) => {
			bf.addControl('circumstance_of_loss', new UntypedFormControl('', [Validators.required]));
			bf.addControl('description_of_claim', new UntypedFormControl('', [Validators.required]));
			bf.addControl('recommendations', new UntypedFormControl('', [Validators.required]));
			bf.addControl('current_state', new UntypedFormControl(97, [Validators.required]));
			bf.addControl('new_state', new UntypedFormControl(97, [Validators.required]));
			bf.addControl('purpose', new UntypedFormControl('SP Report', [Validators.required]));
			sq.queryStore(
				gql`
					{
						selectedContext {
							fullItemTwo {
								job_information {
									circumstance_of_loss
									description_of_claim
									recommendations
								}
							}
						}
					}
				`
			)
				.pipe(
					skipWhile(res => !res),
					take(1)
				)
				.subscribe(values => {
					bf.patchValues({
						circumstance_of_loss: values.circumstance_of_loss ? values.circumstance_of_loss : bf.getControl('circumstance_of_loss')?.value || '',
						description_of_claim: values.description_of_claim ? values.description_of_claim : bf.getControl('description_of_claim')?.value || '',
						recommendations: values.recommendations ? values.recommendations : bf.getControl('recommendations')?.value || ''
					});
				});
		},
		serverCalls: {
			dataFile: {
				serviceVariable: '',
				functionName: '',
				errorMessage: 'No file could be found!',
				directCall: (http, store, sq) => {
					return sq
						.queryStore(
							gql`
								{
									selectedContext {
										IAReports
									}
								}
							`
						)
						.pipe(
							skipWhile(result => !result),
							take(1),
							switchMap(result => {
								const allReports = result.IAReports;
								const spReports = [];

								if (allReports) {
									allReports.forEach(report => {
										if (report.purpose === 'SP Report' || report.purpose === 'Internal Assessor Report' || report.purpose === 'SP Invalid Work Report') {
											spReports.push({ ...report });
										}
									});

									if (spReports) {
										spReports.sort((a, b) => (a.created < b.created ? 1 : -1));

										if (spReports.length > 0) {
											return http
												.post(`${environment.api_url}v1/file_action/get_file/`, {
													file_id: spReports[0]?.id,
													return_type: 1
												})
												.pipe(
													skipWhile(report => !report),
													take(1),
													map((value: any) => {
														return value?.payload;
													})
												);
										}
									}
								}

								//Returning of empty object as when returning empty the loader isn't being removed - tried EMPTY and it does not work either.
								return of({});
							})
						);
				}
			}
		},
		component: {
			children: [
				{
					component: 'FileViewWithExtraComponent',
					inputs: {
						dataFiles$: 'dataFile'
					}
				},
				{
					component: 'FLXFlatDynamicFormComponent',
					inputs: {
						formControls: {
							0: {
								formControlName: 'circumstance_of_loss',
								inputType: 'textarea',
								rows: 3,
								label: 'What caused the loss'
							},
							1: {
								formControlName: 'description_of_claim',
								inputType: 'textarea',
								rows: 3,
								label: 'What is the damage'
							},
							2: {
								formControlName: 'recommendations',
								inputType: 'textarea',
								rows: 3,
								label: 'Recommendations'
							}
						},
						formLayout: 'stacked',
						containerWidth: '50vw'
					}
				}
			]
		},
		checkValidityForFields: ['circumstance_of_loss', 'description_of_claim', 'recommendations'],
		navs: [
			{
				text: 'Submit',
				nextNode: 'SubmissionSuccess',
				serverFirst: true,
				optIntoValidation: true,
				color: 'primary',
				serverCalls: {
					response: {
						serviceVariable: 'betService',
						errorMessage: 'Job could not be updated!!',
						directCall: (http, store, sq, bf) => {
							return forkJoin([
								sq
									.queryObject(
										gql`
											{
												selectedContext {
													fullItemTwo
												}
											}
										`,
										store
									)
									.pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
								store.select(getSubmissionData)?.pipe(
									skipWhile(x => !x),
									take(1),
									map(res => res as any[])
								)
							])?.pipe(
								map(([selected, submissiondata]: any[]) => {
									if (selected && submissiondata) {
										const job_id = selected?.fullItemTwo?.id;
										const create_sp_report = 1;
										const circumstance_of_loss = submissiondata?.job_information?.circumstance_of_loss;
										const description_of_claim = submissiondata?.job_information?.description_of_claim;
										const recommendations = submissiondata?.job_information?.recommendations;

										return {
											job_id: job_id,
											create_sp_report: create_sp_report,
											job_information: {
												...selected?.fullItemTwo?.job_information,
												circumstance_of_loss: circumstance_of_loss,
												description_of_claim: description_of_claim,
												recommendations
											},
											purpose: 'SP Report',
											...(selected?.selectedItem?.state === 89 ? { new_state: 97 } : {})
										};
									}
								}),
								switchMap(data => http.post(`${environment.api_url}v1/job_action/update_job/`, data))
							);
						}
					}
				}
			}
		]
	},
	SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
};
