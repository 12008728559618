import { UntypedFormControl } from '@angular/forms';
import {
  FetchTemplates,
  MakeBillingServerCall,
  MakeServerCall,
  NodePoint_0_0_2,
  ServerCall_0_0_2,
  SetNextNode,
  getAvailableTemplates,
  getFullItemTwo,
} from '@flexus/core';
import { EMPTY, of } from 'rxjs';
import { catchError, map, skipWhile, switchMap, take } from 'rxjs/operators';

const callOutStates = [25, 65, 66, 67, 69, 80, 94, 105, 171, 206, 207];
const invoicingStates = [26, 46, 47];

let isHomeAssistItem;
let jobState;
let jobSkill;
let usedConsignment;
let isInvoiceOnlyState;

export const templateSkillAndStateCheck: ServerCall_0_0_2 = {
  errorMessage: '',
  directCall: (http, store, sq) => {
    return store.select(getFullItemTwo).pipe(
      skipWhile((res) => !res),
      take(1),
      map((res: any) => {
        const {
          state,
          skill,
          appointment,
          claim: { claim_type },
        } = res;
        const isAfterHoursItem = appointment?.[0]?.after_hours;
        jobState = state;
        jobSkill = skill;
        usedConsignment = res?.job_information?.used_consignment;
        usedConsignment = res?.job_information?.used_consignment;

        let type = 'call_out';
        let callOutIdType: 'skill' | 'claim_type' = 'skill';

        if (claim_type === 28) {
          isHomeAssistItem = true;
          jobSkill = 28;
          callOutIdType = 'claim_type';
          if (state === 26) {
            type = 'labour';
          }
        } else if (callOutStates.includes(state)) {
          type = 'call_out';
        } else if (skill === 1 && usedConsignment?.includes('1')) {
          type = 'consignment';
        }

        isInvoiceOnlyState =
          callOutStates.includes(jobState) ||
          invoicingStates.includes(jobState);
        OWN_BILLING_INVOICE.inputs.billingTemplates.children[0].inputs.isInvoiceOnlyState =
          isInvoiceOnlyState;

        return store.dispatch(
          new FetchTemplates({
            callOutSkill: jobSkill,
            callOutIdType,
            type,
            isAfterHoursItem,
          })
        );
      })
    );
  },
};

export const billingServerCall = (
  functionName,
  responseSlice,
  errorMessage,
  dataKey,
  loaderID?,
  ignoreFalseError?
) => {
  return {
    errorMessage: '',
    directCall: (http, store) => {
      return of(
        store.dispatch(
          new MakeBillingServerCall({
            functionName,
            responseSlice,
            errorMessage,
            dataKey,
            loaderID: loaderID ?? functionName,
            ...{
              displayError: false,
              ignoreFalseError: ignoreFalseError,
            },
          })
        )
      );
    },
  } as ServerCall_0_0_2;
};

export const billingServerCalls = {
  templateSkillAndStateCheck,
  lineItems: billingServerCall(
    'getBoqItems',
    'payload.items',
    'No BOQ items could be retrieved',
    'lineItems'
  ),
  sp: billingServerCall(
    'getServiceProvider',
    'payload',
    'No SP could be retrieved',
    'sp'
  ),
  invoice: billingServerCall(
    'getJobInvoicingStatus',
    'payload',
    'No invoice could be retrieved',
    'invoice'
  ),
  getCustomTypesList: billingServerCall(
    'getCustomTypesList',
    'payload',
    'No customTypesList could be retrieved',
    'customTypesList'
  ),
  customYearsList: billingServerCall(
    'getCustomYearsList',
    'payload.Year',
    'No customYearsList could be retrieved',
    'customYearsList'
  ),
  skillCategories: billingServerCall(
    'getSkillCategories',
    'payload',
    'No skillCategories could be retrieved',
    'skillCategories'
  ),
  skillSubCategories: billingServerCall(
    'getSkillSubCategories',
    'payload',
    'No skillSubCategories could be retrieved',
    'skillSubCategories'
  ),
  unitsOfMeasurement: billingServerCall(
    'getUnitsOfMeasurement',
    'payload',
    'No unitsOfMeasurement could be retrieved',
    'unitsOfMeasurement'
  ),
  banks: billingServerCall(
    'getBanks',
    '',
    'No banks could be retrieved',
    'banks'
  ),
  boq: billingServerCall(
    'getBOQInfo',
    'payload',
    'No boq could be retrieved',
    'boq',
    null,
    true
  ),
  excessInfo: billingServerCall(
    'getExcessInfo',
    'payload',
    'No excessInfo could be retrieved',
    'excess'
  ),
  travelDetails: billingServerCall(
    'getPermittedTravelDistance',
    'payload',
    'no travel details could be retrieved',
    'travelDetails'
  ),
  excessChangeDetails: billingServerCall(
    'getExcessChangeDetails',
    'payload',
    'no excess details could be retrieved',
    'excessChangeDetails'
  ),
};

export const SELECT_CALLOUT_TEMPLATE: NodePoint_0_0_2 = {
  hideTabItem: true,
  serverCalls: {
    availableTemplates: {
      errorMessage: '',
      directCall: (http, store, sq) => {
        return store.select(getAvailableTemplates).pipe(
          skipWhile((x) => !x),
          take(1)
        );
      },
    },
  },
  component: {
    children: [
      {
        component: 'FLXSelectListComponent',
        inputs: {
          heading: 'What inspection was done?',
          options$: 'availableTemplates',
          selectOptionFunc: (instance) =>
            instance.bf.bigForm
              .get('selectedTemplate')
              .patchValue(instance.selectedOption.template),
          collapseOnSelect: false,
          maxWidth: '40%',
        },
      },
    ],
  },
  initFormFields: (bf) =>
    bf.bigForm.addControl('selectedTemplate', new UntypedFormControl('', [])),
  checkValidityForFields: ['selectedTemplate'],
  navs: [
    {
      text: 'Continue',
      optIntoValidation: false,
      nextNode: 'OWN_BILLING_INVOICE',
    },
  ],
};

export const AUTO_SELECT_TEMPLATE_DECISION: NodePoint_0_0_2 = {
  hideTabItem: true,
  nodeType: 'decision',
  errorHandler: {
    displayFormat: 'dialog',
    retryPolicy: 'manual',
    onRetryComplete: () => {
      return EMPTY;
    },
  },
  decisions: {
    checkIfMoreThanOneTemplate: (navs, store) => {
      return store.dispatch(
        new MakeServerCall({
          errorMessage: 'Could not go to next node!',
          directCall: (http, s, sq, bf, controller) => {
            return store.select(getAvailableTemplates).pipe(
              skipWhile((x) => x === null),
              take(1),
              switchMap((availableTemplates: any) => {
                bf.bigForm.addControl(
                  'selectedTemplate',
                  new UntypedFormControl(null, [])
                );

                if (
                  !isHomeAssistItem &&
                  !callOutStates.includes(jobState) &&
                  (jobSkill !== 1 ||
                    usedConsignment === undefined ||
                    !usedConsignment.includes('1'))
                ) {
                  controller.dispatch(new SetNextNode('OWN_BILLING_INVOICE'));
                  return of({});
                } else if (availableTemplates.length <= 1) {
                  bf.bigForm
                    .get('selectedTemplate')
                    .patchValue(availableTemplates?.[0]?.['template'] ?? null);
                  controller.dispatch(new SetNextNode('OWN_BILLING_INVOICE'));
                  return of({});
                } else if (availableTemplates.length > 1) {
                  controller.dispatch(
                    new SetNextNode('SELECT_CALLOUT_TEMPLATE')
                  );
                  return of();
                } else {
                  controller.dispatch(new SetNextNode('OWN_BILLING_INVOICE'));
                  return of({});
                }
              }),
              catchError(() =>
                of(controller.dispatch(new SetNextNode('OWN_BILLING_INVOICE')))
              )
            );
          },
        })
      );
    },
  },
  navs: [],
};
export const OWN_BILLING_INVOICE: NodePoint_0_0_2 = {
  hideTabItem: true,
  component: 'BillingComponent',
  checkValidityForFields: [
    'invoiceDate',
    'invoiceNumber',
    'actualLineItemsFormArray',
  ],
  inputs: {
    docType: 'quote',
    numberLabel: 'Quote',
    currentState: 85,
    newState: 27,
    showLineItemGenerator: true,
    canEditLineItems: true,
    boqLogo: 'assets/images/boq-bettersure-logo.svg',
    boqLogoAlt: 'Bettersure',
    clientName: 'Bettersure',
    billingTemplates: {
      children: [
        {
          component: 'LineItemsComponent',
          name: 'Billing Line Items',
          inputs: {
            sp$: 'sp',
            createOrUpdateCustomLineItem: (instance, data, functionName) => {
              instance.store.dispatch(
                new MakeBillingServerCall({
                  dataKey: 'lineItems',
                  responseSlice: 'payload.items',
                  functionName,
                  errorMessage: 'Could not add custom line item',
                  data,
                })
              );
            },
            boqLogo: 'assets/images/boq-bettersure-logo.svg',
            boqLogoAlt: 'Bettersure',
            clientName: 'Bettersure',
          },
        },
      ],
      layout: 'horizontal',
    },
  },
  navs: [
    {
      text: 'Generate BOQ',
      nextNode: 'SubmissionSuccess',
      location: 'right',
      linkType: 'submit',
      optIntoValidation: true,
      serverFirst: true,
      serverCalls: {
        postInvoice: {
          errorMessage:
            'An error occurred while trying to create or update BOQ!',
          serviceVariable: 'betService',
          functionName: 'createOrUpdateBOQ',
          followUpSuccessCalls: {
            response: {
              errorMessage:
                'An error occurred when generating this quote/invoice!',
              serviceVariable: 'spService',
              functionName: 'generateBOQ',
            },
          },
        },
      },
    },
  ],
};
