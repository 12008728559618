import { Flow_0_0_2 } from '@flexus/core';
import { jobInfoNode } from '../reusable/JOB_INFO_NODE';
import { voucherInfoNode } from '../reusable/VOUCHER_INFO_NODE';
import { SATPAL_INFO_NODE } from '../reusable/SATPAL_INFO_NODE';
import { setActionPanelItems } from '../../../../app-shell-features';
import { REWORK_NODE } from '../reusable/REWORK_NODE';
import { requestQANode } from '../reusable/REQUEST_QA_NODE';
import { resolvedProblemNode } from '../reusable/RESOLVED_PROBLEM_NODE';
import { GENERIC_BFTSMAPPER } from '../reusable/GENERIC_BFTSMAPPER';

export const MUL_182: Flow_0_0_2 = {
	id: '182',
	name: 'onsite_qa_done',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
	header: {
		title: 'Onsite QA Done',
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Onsite QA Done'
		},
		viewRoles: {
			0: 'Onsite QA Done'
		}
	},
	startNode: 'jobInformation',
	serverCalls: {
		files: {
			serviceVariable: 'mulService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		},
		voucher: {
			serviceVariable: 'mulService',
			functionName: 'getVoucherInfo',
			responseSlice: 'payload',
			errorMessage: 'Could not get voucher from server!'
		}
	},
	nodes: {
		voucherData: {
			...voucherInfoNode(182)
		},
		jobInformation: {
			showTabs: true,
			...jobInfoNode(182)
		},
		saptal: {
			...SATPAL_INFO_NODE
		},
		requestQA: {	
			...requestQANode
		},
		resolvedProblem: {
			...resolvedProblemNode(182)
		},

		rework: {
			...REWORK_NODE
		},
		submissionSuccess: {
			component: 'FLXSuccessTickComponent',
			navs: []
		}
	},
	bigFormToStoreMapper: {
		...GENERIC_BFTSMAPPER
	}
};
