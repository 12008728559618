import { Renderer2 } from '@angular/core';
import { Manifest_0_0_2 } from '@flexus/core';
import { ItemTwo_Permission_0_0_2 } from '@flexus/core';
import { jobStateLookupTable } from '../../models/indicator-lookup.model';
import moment from 'moment';
import { findName, humaniseDate } from '@flexus/utilities';

export const jobPermissions: ItemTwo_Permission_0_0_2 = {
  show_default_button: (
    job: any,
    claim: any,
    currentUser: any,
    renderer: Renderer2,
    domElement: HTMLElement,
    activeOrg: Manifest_0_0_2,
    allInfo,
    instance
  ) => {
    if (job) {
      const stateFlow = activeOrg.manifestItems[job.state];
      const instructions =
        stateFlow?.itemType === 'flow' && stateFlow?.instructions;
      const editStates = currentUser?.user?.edit_states;
      const userCanEdit = editStates?.includes(job.state);
      const userRole = currentUser?.user?.roles[0];
      const instructionText = userCanEdit
        ? (instructions?.editRoles && instructions?.editRoles[userRole]) ||
          (instructions?.editRoles && instructions?.editRoles[0]) ||
          ''
        : (instructions?.viewRoles && instructions?.viewRoles[userRole]) ||
          (instructions?.viewRoles && instructions?.viewRoles[0]) ||
          '';

      const instructionHolder = domElement
        .getElementsByClassName('take-action')
        .item(0);
      const span = renderer.createElement('span');
      const spanText = renderer.createText(instructionText);
      renderer.appendChild(span, spanText);
      renderer.appendChild(instructionHolder, span);
    }
  },
  get_indicator_color: (
    job: any,
    claim: any,
    currentUser: any,
    renderer: Renderer2,
    domElement: HTMLElement,
    activeOrg: Manifest_0_0_2,
    allInfo,
    instance
  ) => {
    const claimState = claim?.state;
    const jobState = job?.state;
    const editRoles = currentUser?.user?.edit_states;
    let color = 'grey';
    switch (true) {
      case claimState === 109 && jobState === 97:
        break;
      case editRoles?.includes(jobState):
        color = jobStateLookupTable[job.state];
        break;
    }

    instance.indicator.color = color;
  },
  calculate_appointment_time: (
    job,
    claim,
    user,
    renderer,
    domElement,
    activeOrg,
    allInfo,
    instance
  ) => {
    if (!allInfo) return;

    const appointmentInfoEl = domElement
      .getElementsByClassName('appointment-info')
      .item(0);
    const appointmentType = job?.appointment?.appointment_type
      ? allInfo.appointment_types?.find(
          (type) => type.id === job.appointment.appointment_type
        )?.name || ''
      : '';
    let text = 'No Appointment';

    if (job.skill === 44 && job?.state === 45) {
      text = 'No Appointment';
    } else if (job.appointment?.range_start && appointmentType) {
      text = `${humaniseDate(
        job.appointment.range_start
      )} ${appointmentType} ${moment(job.appointment.range_start).format(
        'HH:mm'
      )}`;
      if (job.appointment.range_end) {
        text += ` - ${moment(job.appointment.range_end).format('HH:mm')}`;
      }
    }

    if (
      !job.appointment?.range_start ||
      !appointmentType ||
      (job.skill === 44 && job?.state === 45)
    ) {
      appointmentInfoEl?.setAttribute(
        'style',
        'color: var(--input-placeholder);'
      );
    }

    if (appointmentInfoEl) {
      const appointmentText = renderer.createText(text);
      renderer.appendChild(appointmentInfoEl, appointmentText);
    }
  },
};
