// PLUGINS
import { ActionPanelModule } from 'apps/studio/src/app/app-shell-features/action-panel/action-panel.module';
import { AppBarModule } from 'apps/studio/src/app/app-shell-features/app-bar/app-bar.module';
import { AppFooterModule } from 'apps/studio/src/app/app-shell-features/app-footer/app-footer.module';
import { AppMenuModule } from 'apps/studio/src/app/app-shell-features/app-menu';
import { HeaderActionsModule } from 'apps/studio/src/app/app-shell-features/header-actions/header-actions.module';
// APPS
import { WorkflowAppModule } from 'apps/studio/src/app/application-modules';

export const environment: { [key: string]: any } = {
	client: 'sil',
	production: false,
	branding: {
		logo_url: '',
		byline: ''
	},
	hmr: true,
	applicationModules: [WorkflowAppModule],
	appShellFeatures: [ActionPanelModule, AppBarModule, HeaderActionsModule, AppFooterModule, AppMenuModule],
	plugins: {},
	api_url: 'https://sildev.4-sure.net:10000/api/',
	api_urls: {},
	base_url: 'http://localhost:4200',
	websocket_endpoint: 'wss://devchan.4-sure.net:10000/',
	// google_maps_api_key: 'AIzaSyCPHDJTIcnowLWBK3ABKrzv7PieCiKMWmM',
	google_maps_api_key: 'AIzaSyA28JqiZDQ8_CYVbdLKsrA-l_E0iDkW0pw',
	version: '9.0.1',
  experimentalFeatures: {
    needHelp: true,
    draftQuoteAndInvoice: true
  }
};
