/* eslint-disable @typescript-eslint/no-explicit-any */
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BigFormService, Flow_0_0_2, getAllInfo, getFullItemOne, getFullItemTwo, getSelectedItemTwo } from '@flexus/core';
import { CustomValidators } from '@flexus/utilities';
import { setActionPanelItems, CollapseActionPanel } from '../../../../app-shell-features';
import { gql } from 'graphql-tag';
import { forkJoin } from 'rxjs';
import { skipWhile, take, map, mergeMap, switchMap } from 'rxjs/operators';
import { environment } from 'apps/studio/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

export const PGG_261: Flow_0_0_2 = {
	id: '261',
	name: 'review-voucher-refund-request',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['installation-summary', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	instructions: {
		editRoles: { 0: 'Review Voucher Refund Request' },
		viewRoles: { 0: 'Refund Request Review Pending' }
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemOne).pipe(
				skipWhile((x: any) => !x),
				take(1),
				map((itemOne: any) => {
					let headingstring = 'Review Refund Request';
					if (itemOne) {
						headingstring += ` :  ${itemOne?.loan_information?.voucher_key} - ${itemOne?.applicant?.first_name}`;
					}
					return headingstring;
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		customer_details: {
			errorMessage: `Could not get important information`,
			directCall: (http: any, store: any, sq: any) => {
				return sq
					.queryObject(
						gql`
							{
								fullItemTwo {
									claim {
										applicant {
											first_name
											contact_number
										}
									}
								}
							}
						`,
						store.select(getFullItemTwo).pipe(
							skipWhile((fit: any) => !fit),
							take(1),
							map((res: any) => ({ fullItemTwo: res }))
						)
					)
					.pipe(
						map((querydata: any) => {
							const name: string = querydata?.first_name;
							const number: string = querydata?.contact_number;
							const list: any[] = [];
							const contactinfo = {
								'Customer name': `${name}`,
								'Customer Contact Number': `${number}`
							};
							list.push(contactinfo);
							return list;
						})
					);
			}
		},
		installation_summary: {
			errorMessage: 'could not get the pinggo installation summary',
			directCall: (http: any, store: any, sq: any) => {
				return forkJoin([
					sq.queryObject(
						gql`
							{
								fullItemTwo {
									claim {
										loan_information {
											voucher_key
											skill_id
											voucher_type
										}
									}
								}
							}
						`,
						store.select(getFullItemTwo).pipe(
							skipWhile((fit: any) => !fit),
							take(1),
							map((res: any) => ({ fullItemTwo: res }))
						)
					),
					store.select(getAllInfo).pipe(
						skipWhile((data: any) => !data),
						take(1),
						map((rest: any) => rest)
					)
				]).pipe(
					map(([queryData, estimates]: any) => {
						console.log('queryData ==========  !!!', queryData);
						const { estimate_installation_times }: any = estimates;
						const skillid: number = +queryData?.skill_id;
						const estimate: any = estimate_installation_times.find(el => el.skill === skillid).installation_time;
						const voucherkey: string = queryData?.voucher_key;
						const product: string = queryData?.voucher_type;

						const list: any[] = [];
						const installationinfo = {
							'Voucher Number': `${voucherkey}`,
							Product: `${product}`,
							'Estimated time to complete installation': `${estimate} hrs`
						};
						list.push(installationinfo);
						return list;
						// return { fullItemTwo: queryData };
					})
				);
			}
		},
		installer_details: {
			errorMessage: 'could not get the pinggo installer details',
			directCall: (http: any, store: any) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						skipWhile((data: any) => !data),
						take(1),
						map((result: any) => result)
					)
					.pipe(
						map((leader: any) => {
							let installername: string = '';
							let installernumber: string = '';
							if (leader?.team_leader === null) {
								installername = 'no team leader detail';
								installernumber = 'no contact number provided';
							}
							const installer_onsite: string = 'Yes';
							const installer_complete: string = 'Yes';
							const installerobj = {
								'Installer name': `${installername}`,
								'Installer Contact Number': `${installernumber}`,
								'Installer was on site': `${installer_onsite}`,
								'Installer completed installation': `${installer_complete}`
							};
							const list: any[] = [];
							list.push(installerobj);
							return list;
						})
					);
			}
		}
	},
	startNode: 'RefundQueryCalculator',
	nodes: {
		RefundQueryCalculator: {
			name: 'Refund Approval',
			showTabs: true,
			initFormFields: (bf: any) => {
				bf.addControl(
					'amounts',
					new UntypedFormGroup({
						ins_takealot_fee: new UntypedFormControl('', [CustomValidators.currency]),
						ins_installer_fee: new UntypedFormControl('', [CustomValidators.currency]),
						ins_installer_incentive: new UntypedFormControl('', [CustomValidators.currency]),
						ins_installer_additional: new UntypedFormControl('', [CustomValidators.currency]),
						ins_sure_fee: new UntypedFormControl('', []),
						cus_takealot_fee: new UntypedFormControl('', [CustomValidators.numeric]),
						cus_installer_fee: new UntypedFormControl('', [CustomValidators.currency]),
						cus_installer_incentive: new UntypedFormControl('', [CustomValidators.currency]),
						cus_installer_additional: new UntypedFormControl('', [CustomValidators.currency]),
						cus_sure_fee: new UntypedFormControl('', [])
					})
				);
				bf.addControl('new_state', new UntypedFormControl(260, [Validators.required]));
			},
			serverCalls: {
				refundVariablesFromSource: {
					errorMessage: `Couldn't get refund variables`,
					directCall: (http: any, store: any) => {
						return store
							.select(getSelectedItemTwo)
							.pipe(
								skipWhile((storeitem: any) => !storeitem),
								take(1),
								mergeMap((selected: any) => {
									const job_id = selected?.id;
									return http
										.post(`${environment.api_url}v1/job_refund_action/get_job_for_refund/`, { job_id: job_id })
										.pipe(
											skipWhile((refundObject: any) => !refundObject),
											take(1)
										)
										.pipe(map((refund_object: any) => refund_object));
								})
							)
							.pipe(
								map((values: any) => {
									if (typeof values === 'object' && values !== null) {
										const { loan_information, states, suggested_amounts } = values.payload;
									const voucheramount = loan_information?.total_value;
									const sp_amount = loan_information?.sp_value;
									const takealot_amount = loan_information?.client_value;
									const refundVariableObj = {
										voucher_amount: voucheramount,
										sp_value: sp_amount,
										client_value: takealot_amount,
										suggested_amounts: suggested_amounts,
										states: states
									};
									return { refundVariableObj };
									} else if( typeof values !== 'object' && values === null) {
										throw new Error('Expeced values to be an object');
									}
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'PinggoVoucherRefundCalculatorComponent',
						inputs: {
							refundVariables$: 'refundVariablesFromSource',
							isAuthorise: true
						}
					}
				]
			},
			navs: [
				{
					text: 'submit',
					color: 'primary',
					linkType: 'portal',
					portalData: {
						type: 'modal',
						paramFunc: (instance: any, store) => {
							instance.type = 'warning';
							instance.heading = 'Confirmation of Refund';
							instance.setMessage(['Please confirm that you would like to proceed with a refund', 'Once you confirm, this can not be undone']);
							instance.navButtons = [
								{
									text: 'back',
									color: 'default',
									linkType: 'close'
								},
								{
									text: 'confirm',
									color: 'default',
									linkType: 'submitThenNext',
									serverCalls: {
										payout: {
											erroMessage: '',
											directCall: (_http: HttpClient, _store: Store, sq, _bf: BigFormService) => {
												return store
													.select(getSelectedItemTwo)
													.pipe(
														skipWhile((fit: any) => !fit),
														take(1),
														map((res: any) => res)
													)

													.pipe(
														switchMap((result:any) => {
															const job_id = result?.id;
															const amounts_control = _bf.getControl('amounts').value;
															const request = {
																job_id: job_id,
																installer: {
																	incentive: amounts_control.ins_installer_incentive,
																	additional: amounts_control.ins_installer_additional,
																	total: amounts_control.ins_installer_fee
																},
																customer: {
																	incentive: 0,
																	additioanl: 0,
																	total: _bf.getControl('pay_refund').value
																}
															};
															console.log('	REQUEST', _bf.getControl('pay_refund').value);
															return _http.post(`${environment.api_url}v1/job_refund_action/refund_approval/`, request);
														})
													);
											}
										}
									},
									nextNode: 'SubmissionSuccess'
								}
							];
						}
					}
				}
			]
		},

		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		// new_state: 'new_state'
	}
};
