import {
  BigFormService,
  Flow_0_0_2,
  getAllInfo,
  getFullItemTwo,
  getSelectedItemTwo,
  getSubmissionData,
} from '@flexus/core';
import { setActionPanelItems } from '../../../../app-shell-features';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { findName } from '@flexus/utilities';
import gql from 'graphql-tag';
import {
  map,
  skipWhile,
  switchMap,
  take,
  mergeMap,
  pluck,
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { jobInfoNode } from '../../../multichoice/configs/reusable/JOB_INFO_NODE';

export const SIL_311: Flow_0_0_2 = {
  id: '311',
  name: 'sp_allocation_missed ',
  itemType: 'flow',
  actionPanel: (instance) =>
    setActionPanelItems(instance, ['job-card', 'notes', 'documents']),
  header: {
    title: 'SP Allocation Missed',
    controls: () => () => [],
  },
  footer: {
    type: 'node_nav',
  },
  instructions: {
    editRoles: {
      0: 'SP Allocation Missed',
    },
    viewRoles: {
      0: 'SP Allocation Missed ',
    },
  },
  serverCalls: {
    files: {
      serviceVariable: 'silService',
      functionName: 'getAllJobFiles',
      responseSlice: 'payload',
      errorMessage: 'Could not get files from server!',
    },
    voucher: {
      serviceVariable: 'silService',
      functionName: 'getVoucherInfo',
      responseSlice: 'payload',
      errorMessage: 'Could not get voucher from server!',
    },
  },
  startNode: 'jobInformation',
  nodes: {
    jobInformation: {
      showTabs: true,
      // name: 'Customer Details',
      ...jobInfoNode(311),
    },
    applicableSpListNode: {
      showTabs: true,
      name: 'SP Details',
      serverCalls: {
        suitableSPs: {
          errorMessage: 'Could not get any suitable sps',
          directCall: (_http: HttpClient, _store: Store) => {
            let sps;
            return _store.select(getSelectedItemTwo).pipe(
              skipWhile((resp: any) => !resp),
              take(1),
              mergeMap((selected: any) => {
                const job_id = selected?.id;
                return forkJoin([
                  _store.select(getAllInfo).pipe(
                    skipWhile((allinfo: any) => !allinfo),
                    pluck('sps'),
                    take(1),
                    map((x: any) => x)
                  ),
                  _http
                    .post(
                      `${environment.api_url}v1/job_action/get_suitable_sps`,
                      { job_id: job_id }
                    )
                    .pipe(
                      //get suitable sps api
                      skipWhile((resp: any) => !resp),
                      take(1),
                      map((y: any) => y?.payload)
                    ),
                ]).pipe(
                  map(([sps, suitable]: any) => {
                    const ref_sps = sps;
                    const ref_suitable = suitable;
                    let sps_with_distances: any[] = [];
                    for (let i = 0; i < ref_suitable.length; i++) {
                      for (let j = 0; j < ref_sps.length; j++) {
                        if (ref_suitable[i]?.sp === ref_sps[j]?.id) {
                          let sp_distance_obj = {
                            sp_id: ref_suitable[i]?.sp,
                            sp_name: ref_sps[j]?.name,
                            sp_contact: ref_sps[j]?.contact_primary,
                            sp_distance: ref_suitable[i]?.distance,
                          };
                          sps_with_distances.push(sp_distance_obj);
                        }
                      }
                    }
                    return sps_with_distances;
                  })
                );
              })
            );
          },
        },
      },
      component: 'FLXSpWithDistancesComponent',
      navs: [
        {
          text: 'set appointment',
          nextNode: 'SetAppointment',
          color: 'primary',
        },
      ],
    },
    SetAppointment: {
      hideTabItem: true,
      inputs: {
        minDate: new Date(),
      },
      initFormFields: (bf, item, instance, sq) => {
        sq.queryStore(
          gql`
            {
              selectedContext {
                fullItemTwo {
                  skill
                }
              }
              allInfo {
                skills
              }
            }
          `
        )
          .pipe(
            skipWhile((res) => !res || !res?.skill || !res?.skills),
            take(1)
          )
          .subscribe((values) => {
            const skillName = findName(values.skill, values.skills).name;
            bf.addControl(
              'appointmentData',
              new UntypedFormGroup({
                appointmentDatePicker: new UntypedFormControl(null, [
                  Validators.required,
                ]),
                appointmentTime: new UntypedFormControl(null, [
                  Validators.required,
                ]),
                appointmentTimePicker: new UntypedFormControl(null, [
                  Validators.required,
                ]),
                appointmentdatetype: new UntypedFormControl(null),
                skill: new UntypedFormControl(skillName),
              })
            );
          });
      },
      serverCalls: {
        appointmentTypes: {
          serviceVariable: 'service',
          functionName: 'getAppointmentTypes',
          errorMessage: 'No Appointment Types could be found!',
        },
      },
      component: 'FLXJobAppointmentComponent',
      navs: [
        {
          text: 'manually allocate sp',
          linkType: 'submit',
          color: 'primary',
          serverFirst: true,
          nextNode: 'SpReplyList',
          serverCalls: {
            response: {
              errorMessage: 'Appointment not set!',
              directCall: (http, store, sq, bf) => {
                return forkJoin([
                  store.select(getFullItemTwo).pipe(
                    skipWhile((x) => !x),
                    take(1)
                  ),
                  store.select(getSubmissionData).pipe(
                    skipWhile((x) => !x),
                    take(1)
                  ),
                ]).pipe(
                  map(([job, submit]: any) => {
                    const job_id = job?.id;
                    // const current_state = job?.state;
                    const appointment = submit.appointment;
                    const data = {
                      job_id: job_id,
                      appointment: appointment,
                    };
                    return data;
                  }),
                  switchMap((data) => {
                    return http.post(
                      `${environment.api_url}v1/job_action/update_job/`,
                      data
                    );
                  })
                );
              },
            },
          },
        },
        {
          text: 'auto-allocate sp',
          linkType: 'submit',
          color: 'primary',
          nextNode: 'EndSummary',
          serverFirst: true,
          serverCalls: {
            response: {
              errorMessage: 'Appointment not set!',
              directCall: (http, store, sq, bf) => {
                return forkJoin([
                  store.select(getFullItemTwo).pipe(
                    skipWhile((x) => !x),
                    take(1)
                  ),
                  store.select(getSubmissionData).pipe(
                    skipWhile((x) => !x),
                    take(1)
                  ),
                ]).pipe(
                  map(([job, submit]) => {
                    // debugger;
                    const job_id = job?.id;
                    const appointment = submit.appointment;
                    const data = {
                      job_id: job_id,
                      appointment: appointment,
                    };
                    return data;
                  }),
                  switchMap((data) => {
                    return http.post(
                      `${environment.api_url}v1/job_action/update_job/`,
                      data
                    );
                  })
                );
              },
              followUpSuccessCalls: {
                repingSP: {
                  errorMessage:
                    'An error occurred while repinging the Service Provider',
                  serviceVariable: 'mulService',
                  functionName: 'repingSP',
                },
              },
            },
          },
        },
      ],
    },
    SubmissionSuccess: {
      hideTabItem: true,
      component: 'FLXSuccessTickComponent',
      navs: [{ text: 'continue', nextNode: 'SpReplyList' }],
    },
    SpReplyList: {
      hideTabItem: true,
      component: 'FLXSpReplyListComponent',
      inputs: {
        assignSpStateList: [311],
        selectSPNavigateToNode: 'AllocateSPSummary',
      },
      serverCalls: {
        suitableSps: {
          serviceVariable: 'silService',
          functionName: 'getSuitableSps',
          responseSlice: 'payload',
          errorMessage: 'An error occurred getting the interested parties',
        },
        replyListData: {
          errorMessage: 'An error occurred getting the SP Data',
          serviceVariable: 'silService',
          functionName: 'getSPReplyListData',
        },
      },
    },
    AllocateSPSummary: {
      serverCalls: {
        title: {
          errorMessage: 'Could not get SP name',
          directCall: (http: any, store: any, sq: any, bf: any) => {
            console.log('sp :', bf.bigForm.get('sp_selected_item')?.value);
            return of(bf.bigForm.get('sp_selected_item')?.value?.name);
          },
        },
        // title: {
        // 	errorMessage: 'Could not get ',
        // 	directCall: (_http: any, _store: any, sq: any, _bf: BigFormService) => {
        // 		return _store.select(getSelectedItemTwo).pipe(
        // 			skipWhile((x: any) => !x),
        // 			take(1),
        // 			mergeMap((item: any) => {
        // 				const job = item;
        // 				return forkJoin([
        // 					_http.post(`${environment.api_url}v1/mc_actions/get_suitable_sps/`, { job_id: job?.id }).pipe(
        // 						skipWhile((xy: any) => !xy),
        // 						take(1),
        // 						pluck('payload')
        // 					),
        // 					_store.select(getAllInfo).pipe(
        // 						skipWhile((x: any) => !x),
        // 						take(1),
        // 						pluck('sps')
        // 					)
        // 				]).pipe(
        // 					switchMap(([esp, sps]: any) => {
        // 						console.log('vaule', _bf.bigForm.get('sp_selected_item')?.value);
        // 						let spname;
        // 						for (let i = 0; i < sps.length; i++) {
        // 							if (sps[i].id === _bf.bigForm.get('sp_selected_item')?.value) {
        // 								for (let j = 0; j < esp.length; j++) {
        // 									if (_bf.bigForm.get('sp_selected_item')?.value === esp[j].sp) {
        // 										spname = sps[i].name;
        // 									}
        // 								}
        // 							}
        // 						}
        // 						return of(spname);
        // 					})
        // 				);
        // 			})
        // 		);
        // 	}
        // },

        spkeyvalues: {
          errorMessage: 'no info',
          directCall: (
            _http: HttpClient,
            _store: Store,
            sq: any,
            _bf: BigFormService
          ) => {
            return _store.select(getSelectedItemTwo).pipe(
              skipWhile((x: any) => !x),
              take(1),
              mergeMap((item: any) => {
                const job: any = item;
                return forkJoin([
                  _store.select(getAllInfo).pipe(
                    skipWhile((aresp: any) => !aresp),
                    take(1),
                    pluck('sps')
                  ),
                  _http
                    .post(
                      `${environment.api_url}v1/job_action/get_suitable_sps/`,
                      { job_id: job?.id }
                    )
                    .pipe(
                      // get suitable sp info api
                      skipWhile((xy: any) => !xy),
                      take(1),
                      pluck('payload')
                    ),
                ]).pipe(
                  switchMap(([spinfo, sps]: any) => {
                    let sp;
                    for (let i = 0; i < sps.length; i++) {
                      if (
                        sps[i].sp === _bf.bigForm.get('sp_selected_item')?.value
                      ) {
                        for (let j = 0; j < spinfo.length; j++) {
                          if (
                            _bf.bigForm.get('sp_selected_item')?.value ===
                            spinfo[j].id
                          ) {
                            sp = spinfo[j];
                          }
                        }
                      }
                    }
                    const returnobj = {
                      'Primary Contact': sp?.contact_person,
                      'Primary Contact Number': sp?.contact_primary,
                    };
                    return [returnobj];
                  })
                );
              })
            );
          },
        },
      },
      component: {
        children: [
          {
            component: 'FLXKeyValueDisplayWithInstructionsComponent',
            inputs: {
              title: 'Details for ',
              instructions: [
                'Please double check the details of the selected service provider',
              ],
            },
          },

          {
            component: 'FLXKeyValueListComponent',
            inputs: {
              data$: 'spkeyvalues',
            },
          },
        ],
      },
      navs: [
        {
          disableOnFirstClick: true,
          text: 'allocate this sp',
          color: 'primary',
          nextNode: 'EndSummary',
          serverFirst: true,
          serverCalls: {
            appointSP: {
              errorMessage:
                'An error occurred while manually allocating a service provider',
              directCall: (http, store, sq, bf) => {
                return forkJoin([
                  store.select(getSelectedItemTwo).pipe(
                    skipWhile((x) => !x ?? !!x),
                    take(1)
                  ),
                  of(bf.bigForm.get('sp_selected_item')?.value),
                ]).pipe(
                  switchMap(([selectedjob, selectedsp]: any) => {
                    const job_id = selectedjob?.id;
                    return http.post(
                      `${environment.api_url}v1/job_action/appoint_sp/`,
                      { job_id: job_id, sp_id: selectedsp }
                    );
                  })
                );
              },
            },
          },
        },
      ],
    },
    EndSummary: {
      hideTabItem: true,
      component: 'FLXSuccessTickComponent',
      navs: [],
    },
  },
  bigFormToStoreMapper: {
    new_state: 'new_state',
    appointmentData: [
      (appointment) => {
        if (
          appointment &&
          appointment.appointmentDatePicker &&
          appointment.appointmentTimePicker
        ) {
          const date = moment(appointment.appointmentDatePicker);
          date.hour(appointment.appointmentTimePicker.hour);
          date.minutes(appointment.appointmentTimePicker.minutes);
          const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
          const appointment_formatted = {
            range_start: date_formatted,
            range_end: date_formatted,
            appointment_type: appointment.appointmentTime,
          };
          return appointment_formatted;
        }
      },
      'appointment',
    ],
  },
};
