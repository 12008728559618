export class BusinessRules {
	// public static IACanComplete(claimState: Number, jobs: any[]): boolean {
	// 	if (claimState === 95 || claimState === 109) {
	// 		let iaState = 0; // IA must be in 97
	// 		let excludeStates = 0; //no other job may be in 91,92,96

	// 		const IAJob = jobs.find(job => job?.skill === 44 && job?.state !== 45);
	// 		if (IAJob && IAJob.state === 97) {
	// 			iaState = 1;
	// 		}

	// 		jobs
	// 			.filter(job => job?.skill !== 44)
	// 			.forEach(job => {
	// 				const exists = [91, 92, 96]?.indexOf(job.state);

	// 				if (exists !== -1) {
	// 					excludeStates = 1;
	// 				}
	// 			});

	// 		return iaState === 1 && excludeStates === 0;
	// 	}

	// 	return false;
	// }

  public static IACanComplete(claimState: number, jobs: any[]): boolean {
    // Check if claim state is 97
    if (claimState !== 95 && claimState !== 109) {
      return false;
    }

    // Define the states to exclude for IA job and other jobs
    const iaExcludeStates: number[] = [91, 92, 96];
    const otherJobsExcludeStates: number[] = [
      20, 21, 22, 23, 32, 37, 84, 85, 88,
    ];

    // Find an IA job with skill 44 that is not in states 91, 92, or 96
    const iaJobInValidState: boolean = jobs.some(
      (job) => job.skill === 44 && !iaExcludeStates.includes(job.state)
    );

    // Ensure no other job is in the specified exclude states
    const noOtherJobInExcludeStates: boolean = jobs.every(
      (job) => job.skill !== 44 && !otherJobsExcludeStates.includes(job.state)
    );

    // Return true if IA job is in valid state and no other job is in exclude states
    return iaJobInValidState && noOtherJobInExcludeStates;
  }
}
