import {
  Flow_0_0_2,
  getAllInfo,
  getSelectedItemOne,
  ChangeManifestState,
  getCurrentUser,
  BigFormService,
  MakeServerCall,
  getData,
} from '@flexus/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import gql from 'graphql-tag';
import {
  take,
  map,
  tap,
  withLatestFrom,
  switchMap,
  debounceTime,
  skipWhile,
  pluck,
  filter,
} from 'rxjs/operators';
import { flatten, values } from 'ramda';
import moment from 'moment';
import { empty, of, from, Observable } from 'rxjs';
import { BET_BANK_DETAILS } from '../flows/BANK_DETAILS';
import { environment } from '../../../../../environments/environment';
import {
  transformJobsToJobCardRequest,
  checkPolicyType,
  toggleUpfrontValidation,
  checkBusinessRules,
} from './transform.functions';
import {
  addObjectProperty,
  CustomValidators,
  generateRange,
} from '@flexus/utilities';

import { Store } from '@ngrx/store';
import {
  CollapseActionPanel,
  setActionPanelItems,
} from '../../../../app-shell-features';
import { CurrencyPipe } from '@angular/common';

export const BetCreateClaim: Flow_0_0_2 = {
  id: 'create_item_one',
  name: 'new claim',
  startNode: 'PolicyLookup',
  serverCalls: {
    provinceList: {
      errorMessage: '',
      directCall: (http, store, sq) => {
        return sq
          .queryObject(
            gql`
              {
                allInfo {
                  active_regions
                }
              }
            `,
            store
          )
          .pipe(
            take(1),
            map((res) => {
              return (res as any)?.active_regions;
            })
          );
      },
    },
  },
  deactivateStateGuard: (
    guardService: any,
    comp,
    router,
    currentRoute,
    currentState,
    nextState
  ) => {
    return from(
      guardService.indexedDbService.currentItem.get('currentItem')
    ).pipe(
      withLatestFrom(guardService.canGo$),
      switchMap(([currentItem, canGo]: any) => {
        return <Observable<any>>(
          guardService.store.select(getSelectedItemOne).pipe(
            take(1),
            map((itemOne: any) => {
              if (
                !canGo &&
                currentItem &&
                currentItem.applicant &&
                currentItem.loan_information
              ) {
                if (
                  itemOne &&
                  itemOne?.tempKey &&
                  guardService.bf.bigForm.touched
                ) {
                  // Local draft has been edited
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      inst.color = 'alert';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving away from this local draft.',
                        ' Your changes will be discarded!',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Discard Changes',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Save Changes Locally',
                          color: 'primary',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            const localDraft = {
                              ...currentItem,
                              tempKey: itemOne?.tempKey,
                              state: 169,
                            };
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.claimInDraft
                                .put(localDraft)
                                .then(() => {
                                  guardService.indexedDbService.currentItem.delete(
                                    'currentItem'
                                  );
                                });
                            });
                          },
                        },
                        {
                          text: 'Cancel',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                } else if (
                  itemOne &&
                  itemOne?.tempKey &&
                  !guardService.bf.bigForm.touched
                ) {
                  // Local draft opened but not edited yet
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      inst.color = 'alert';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving away from this local draft.',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Leave',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Stay',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                } else if (
                  itemOne &&
                  !itemOne.tempKey &&
                  !guardService.bf.bigForm.touched
                ) {
                  // Any Server Draft not edited
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      inst.color = 'alert';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving away from your current view.',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Leave',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Stay',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                } else if (
                  itemOne &&
                  !itemOne.tempKey &&
                  guardService.bf.bigForm.touched
                ) {
                  // Any server draft that is edited
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      inst.color = 'alert';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving away from this draft.',
                        ' Your changes will be discarded!',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Discard Changes',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Cancel',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                } else if (!itemOne && !guardService.bf.bigForm.touched) {
                  // Creating New Claim but forms has not been touched yet
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      inst.color = 'alert';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving from your current view.',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Leave',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Stay',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                } else if (!itemOne && guardService.bf.bigForm.touched) {
                  // In Claim creation and form has been touched
                  guardService.modalService.openModalDirectly(
                    (inst, store, bf) => {
                      inst.type = 'warning';
                      inst.closeButton = 'false';
                      // inst.message = '';
                      inst.setMessage([
                        'You are moving from your current view.',
                        ' Your changes will be discarded!',
                      ]);
                      // instance.open();
                      inst.navButtons = [
                        {
                          text: 'Discard Changes',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            guardService.canGo$.next(true);
                            router.navigate([nextState.url]).then(() => {
                              inst.close();
                              setTimeout(() => {
                                guardService.resetCanGoVariable();
                              }, 2500);
                              guardService.indexedDbService.currentItem.delete(
                                'currentItem'
                              );
                            });
                          },
                        },
                        {
                          text: 'Cancel',
                          color: 'alert',
                          linkType: 'close',
                          clickHandler: (ev) => {
                            inst.close();
                          },
                        },
                      ];
                    }
                  );
                  return guardService.canGo$.asObservable();
                }
              } else {
                return true;
              }
            })
          )
        );
      })
    );
  },
  activateStateGuard: () => {
    return of(true);
  },
  onStateInit: (instance) => {
    const paramFunc = (inst, mapper) => {
      return {
        id: '1',
        icon: 'what-matters',
        command: inst.loadComponent.bind(inst, {
          component: 'FLXWhatMattersComponent',
          inputs: {
            whatmattersFormValidators: [
              Validators.required,
              Validators.minLength(10),
              CustomValidators.onlyAllowedASCII([
                ...generateRange(32, 126, 1),
                10,
              ]),
            ],
          },
        }),
      };
    };
    instance.navService.portalActions.next({ call: 'onClick', paramFunc });
  },
  onStateDestroy: (instance) => {
    instance.store.dispatch(new CollapseActionPanel());
  },
  itemType: 'flow',
  actionPanel: (instance) => setActionPanelItems(instance, ['what-matters']),
  instructions: {
    editRoles: {
      0: 'Placeholder',
    },
    viewRoles: {
      0: 'Placeholder',
    },
  },
  header: {
    title: 'New Claim',
    controls: () => () => [],
  },
  footer: {
    type: 'node_nav',
  },
  events: {
    //
    checkPolicyIsVaild: {
      // sys. denotes system events
      triggerOn: ['sys.online', 'date_of_loss', 'currentPolicyDetails'],
      triggerWhen: (online, date_of_loss, currentPolicyDetails) => {
        return (
          online &&
          !!date_of_loss &&
          date_of_loss instanceof Date &&
          !isNaN(date_of_loss.valueOf()) &&
          !!currentPolicyDetails
        );
      },
      dataMutations: (bf) => {},
      serverCalls: {
        checkPolicyIsVaild: {
          dataKey: 'policyCheckPremiumStatus',
          serviceVariable: 'betService',
          errorMessage: ' No policy status found.',
          timeoutMilliseconds: 120000,
          directCall: (http, store, sq, bf, control) =>
            http
              .post(
                `${environment.api_url}v1/claim_action/check_premium_status/`,
                {
                  policy_number:
                    bf.bigForm?.value?.currentPolicyDetails.PolicyNumber,
                  date_of_loss: moment(bf.bigForm?.value?.date_of_loss)?.format(
                    'YYYY-MM-DD'
                  ),
                  product_code:
                    bf.bigForm?.value?.currentPolicyDetails.ProductCode,
                  product_option_name:
                    bf.bigForm?.value?.currentPolicyDetails.ProductOptionName,
                }
              )
              .pipe(
                map((response: any) => {
                  const noPremiumCheck =
                    response?.payload?.PremiumStatus?.toLowerCase()?.includes(
                      'no premium for cover period'
                    );
                  const res = {
                    success:
                      response.success &&
                      response.payload.PremiumStatus &&
                      (response.payload?.PremiumStatus?.toLowerCase()?.includes(
                        'up to date'
                      ) ||
                        response.payload.PremiumStatus.toLowerCase().includes(
                          'current premium due'
                        )),
                  };

                  if (!res?.success) {
                    if (
                      response.payload.PremiumStatus?.toLowerCase()?.includes(
                        'arrears'
                      )
                    ) {
                      res['payload'] = {
                        reason:
                          'Policy is in arrears. Client will have up to 15 days from the debit date to pay the premium',
                      };
                    } else if (noPremiumCheck) {
                      res['payload'] = {
                        reason:
                          'No cover period for period. Client has not paid the premium.',
                      };
                      let upfrontControl = bf.bigForm.get('upfrontrepudiation');
                      if (!upfrontControl) {
                        bf.addControl(
                          'upfrontrepudiation',
                          new UntypedFormControl()
                        );
                        upfrontControl = bf.bigForm.get('upfrontrepudiation');
                      }
                      upfrontControl.patchValue(true);
                      upfrontControl.disable();
                    }
                  }

                  if (!bf.bigForm.get('is_valid')) {
                    bf.addControl(
                      'is_valid',
                      new UntypedFormControl(null, Validators.required)
                    );
                  }
                  bf.bigForm.patchValue({
                    is_valid: res?.success || noPremiumCheck || null,
                  });
                  return res;
                })
              ),
        },
      },
    },
    autoDontPingSPs: {
      triggerOn: ['upfrontrepudiation'],
      triggerWhen: (upfrontrep) => !!upfrontrep,
      dataMutations: (bf) => {
        if (
          bf.bigForm.get('dontpingsp') &&
          bf.bigForm.get('previous_upfrontrepudiation')
        ) {
          bf.bigForm.get('dontpingsp').patchValue(1);
          bf.bigForm.get('previous_upfrontrepudiation').patchValue(1);
          toggleUpfrontValidation(
            bf.bigForm.get('upfrontrepudiation')?.value,
            bf.bigForm
          );
        }
      },
    },
    resetUpfront: {
      triggerOn: ['upfrontrepudiation', 'previous_upfrontrepudiation'],
      triggerWhen: (upfrontrep, prev) =>
        !!upfrontrep === false && !!prev !== !!upfrontrep,
      dataMutations: (bf) => {
        if (
          bf.bigForm.get('dontpingsp') &&
          bf.bigForm.get('previous_upfrontrepudiation')
        ) {
          bf.bigForm.get('dontpingsp').patchValue(0);
          bf.bigForm.get('previous_upfrontrepudiation').patchValue(0);
          if (
            bf.bigForm.get('upfrontrepudiation') &&
            !!bf.bigForm.get('upfrontrepudiation')?.value
          ) {
            toggleUpfrontValidation(
              !!bf.bigForm.get('upfrontrepudiation')?.value,
              bf.bigForm
            );
          } else {
            checkBusinessRules(bf.bigForm);
          }
        }
      },
    },
  },
  nodes: {
    PolicyLookup: {
      name: 'Policy Lookup',
      showTabs: true,
      errorHandler: {
        retryPolicy: 'manual',
        displayFormat: 'inline',
        onRetryComplete: () => {
          return empty();
        },
      },
      initFormFields: (bf, item, inst, storeQuery, store) => {
        const upfrontControl = bf.bigForm.get('upfrontrepudiation');
        if (upfrontControl) {
          upfrontControl.patchValue(false);
          upfrontControl.enable();
        }
        bf.bigForm.addControl(
          'checked_policy_details',
          new UntypedFormControl(null, Validators.required)
        );
        bf.bigForm.addControl(
          'checked_address_confirmation',
          new UntypedFormControl(null, Validators.required)
        );
        bf.bigForm.addControl(
          'checked_appointments',
          new UntypedFormControl(null, Validators.required)
        );
        bf.bigForm.addControl(
          'checked_claim_details',
          new UntypedFormControl(null, Validators.required)
        );
        bf.bigForm.addControl('checked_excess', new UntypedFormControl(null));
        bf.bigForm.addControl('new_state', new UntypedFormControl(1));
        bf.bigForm.addControl('policy_lookup', new UntypedFormControl(''));
        bf.bigForm.addControl(
          'lookup_type',
          new UntypedFormControl('PolicyNumber')
        );
        store
          .select(getCurrentUser)
          .pipe(take(1))
          .subscribe((user) => {
            const { full_name, email_address, contact_number } = user?.user;
            bf.bigForm.addControl('handler', new UntypedFormControl(full_name));
            bf.bigForm.addControl(
              'handlercontact',
              new UntypedFormControl(contact_number)
            );
            bf.bigForm.addControl(
              'handleremail',
              new UntypedFormControl(email_address)
            );
          });
      },
      checkValidityForFields: [],
      inputs: {
        title: 'Policy Lookup',
        lookupSelectedEvent: (
          lookupDetails: any,
          bf: BigFormService,
          store: Store<any>
        ) => {
          lookupDetails = addObjectProperty(
            'UNDERWRITERCODE',
            'BET',
            lookupDetails
          );

          if (lookupDetails.MARKETSEGMENTATIONREF_KEY !== '') {
            bf.patchValues({ privatebankingflag: 1 });
          }

          if (lookupDetails) {
            let controlCode = bf.bigForm.get('product_code');
            if (!controlCode) {
              bf.bigForm.addControl('product_code', new UntypedFormControl(''));
              controlCode = bf.bigForm.get('product_code');
            }
            controlCode?.setValue(lookupDetails.ProductCode);

            let controlName = bf.bigForm.get('product_option_name');
            if (!controlName) {
              bf.bigForm.addControl(
                'product_option_name',
                new UntypedFormControl('')
              );
              controlName = bf.bigForm.get('product_option_name');
            }
            controlName?.setValue(lookupDetails.ProductOptionName);

            let controlProdName = bf.bigForm.get('product_name');
            if (!controlProdName) {
              bf.bigForm.addControl('product_name', new UntypedFormControl(''));
              controlProdName = bf.bigForm.get('product_name');
            }
            controlProdName?.setValue(lookupDetails.ProductName);
          }

          store.dispatch(
            new MakeServerCall({
              dataKey: 'selectedLookupDetails',
              serviceVariable: 'betService',
              functionName: 'policyLookupDetails',
              errorMessage: ' No policy found.',
              timeoutMilliseconds: 120000,
              responseSlice: 'payload',
              data: {
                transactionNumber:
                  bf.bigForm?.value?.currentPolicyDetails.TransactionNumber,
              },
            })
          );
        },
        isValidLookup: (store: Store<any>, lookupDetails: any) => {
          return (
            lookupDetails.PolicyStatusId === 2 ||
            lookupDetails.PolicyStatusId === 16
          );
        },
        getLookupValidStatus: (store: Store<any>, lookupDetails: any) => {
          return lookupDetails.PolicyStatusId === 2 ||
            lookupDetails.PolicyStatusId === 16
            ? 'Valid'
            : 'Invalid';
        },
        getIndicatorColor: (store: Store<any>, lookupDetails: any) => {
          switch (lookupDetails.ProductCode) {
            case 'Home_Owners_Cover' || 'BetterSure_Home_Cover':
              return 'green';
            case 'HomeVantage':
              return 'blue';
          }
        },
        lookupName: 'Policy',
        nextNode: 'PolicyDetails',
        lookupItemColumns: [
          { lookupDetailsProperty: 'AccountName' },
          { lookupDetailsProperty: 'ProductName' },
        ],
        lookupEndpoint: {
          dataKey: 'lookupDetails',
          serviceVariable: 'betService',
          functionName: 'policyLookup',
          errorMessage: ' No policy found.',
          timeoutMilliseconds: 120000,
          loaderID: 'policy_lookup',
          responseSlice: 'payload',
        },
        lookupCallOptions: {
          formControlName: 'lookup_type',
          placeholder: 'Select search type',
        },
      },
      serverCalls: {
        lookupCallOptionsList: {
          errorMessage: '',
          directCall: (http, store, sq) => {
            return sq
              .queryObject(
                gql`
                  {
                    allInfo {
                      config_options {
                        general {
                          policy_search_type
                        }
                      }
                    }
                  }
                `,
                store
              )
              .pipe(
                take(1),
                map((res) =>
                  (res as any).policy_search_type.map(
                    (policy_search_type_item) => ({
                      display: policy_search_type_item.name,
                      value: policy_search_type_item.id,
                    })
                  )
                )
              );
          },
        },
      },
      hasLoader: false,
      component: 'AccountLookupComponent',
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance, mapper) => {
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(
                  instance,
                  mapper['FLXWhatMattersComponent']
                ),
              };
            },
          },
        },
        { text: 'Continue', color: 'primary', nextNode: 'PolicyDetails' },
      ],
    },
    PolicyDetails: {
      name: 'Policy Details',
      showTabs: true,
      initFormFields: (bf, _i, _in, _sq, store) => {
        bf.bigForm.patchValue({ checked_policy_details: true });

        bf.addControl(
          'client_details',
          new UntypedFormGroup({
            surname: new UntypedFormControl('', [
              Validators.required,
              Validators.minLength(2),
              CustomValidators.hardMaxLength(140),
              CustomValidators.onlyAllowedASCII([
                32,
                39,
                44,
                45,
                46,
                ...generateRange(65, 90, 1),
                ...generateRange(96, 122, 1),
              ]),
            ]),
            id_number: new UntypedFormControl('', [
              Validators.required,
              // CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
              CustomValidators.alphaNumericWithForwardSlash,
              Validators.minLength(6),
              CustomValidators.hardMaxLength(16),
            ]),
            first_name: new UntypedFormControl('', [
              Validators.required,
              Validators.minLength(2),
              CustomValidators.hardMaxLength(140),
              CustomValidators.onlyAllowedASCII([
                32,
                39,
                44,
                45,
                46,
                ...generateRange(65, 90, 1),
                ...generateRange(96, 122, 1),
              ]),
            ]),
            policy_number: new UntypedFormControl(
              bf.bigForm?.value?.policy_lookup || '',
              [
                Validators.required,
                CustomValidators.alphaNumericWithForwardSlash,
                Validators.minLength(8),
                CustomValidators.hardMaxLength(20),
              ]
            ),
            bond_number: new UntypedFormControl(''),
          })
        );
        bf.addControl(
          'contact_details',
          new UntypedFormGroup({
            contact_number: new UntypedFormControl('', [
              Validators.required,
              CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
              CustomValidators.contact_number,
              Validators.minLength(10),
              CustomValidators.hardMaxLength(12),
            ]),
            cell_number: new UntypedFormControl('', [
              Validators.required,
              CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
              CustomValidators.numeric,
              Validators.minLength(10),
              CustomValidators.hardMaxLength(12),
            ]),
            email: new UntypedFormControl('', [
              Validators.required,
              CustomValidators.onlyAllowedASCII(generateRange(32, 126, 1)),
            ]),
            preferred_com_method: new UntypedFormControl(
              null,
              Validators.required
            ),
          })
        );
        bf.addControl(
          'insured_address',
          new UntypedFormGroup({
            complex: new UntypedFormControl(''),
            complex_unit_number: new UntypedFormControl(
              '',
              CustomValidators.hardMaxLength(64)
            ),
            complex_block: new UntypedFormControl(''),
            street_address: new UntypedFormControl('', Validators.required),
            suburb: new UntypedFormControl('', Validators.required),
            city: new UntypedFormControl(''),
            postal_code: new UntypedFormControl('', [
              Validators.required,
              CustomValidators.numeric,
              Validators.maxLength(4),
            ]),
            province: new UntypedFormControl('', [Validators.required]),
          })
        );

        store
          .select(getData)
          .pipe(
            pluck('selectedLookupDetails'),
            skipWhile(
              (selectedLookupDetails) =>
                !selectedLookupDetails || !selectedLookupDetails?.Owner
            ),
            filter(
              (selectedLookupDetails) =>
                !selectedLookupDetails?.Owner?.IdNumber ||
                bf.bigForm?.value?.client_details?.id_number !==
                  selectedLookupDetails?.Owner?.IdNumber
            ),
            take(1)
          )
          .subscribe((selectedLookupDetails) => {
            bf.bigForm.setControl(
              'client_details',
              new UntypedFormGroup({
                surname: new UntypedFormControl(
                  selectedLookupDetails.Owner.Surname,
                  [
                    Validators.required,
                    Validators.minLength(2),
                    CustomValidators.hardMaxLength(140),
                    CustomValidators.onlyAllowedASCII([
                      32,
                      39,
                      44,
                      45,
                      46,
                      ...generateRange(65, 90, 1),
                      ...generateRange(96, 122, 1),
                    ]),
                  ]
                ),
                id_number: new UntypedFormControl(
                  selectedLookupDetails.Owner.IdNumber,
                  [
                    Validators.required,
                    CustomValidators.onlyAllowedASCII(generateRange(48, 57, 1)),
                    CustomValidators.numeric,
                    Validators.minLength(13),
                    CustomValidators.hardMaxLength(13),
                  ]
                ),
                first_name: new UntypedFormControl(
                  selectedLookupDetails.Owner.Name,
                  [
                    Validators.required,
                    Validators.minLength(2),
                    CustomValidators.hardMaxLength(140),
                    CustomValidators.onlyAllowedASCII([
                      32,
                      39,
                      44,
                      45,
                      46,
                      ...generateRange(65, 90, 1),
                      ...generateRange(96, 122, 1),
                    ]),
                  ]
                ),
                policy_number: new UntypedFormControl(
                  selectedLookupDetails.TransactionNumber,
                  [
                    Validators.required,
                    CustomValidators.alphaNumericWithForwardSlash,
                    Validators.minLength(8),
                    CustomValidators.hardMaxLength(20),
                  ]
                ),
                bond_number: new UntypedFormControl(''),
              })
            );
            bf.bigForm.setControl(
              'contact_details',
              new UntypedFormGroup({
                contact_number: new UntypedFormControl(
                  selectedLookupDetails.Owner.WorkNumber,
                  Validators.required
                ),
                cell_number: new UntypedFormControl(
                  selectedLookupDetails.Owner.MobileNumber,
                  Validators.required
                ),
                email: new UntypedFormControl(
                  selectedLookupDetails.Owner.EmailAddress,
                  [Validators.required]
                ),
                preferred_com_method: new UntypedFormControl(
                  '',
                  Validators.required
                ),
              })
            );
            bf.bigForm.setControl(
              'insured_address',
              new UntypedFormGroup({
                complex: new UntypedFormControl(''),
                complex_unit_number: new UntypedFormControl(
                  '',
                  CustomValidators.hardMaxLength(64)
                ),
                complex_block: new UntypedFormControl(''),
                street_address: new UntypedFormControl(
                  selectedLookupDetails.Asset.AddressLine1,
                  Validators.required
                ),
                suburb: new UntypedFormControl(
                  selectedLookupDetails.Asset.Suburb,
                  [Validators.required]
                ),
                city: new UntypedFormControl(selectedLookupDetails.Asset.City),
                postal_code: new UntypedFormControl(
                  selectedLookupDetails.Asset?.PostalCode ?? ''
                ),
                province: new UntypedFormControl(
                  selectedLookupDetails.Asset?.Province ?? '',
                  [Validators.required]
                ),
                // postal_code: new UntypedFormControl('', [Validators.required, CustomValidators.numeric, Validators.maxLength(5)])
              })
            );
          });
      },
      inputs: {
        title: 'Policy Details',
        commsOptions: [
          { value: 'Phone', display: 'Phone' },
          { value: 'Sms', display: 'SMS' },
          { value: 'Email', display: 'Email' },
          { value: 'Other', display: 'Other' },
        ],
        sourceToFormMapper: {
          ADDITIONALEXCESS: 'additionalexcess',
          FIRSTNAME: 'client_details.first_name',
          SURNAME: 'client_details.surname',
          IDNUMBER: 'client_details.id_number',
          POLICYNUMBER: 'client_details.policy_number',
          ACCOUNTNUMBER: 'client_details.bond_number',
          HOMETELNUMBER: 'contact_details.contact_number',
          CELLNUMBER: 'contact_details.cell_number',
          EMAIL: 'contact_details.email',
          LOCALFILE: 'local_file',
          ISJOINTACCOUNT: 'is_joint_account',
          CLIENTCODE: 'clientcode',
          PREFEREDCONTACT: 'contact_details.preferred_com_method',
          ADDRESS1: 'insured_address.street_address',
          ADDRESS2: 'insured_address.suburb',
          SUBURB: 'insured_address.city',
          SUBURBCODE: 'suburb_code',
          SUMINSURED: 'sum_insured',
          // UNDERWRITERCODE: 'underwriter',
          REINSURANCEEXCESS: 'reinsuranceexcess',
          SPECIALCLAUSE: 'specialclause',
          VOLEXCESS: 'voluntaryexcess',
          MARKETSEGMENTATIONREF_KEY: 'market_segment_key',
          ITEMNUMBER: 'itemnumber',
        },
      },
      component: 'PolicyDetailsComponent',
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance, mapper) => {
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(
                  instance,
                  mapper['FLXWhatMattersComponent']
                ),
              };
            },
          },
        },
        {
          text: 'Continue',
          location: 'right',
          nextNode: 'AddressConfirmation',
          color: 'primary',
        },
      ],
    },
    AddressConfirmation: {
      name: 'Maps',
      showTabs: true,
      serverCalls: {
        provinceList: {
          errorMessage: '',
          directCall: (http, store, sq) => {
            return sq
              .queryObject(
                gql`
                  {
                    allInfo {
                      active_regions
                    }
                  }
                `,
                store
              )
              .pipe(
                take(1),
                map((res) => {
                  return (res as any)?.active_regions;
                })
              );
          },
        },
        policyAddress: {
          errorMessage: '',
          directCall: (http, store, sq) => {
            return sq
              .queryObject(
                gql`
                  {
                    selectedContext {
                      submissionData {
                        loan_information {
                          propertycomplex
                          propertycomplexunitnumber
                          propertycomplexblock
                          propertystreetaddress
                          propertysuburb
                          propertycity
                          suburbcode
                        }
                      }
                    }
                  }
                `,
                store
              )
              .pipe(
                skipWhile((f) => !f),
                take(1),
                map((result) => {
                  const res = result as any;
                  const addressArray = [];

                  if (res?.propertycomplex)
                    addressArray.push(res?.propertycomplex);
                  if (res?.propertycomplexunitnumber)
                    addressArray.push(res?.propertycomplexunitnumber);
                  if (res?.propertycomplexblock)
                    addressArray.push(res?.propertycomplexblock);
                  if (res?.propertystreetaddress)
                    addressArray.push(res?.propertystreetaddress);
                  if (res?.propertysuburb)
                    addressArray.push(res?.propertysuburb);
                  if (res?.propertycity) addressArray.push(res?.propertycity);
                  if (res?.suburbcode) addressArray.push(res?.suburbcode);

                  return addressArray?.join(', ');
                })
              );
          },
        },
      },
      initFormFields: (bf, item) => {
        bf.bigForm.patchValue({ checked_address_confirmation: true });
        let suburb_code, latitude, longitude, province, area_code;

        if (bf.bigForm.get('latitude')) {
          latitude = bf.bigForm.get('latitude')?.value;
          bf.bigForm.get('latitude')?.setValidators([Validators.required]);
        } else {
          bf.addControl(
            'latitude',
            new UntypedFormControl(null, [Validators.required])
          );
        }

        if (bf.bigForm.get('longitude')) {
          longitude = bf.bigForm.get('longitude')?.value;
          bf.bigForm.get('longitude')?.setValidators([Validators.required]);
        } else {
          bf.addControl(
            'longitude',
            new UntypedFormControl(null, [Validators.required])
          );
        }

        if (bf.bigForm.get('suburb_code')) {
          suburb_code = bf.bigForm.get('suburb_code')?.value;
        } else {
          bf.addControl('suburb_code', new UntypedFormControl(null));
        }

        if (bf.bigForm.get('province')) {
          province = bf.bigForm.get('province')?.value;
          bf.bigForm.get('province')?.setValidators([Validators.required]);
        } else {
          bf.addControl(
            'province',
            new UntypedFormControl(null, [Validators.required])
          );
        }

        if (bf.bigForm.get('area_code')) {
          area_code = bf.bigForm.get('area_code')?.value;
          bf.bigForm.get('area_code')?.setValidators([Validators.required]);
        } else {
          bf.addControl(
            'area_code',
            new UntypedFormControl(null, [Validators.required])
          );
        }

        if (!latitude || !longitude) {
          latitude = null;
          longitude = null;
        }

        bf.addControl(
          'underwriter',
          new UntypedFormControl(
            bf.bigForm.get('client_details')?.value?.policy_number
          )
        );
      },
      component: 'AddressConfirmationComponent',
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance, mapper) => {
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(
                  instance,
                  mapper['FLXWhatMattersComponent']
                ),
              };
            },
          },
        },
        { text: 'Continue', color: 'primary', nextNode: 'ClaimDetails' },
      ],
    },
    ClaimDetails: {
      name: 'Claim Details',
      showTabs: true,
      serverCalls: {
        claimDetailsInfo: {
          errorMessage: '',
          directCall: (http, store, sq) => {
            return sq
              .queryObject(
                gql`
                  {
                    allInfo {
                      claim_types {
                        id
                        name
                        description
                        skill_groups
                        priority
                      }
                      skills {
                        id
                        name
                      }
                      config_options {
                        desktop {
                          upfront_rejection_reasons
                        }
                        general {
                          ia_request_reasons
                        }
                      }
                    }
                  }
                `,
                store
              )
              .pipe(
                skipWhile((x) => !x),
                take(1)
              );
          },
        },
      },
      initFormFields: (bf, item, comp, sq) => {
        bf.bigForm.patchValue({ checked_claim_details: true });

        const patterNotZero = '[1-9][0-9]*';
        bf.addControl(
          'claim_class',
          new UntypedFormControl(null, [
            Validators.required,
            Validators.pattern(patterNotZero),
          ])
        );
        bf.addControl('selectedClaimClass', new UntypedFormControl());
        bf.addControl('claim_class_description', new UntypedFormControl(''));
        bf.addControl('jobs', new UntypedFormArray([comp.createJob()]));

        // Contextual form values
        //fire
        bf.bigForm.addControl('firedamageextent', new UntypedFormControl(null));
        bf.bigForm.addControl(
          'recomendedadjuster',
          new UntypedFormControl(null)
        );
        //impact
        bf.bigForm.addControl(
          '_3rdpartyinsurancecompany',
          new UntypedFormControl(null)
        );
        bf.bigForm.addControl(
          '_3rdpartyvechilereg',
          new UntypedFormControl(null)
        );
        bf.bigForm.addControl(
          '_3rdpartyvechilemake',
          new UntypedFormControl(null)
        );
        bf.bigForm.addControl(
          '_3rdpartydrivername',
          new UntypedFormControl(null)
        );
        bf.bigForm.addControl(
          '_3rdpartydriverid',
          new UntypedFormControl(null)
        );
        bf.bigForm.addControl(
          '_3rdpartydrivercell',
          new UntypedFormControl(null)
        );
        // Theft / damage
        bf.bigForm.addControl(
          'policeclaimnumber',
          new UntypedFormControl(null)
        );
        // Miscellaneous
        bf.bigForm.addControl(
          'upfrontrepudiationreason',
          new UntypedFormControl(null)
        );
        bf.bigForm.addControl('dontpingsp', new UntypedFormControl(0));
        bf.bigForm.addControl('upfrontrepudiation', new UntypedFormControl(0));
        bf.bigForm.addControl(
          'previous_upfrontrepudiation',
          new UntypedFormControl(0)
        );
        bf.bigForm.addControl('extra_info_type', new UntypedFormControl(null));
        // IA Reason needed
        bf.bigForm.addControl(
          'ia_request_reason',
          new UntypedFormControl(null)
        );
      },
      inputs: {
        cashInLieuComp: 'CashInLieu',
        offPanelComp: 'OffPanel',
        jobLimit: [{ lossClass: 28, limit: 1 }], // After hours only one Job
        claimActions: {
          impact: {
            triggerOn: [6],
            action: 'form',
            layout: 'three-column',
            inputs: {
              0: {
                label: 'Insurance Company',
                inputType: 'input',
                defaultValue: '',
                formControlName: '_3rdpartyinsurancecompany',
                validators: [Validators.required, Validators.minLength(4)],
              },
              1: {
                label: 'Vehicle Registration',
                inputType: 'input',
                defaultValue: '',
                formControlName: '_3rdpartyvechilereg',
                validators: [Validators.required, Validators.minLength(4)],
              },
              2: {
                label: 'Vehicle Make',
                inputType: 'input',
                defaultValue: '',
                formControlName: '_3rdpartyvechilemake',
                validators: [Validators.required, Validators.minLength(2)],
              },
              3: {
                label: 'Name',
                inputType: 'input',
                defaultValue: '',
                formControlName: '_3rdpartydrivername',
                validators: [Validators.required, Validators.minLength(2)],
              },
              4: {
                label: 'ID Number',
                inputType: 'input',
                defaultValue: '',
                formControlName: '_3rdpartydriverid',
                validators: [
                  Validators.required,
                  Validators.minLength(8),
                  CustomValidators.numeric,
                  CustomValidators.noSpaces,
                ],
              },
              5: {
                label: 'Cell Number',
                inputType: 'input',
                defaultValue: '',
                formControlName: '_3rdpartydrivercell',
                validators: [
                  Validators.required,
                  CustomValidators.cellphone,
                  CustomValidators.noSpaces,
                ],
              },
            },
          },
          fire: {
            triggerOn: [7],
            action: 'fire',
          },
          storm: {
            triggerOn: [13],
            action: 'storm',
          },
          police: {
            triggerOn: [8, 9, 16],
            action: 'form',
            layout: 'flex',
            inputs: {
              0: {
                label: 'Police Case Number:',
                inputType: 'input',
                defaultValue: '',
                width: '100%',
                formControlName: 'policeclaimnumber',
                validators: [Validators.required, Validators.minLength(4)],
              },
            },
          },
          subsidence: {
            triggerOn: [14, 15, 19],
            action: 'display-list',
            list: {
              heading: 'Exclusions:',
              subheading:
                'The following items are excluded from subsidence claims',
              points: [
                'Any digging or excavation (other than mining activities) or removal or weakening of support.',
                'Any alteration, addition or repair to the Insured Property, even if done before this Policy started.',
                'The compaction of made up ground or fill',
                'Any defective design, materials or workmanship',
                'Any changes in the volume or moisture of Active Soils',
                'Any cause that existed for over 12 months or before this Policy started',
                'Any similar cause that You already claimed for, unless You did what is needed to prevent future damage from that cause and maintained it',
                'Any foundation system, foundation, plinth wall, floor or flooring system below the level of the main living area level (for example, in any basement, entrance or garage below the main living ground floor area)',
                'Any swimming pool, tennis court, patio, terrace, driveway, path, paving, surfacing system, concealed or exposed pipe (or other * fluid conduit), boundary, garden, retaining wall, fence, post or gate.',
                'Settlement, shrinkage or expansion of the Insured Property because of active soil; and/or',
                'Consequential loss or damage of any kind whatsoever upfront rejection reason',
              ],
            },
          },
          lossAdjustWarning: {
            triggerOn: [20],
            action: 'warning',
            message:
              'It is strongly advised that you ONLY select a LOSS ADJUSTER for this claim',
          },
          miscellaneous: {
            triggerOn: [21],
            action: 'upfront-reject',
          },
        },
        fireContainedOptions: [
          { display: 'Contained to Kitchen', value: 0 },
          { display: 'Contained to Other Area', value: 1 },
          { display: 'Not Contained', value: 2 },
        ],
        providerType: [
          { value: 1, display: 'Request SP' },
          { value: 2, display: 'Cash in Lieu' },
        ],
        // Remove off panel for now
        // providerType: [{ value: 1, display: 'Request SP' }, { value: 2, display: 'Cash in Lieu' }, { value: 3, display: 'Off Panel' }],
      },
      component: 'ClaimDetailsComponent',
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance, mapper) => {
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(
                  instance,
                  mapper['FLXWhatMattersComponent']
                ),
              };
            },
          },
        },
        { text: 'Continue', color: 'primary', nextNode: 'ClaimAppointments' },
      ],
    },
    ClaimAppointments: {
      name: 'Appointments',
      initFormFields: (bf) => {
        bf.bigForm.patchValue({ checked_appointments: true });

        bf.addControl(
          'on_site_person',
          new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(2),
            CustomValidators.hardMaxLength(140),
            CustomValidators.onlyAllowedASCII([
              32,
              39,
              44,
              45,
              46,
              ...generateRange(65, 90, 1),
              ...generateRange(96, 122, 1),
            ]),
          ])
        );
        bf.addControl(
          'on_site_notes',
          new UntypedFormControl('', [
            CustomValidators.onlyAllowedASCII(generateRange(32, 126, 1)),
          ])
        );
        bf.addControl(
          'on_site_contact',
          new UntypedFormControl('', [
            CustomValidators.contact_number,
            Validators.required,
          ])
        );
        bf.addControl('timepicker', new UntypedFormControl(''));
        bf.addControl('selectedDateType', new UntypedFormControl(''));
        bf.addControl('isSameAppointment', new UntypedFormControl(''));
        bf.addControl('appointments', new UntypedFormArray([]));
      },
      showTabs: true,
      inputs: {},
      serverCalls: {
        appointmentTypes: {
          errorMessage: '',
          directCall: (http, store, sq) => {
            return sq
              .queryObject(
                gql`
                  {
                    allInfo {
                      appointment_types {
                        id
                        name
                      }
                    }
                  }
                `,
                store
              )
              .pipe(take(1), map(values), map<any, any>(flatten));
          },
        },
        appointmentData: {
          errorMessage: '',
          directCall: (http, store, sq) => {
            return sq
              .queryObject(
                gql`
                  {
                    allInfo {
                      appointment_types {
                        id
                        name
                      }
                    }
                  }
                `,
                store
              )
              .pipe(take(1), map(values), map<any, any>(flatten));
          },
        },
      },
      component: 'ClaimAppointmentsComponent',
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance, mapper) => {
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(
                  instance,
                  mapper['FLXWhatMattersComponent']
                ),
              };
            },
          },
        },
        { text: 'Continue', color: 'primary', nextNode: 'ClaimPaymentDetails' },
      ],
    },
    ClaimPaymentDetails: {
      name: 'Excess',
      initFormFields: (bf, item, inst, storeQuery, store) => {
        bf.addControl('voluntaryexcess', new UntypedFormControl(0));
        bf.addControl('reinsuranceexcess', new UntypedFormControl(0));

        store
          .select(getData)
          .pipe(pluck('selectedLookupDetails'), take(1))
          .subscribe((selectedLookupDetails) => {
            const transNumber =
              bf.bigForm?.value?.currentPolicyDetails &&
              bf.bigForm?.value?.currentPolicyDetails.TransactionNumber;
            const productName =
              bf.bigForm.get('product_name') &&
              bf.bigForm.get('product_name')?.value;

            if (
              selectedLookupDetails &&
              selectedLookupDetails.Policies &&
              transNumber &&
              productName
            ) {
              const extraPolicyDetails = selectedLookupDetails.Policies.filter(
                (policy) => policy.ProductName === productName
              );
              if (!bf.bigForm.get('additionalexcess')) {
                bf.addControl('additionalexcess', new UntypedFormControl(0));
              }
              if (!bf.bigForm.get('sum_insured')) {
                bf.addControl('sum_insured', new UntypedFormControl(0));
              }

              if (extraPolicyDetails && extraPolicyDetails[0]) {
                bf.bigForm.patchValue({
                  additionalexcess: extraPolicyDetails[0]?.BasicExcess || 0,
                  sum_insured: new CurrencyPipe('en-US').transform(
                    Number(extraPolicyDetails[0]?.InsuredAmount || 0),
                    'R',
                    true
                  ),
                });
              }
            }
          });

        bf.bigForm.patchValue({ checked_excess: true });
        bf.addControl(
          'policy_excess',
          new UntypedFormGroup({
            is_cancelled: new UntypedFormControl(false),
            excess_description: new UntypedFormControl('Additional Excess'),
            amount: new UntypedFormControl({ value: '', disabled: false }),
            who_collects: new UntypedFormControl({
              value: null,
              disabled: false,
            }),
            who_collects_description: new UntypedFormControl(''),
            payment_method: new UntypedFormControl({
              value: null,
              disabled: false,
            }),
            payment_method_description: new UntypedFormControl(''),
            assessorWaived: new UntypedFormControl(false),
          })
        );
        bf.addControl('jobs', new UntypedFormArray([]));

        const jobs = bf.bigForm?.value?.jobs;
        const geyserJobs = [1];
        jobs.forEach((job, index) => {
          if (
            (parseInt(bf.bigForm?.value?.claim_class, 10) === 28 &&
              !geyserJobs.includes(job.skill_id) &&
              !job.amount) ||
            (parseInt(bf.bigForm?.value?.claim_class, 10) !== 28 &&
              Number(job.skill_id) === 86) // waive excess for incident fee jobs
          ) {
            bf.bigForm.get(`jobs.${index}`).get('is_cancelled')?.setValue(true);
          } else {
            bf.bigForm
              .get(`jobs.${index}`)
              .get('is_cancelled')
              ?.setValue(false);
          }
        });
      },
      showTabs: true,
      inputs: {},
      serverCalls: {
        serviceProviderList: {
          errorMessage: '',
          directCall: (http, store) => {
            return store
              .select(getAllInfo)
              .pipe(map((allInfo: any) => allInfo.excess_who));
          },
        },
        paymentMethodList: {
          errorMessage: '',
          directCall: (http, store) => {
            return store
              .select(getAllInfo)
              .pipe(map((allInfo: any) => allInfo.excess_how));
          },
        },
      },
      component: 'ClaimPaymentDetailsComponent',
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance, mapper) => {
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(
                  instance,
                  mapper['FLXWhatMattersComponent']
                ),
              };
            },
          },
        },
        { text: 'Continue', color: 'primary', nextNode: 'ClaimHistory' },
      ],
    },
    ClaimHistory: {
      name: 'History',
      showTabs: true,
      serverCalls: {
        claimHistory: {
          errorMessage: 'No history could be found!',
          directCall: (http, store, sq) => {
            return sq
              .queryObject(
                gql`
                  {
                    selectedContext {
                      claimHistory
                    }
                  }
                `,
                store
              )
              .pipe(take(1), map(values), map<any, any>(flatten));
          },
        },
      },
      initFormFields: (bf, item) => {
        bf.bigForm.addControl('claim_history', new UntypedFormControl(''));
      },
      inputs: {
        title: 'Review Previous Claims History',
        // To do make a custom flag indicator for the claim history warnings
        warningFlags: [
          {
            claimType: 'Pipes',
            time: 1,
            timePeriod: 'years',
            warningText: '',
          },
          {
            claimType: null,
            time: 30,
            timePeriod: 'days',
          },
        ],
      },
      component: 'ClaimHistoryComponent',
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance, mapper) => {
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(
                  instance,
                  mapper['FLXWhatMattersComponent']
                ),
              };
            },
          },
        },
        {
          text: 'Continue',
          color: 'primary',
          nextNode: 'ClaimSubmissionSummary',
        },
      ],
    },
    ClaimSubmissionSummary: {
      name: 'Submit',
      showTabs: true,
      component: {
        children: [
          {
            component: 'FLXFlatDynamicFormComponent',
            inputs: {
              heading: 'Submit Claim To Proceed',
              formControls: {
                0: {
                  label: 'Upfront repudiation. Repudiate this claim now.',
                  inputType: 'checkbox',
                  formControlName: 'upfrontrepudiation',
                  defaultValue: false,
                },
              },
              formLayout: 'stacked',
              width: '200px',
              containerWidth: '340px',
              headingSize: 'medium',
              headingWeight: 'extra-light',
              headingType: 'creation',
            },
          },
          {
            component: 'FLXAlertMessagesComponent',
            inputs: { alertMessages$: 'alertMessages' },
          },
        ],
      },
      serverCalls: {
        alertMessages: {
          errorMessage: 'Could not retrieve the form errors',
          directCall: (http, store, sq, bf) => {
            if (!!bf.bigForm.get('jobs')) {
              const jobs = bf.bigForm?.value?.jobs;

              jobs.forEach((job, index) => {
                if (
                  job.skill === 'Loss Adjusting' ||
                  job?.skill === 'Internal Assessor'
                ) {
                  if (bf.bigForm.get(`jobs.${index}`).get('assessorWaived')) {
                    bf.bigForm
                      .get(`jobs.${index}`)
                      .get('assessorWaived')
                      ?.setValue(true);
                  }
                } else {
                  if (bf.bigForm.get(`jobs.${index}`).get('assessorWaived')) {
                    bf.bigForm
                      .get(`jobs.${index}`)
                      .get('assessorWaived')
                      ?.setValue(false);
                  }
                }
              });
            }

            if (
              bf.bigForm.get('upfrontrepudiation') &&
              !!bf.bigForm.get('upfrontrepudiation')?.value
            ) {
              toggleUpfrontValidation(
                !!bf.bigForm.get('upfrontrepudiation')?.value,
                bf.bigForm
              );
            } else {
              checkBusinessRules(bf.bigForm);
            }
            bf.bigForm?.updateValueAndValidity();
            return bf.bigForm?.valueChanges?.pipe(
              // takeUntil(of({ type: 'ROUTER_NAVIGATED' })),
              debounceTime(800),
              switchMap(() => {
                return bf.retrieveErrors$();
              })
            );
          },
        },
      },
      navs: [
        {
          text: 'What Matters',
          location: 'center',
          linkType: 'portal',
          portalData: {
            type: 'actionPanel',
            paramFunc: (instance, mapper) => {
              return {
                id: '1',
                icon: 'what-matters',
                command: instance.loadComponent.bind(
                  instance,
                  mapper['FLXWhatMattersComponent']
                ),
              };
            },
          },
        },
        {
          text: 'Save as Draft',
          nextNode: 'SubmissionSuccess',
          serverFirst: true,
          serverCalls: {
            saveAsServerDraft: {
              errorMessage: 'Could not save the draft!',
              serviceVariable: 'betService',
              functionName: 'checkNetworkAndSaveDraft',
              followUpSuccessCalls: {
                deleteCurrentEditableClaim: {
                  errorMessage: 'Could not delete the current editable claim!',
                  serviceVariable: 'betService',
                  functionName: 'deleteCurrentEditableItem',
                },
              },
            },
          },
        },
        {
          text: 'Submit',
          linkType: 'submit',
          color: 'primary',
          nextNode: 'SubmissionSuccess',
          serverFirst: true,
          serverCalls: {
            response: {
              errorMessage: 'This claim could not be created!',
              serviceVariable: 'betService',
              functionName: 'createClaim',
              followUpSuccessCalls: {
                deleteCurrentEditableClaim: {
                  errorMessage: 'Could not delete the current editable claim!',
                  serviceVariable: 'betService',
                  functionName: 'deleteCurrentEditableItem',
                },
                clearCachedClaims: {
                  errorMessage: 'Could not clear cached claims!',
                  directCall: (http, store, sq, bf, controller, modal) => {
                    if (!!bf.bigForm?.value?.is_cat === true) {
                      modal.openModalDirectly((inst) => {
                        inst.heading = 'Appointment will be ignored';
                        inst.type = 'info';
                        inst.subheading =
                          'This claim is part of a catastrophe.';
                        inst.message =
                          'Notify customer that appointment times cannot be met based on the volume of claims.';
                        inst.navButtons = [
                          {
                            text: 'Continue',
                            color: 'primary',
                            linkType: 'close',
                          },
                        ];
                        inst.closeButton = false;
                      });
                    }
                    bf.bigForm.reset();
                    Object.keys(bf.bigForm.controls)?.forEach((control) =>
                      bf.bigForm.removeControl(control)
                    );
                    controller.dispatch(
                      new ChangeManifestState((state) => {
                        return {
                          ...state,
                          viewData: {
                            ...state.viewData,
                            default: [],
                          },
                        };
                      })
                    );
                    return empty();
                  },
                },
              },
              followUpFailCalls: {
                createLocalDraft: {
                  errorMessage: 'Could not create the claim in local draft',
                  serviceVariable: 'betService',
                  functionName: 'saveAsLocalDraft',
                  followUpSuccessCalls: {
                    deleteCurrentEditableClaim: {
                      errorMessage:
                        'Could not delete the current editable claim!',
                      serviceVariable: 'betService',
                      functionName: 'deleteCurrentEditableItem',
                    },
                    navigateToWorkflow: {
                      errorMessage: '',
                      serviceVariable: 'betService',
                      functionName: 'navigateToWorkflow',
                    },
                  },
                },
              },
            },
          },
        },
      ],
    },
    SubmissionSuccess: {
      component: 'FLXSuccessTickComponent',
      serverCalls: {
        copyText: {
          errorMessage: 'Could not retrieve new claim number!',
          directCall: (http, store, sq, bf) => {
            return sq
              .queryObject(
                gql`
                  {
                    selectedContext {
                      response {
                        payload {
                          mid
                        }
                      }
                    }
                  }
                `,
                store
              )
              .pipe(
                take(1),
                map(values),
                map<any, any>(flatten),
                switchMap((val: any) => {
                  if (!!val) return val;
                  else return null;
                }),
                tap(() => {
                  store.dispatch(new CollapseActionPanel());
                })
              );
          },
        },
      },
      inputs: {
        copyTextHeading: 'Claim Number',
        autoClose: false,
      },
      navs: [],
    },
    CashInLieu: {
      ...BET_BANK_DETAILS,
      initFormFields: (bf, item, comp, sq, store) => {
        store
          .select(getData)
          .pipe(
            pluck('selectedLookupDetails'),
            skipWhile((selectedLookupDetails) => !selectedLookupDetails),
            take(1),
            switchMap((selectedLookupDetails) =>
              store.select(getAllInfo).pipe(
                pluck('account_type'),
                skipWhile((account_type) => !account_type),
                take(1),
                map((account_type) => ({ selectedLookupDetails, account_type }))
              )
            )
          )
          .subscribe((obj) => {
            const selectedLookupDetails = obj.selectedLookupDetails;
            if (
              selectedLookupDetails.Owner &&
              selectedLookupDetails.BankingDetail
            ) {
              const acc_type = obj.account_type.filter((acc) =>
                acc.description.includes(
                  selectedLookupDetails.BankingDetail.AccountType
                )
              )[0];
              comp.bankDetailsForm = new UntypedFormGroup({
                initials: new UntypedFormControl(
                  selectedLookupDetails.Owner.Initial || '',
                  Validators.required
                ),
                account_holder_name: new UntypedFormControl(
                  selectedLookupDetails.Owner.Surname || '',
                  Validators.required
                ),
                identification_number: new UntypedFormControl(
                  selectedLookupDetails.Owner.IdNumber || '',
                  Validators.required
                ),
                account_number: new UntypedFormControl(
                  selectedLookupDetails.BankingDetail.AccountNumber || '',
                  Validators.required
                ),
                acc_type: new UntypedFormControl(
                  (acc_type && acc_type.mid) || '',
                  Validators.required
                ),
                bank_name: new UntypedFormControl(
                  selectedLookupDetails.BankingDetail.BankName || '',
                  Validators.required
                ),
                branch: new UntypedFormControl(
                  selectedLookupDetails.BankingDetail.Branch || ''
                ),
                branch_code: new UntypedFormControl(
                  selectedLookupDetails.BankingDetail.BranchCode,
                  Validators.required
                ),
              });
            }
            bf.bigForm.addControl('avs_data', comp.bankDetailsForm);
          });
      },
      inputs: {
        ...BET_BANK_DETAILS.inputs,
      },
      navs: [
        ...BET_BANK_DETAILS.navs,
        {
          text: 'Bank details confirmed',
          location: 'right',
          color: 'secondary',
          nextNode: 'ClaimDetails',
        },
      ],
    },
  },
  bigFormToStoreMapper: {
    new_state: [
      (state, storeObj, formValue, bf) => {
        if (bf.bigForm?.value?.upfrontrepudiation) {
          return 19;
        } else {
          return state;
        }
      },
      'new_state',
    ],
    // General
    handler: 'loan_information.handler',
    handlercontact: 'loan_information.handlercontact',
    handleremail: 'loan_information.handleremail',
    currentPolicyDetails: 'loan_information.policydetails',

    product_code: 'loan_information.product_code',
    product_option_name: 'loan_information.product_option_name',

    // Policy Details
    'client_details.first_name': ['applicant.first_name', 'name'],
    'client_details.surname': ['applicant.surname', 'surname'],
    'client_details.id_number': ['applicant.id_number'],
    'contact_details.contact_number': ['loan_information.contactnumber'],
    'client_details.policy_number': [
      'policy_id',
      'loan_information.policy_number',
      'applicant.claimantpoliceynum',
    ],
    'client_details.bond_number': ['applicant.bondnumber'],
    market_segment_key: [
      'market_segment',
      'loan_information.market_segment_key',
    ],
    // local_file: 'applicant.local_file',
    'contact_details.cell_number': ['loan_information.cellnumber'],
    'contact_details.email': ['loan_information.email'],
    'contact_details.preferred_com_method':
      'loan_information.preferedcommethod',
    is_joint_account: ['loan_information.isjointaccount'],
    clientcode: ['loan_information.clientcode'],
    reinsuranceexcess: ['loan_information.reinsuranceexcess'],
    specialclause: ['loan_information.specialclause'],
    voluntaryexcess: ['loan_information.voluntaryexcess'],
    additionalexcess: ['loan_information.additionalexcess'],
    underwriter: [
      (val) => checkPolicyType(val),
      'loan_information.underwriter',
    ],
    sum_insured: 'loan_information.suminsured',

    // Address confirmation
    latitude: ['location.lat', 'loan_information.claimlatitude'],
    longitude: ['location.long', 'loan_information.claimlongitude'],
    suburb_code: ['postal_code', 'loan_information.suburbcode'],
    province: ['claimprovince', 'loan_information.claimprovince'],
    'insured_address.complex': ['loan_information.propertycomplex'],
    'insured_address.complex_unit_number': [
      'loan_information.propertycomplexunitnumber',
    ],
    'insured_address.complex_block': ['loan_information.propertycomplexblock'],
    'insured_address.street_address': [
      'address',
      'loan_information.propertystreetaddress',
    ],
    'insured_address.suburb': ['suburb', 'loan_information.propertysuburb'],
    'insured_address.city': ['loan_information.propertycity'],
    'insured_address.postal_code': [
      'loan_information.postal_code_input',
      'loan_information.postal_code',
      'loan_information.postal_code',
    ],
    addressconfirmation: 'loan_information.addressconfirmation',
    area_code: ['area', 'loan_information.areacode'],

    // Claim details
    claim_class: ['claim_type_id', 'loan_information.claimtype_id'],
    claim_class_description: 'loan_information.claimtype',
    jobs: [
      [transformJobsToJobCardRequest, 'jobcardrequest'],
      [transformJobsToJobCardRequest, 'loan_information.jobcardrequest'],
    ],

    // Claim Appointment
    on_site_contact: 'loan_information.onsitecontact',
    on_site_notes: 'loan_information.onsitenotes',
    on_site_person: 'loan_information.onsiteperson',

    // Other
    upfrontrepudiation: ['loan_information.upfrontrepudiation'],
    upfrontrepudiationreason: ['loan_information.upfrontrepudiationreason'],
    dontpingsp: 'loan_information.dontpingsp',

    additional_excess: 'loan_information.additionalexcess',

    // What Matters Action Panel
    date_of_loss: [
      (date) => moment(date)?.format('YYYY-MM-DD'),
      'loan_information.dateofloss',
    ],
    whatmatters: 'loan_information.whatmatters',
    claimdescription: [
      (claimdescription, storeObj, formValues) => {
        const handler = storeObj?.identity?.currentUser?.user?.full_name;
        return (
          'Date: ' +
          moment()?.format('YYYY-MM-DD') +
          ' \nStaff: ' +
          (handler || '') +
          ' \n' +
          formValues.whatmatters +
          '.'
        );
      },
      'loan_information.claimdescription',
    ],

    // Claim Details context actions
    _3rdpartyinsurancecompany: [
      (val, storeObj, formValue) => {
        return formValue.claim_class === 6 ? val : null;
      },
      'loan_information._3rdpartyinsurancecompany',
    ],
    _3rdpartyvechilereg: [
      (val, storeObj, formValue) => {
        return formValue.claim_class === 6 ? val : null;
      },
      'loan_information._3rdpartyvechilereg',
    ],
    _3rdpartyvechilemake: [
      (val, storeObj, formValue) => {
        return formValue.claim_class === 6 ? val : null;
      },
      'loan_information._3rdpartyvechilemake',
    ],
    _3rdpartydriverid: [
      (val, storeObj, formValue) => {
        return formValue.claim_class === 6 ? val : null;
      },
      'loan_information._3rdpartydriverid',
    ],
    _3rdpartydrivercell: [
      (val, storeObj, formValue) => {
        return formValue.claim_class === 6 ? val : null;
      },
      'loan_information._3rdpartydrivercell',
    ],
    _3rdpartydrivername: [
      (val, storeObj, formValue) => {
        return formValue.claim_class === 6 ? val : null;
      },
      'loan_information._3rdpartydrivername',
    ],
    firedamageextent: [
      (val, storeObj, formValue) => {
        return formValue.claim_class === 7 ? val : null;
      },
      'loan_information.firedamageextent',
    ],
    recomendedadjuster: [
      (val, storeObj, formValue) => {
        return formValue.claim_class === 7 ? val : null;
      },
      'loan_information.recomendedadjuster',
    ],
    policeclaimnumber: [
      (val, storeObj, formValue) => {
        return [8, 9, 6]?.includes(formValue.claim_class) ? val : null;
      },
      'loan_information.policeclaimnumber',
    ],
    ia_request_reason: ['loan_information.ia_requested_reason'],

    // Catastrophe Check
    is_cat: 'is_cat',
    cat_checked: ['loan_information.cat_checked'],
    cat_code: ['cat_code', 'loan_information.cat_ID'],
  },
  flowErrorMessages: {
    'checked_policy_details:required': 'Please fill in the Policy Details',
    'checked_address_confirmation:required':
      'Please confirm the address on the Map.',
    'checked_claim_details:required': 'Please fill in the Claim Details.',
    'checked_appointments:required': 'Please set an Appointment.',
    'checked_excess:required': 'Please fill in the Excess.',
    'amount:required': 'Excess amount is required',
    'amount:onlyAllowedASCII': 'Please enter a Valid Excess Amount',
    'who_collects:required': 'Specify who collects excess',
    'payment_method:required': 'Specify payment method',
    'clientcode:required': 'Please search for a Valid Policy (Policy Lookup)',
    'currentPolicyDetails:required':
      'Please search for a Valid Policy (Policy Lookup)',
    'claim_class:required': 'Claim class is required (Claim Details)',
    'claim_class:pattern': 'Claim class is required (Claim Details)',
    'jobs:required': 'At least one job card request is needed (Claim Details)',
    'appointmentDatePicker:flxDatepickerMin':
      'Job appointment date has to be from today',
    'street_address:required':
      'Property street address is required (in Policy Details)',
    'suburb:required': 'Property suburb is required (in Policy Details)',
    'postal_code:required': 'Postal code is required (in Policy Details)',
    'email:required': 'Email address is required (in Policy Details)',
    'email:email':
      'You need to enter a valid email address (in Policy Details)',
    'cell_number:cellphone': 'Cell number is invalid (in Policy Details)',
    'cell_number:required': 'Cell number is required (in Policy Details)',
    'contact_number:contact_number':
      'Contact number is invalid (in Policy Details)',
    'contact_number:required': 'Contact number is required (in Policy Details)',
    'preferred_com_method:required':
      'Communication method is required (in Policy Details)',
    'policy_number:required': 'Policy number is required (in Policy Details)',
    'addressconfirmation:required': 'Please confirm property address',
    'longitude:required': 'No address longitude has been picked up (in Maps)',
    'latitude:required': 'No address latitude has been picked up (in Maps)',
    'date_of_loss:required':
      'No date of loss has been selected (on your right Action Panel)',
    'whatmatters:required':
      'What Matters has not been completed (on your right Action Panel)',
    'first_name:required':
      'Applicant first name is required (in Policy Details)',
    'surname:required': 'Applicant surname is required (in Policy Details)',
    'id_number:required': 'Applicant ID number is required (in Policy Details)',
    'first_name:text':
      'Applicant first name has additional characters (in Policy Details)',
    'appointmentDatePicker:required':
      'Job appointment date is required (in Appointments)',
    'appointmentTimePicker:required':
      'Job appointment time is required (in Appointments)',
    'on_site_contact:required':
      'On site contact number required (in Appointments)',
    'on_site_person:required':
      'On site person name is required (in Appointments)',
    'is_valid:required':
      'The policy is in arrears (Client will have up to 15 days from debit date to pay the premium amount)',
    'skillcatagory_id:required': 'Skill group is required (Claim Details)',
    'skill_id:required': 'Skill is required (Claim Details)',
    'providertype_id:required': 'Provider type is required (Claim Details)',
  },
};
