import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Observable, map, of } from 'rxjs';
import { MulService } from 'apps/studio/src/app/organisations/multichoice/services/mul.service';
import { getCurrentUser } from '@flexus/core';
import { Store } from '@ngrx/store';

@Component({
	selector: 'flx-agent-mini-dashboard',
	templateUrl: './agent-mini-dashboard.component.html',
	styleUrls: ['./agent-mini-dashboard.component.scss']
})
export class AgentMiniDashboardComponent implements OnInit {
	@ViewChild(BaseChartDirective) chart?: BaseChartDirective;
	colors = ['#29a845', '#a33c02', 'blue', 'navy', 'orange', 'purple', 'red', 'silver', 'teal', 'white', 'yellow'];

	public barChartOptions: ChartConfiguration['options'] = {
		responsive: true,
		scales: {
			x: {
				stacked: true, // Enable stacking for x-axis
				grid: {
					display: false // Hide vertical grid lines for x-axis
				},
				ticks: {
					color: '#bababa', // Set the font color of labels
					minRotation: 45 // Rotate labels by 45 degrees (diagonally)
				}
			},
			y: {
				min: 0,
				stacked: true, // Enable stacking for y-axis
				ticks: {
					display: false // Hide y-axis ticks
				}
			}
		},
		plugins: {
			legend: {
				display: false
			}
		}
	};
	public barChartType: ChartType = 'bar';

	public barChartData: ChartData<'bar'> = {
		labels: [],
		datasets: []
	};

	state_number!: string;
	agent_self_performance: Observable<any> = of(null);

	states: number[] = [];
	agent_matrices = { agent_names: [], states: [], performance_grid: [] };
	user$: Observable<any> = of(null);
	showGraph: boolean = false;
	constructor(private mulService: MulService, private cdr: ChangeDetectorRef, private _store: Store<any>) { }

	ngOnInit(): void {
		this.user$ = this._store.select(getCurrentUser);
		this.fetchChartData();
		this.fetchMyPerformanceCounts();
		this.fetchPerformanceMatrix();
	}

	fetchChartData(): void {
		this.mulService.fetchWorkflowStatusStats().pipe(map(data => {
			const dataWithTransformedLabels = data.map(entry => {
				return ({ counts: entry?.counts, label: this.transformLabel(entry?.label) })
			});

			return dataWithTransformedLabels;
		}))
			.subscribe((graph_data: any) => {
				if (!graph_data?.length) return;
				this.showGraph = true;
				const countsArrayOfLengths = graph_data.map(item => item?.counts?.length ?? 0); // Get number of each entry's state count
				const longestArrLength: number = Math.max(...countsArrayOfLengths); // used to make the data length the same for every stack dynamically
				const tempData: any = [];
				this.barChartData['labels'] = graph_data?.map(item => item.label);

				// Create Dynamic Stacks Objects Based On The Longest Array Length
				for (let i = 0; i < longestArrLength; i++) {
					const arrayFromLength: any = Array(longestArrLength).fill({ ...graph_data[i] }, 0, longestArrLength);
					const colors = [...this.colors].slice(i);
					tempData.push(arrayFromLength);
					this.barChartData['datasets'].push({
						data: [],
						label: '',
						backgroundColor: [...colors],
						barPercentage: 0.2
					});
				}
				this.setChartDataView(graph_data, tempData);
			})
	}

	setChartDataView(graph_data, tempData): void {
		graph_data.forEach((item, i) => {
			item?.counts?.forEach((obj, j) => {
				tempData[j][i] = obj['value'];
				this.barChartData['datasets'][j]['data'] = [...tempData[j]];
				this.barChartData['datasets'][j]['label'] = `Job Count In State`;
			});
		});
		this.cdr.detectChanges();
	}

	fetchMyPerformanceCounts(): void {
		this.agent_self_performance = this.mulService.fetchMyPerformanceStats() ?? of(null);
	}

	fetchPerformanceMatrix(): void {
		this.mulService.fetchAgentPerformanceStats().subscribe((average_closure_time_per_state) => {
			if (!average_closure_time_per_state?.length) return;
			this.states = this.setPerformanceMatricesStates(average_closure_time_per_state);

			// Create dynamic array using record with highest quantity of states
			let performance_grid = Array(average_closure_time_per_state?.length).fill([], 0, average_closure_time_per_state?.length);

			// Map each state to own agent
			average_closure_time_per_state.forEach((item: any, indx) => {
				this.states.forEach(state => {
					let average_closure_time = item?.states?.find(item => item?.state === state)?.value;
					average_closure_time = this.convertHoursToHrsAndMin(average_closure_time);
					performance_grid[indx] = [...performance_grid[indx], { [state]: average_closure_time }];
					performance_grid[indx] = performance_grid[indx]?.sort((item1, item2) => item1 - item2);
				});
			});

			// Create Agent Matrices Object
			this.agent_matrices = {
				agent_names: average_closure_time_per_state.map(({ agent_name }) => agent_name ?? '--'),
				states: this.states,
				performance_grid: performance_grid
			};
			this.cdr.detectChanges();
		});

	}

	setPerformanceMatricesStates(data: any[]): number[] {
		let states = [];

		// Loop over the objects and pluck the state
		data?.forEach((item: any) => item?.states.forEach(obj => obj.state && (states = [...states, obj.state])));

		// Remove duplicates from the states array
		const set = new Set([...states]);
		return Array.from(set)?.sort((a, b) => a - b);
	}

	transformLabel(label: string): string {
		const labelSubstrings = label.split(' ');
		if (!labelSubstrings.length) return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
		return labelSubstrings.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
	}

	convertHoursToHrsAndMin(hours): string {
		if (isNaN(hours) || hours < 0) {
			return '--';
		}

		const totalMinutes = hours * 60;
		const hrs = Math.floor(totalMinutes / 60);
		const min = Math.floor(totalMinutes % 60);

		const hrsString = hrs > 0 ? `${hrs}hrs` : '0hr';
		const minString = min > 0 ? `${min}m` : '0m';

		return `${hrsString} ${minString}`;
	}
}
